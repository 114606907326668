import React from "react"
import { ICNoResults, ICSearch } from "../../icons/index";
import { HRButton } from "../../components/button"
import { Typography } from 'antd';

const { Title } = Typography;

type LibraryNoDataProps = {
    onSearch: () => void;
}
export const LibraryNoData: React.FC<LibraryNoDataProps> = ({
    onSearch
}) => {
   
    return (
        <div className="container mx-auto flex justify-between">
            <div className="grid grid-cols-[1fr] gap-[32px] justify-items-center items-center w-full">
                <ICNoResults />
                <Title level={3} style={{
                        color: '#110C22',
                        fontWeight: 700,
                        marginBottom: 0
                }}>Không có kết quả nào phù hợp</Title>
                <div>
                    <HRButton onClick={onSearch} btnType="outline" btnSize="md">
                        <div className="flex gap-[8px]">
                            <ICSearch width={18} height={18} stroke="#7357FF" />
                            <span>Tìm mới</span>
                        </div>                        
                    </HRButton>
                </div>
            </div> 
        </div>
    )
}
