import { Input, Modal } from "antd"
import React from "react";
import { useDispatch } from "react-redux";
import { changePasswordAPI } from "../../api/user";
import { HRButton } from "../../components/button";
import { inputPasswordProps } from "../../components/form/input";
import { ICVerify } from "../../icons";
import { setLoading } from "../../redux/slices/appInfo";
import { haveAtLeastOneLowerCase, haveAtLeastOneNumber, haveAtLeastOneUpperCase, haveUnallowedCharacters, isStrongPassword } from "../../utils";
import { useNotification } from "../../hooks/useNotification";
import { useNavigate } from "react-router-dom";

type ChangePasswordProps = {
    isOpen: boolean;
    onClose: () => void;
}
const BASE_STYLE = {
    height: "52px",
    borderRadius: "10px"
}
export const ChangePassword: React.FC<ChangePasswordProps> = ({
    isOpen,
    onClose,
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { showSuccess } = useNotification();
    const [currentPassword, setCurrentPassword] = React.useState<string>("");
    const [password, setPassword] = React.useState<string>("");
    const [confirmPassword, setConfirmPassword] = React.useState<string>("");

    const [errorCurrentPassword, setErrorCurrentPassword] = React.useState<string>("");
    const [errorNewPassword, setErrorNewPassword] = React.useState<string>("");

    React.useEffect(() => {
        if (currentPassword.length > 0) {
            if (currentPassword.length < 6 || currentPassword.length > 20) {
                setErrorCurrentPassword("Mật khẩu có ít nhất 6 ký tự và nhiều nhất 20 ký tự");
            } else {
                setErrorCurrentPassword("");
            }
        }
    }, [currentPassword]);
    const errorSamePassword = React.useMemo(() => {
        if (password.length > 0 && confirmPassword.length > 0 && password !== confirmPassword) {
            return "Mật khẩu không khớp";
        }
        return ""
    }, [password, confirmPassword]);

    const errorPassword = React.useMemo(() => {
        return !password.length || isStrongPassword(password) ? "" : "Có ít nhất 1 chữ HOA, 1 chữ thường và 1 ký tự số";
    }, [password]);

    const rules = React.useMemo(() => {
        return [{
            name: "Mật khẩu không bao gồm các kí tự ( )',|",
            rule: !haveUnallowedCharacters(password)
        }, {
            name: "Mật khẩu có ít nhất 6 ký tự và nhiều nhất 20 ký tự",
            rule: password.length >= 6 && password.length <= 20
        }, {
            name: "Có ít nhất 1 chữ HOA",
            rule: haveAtLeastOneUpperCase(password)
        }, {
            name: "Có ít nhất 1 chữ thường",
            rule: haveAtLeastOneLowerCase(password)
        }, {
            name: "Có ít nhất 1 ký tự số",
            rule: haveAtLeastOneNumber(password)
        }]
    }, [password]);

    const canNext = React.useMemo(() => {
        return currentPassword && password && confirmPassword && !errorPassword && !errorSamePassword && !errorCurrentPassword;
    }, [password, errorPassword, currentPassword, confirmPassword, errorSamePassword, errorCurrentPassword]);

    const onChangePassword = async () => {
        // Call api change password
        dispatch(setLoading(true));
        try {
            await changePasswordAPI(currentPassword, password);
            showSuccess('Thành công');
            setErrorCurrentPassword("");
            setErrorNewPassword("");
            navigate(`/login`, { replace: true });
            onClose();
        } catch (e: any) {
            const { data } = e.response;
            if (data.errorCode === 400) {
                if (data.errorMessage === "Invalid password") {
                    setErrorCurrentPassword("Mật khẩu hiện tại không đúng");
                } else {
                    setErrorCurrentPassword("");
                }
            }

            if (data.errorCode === 404) {
                if (data.errorMessage === "New password does not match old password") {
                    setErrorNewPassword("Mật khẩu mới không được trùng với mật khẩu hiện tại");
                } else {
                    setErrorNewPassword("");
                }
            }
        }
        finally {
            dispatch(setLoading(false));
        }
    }

    return (
        <Modal
            centered
            onCancel={onClose}
            open={isOpen}
            title={
                <div className="font-bold text-[18px]">Thay đổi mật khẩu</div>
            }
            footer={
                <div className="flex justify-end space-x-6">
                    <HRButton 
                        btnSize="sm"
                        btnType="sub"
                        onClick={onClose}>
                        Hủy bỏ
                    </HRButton>
                    <HRButton
                        btnSize="sm"
                        disabled={!canNext}
                        onClick={onChangePassword}>
                        Xác nhận
                    </HRButton>
                </div>
            }
        >
            <div className="space-y-3">
                <div className="space-y-1">
                    <Input.Password
                        value={currentPassword}
                        maxLength={20}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        {...inputPasswordProps(errorCurrentPassword)}
                        placeholder="Mật khẩu cũ"
                    />
                    <div className="pt-2 font-medium text-xs text-error-500">{errorCurrentPassword}</div>
                </div>
                <div className="space-y-1">
                    <Input.Password
                        value={password}
                        maxLength={20}
                        onChange={(e) => setPassword(e.target.value)}
                        {...inputPasswordProps(errorPassword || errorNewPassword)}
                        placeholder="Mật khẩu mới"
                    />
                    <div className="pt-2 font-medium text-xs text-error-500">{errorNewPassword}</div>
                </div>
                <div className="space-y-1">
                    <Input.Password
                        value={confirmPassword}
                        maxLength={20}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        {...inputPasswordProps(!!errorSamePassword)}
                        placeholder="Nhập lại mật khẩu mới"
                    />
                    <div className="pt-2 font-medium text-xs text-error-500">{errorSamePassword}</div>
                </div>
                <div>
                    {
                        rules.map((rule, index) => (
                            <div key={index} className="text-sm font-medium text-n-800 flex space-x-1">
                                <ICVerify isValid={rule.rule} />
                                <span>{rule.name}</span>
                            </div>
                        ))
                    }
                </div>
            </div>
        </Modal>
    )
}
