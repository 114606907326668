import React from "react";
import { HRCollapse } from "../../../../components/collapse";
import classNames from 'classnames'
import { useTestingPageContext } from "../..";
import { TQuestionTesting } from "../../type";
import _ from "lodash";
import styled from "styled-components";
import { EQuestionType } from "../../../create_test/tab/question/type";

const Wrapper = styled.div`
    &:hover {
        .ant-collapse .ant-collapse-header,
        .ant-collapse-borderless>.ant-collapse-item>.ant-collapse-content {
            opacity: 1;
        }
    }
    .ant-collapse .ant-collapse-header,
    .ant-collapse-borderless>.ant-collapse-item>.ant-collapse-content {
        opacity: 0.2;
    }
`;

type QuestionListDrawProps = {
    currentQuestionIndex: number;
    questionData: TQuestionTesting[];
    onNavigateQuestion: (index: number) => void;
    correctAnswer?: (number[] | number | undefined | string)[];
    sectionName?: string;
    questionViewed?: Set<string>;
    hasPinQuestions?: boolean;
}
export const QuestionListDraw: React.FC<QuestionListDrawProps> = ({
    currentQuestionIndex,
    questionData = [],
    onNavigateQuestion,
    correctAnswer,
    sectionName,
    questionViewed,
    hasPinQuestions,
}) => {

    const onSelectQuestion = React.useCallback((question: TQuestionTesting, index: number) => {
        onNavigateQuestion(index);
    }, [onNavigateQuestion]);
    
    const renderQuestion = React.useMemo(() => {
        const setBackground = (question: TQuestionTesting, index: number, currentQuestionIndex: number) => {
            if (index === currentQuestionIndex) {
                return "bg-surface-primary";
            }
            if (question.type === EQuestionType.SINGLE_CHOICE || question.type === EQuestionType.ESSAY) {
                const isCorrect = Array.isArray(correctAnswer) && correctAnswer[index] === 0 ? true : correctAnswer && correctAnswer[index];
                if (isCorrect) {
                    return "bg-surface-med text-high-em xxx";
                }
            }
            if (question.type === EQuestionType.MULTIPLE_CHOICE) {
                if (correctAnswer && !_.isEmpty(correctAnswer[index])) {
                    return "bg-surface-med text-high-em";
                }
            }
            return "bg-white";
        };

        const setTextColor = (question: TQuestionTesting, index: number, currentQuestionIndex: number) => {
            if (index === currentQuestionIndex) {
                return "text-white";
            }
            if (question.type === EQuestionType.SINGLE_CHOICE || question.type === EQuestionType.ESSAY) {
                const isCorrect = Array.isArray(correctAnswer) && correctAnswer[index] === 0 ? true : correctAnswer && correctAnswer[index];
                if (isCorrect) {
                    return "text-high-em";
                }
            }
            if (question.type === EQuestionType.MULTIPLE_CHOICE) {
                if (correctAnswer && !_.isEmpty(correctAnswer[index])) {
                    return "text-high-em";
                }
            }
            return "text-low-em";
        };
        const hasSelectedAnswer = (question: TQuestionTesting, index: number) => {
            if (question.type === EQuestionType.SINGLE_CHOICE || question.type === EQuestionType.ESSAY) {
                return Array.isArray(correctAnswer) && correctAnswer[index] === 0 ? true : correctAnswer && correctAnswer[index];
            }
            if (question.type === EQuestionType.MULTIPLE_CHOICE) {
                return correctAnswer && !_.isEmpty(correctAnswer[index]);
            }
            return false;
        };

        return (
            <div className="flex flex-col gap-[16px]">
                <div 
                    className={classNames([
                        "flex flex-row gap-[16px] overflow-auto",
                        {
                            "flex-wrap": !hasPinQuestions,
                        }
                    ])}
                >
                    {questionData.map((question: TQuestionTesting, index: number) => (
                        <div key={index} 
                            onClick={() => onSelectQuestion(question, index)}
                            style={{
                                borderColor: index === currentQuestionIndex ? "#7357FF" : "",
                            }}
                            className={
                                classNames([
                                    "flex items-center min-w-[80px] cursor-pointer text-center justify-center rounded-[4px] border-solid border-[1px] border-transparent px-[8px] py-[2px]",
                                    {
                                        "bg-surface-warning-accent-1 text-warning": (index === 0 || questionViewed?.has(question._id)) && !hasSelectedAnswer(question, index) && index !== currentQuestionIndex,
                                    },
                                    setBackground(question, index, currentQuestionIndex),
                                    setTextColor(question, index, currentQuestionIndex),
                                ])
                        }>
                            <div className="text-body-semi-bold">
                                Câu {index + 1}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }, [hasPinQuestions, questionData, correctAnswer, currentQuestionIndex, questionViewed, onSelectQuestion]);

    return <Wrapper className="flex space-x-2 items-center">
        {/* <HRCollapse title={header} bordered={false} childNode={content} className="w-full" /> */}
        {renderQuestion}
    </Wrapper>
}
