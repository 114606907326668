import { Col, Row } from "antd";
import React from "react";
import { useCandidateCreditPageContext } from ".";
import { QuantityPayment } from "./quantity";
import { formatMoney } from "../../utils";

export const ListPayment: React.FC = () => {
    const { candidatePayment, quantity, setQuantity, totalMoneyNotPaid } = useCandidateCreditPageContext();
    const originalPrice = candidatePayment?.price?.originalPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    const totalCandidateCredit = React.useMemo(() => {
        if (candidatePayment?.creditPerQuantity) {
            return candidatePayment?.creditPerQuantity * quantity;
        }
        return 0;
    }, [candidatePayment, quantity]);

    return <div className="flex flex-col w-full gap-[16px]">
        <span className="text-title-bold text-high-em">Thông tin đơn hàng</span>
        <div className="flex flex-col w-full">
            <div className="flex w-full gap-[32px] bg-white p-[16px] rounded-t-[8px] shadow-header-payment">
                <div className="flex flex-col gap-[5px]">
                    <span className="text-primary text-body-bold">Thêm lượt đánh giá</span>
                    <span className="text-med-em text-tiny">Được áp dụng cho mọi bài đánh giá</span>
                </div>
                <div className="flex bg-[#ECECED] h-wull w-[1px]"></div>
                <div className="flex gap-[32px]">
                    <div className="flex flex-col gap-[5px]">
                        <span className="text-tiny-bold text-med-em">Lượt đánh giá hiện có</span>
                        <span className="text-high-em text-body-bold">{candidatePayment?.remainingCandidateCredit}</span>
                    </div>
                </div>
            </div>
            <div className="bg-white">
                <Row
                    style={{
                        borderTop: '1px solid #ECECED',
                        padding: '0px 24px',
                    }}
                    gutter={16}
                >
                    <Col span={10}>
                        <div className="flex flex-col p-[16px] pl-0">
                            <span className="text-sub-bold text-low-em">
                                LƯỢT ĐÁNH GIÁ TỐI THIỂU
                            </span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <div className="flex flex-col p-[16px] pl-0">
                            <span className="text-sub-bold text-low-em">
                                SỐ LƯỢNG
                            </span>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className="flex flex-col p-[16px] pl-0 items-end">
                            <span className="text-sub-bold text-low-em">
                                ĐƠN GIÁ
                            </span>
                        </div>
                    </Col>
                </Row>
                <Row
                    align="middle"
                    style={{
                        padding: '0px 24px',
                        borderBottom: '1px solid #ECECED',
                        borderTop: '1px solid #ECECED',
                    }}
                    gutter={16}
                >
                    <Col span={10}>
                        <div className="flex flex-col p-[16px] pl-0">
                            <span className="text-high-em text-body-medium">
                            {candidatePayment?.creditPerQuantity} lượt
                            </span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <div className="flex flex-col p-[16px] pl-0">
                            <span className="text-high-em text-sub-bold">
                                <QuantityPayment quantity={quantity} setQuantity={setQuantity} />
                            </span>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className="flex flex-col p-[16px] pl-0 items-end">
                            <div className="text-high-em text-[14px] leading-[24px] font-medium">
                                <div className="flex items-start font-medium text-[14px] leading-[24px] text-high-em">
                                    <div className="flex flex-col items-end">
                                        {originalPrice && <div className="flex gap-[6px]">
                                            <span className="text-success-500 text-body-medium">Giảm {candidatePayment?.price?.discount}%</span>
                                            <div className="line-through text-[#B3B1B8]">
                                                <span>{originalPrice}</span>
                                                <span>{' '}{candidatePayment?.price?.unit}</span>
                                            </div>
                                        </div>}
                                        <div className="flex gap-[5px] text-high-em text-body-bold">
                                            <span>{candidatePayment?.price?.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</span>
                                            <span>{candidatePayment?.price?.unit}/lượt</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Col>
                </Row>
                <Row
                    style={{
                        padding: '0px 24px',
                    }}
                    gutter={16}
                >
                    <Col span={24}>
                        <div className="flex flex-col items-end justify-end">
                            <div className="flex flex-row p-[16px] pb-0 pl-0 items-center justify-between gap-[16px]">
                                <span className="text-left text-body-medium text-med-em min-w-[150px]">
                                    Tổng số lượt đánh giá
                                </span>
                                <span className="min-w-[200px] text-right text-high-em text-body-bold">{totalCandidateCredit}</span>
                            </div>
                            <div className="flex flex-row p-[16px] pl-0 items-center justify-between gap-[16px]">
                                <span className="text-body-medium text-med-em text-left min-w-[150px]">
                                    Tạm tính
                                </span>
                                <span className="min-w-[200px] text-right text-high-em text-body-bold">{formatMoney(totalMoneyNotPaid, candidatePayment?.price?.unit)}</span>
                            </div>

                        </div>
                    </Col>
                    {/* <Col span={6}>
                        <div className="flex flex-col p-[16px] pl-0">
                            <span className="text-[#B3B1B8] text-[12px] leading-[16px] font-bold">
                                {paymentDetails?.paidTests?.length}
                            </span>
                        </div>
                    </Col> */}
                </Row>

            </div>

        </div>
    </div>;
}
