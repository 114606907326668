import { IconProps } from "./type"

export const ICNotification: React.FC<IconProps> = ({
    fill = "#4F4B5C"
}) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M12 3C8.44917 3 5.45455 6.10646 5.45455 10.0868V10.0982L5.42709 12.4965C5.42576 14.161 4.8667 15.5664 4.22902 16.5999H19.7769C19.1649 15.5643 18.6291 14.1563 18.6291 12.4906V12.4786L18.6565 10.2001C18.6536 6.15677 15.6104 3 12 3ZM3.45456 10.0809C3.45752 5.13141 7.21823 1 12 1C16.845 1 20.6565 5.18801 20.6565 10.2059V10.2179L20.6291 12.4964C20.6305 13.9145 21.1587 15.1049 21.7152 15.9236C22.0848 16.4673 22.0474 17.1113 21.8676 17.5641C21.6917 18.007 21.2272 18.5999 20.43 18.5999H3.57106C2.76088 18.5999 2.29758 17.9891 2.12628 17.5444C1.9506 17.0884 1.91637 16.4336 2.30289 15.8882C2.8843 15.0679 3.42709 13.8885 3.42709 12.4906V12.4792L3.45456 10.0809ZM7.99997 21.1999C7.99997 20.6476 8.44769 20.1999 8.99997 20.1999H15C15.5523 20.1999 16 20.6476 16 21.1999C16 21.7521 15.5523 22.1999 15 22.1999H8.99997C8.44769 22.1999 7.99997 21.7521 7.99997 21.1999Z" fill={fill}/>
        </svg>

    )
}

export const ICNotificationV2: React.FC<IconProps> = ({
    height = 24,
    width = 24,
    fill = "#7357FF"
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.04878 1.92573C7.31071 2.41195 7.1289 3.01844 6.64268 3.28038C5.41518 3.94166 4.46327 5.04249 3.92847 6.37662C3.72297 6.88925 3.14082 7.13823 2.62819 6.93274C2.11555 6.72725 1.86657 6.14509 2.07207 5.63246C2.76927 3.89318 4.02713 2.41767 5.69413 1.51963C6.18034 1.25769 6.78684 1.43951 7.04878 1.92573ZM16.9345 1.9261C17.2016 1.4427 17.81 1.26733 18.2934 1.53442C19.9363 2.44214 21.2479 3.87065 21.9324 5.63256C22.1324 6.14736 21.8772 6.72682 21.3624 6.92682C20.8476 7.12683 20.2681 6.87164 20.0681 6.35684C19.5655 5.06295 18.5897 3.98307 17.3262 3.28499C16.8428 3.01791 16.6675 2.40951 16.9345 1.9261ZM12.0022 4.68488C8.70864 4.68488 5.92998 7.55343 5.92998 11.2305V11.242L5.92992 11.242L5.90424 13.4795C5.90295 14.9934 5.41012 16.279 4.83413 17.2409H19.1784C18.6253 16.2769 18.1528 14.9887 18.1528 13.4735V13.4614L18.1528 13.4615L18.1785 11.3359C18.1756 7.60018 15.3515 4.68488 12.0022 4.68488ZM3.92998 11.2246C3.93298 6.56965 7.48587 2.68488 12.0022 2.68488C16.5779 2.68488 20.1785 6.62269 20.1785 11.3417V11.3537L20.1784 11.3537L20.1528 13.4793C20.1542 14.7845 20.6427 15.8816 21.1588 16.6375C21.5212 17.1683 21.4835 17.7951 21.3086 18.2337C21.1372 18.6632 20.6833 19.2409 19.9034 19.2409H4.1019C3.30923 19.2409 2.85655 18.6457 2.68975 18.2146C2.51893 17.7732 2.48426 17.1358 2.86333 16.6034L3.67795 17.1834L2.86333 16.6034C3.40267 15.8458 3.90424 14.7592 3.90424 13.4735V13.462L3.90431 13.462L3.92998 11.2246ZM8.19031 21.6C8.19031 21.0477 8.63803 20.6 9.19031 20.6H14.814C15.3663 20.6 15.814 21.0477 15.814 21.6C15.814 22.1523 15.3663 22.6 14.814 22.6H9.19031C8.63803 22.6 8.19031 22.1523 8.19031 21.6Z" fill={fill}/>
        </svg>
    )
}
