export const dateFormat = (value: string) => {
  if (!value) {
    return null;
  }
  return new Date(value).toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric'
  }).split('/').join('-');
}

export function formatLocaleDateString(date: number | string, dotFormat?: boolean) {
  if (!date) {
    return null;
  }
  if (typeof date === 'number') {
    const longEnGBFormatter = new Intl.DateTimeFormat('en-GB', {
      year:  'numeric',
      month: 'numeric',
      day:   'numeric',
    });
    if (dotFormat) {
      return longEnGBFormatter.format(date).split('/').join('.');
    }
    return longEnGBFormatter.format(date);
  }
  if (typeof date === 'string') {
    return new Date(date).toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric'
    }).split('-').join('/');
  }
  return date;
}

export function formatLocaleDateTimeString(date: number) {
  const longEnGBFormatter = new Intl.DateTimeFormat('en-GB', {
    year:  'numeric',
    month: 'numeric',
    day:   'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  });
  return longEnGBFormatter.format(date);
}


export function formatTimeSince(date: number) {
  if (!date) {
    return null;
  }
  const currentDate = formatLocaleDateString(date);

  const seconds = Math.floor((new Date().valueOf() - date) / 1000);
  const years = Math.floor(seconds / 31536000);
  const months = Math.floor(seconds / 2592000);
  const days = Math.floor(seconds / 86400);

  if (days > 548) {
    return years + " năm trước";
  }
  if (days >= 320 && days <= 547) {
    return "một năm trước";
  }
  if (days >= 45 && days <= 319) {
    return months + " tháng trước";
  }
  if (days >= 26 && days <= 45) {
    return "một tháng trước";
  }

  var hours = Math.floor(seconds / 3600);

  if (hours >= 36 && days <= 25) {
    // return days + " ngày trước";
    return currentDate;
  }

  if (hours > 24 && hours <= 35) {
    return currentDate;
  }

  if (hours >= 22 && hours <= 24) {
    return hours + " giờ trước";
  }

  var minutes = Math.floor(seconds / 60);

  if (minutes >= 90 && hours <= 21) {
    return hours + " giờ trước";
  }
  if (minutes >= 45 && minutes <= 89) {
    return "một giờ trước";
  }
  if (seconds >= 90 && minutes <= 44) {
    return minutes + " phút trước";
  }
  if (seconds >= 45 && seconds <= 89) {
    return "một phút trước";
  }
  if (seconds >= 0 && seconds <= 45) {
    return "một vài giây trước";
  }
}
