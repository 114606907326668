import React from "react"
import { Col, Row } from 'antd';
import { Comment } from './comment';
import { useCreateTestPageContext } from "..";
import { dateFormat } from '../../../utils/date';

type OverviewTabProps = {
    
}

export const OverviewTab: React.FC<OverviewTabProps> = () => {
    const { testDetails } = useCreateTestPageContext();

    const data: any[] = [
        {
            label: 'Ngày tạo',
            value: dateFormat(testDetails?.createdAt || '')
        },
        {
            label: 'Cập nhật mới',
            value: dateFormat(testDetails?.updatedAt || '')
        },
        {
            label: 'Ngày xét duyệt',
            value: dateFormat(testDetails?.approvalDate || '')
        },
        {
            label: 'Trả về bởi',
            value: testDetails?.createdBy?.name
        },
        {
            label: 'Ngày trả về',
            value: dateFormat(testDetails?.returnedDate || '')
        },
    ];
    return <div className="bg-white p-[32px] rounded-b-[10px] grid gap-[32px]">
            <Row gutter={[16, 16]}>
                {
                    data.map((item, index) => {
                        return <Col key={index} span={6}>
                            <div className="grid grid-cols-[1fr] gap-[16px] rounded-[12px] border-[1.2px] border-[#E2E4EB] justify-items-left items-left py-[16px] px-[32px] min-h-[113px]">
                                <label className="text-[#46485C] font-bold text-[16px]">{item.label}</label>
                                <span className="text-[#A6A8B4] text-[24px] font-medium">{item.value}</span>
                            </div>
                        </Col>
                    })
                }
            </Row>
            <div className="grid gap-[32px] rounded-[12px] border-[1.2px] border-[#E2E4EB] py-[16px] px-[32px]">
                <label className="font-bold text-[16px] leading-[19px] text-[#46485C]">Bình luận trả về</label>
                <Comment />
            </div>
    </div>
}
