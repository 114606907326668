import React from 'react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { deleteTestLibrary, getMyTestLibrary } from '../../api/library';
import { HRButton, IconButton } from "../../components/button"
import { HRFilter } from '../../components/filter';
import { SearchParamsStateType, useFilter } from '../../hooks/useFilter';
import { useNotification } from '../../hooks/useNotification';
import { ICAdd } from "../../icons"
import { ICBack } from "../../icons/back"
import { firstValueCommonData } from '../../redux/selectors/commonData';
import { setLoading } from '../../redux/slices/appInfo';
import { IPagination, ISearchCriteria } from '../library/models';
import { TestList } from "./list"
import { TTestData } from './type';
import { useTranslation } from 'react-i18next';
import { ERROR_CODE } from '../../utils/constants';
type TSortData = {
    field?: string;
    order?: 'asc' | 'desc';
}
type TSortDataContext = {
    sortData: TSortData;
    setSortData: (data: TSortData) => void;
}
const SortDataContext = React.createContext<TSortDataContext>({} as TSortDataContext);
export const useSortData = () => React.useContext(SortDataContext);

export const MyTestPage: React.FC = () => {
    const dispatch = useDispatch();
    const [sortData, setSortData] = React.useState<TSortData>({ field: 'createdAt', order: 'desc' });
    const [pagination, setPagination] = React.useState<IPagination>();
    const { i18n } = useTranslation();
    
    const languageDefault = firstValueCommonData('language', i18n.language) || '';
    const filtersDefaults: SearchParamsStateType = {
        page: { type: 'number', default: 1 },
        limit: { type: 'number', default: 5 },
        language: { type: 'string', default: null },
        searchText: { type: 'string', default: null },
        roles: { type: 'string', default: [], multiple: true },
        industry: { type: 'string', default: [], multiple: true },
        type: { type: 'string', default: null },
        statusTag: { type: 'string', default: null },
        planTag: { type: 'string', default: null },
        orderBy: { type: 'string', default: "createdAt::desc" },
    }
    const [filterParams, setFilterParams] = useFilter(filtersDefaults);
    const [testList, setTestList] = React.useState<TTestData[]>([])
    const onChangeSort = useCallback((data: TSortData) => {
        setSortData(data);
        const formatSortData = `${data.field}::${data.order}`;
        onChangeFilter({
            orderBy: formatSortData,
            page: 1
        })
    }, []);
    React.useEffect(() => {
        loadData(filterParams);
    }, [])

    const onChangeFilter = useCallback((values: any) => {
        const newParams = { ...filterParams, ...values };
        loadData(newParams);
    }, [filterParams]);

    const loadData = async (value?: ISearchCriteria) => {
        const newParams = value || filterParams;
        setFilterParams(newParams);
        dispatch(setLoading(true));
        newParams.language = newParams.language || languageDefault;
        try {
            const results = await getMyTestLibrary(newParams);
            const { data } = results || {};
            const newData = data.data || [];
            setTestList(newData);
            setPagination(data.metaData);
        } catch (e) {
            console.error(e)
        }
        finally {
            dispatch(setLoading(false));
        }
    };
    const {
        showError
    } = useNotification();
    const onDeleteTest = useCallback(async (id: string) => {
        dispatch(setLoading(true));
        try {
            await deleteTestLibrary(id);
            loadData();
        } catch (error: any) {
            const errorCode = error?.response?.data?.errorCode || '';
            if(errorCode === ERROR_CODE.TEST_HAS_USED_IN_ASSESSMENT) {
                showError("", "Bộ đề đã được chọn vào Bài đánh giá, vui lòng gỡ bỏ Bộ đề trong Bài đánh giá trước khi thực hiện thao tác này.");
            } else {
                showError("Error", error?.response?.statusText);
            }
        } finally {
            dispatch(setLoading(false));
        }
    }, []);
    return (
        <div className="text-center">
            <div className="pt-8 space-y-6 min-h-screen">
                <div className="pb-16 space-y-8 text-left container mx-auto">
                    <SortDataContext.Provider value={{ sortData, setSortData: onChangeSort }}>
                        <TestHeader />
                        <HRFilter
                            onChange={onChangeFilter}
                            filterParams={filterParams}
                            removeAdditionalOptions={true}
                        />
                        <TestList
                            testList={testList}
                            keyword={filterParams?.searchText}
                            pagination={pagination}
                            onChange={onChangeFilter}
                            onDelete={onDeleteTest}
                        />
                    </SortDataContext.Provider>
                </div>
            </div>
        </div>
    )
}

const TestHeader: React.FC = () => {
    const navigate = useNavigate();
    const goTestLibrary = useCallback(() => {
        navigate('/library');
    }, [navigate]);
    const goCreateTest = useCallback(() => {
        navigate('/create-test');
    }, [navigate]);
    return (
        <div className="flex items-center justify-between">
            <div className="flex space-x-2 items-center">
                <IconButton btnType="sub" btnSize="sm" icon={<ICBack />} onClick={goTestLibrary} />
                <span className="text-high-em text-heading-5-semi-bold">Đề kiểm tra của tôi</span>
            </div>
            <div>
                <HRButton onClick={goCreateTest} btnSize="md">
                    <div className="flex space-x-2 justify-center">
                        <ICAdd />
                        <span>Tạo đề kiểm tra</span>
                    </div>
                </HRButton>
            </div>
        </div>
    )
}
