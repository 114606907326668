import { HRButton } from "../../components/button"
import { ICNext } from "../../icons"
import React from "react";
import { useTesting } from "./hooks/useTesting";
import { useTestingPageContext } from ".";
import { useNotification } from "../../hooks/useNotification";
import { ERROR_CODE, ERROR_CODE_DESC_MESSAGE, ERROR_CODE_TITLE_MESSAGE, EVENT_NAME_SOCKET } from "../../utils/constants";
import { ErrorLink } from "../../components/errorLink";
import { setLoading } from "../../redux/slices/appInfo";
import { useDispatch } from "react-redux";
import { useScreenRecord } from "../../hooks/useScreenRecord";

export const TestingIntro: React.FC = () => {
    const dispatch = useDispatch();
    const { getCurrentSectionTesting } = useTesting();
    const { assessmentDetails, setSectionTesting, handleFullScreen, socket, setStep, companyProfile } = useTestingPageContext();
    const isForceTurnOnCamera = assessmentDetails.assessment.forceTurnOnCamera || false;
    const { showError } = useNotification();
    const [errorCodeCandidate, setErrorCodeCandidate] = React.useState<number | undefined>(undefined);
    const {
        onStartRecording
    } = useScreenRecord();
    const onBeginTesting = React.useCallback(async () => {
        if (!socket) {
            showError("Lỗi", "Không thể kết nối đến server. Vui lòng thử lại sau.");
            return;
        }
        dispatch(setLoading(true));
        await socket.emitWithAck(EVENT_NAME_SOCKET.SEND.JOIN_TEST);
        if (assessmentDetails?._id) {
            getCurrentSectionTesting(assessmentDetails?._id, handleFullScreen, setSectionTesting, setStep, setErrorCodeCandidate);
            onStartRecording({
                socket,
                assessmentId: assessmentDetails.assessment?._id!,
            })
        }
    }, [dispatch, assessmentDetails, getCurrentSectionTesting, handleFullScreen, setSectionTesting, socket, setStep, showError]);

    const candidateName = React.useMemo(() => {
        return (assessmentDetails?.candidate?.lastName || '') + " " + assessmentDetails?.candidate?.firstName;
    }, [assessmentDetails]);

    const titleError = React.useMemo(() => {
        if (errorCodeCandidate === ERROR_CODE.LOCKED) {
            return ERROR_CODE_TITLE_MESSAGE[errorCodeCandidate];
        }
        return undefined;
    }, [errorCodeCandidate]);
    const descriptionError = React.useMemo(() => {
        if (errorCodeCandidate === ERROR_CODE.LOCKED) {
            return ERROR_CODE_DESC_MESSAGE[errorCodeCandidate];
        }
        return undefined;
    }, [errorCodeCandidate]);

    return errorCodeCandidate ?
        <ErrorLink title={titleError} description={descriptionError} />
        :
        <div className="container mx-auto bg-white px-8 py-[54px] rounded-[12px]">
            <div className="flex space-x-4">
                <div className="w-1/2 space-y-6">
                    <div className="text-heading-5-bold text-high-em">Xin chào, {candidateName}</div>
                    <div className="text-body text-high-em">
                        <div>Chào mừng đến với buổi thẩm định <strong>{assessmentDetails?.assessment?.title}</strong> {companyProfile?.name ? `của công ty ${companyProfile?.name}.` : ''}</div>
                        <div>Nhấn <strong>Bắt đầu</strong> để bắt đầu làm bài đánh giá.</div>
                    </div>
                    <div className="text-body text-high-em">
                        <div>Đây là cơ hội giúp bản thể hiện bản thân trong mắt nhà tuyển dụng.</div>
                        <div>Chúc bạn may mắn!</div>
                    </div>
                    {
                        isForceTurnOnCamera && <div className="text-body font-bold text-red-400">
                            * Lưu ý: Vui lòng bật camera trong suốt quá trình làm bài.
                        </div>
                    }
                </div>
                <div className="w-1/2 space-y-4">
                    <div className="text-standard-bold text-high-em">Trước khi bạn bắt đầu</div>
                    <div>
                        <ul className="list-disc text-body text-high-em space-y-3">
                            <li>Đánh giá này bao gồm <strong>{assessmentDetails?.overview?.totalTests || 0}</strong> bài kiểm tra và <strong>{assessmentDetails?.overview?.totalQuestions || 0}</strong> câu hỏi tùy chỉnh cho từng cá nhân. Bạn sẽ phải mất khoảng <strong>{assessmentDetails?.overview?.totalDuration || 0}</strong> phút để hoàn thành.</li>
                            <li>Thời gian sẽ được tính khi bắt đầu làm bài và mỗi đề kiểm tra sẽ có khoảng thời gian làm bài khác nhau. Nếu quá thời gian, hệ thống sẽ tự động kết thúc bài đánh giá.</li>
                            <li>Xin vui lòng cho phép sử dụng camera/microphone và không rời khỏi chế độ toàn màn hình trong suốt thời gian làm bài. Hình ảnh của bạn sẽ được chụp định kỳ trong suốt quá trình đánh giá. Những biện pháp này được thực hiện để đảm bảo tính công bằng cho tất cả mọi người.</li>
                            <li>Vui lòng bật loa hoặc đeo tai nghe để có thể nghe được âm thanh trong các bài kiểm tra.</li>
                            <li>Bạn có thể sử dụng máy tính bỏ túi, bút và giấy trong quá trình làm bài.</li>
                            <li>Chúng tôi khuyến khích bạn nên hoàn thành đánh giá trong một lần để đảm bảo kết quả chính xác nhất.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="flex justify-center mt-6">
                <span>
                    <HRButton
                        btnType="primary"
                        onClick={onBeginTesting}
                        style={{
                            backgroundColor: companyProfile?.color?.backgroundColor,
                            borderColor: companyProfile?.color?.backgroundColor,
                            color: companyProfile?.color?.fontColor || "#fff",
                        }}
                    >
                        <div className="flex space-x-2 items-center justify-center">
                            <span>Bắt đầu</span>
                            <ICNext fill={(companyProfile?.color?.fontColor || "#fff")} />
                        </div>
                    </HRButton>
                </span>
            </div>
        </div>
}
