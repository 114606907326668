import { Typography } from 'antd';
import React from 'react';
import { HRButton } from '../../../components/button';
import { ICInfoV2 } from '../../../icons';
import { TCompanyProfile } from '../../../api/company-profile';

type TEmailConfirmationProps = {
    companyProfile?: TCompanyProfile;
    setIsModalVisible: (value: boolean) => void;
}
export const EmailConfirmation: React.FC<TEmailConfirmationProps> = ({
    companyProfile,
    setIsModalVisible
}) => {

    return <div className="flex bg-white w-full rounded-[12px] px-[32px] py-[54px] shadow-e-03">
        <div className="grid grid-cols-1 gap-[32px] items-start justify-start w-full">
            <div className="text-left">
                <Typography.Title level={3} style={{
                    color: '#110C22',
                    fontWeight: 700,
                    marginBottom: 0,
                    fontSize: '18px',
                }}>Gửi bài đánh giá thành công</Typography.Title>

            </div>
            <div className="grid grid-cols-1 gap-[4px] text-left">
                <span className="text-med-em text-standard-medium">
                    Bài đánh giá đã được gửi về email của bạn.
                </span>
                <span className="text-med-em text-standard-medium">
                    Vui lòng kiểm tra tin nhắn tới.
                </span>
            </div>
            <div className="text-center">
                <HRButton 
                    onClick={() => setIsModalVisible(false)}
                    btnType="primary"
                    btnSize="sm"
                    style={{
                        backgroundColor: companyProfile?.color?.backgroundColor,
                        borderColor: companyProfile?.color?.backgroundColor,
                        color: companyProfile?.color?.fontColor || "#fff",
                    }}
                >
                    <div className="flex items-center justify-center gap-[8px]">
                        <ICInfoV2 width={18} height={18} fill={companyProfile?.color?.fontColor || "#fff"} />
                        <span className="text-body-medium">Đã hiểu</span>
                    </div>
                </HRButton>
            </div>
        </div>
    </div>
}
