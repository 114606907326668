import { TStepItem } from "./type";
import { StepProps } from 'antd';
import { GeneralStep } from './components/general/general';
import { AssessmentAddTest } from "./components/add-test";
import { AssessmentAdditionalTest } from "./components/add-additional-test";
import { ReviewConfigureAssessment } from "./components/review-configure";
import { EStatusCard, ILibrary } from "../../library/models";
import { uuid } from "../../create_test/tab/question/helper";

export type TStepItemProps = StepProps & TStepItem & {
}

export const STEP: TStepItemProps[] = [{
    title: "Thông tin cơ bản",
    component: <GeneralStep />
}, {
    title: "Thêm đề kiểm tra",
    component: <AssessmentAddTest />
}, {
    title: "Câu hỏi bổ sung",
    component: <AssessmentAdditionalTest />
}, {
    title: "Xác nhận thông tin",
    component: <ReviewConfigureAssessment />
}];

export const DEFAULT_ADD_TESTS: ILibrary[] = [
    {
        uuid: uuid(),
        title: 'Đề kiểm tra 1',
        description: 'Đề kiểm tra 1',
        planTag: EStatusCard.FREE,
        statusTag: EStatusCard.POPULAR,
    },
    {
        uuid: uuid(),
        title: 'Đề kiểm tra 2',
        description: 'Đề kiểm tra 2',
        planTag: EStatusCard.FREE,
        statusTag: EStatusCard.POPULAR,
    },
    {
        uuid: uuid(),
        title: 'Đề kiểm tra 3',
        description: 'Đề kiểm tra 3',
        planTag: EStatusCard.FREE,
        statusTag: EStatusCard.POPULAR,
    },
    {
        uuid: uuid(),
        title: 'Đề kiểm tra 4',
        description: 'Đề kiểm tra 4',
        planTag: EStatusCard.FREE,
        statusTag: EStatusCard.POPULAR,
    },
    {
        uuid: uuid(),
        title: 'Đề kiểm tra 5',
        description: 'Đề kiểm tra 5',
        planTag: EStatusCard.FREE,
        statusTag: EStatusCard.POPULAR,
    },
];
