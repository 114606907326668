import { TPricePayment } from '../candidate_credit/type';
import { TAdditionalQuestion } from './create-assessment/type';

export type TAssessmentData = {
    title: string;
    totalQuestions: number;
    totalCandidates: number;
    language: string;
    role: string;
    _id: string;
    additionalQuestions: TAdditionalQuestion;
    tests: any[];
    status?: EStatusAssessment;
    owner: string;
    createdAt: number;
    updatedAt: string;
    needToPay?: boolean;
    isPaid?: boolean;
    isWaitingForPayment?: boolean;
    oldStatus?: EStatusAssessment;
}

export interface ISearchMyAssessment {
    page: number;
    limit: number;
    status?: string;
    title?: string;
    searchString?: string;
    fromScore?: number;
    toScore?: number;
}

export type TCandidate = {
    email: string;
    lastName: string;
    firstName: string;
    _id: string;
}

export type TCandidateRequest = {
    email: string;
    lastName?: string;
    firstName: string;
}

export type TInvitationCandidate = {
    assessmentId: string;
    candidateId: string;
}

export type TPaidTests = {
    _id: string;
    title: string;
    price: {
        originalPrice: number;
        price: number;
        unit: string;
        discount: number;
    };
};

export enum ETypeDifinition {
    ASSESSMENT = 'assessment',
    CANDIDATE_CREDIT = 'candidate_credit',
}

export type TAssessmentDifinition = {
    _id: string;
    title: string;
};

export type TDifinition = {
    type: ETypeDifinition | string;
    quantity?: number;
    creditPerQuantity?: number;
    totalCandidateCredit?: number;
    assessment?: TAssessmentDifinition;
    paidTests?: TPaidTests[];
    price?: TPricePayment;
};

export type TWaitingPaymentOrder = {
    _id: string;
    orderCode: string;
    difinition: TDifinition;
    paymentStatus: string;
};

export enum EStatusAssessment {
    DRAFT = 'draft',
    ACTIVE = 'active',
    ARCHIVED = 'archived',
    LOCKED = 'locked',
    PAIDING = 'paiding',
}

export const StatusAssessmentMapping = {
    [EStatusAssessment.ACTIVE]: 'Công khai',
    [EStatusAssessment.DRAFT]: 'Nháp',
    [EStatusAssessment.ARCHIVED]: 'Lưu trữ',
    [EStatusAssessment.LOCKED]: 'Khóa',
    [EStatusAssessment.PAIDING]: 'Chờ thanh toán',
}

export const StatusAssessmentMappingColor = {
    [EStatusAssessment.ACTIVE]: '#0BAA60',
    [EStatusAssessment.DRAFT]: '#B3B1B8',
    [EStatusAssessment.ARCHIVED]: '#E09400',
    [EStatusAssessment.LOCKED]: 'white',
    [EStatusAssessment.PAIDING]: '#F3F3F4',
}

export type TConfirmCandidate = {
    firstName: string;
    lastName: string;
    assessmentPassword?: string;
}

export type TApproveTesting = {
    id: string;
    token: string;
    isApproved: boolean;
}

export type TSearchCandidateList = {
    searchString: string;
    status: 'invited' | 'in_progress' | 'done';
    fromScore: number;
    toScore: number;
}

export type TSaveNote = {
    rating: number;
    note: string;
}

export type TEvaluateEssayQuestion = {
    testCandidateId?: string;
    questionId: string;
    score?: number;
    comment?: string;
}

export type TEvaluateMultiEssayQuestion = {
    testCandidateId: string;
    evaluateData: TEvaluateEssayQuestion[];
}

export type TViewEssayQuestion = {
    testCandidateId: string;
    questionId: string;
}

export type TMyCandidateCreditInfo = {
    remainingCandidateCredit: number;
    totalCandidateCreditPurchased: number;
    totalUsedCount: number;
    lastSevenDaysUsedCount: number;
};

export type TUpdatePasswordAssessment = {
    activePassword?: boolean;
    password?: string;
};
