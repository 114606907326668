import { Input, notification } from "antd"
import React from "react"
import { useForm } from 'antd/lib/form/Form';
import { useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { loginApi } from "../api/auth"
import { getUserInfo } from "../api/user"
import { HRButton } from "../components/button"
import { HRCheckBox } from "../components/form/checkbox"
import { HRInput, HRInputPassword, inputPasswordProps } from "../components/form/input"
import { ICAlert, ICUserV2 } from "../icons/index"
import { setUserInfo } from "../redux/actions"
import { setLoading } from "../redux/slices/appInfo"
import { isValidEmail } from "../utils"
import { HRForm } from "../components/form/form"

export const LoginPage: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loginForm] = useForm();
    const [email, setEmail] = React.useState<string>("");
    const [password, setPassword] = React.useState<string>("");
    const [error, setError] = React.useState<string>("");
    const [lockStatus, setLockStatus] = React.useState<string>("");
    const hasError = React.useMemo(() => {
        return error.length > 0 || lockStatus.length > 0;
    }, [error, lockStatus]);
    const [emailError, setEmailError] = React.useState<string>("");
    const [isEmptyPassword, isIsEmptyPassword] = React.useState<string>("");
    const [rememberToken, setRememberToken] = React.useState<boolean>(false);
    const onLogin = async () => {
        if (!email) {
            setEmailError("Email không được để trống");
            return;
        } else if (!isValidEmail(email)) {
            setEmailError("Email không đúng định dạng");
            return;
        } else {
            setEmailError("");
        }
        if (!password) {
            isIsEmptyPassword("Mật khẩu không được để trống");
            return;
        } else {
            isIsEmptyPassword("");
        }
        dispatch(setLoading(true));
        try {
            const result = await loginApi(email, password, rememberToken);
            if (result.data.accessToken) {
                const { data: userInfo } = await getUserInfo(result.data.accessToken);
                dispatch(setUserInfo({
                    token: result.data.accessToken,
                    _id: userInfo._id,
                    dateOfBirth: userInfo.dateOfBirth,
                    email: userInfo.email,
                    name: userInfo.name,
                    phoneNumber: userInfo.phoneNumber,
                }));
            }
            notification.success({
                message: "Thành công",
                description: "Đăng nhập thành công",
                placement: "topRight"
            })
            const navigateTo = localStorage.getItem("redirectPathname") ?? "/";
            localStorage.removeItem("redirectPathname");
            navigate(navigateTo);
        } catch (e: any) {
            const status = e.response.status;
            const {
                blockTo,
                failNumber,
                errorMessage
            } = e.response.data;
            if (failNumber) {
                const date = new Date(blockTo);
                const dateStr = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()} ${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
                if (errorMessage === "This account has been blocked") {
                    setLockStatus(`Tài khoản bị khóa, mở khóa lúc ${dateStr}`);
                    setError("")
                    return;
                }
                // Rule lock account
                if (failNumber < 5) {
                    setLockStatus(`Số lần nhập sai còn lại là ${5 - failNumber}`);
                }
                else if (failNumber === 5 || (failNumber > 5 && (failNumber - 8) % 3 === 0)) {
                    setLockStatus(`Tài khoản bị khóa, mở khóa lúc ${dateStr}`);
                } else if (failNumber < 8) {
                    setLockStatus(`Số lần nhập sai còn lại là ${8 - failNumber}`);
                } else if (failNumber < 11) {
                    setLockStatus(`Số lần nhập sai còn lại là ${11 - failNumber}`);
                } else {
                    setLockStatus(`Số lần nhập sai còn lại là ${3 - (failNumber - 5) % 3}`);
                }
            } else {
                setLockStatus("");
            }

            if (status === 401) { // Unauthorized
                setError("Tài khoản hoặc mật khẩu không chỉnh xác");
            }
        }
        finally {
            dispatch(setLoading(false));
        }
    }
    const onGoogleResponse = () => {

    }
    return (
        <div className="flex w-screen h-screen items-center justify-center bg-surface-low">
            <div className="shadow-e-03 w-[478px] p-[32px] flex flex-col gap-[24px] rounded-[12px] overflow-hidden box-border bg-white">
                <div className="flex justify-center">
                    <img src="/images/logo-login.jpg" alt="logo" />
                </div>
                {/* <div className="w-[85%] m-auto">
                    <GoogleLogin
                        clientId="658977310896-knrl3gka66fldh83dao2rhgbblmd4un9.apps.googleusercontent.com"
                        onSuccess={onGoogleResponse}
                        onFailure={onGoogleResponse}
                        cookiePolicy={'single_host_origin'}
                        render={renderProps => (
                            <button onClick={renderProps.onClick} disabled={renderProps.disabled} className="w-full">
                                <div className="flex space-x-2 py-[8px] border border-purple rounded-[6px] w-full justify-center">
                                    <ICGoogle />
                                    <span className="text-primary text-base font-medium">Đăng nhập với Google</span>
                                </div>
                            </button>
                        )}
                    />
                </div> */}
                {/* <div className="flex items-center space-x-4 justify-center pb-6">
                    <div className="w-[60px] h-px bg-n-500" />
                    <div className="text-n-500 font-medium text-center">Hoặc</div>
                    <div className="w-[60px] h-px bg-n-500" />
                </div> */}
                <HRForm form={loginForm} name="loginForm" childNode={<>
                    <div className="form flex flex-col w-full gap-[16px]">
                        <div>
                            <HRInput
                                isError={hasError || !!emailError}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                size="large"
                                maxLength={50}
                                placeholder="Tài khoản/Email"
                                prefix={hasError || !!emailError ? <ICAlert /> : <ICUserV2 fill="#C6C5CA" width={24} height={24} />}
                            />
                            <div className="pt-2 font-medium text-xs text-error-500">{emailError}</div>
                        </div>
                        <div>
                            <HRInputPassword isError={hasError || !!isEmptyPassword}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                size="large"
                                maxLength={20}
                                placeholder="Mật khẩu"
                            />
                            <div className="pt-2 font-medium text-xs text-error-500">{error || isEmptyPassword}</div>
                        </div>
                    </div>
                    {lockStatus && <div className="pt-2 font-medium text-xs text-error-500">{lockStatus}</div>}
                    <div className="flex flex-col w-full gap-[24px]">
                        <div className="flex items-center justify-between">
                            <HRCheckBox
                                className="purple-checkbox"
                                checked={rememberToken} onChange={(e) => {
                                    setRememberToken(e.target.checked)
                                }}>
                                <span className="text-body text-high-em">
                                    Nhớ mật khẩu
                                </span>
                            </HRCheckBox>
                            <Link to="/forgot-password" className="pl-4">
                                <span className="text-body text-info-500 underline font-medium">
                                    Quên mật khẩu
                                </span>
                            </Link>
                        </div>
                        <div>
                            <HRButton htmlType="submit" onClick={onLogin}>
                                Đăng nhập
                            </HRButton>
                        </div>
                        <div className="flex gap-[16px] items-center justify-center text-center text-high-em text-body">
                            <span>Chưa có tài khoản ?</span>
                            <Link to="/register" className="underline text-info-500 font-medium">
                                Đăng ký
                            </Link>
                        </div>

                    </div>
                </>} />
            </div>
        </div>
    )
}
