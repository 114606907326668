import React from 'react';
import { Button, Modal } from "antd"
import { ICCheck } from "../../icons/check"
import { ICWarning } from "../../icons/warning"
import { formatTimeSince } from './../../utils/date';
import { HRButton } from '../../components/button';

export enum DeviceType {
    android = "android",
    ios = "ios",
    mac = "mac",
    window = "window",
    other = "other"
};

export type Device = {
    _id?: string,
    type?: string,
    deviceId?: string;
    isCurrentDevice?: boolean,
    deviceName: string,
    ipAddress?: string;
    isAndroid?: boolean;
    isDesktop?: boolean;
    isIOS?: boolean;
    isMac?: boolean;
    isMobile?: boolean;
    isWindows?: boolean;
    userId?: string;
    location: string,
    lastActive: number,
}
export type DevicesSectionProps = {
    type: "android" | "ios" | "window" | "mac" | "other"
    devices: Device[],
    onActionLogoutDevice?: (type: string, data: Device) => void
}

const typeMapping = {
    window: "máy tính Windows",
    mac: "máy tính Mac",
    android: "thiết bị Android",
    ios: "thiết bị iOS",
    other: "thiết bị khác",
}
const ALERT_DAYS = 30; // 30 days
const HRDevice: React.FC<{
    device: Device,
    onLogoutClicked: (params: Device) => void
}> = ({ device, onLogoutClicked }) => {
    const inActiveDays = Math.floor((Date.now() - device.lastActive) / (1000 * 60 * 60 * 24));
    const onConfirmLogoutDevice = React.useCallback(
        () => {
            onLogoutClicked(device);
        },
        [device, onLogoutClicked],
    );

    return <>
        <div className="px-8 py-3 hover:bg-neutral-bg flex justify-between items-center">
            <div className="space-y-1">
                <div className="font-medium">{device.deviceName}</div>
                <div>{device.location}</div>
                {
                    device.isCurrentDevice
                        ? <div className="flex space-x-2 items-center">
                            <ICCheck />
                            <span>Thiết bị hiện tại</span>
                        </div>
                        : <div>{formatTimeSince(device.lastActive)}</div>
                }
                {
                    inActiveDays > ALERT_DAYS && (
                        <div className="flex space-x-2 items-center">
                            <ICWarning />
                            <div>Đã không hoạt động trong {inActiveDays} ngày</div>
                        </div>
                    )
                }
            </div>
            <div>
                {!device.isCurrentDevice && <Button 
                    onClick={onConfirmLogoutDevice} style={{
                    backgroundColor: "#E2E4EB",
                    color: "#46485C",
                    padding: "16px 24px",
                    height: '40px',
                    border: 0,
                    display: 'flex',
                    fontSize: '14px',
                    borderRadius: '4px',
                    fontWeight: 700,
                    lineHeight: '17px',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    Đăng xuất
                </Button>}
            </div>
        </div>
    </>
}
export const DevicesSection: React.FC<DevicesSectionProps> = ({
    type,
    devices,
    onActionLogoutDevice,
}) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [deviceSelected, setDeviceSelected] = React.useState({} as Device);
    const onLogoutDevice = React.useCallback(
        () => {
            if (onActionLogoutDevice) {
                onActionLogoutDevice(type, deviceSelected);
            }
            setIsOpen(false);
            setDeviceSelected({} as Device);
        },
        [deviceSelected, type, onActionLogoutDevice],
    );
    const onShowConfirmLogoutDevice = React.useCallback(
        (data: Device) => {
            setIsOpen(true);
            setDeviceSelected(data);
        },
        [],
    );
    return <>
        <Modal
            title="Thông báo"
            open={isOpen}
            centered={true}
            onOk={onLogoutDevice}
            onCancel={() => {
                setIsOpen(false);
                setDeviceSelected({} as Device);
            }}
            footer={
                <div className="grid grid-cols-[auto_auto] justify-end gap-[16px]">
                    <HRButton
                        btnSize="sm"
                        btnType="outline"
                        onClick={() => {
                            setIsOpen(false);
                            setDeviceSelected({} as Device);
                        }}>
                        Không
                    </HRButton>
                    <HRButton btnSize="sm" onClick={onLogoutDevice}>
                        Có
                    </HRButton>
                </div>
            }
        >
            <p>Bạn có chắc chắn muốn đăng xuất thiết bị <strong>{deviceSelected?.deviceName}</strong>?</p>
        </Modal>
        <div className="bg-white p-[24px] rounded-[10px] border border-n-300">
            <div className="flex items-center space-x-8">
                <img src={`/images/${type}_device.jpg`} alt="device" className="w-[80px] h-[80px]" />
                <div className="font-bold text-[18px]">
                    <span className="text-primary">{devices.length} hoạt động </span>
                    <span>trên {typeMapping[type]}</span>
                </div>
            </div>
            <div>
                {
                    devices.map((device, index) => <div key={index} className={index !== 0 ? "border-t border-n-300" : ""}>
                        <HRDevice onLogoutClicked={onShowConfirmLogoutDevice} device={device} />
                    </div>
                    )
                }
            </div>
        </div>
    </>
}
