import React from "react";
import { Input, Switch } from "antd";
import { HRButton, IconButton } from "../../../components/button";
import { ICClose, ICCheckV2, ICClock, ICReloadV2, ICCopy, ICLockV2 } from "../../../icons";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useCopyToClipboard } from "../../../hooks/useCopyToClipboard";
import { useAssementDetailsData } from ".";
import { useAssessment } from "../create-assessment/hooks/useAssessment";
import { generateRandomPassword } from "../../../utils";
import { TUpdatePasswordAssessment } from "../type";

export const CreatePasswordAssessment: React.FC = () => {
    const { setPasswordAssessment, activePasswordAssessment } = useAssessment();
    const [password, setPassword] = React.useState('');
    const [confirm, setConfirm] = React.useState(false);
    const { copy } = useCopyToClipboard();
    const { assessmentId, assessmentDetails, setAssessmentDetails, myCandidateCreditInfo } = useAssementDetailsData();
    const [active, setActive] = React.useState(false);
    
    React.useEffect(() => {
        if (assessmentDetails?.password) {
            setPassword(assessmentDetails.password);
            setConfirm(true);
        }
        setActive(assessmentDetails?.activePassword || false);
    }, [assessmentDetails]);

    const handleCreatePassword = React.useCallback(() => {
        if (assessmentDetails?.id && password && password.trim()) {
            setPasswordAssessment(assessmentDetails?.id, password, setAssessmentDetails);
            setConfirm(true);
        }
    }, [assessmentDetails, password, setPasswordAssessment, setAssessmentDetails]);

    const handleCopy = React.useCallback(() => {
        copy(password);
    }, [copy, password]);

    const handleGeneratePassword = React.useCallback(() => {
        const newPassword = generateRandomPassword();
        setPassword(newPassword);
        assessmentDetails?.id && setPasswordAssessment(assessmentDetails?.id, newPassword, setAssessmentDetails);
    }, [assessmentDetails, setAssessmentDetails, setPasswordAssessment]);

    const handleActiveChanged = React.useCallback(() => {
        setActive(prev => !prev);
        const payload: TUpdatePasswordAssessment = {
            activePassword: !active,
        };
        activePasswordAssessment(assessmentId, payload, setAssessmentDetails);
        if (!assessmentDetails?.password && !active) {
            handleGeneratePassword();
        }
    }, [assessmentId, assessmentDetails, handleGeneratePassword, activePasswordAssessment, setAssessmentDetails, active]);

    const isPasswordValid = React.useMemo(() => {
        return password && password.trim();
    }, [password]);

    return <div className="grid grid-cols-1 gap-[32px]">
        <div>
            <div className="flex flex-row gap-[16px] justify-between items-center flex-wrap">
                <div className="grid grid-cols-[56px_1fr_50px] gap-[16px] items-center">
                    {/* <ICCreatePassword /> */}
                    <div className="p-[16px] bg-surface-primary-accent-1 rounded-[44px]">
                        <ICLockV2 />
                    </div>
                    <span className="flex flex-col gap-[4px]">
                        <span className="text-high-em text-title-bold">Thiết lập mật khẩu</span>
                        <span className="text-med-em text-body">
                            Tăng tính bảo mật cho bài đánh giá
                        </span>
                    </span>
                    <Switch checked={active} disabled={myCandidateCreditInfo?.remainingCandidateCredit === 0} style={{
                        width: '36px',
                    }} onChange={handleActiveChanged} />
                </div>
                {/* {active && } */}
                {active && !confirm && <div className="flex flex-row gap-[16px] items-center justify-end">
                    <div className="flex">
                        <Input.Password
                            placeholder="Mật khẩu"
                            prefix={<ICClock />}
                            value={password}
                            maxLength={50}
                            style={{
                                width: '100%',
                                height: '44px',
                                borderRadius: '4px',
                                border: '1px solid #BEC0CA',
                            }}
                            onClick={() => setConfirm(false)}
                            onChange={(e) => setPassword(e.target.value)}
                            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        />
                    </div>
                    <div>
                        <HRButton 
                            onClick={() => setConfirm(true)}
                            btnType="sub"
                            btnSize="sm"
                        >
                            <div className="flex items-center space-x-2 justify-center">
                                <ICClose fill="#46485C" width={18} height={18} />
                                <span>Hủy bỏ</span>
                            </div>
                        </HRButton>
                    </div>
                    <div>
                        <HRButton btnType="primary"
                            disabled={!isPasswordValid}
                            btnSize="sm"
                            onClick={handleCreatePassword}
                        >
                            <div className="flex space-x-2 items-center justify-center">
                                <ICCheckV2 fill={!isPasswordValid ? '#B2B4BF' : 'white'} width={18} height={18} />
                                <span>Xác nhận</span>
                            </div>
                        </HRButton>
                    </div>
                </div>}
                {active && confirm && <div className="flex flex-row gap-[16px] items-center justify-end">
                    <div className="flex">
                        <Input.Password
                            placeholder="Mật khẩu"
                            prefix={<ICClock />}
                            value={password}
                            maxLength={50}
                            style={{
                                width: '100%',
                                height: '44px',
                                borderRadius: '4px',
                                border: '1px solid #BEC0CA',
                            }}
                            onClick={() => setConfirm(false)}
                            onChange={(e) => setPassword(e.target.value)}
                            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        />
                    </div>
                    <div>
                        <IconButton 
                            onClick={handleGeneratePassword}
                            btnSize="sm"
                            disabled={myCandidateCreditInfo?.remainingCandidateCredit === 0}
                            btnType="sub"
                            icon={<ICReloadV2 />}
                        />
                    </div>
                    <div>
                        <IconButton btnType="info"
                            onClick={handleCopy}
                            btnSize="sm"
                            icon={<ICCopy fill="white" width={18} height={18} />}
                        />
                    </div>
                </div>}
            </div>
        </div>
    </div>
}
