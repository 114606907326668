import React from "react"
import { HRButton } from "../../components/button"
import { ICAccount, ICHistory, ICStar } from "../../icons/index"
import { HomeHeader } from "../home/header"
import { Transaction } from "./transaction"
import { getMyCandidateCreditInfo } from "../../api/user"
import { TMyCandidateCreditInfo } from "../my_assessment/type"
import { useNavigate } from "react-router-dom"
import { useGeneralPageData } from "../general"

export const MyAccountPage: React.FC = () => {
    const { setShowComingSoonModal } = useGeneralPageData();
    const navigate = useNavigate();
    const [myCandidateCreditInfo, setMyCandidateCreditInfo] = React.useState<TMyCandidateCreditInfo>({} as TMyCandidateCreditInfo);
    
    const onLoadCandidateCreditInfo = React.useCallback(async () => {
        try {
            const results = await getMyCandidateCreditInfo();
            const { data } = results || {};
            setMyCandidateCreditInfo(data);
        } catch (e) {
            console.error(e)
        }
    }, []);

    React.useEffect(() => {
        onLoadCandidateCreditInfo();
    }, [onLoadCandidateCreditInfo]);

    return (
        <div className="pt-8 space-y-6 pb-16 bg-white">
            <HomeHeader activedIndex={1} />
            <div className="text-left container mx-auto pt-4 space-y-16">

                <div>
                    <div className="flex relative rounded-[24px] min-h-[304px] w-full text-white p-[32px]" style={{
                        background: "linear-gradient(90deg, #F09819 0%, #EDDE5D 100%)",
                        boxShadow: "0px 6px 16px -6px rgba(17, 12, 34, 0.1)",
                    }}>
                        <span className="absolute right-0 top-0">
                            <ICAccount />
                        </span>
                        <div className="flex flex-col gap-[32px]">
                            <div className="flex flex-col gap-[8px]">
                                <h2 className="mb-0 text-white text-[28px] leading-[40px] font-bold">Lượt đánh giá của bạn</h2>
                                <span className="flex gap-[8px] items-center">
                                    <ICStar fill="white" />
                                    <p className="mb-0 text-[14px] font-medium leading-[24px]">{myCandidateCreditInfo?.remainingCandidateCredit} lượt đánh giá </p>
                                </span>
                            </div>
                            <p className="mb-0 max-w-[555px] text-[14px] font-normal leading-[16px]">
                                Lượt đánh giá đóng vai trò quan trọng trong quá trình cho phép ứng viên tham gia vào các bài đánh giá của bạn. Mỗi khi một ứng viên bắt đầu thực hiện một bài đánh giá sẽ tiêu tốn 1 lượt đánh giá. Tất cả các bài đánh giá sẽ sử dụng chung số lượt đánh giá mà bạn có.
                            </p>
                            <HRButton 
                                onClick={() => {
                                    navigate("/candidate-credit/payment");
                                }}
                                style={{
                                    color: "#E09400",
                                    background: "white",
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "208px",
                                    height: "56px",
                                    borderRadius: "8px",
                                    lineHeight: "24px",
                                    boxShadow: "0px 1px 2px -1px rgba(17, 12, 34, 0.08)",
                                }}>
                                <span>Thêm lượt đánh giá</span>
                            </HRButton>
                        </div>
                    </div>
                </div>

                <div className="flex justify-between items-start gap-[32px]">
                    <div className="flex flex-col gap-[8px] max-w-[554px]">
                        <h2 className="mb-0 text-high-em text-title-bold">
                            Thống kê
                        </h2>
                        <p className="mb-0 text-med-em text-body">
                            Đây là bảng thống kê về số lượt đánh giá mà bạn đã sử dụng. Nhằm đánh giá và tối ưu hóa việc quản lý sử dụng dịch vụ của bạn một cách hiệu quả, theo dõi những số liệu này sẽ giúp bạn hiểu rõ hơn về tần suất và cách bạn đã tận dụng các lượt đánh giá đã mua.
                        </p>
                    </div>
                    <div className="flex flex-col justify-center items-start gap-[16px]">
                        <ul className="p-[24px] text-[14px] leading-[24px] font-normal" style={{
                            border: "1px solid #D9D8DC",
                            borderRadius: "12px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                            minWidth: "554px",
                        }}>
                            <li className="flex items-center justify-between pb-[8px]" style={{
                                borderBottom: "1px solid #D9D8DC",
                            }}>
                                <span>
                                    Tổng lượt đánh giá đã mua
                                </span>
                                <span className="font-bold">
                                    {myCandidateCreditInfo?.totalCandidateCreditPurchased}
                                </span>
                            </li>
                            <li className="flex items-center justify-between pb-[8px]" style={{
                                borderBottom: "1px solid #D9D8DC",
                            }}>
                                <span>
                                    Số lượt đã dùng
                                </span>
                                <span className="font-bold">
                                    {myCandidateCreditInfo?.totalUsedCount}
                                </span>
                            </li>
                            <li className="flex items-center justify-between pb-[8px]" style={{
                                borderBottom: "1px solid #D9D8DC",
                            }}>
                                <span>
                                    Số lượt còn lại
                                </span>
                                <span className="font-bold">
                                    {myCandidateCreditInfo?.remainingCandidateCredit}
                                </span>
                            </li>
                            <li className="flex items-center justify-between">
                                <span>
                                    Số lượt dùng trong 7 ngày gần nhất
                                </span>
                                <span className="font-bold">
                                    {myCandidateCreditInfo?.lastSevenDaysUsedCount}
                                </span>
                            </li>
                        </ul>
                        <div className="w-full">
                            <HRButton btnType="sub" 
                                btnSize="sm"
                                onClick={() => setShowComingSoonModal(true)}
                                >
                                    <div className="flex items-center justify-center gap-[8px]">
                                        <span>
                                            Xem lịch sử
                                        </span>
                                        <ICHistory />
                                    </div>
                            </HRButton>
                        </div>

                    </div>
                </div>

                {/* <div className="flex space-x-8">
                    <div className="w-2/3">
                        <div className="text-[24px] font-medium text-n-800 mb-4">Gói</div>
                        <div
                            className="p-8 rounded-[20px]"
                            style={{
                                background: "linear-gradient(90deg, #7357FF 0%, #6E4CF8 100.31%)",
                            }}
                        >
                            <div className="flex justify-between">
                                <div>
                                    <div className="font-bold text-light-purple">Gói hiện tại của bạn</div>
                                    <div className="font-bold text-[24px] text-white">Miễn phí</div>
                                    <div className="text-support-purple text-xs">Với gói miễn phí, bạn có thể sử dụng miễn phí một số bài kiểm tra đã chọn và mười câu hỏi tùy chỉnh cho mỗi bài đánh giá cho số lượng ứng viên và bài đánh giá không giới hạn.</div>
                                    <div></div>
                                </div>
                                <div>
                                    <HRButton style={{
                                        color: "#7357FF",
                                        background: "white",
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        height: "48px",
                                        padding: "14px 22px"
                                    }}>Thay đổi</HRButton>
                                </div>
                            </div>
                            <div className="space-y-2 mt-6">
                                <div className="space-x-2 flex items-center text-white">
                                    <ICVerified />
                                    <span>Số lượng bài thẩm định không giới hạn </span>
                                </div>
                                <div className="space-x-2 flex items-center text-white">
                                    <ICCancel />
                                    <span>Không giới hạn chia sẻ </span>
                                </div>
                                <div className="space-x-2 flex items-center text-white">
                                    <ICCancel />
                                    <span>Lựa chọn các bài kiểm tra chất lượng </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-1/3">
                        <div className="text-[24px] font-medium text-n-800 mb-4">Thanh toán</div>
                        <div className="p-8 border-[2px] border-n-300 rounded-[20px] space-y-4">
                            <div className="text-[18px] font-medium">Phương thức thanh toán</div>
                            <div className="space-y-1">
                                <div className="text-base font-bold text-n-800">Visa: 5342</div>
                                <div className="font-medium text-n-500 text-base">Ngày hết hạn: 15/06/2022</div>
                            </div>
                            <div className="space-y-1">
                                <div className="text-base font-bold text-n-800">Master card: 5342</div>
                                <div className="font-medium text-n-500 text-base">Ngày hết hạn: 15/06/2022</div>
                            </div>
                            <div className="pt-6">
                                <HRButton style={{
                                    background: "white",
                                    color: "#7357FF",
                                    padding: "14px 22px",
                                    border: "1px solid #7357FF",
                                    borderRadius: "6px",
                                    fontSize: "16px",
                                    fontWeight: 700,
                                }}>Cập nhật</HRButton>
                            </div>

                        </div>
                    </div>
                </div> */}


                <div className="h-[2px] bg-n-200"></div>
                <Transaction />
            </div>
        </div>
    )
}
