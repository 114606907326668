import React from "react";
import { Typography } from "antd";
import { useTestingPageContext } from "..";

export const ThankYouTest: React.FC = () => {
    const { companyProfile } = useTestingPageContext();

    return <div className="flex w-full items-center justify-center">
        <div className="rounded-[12px] bg-white shadow-e-03 flex w-full max-w-[562px] flex-col items-start justify-start gap-[32px] p-[32px]">
            <div className="grid grid-cols-1 gap-[16px] items-start justify-center text-left">
                <div className="grid grid-cols-1 gap-[24px]">
                    <Typography.Title style={{
                        fontSize: "28px",
                        lineHeight: "40px",
                        fontWeight: 700,
                        color: "#110C22",
                    }}>Bài đánh giá đã kết thúc</Typography.Title>
                    <Typography.Text style={{
                        fontSize: "16px",
                        lineHeight: "24px",
                        fontWeight: 400,
                        color: "#110C22",
                    }}>Có vẻ như bạn đã hoàn thành bài đánh giá của mình. <br />Xin hãy đợi kết quả từ {companyProfile?.name || 'HUSA'}.</Typography.Text>
                </div>

            </div>
        </div>
    </div>
}
