import React from "react";
import { IconButton } from "../../components/button";
import { ICBack } from "../../icons/back";
import { useNavigate } from "react-router-dom";
import { usePaymentPageContext } from ".";

export const HeaderPayment: React.FC = () => {
    const navigate = useNavigate();
    const { id } = usePaymentPageContext();
    
    return <div className="text-left">
    <div className="flex items-center justify-between">
        <div className="flex items-start gap-[16px]">
            <IconButton btnType="sub" btnSize="sm"
                icon={<ICBack />}
                onClick={() => {
                    navigate(`/assessment/${id}/details`);
                }}
            />
            <span className="text-high-em leading-[40px] text-[28px] font-bold">Tổng quan đơn hàng</span>
        </div>
    </div>
</div>
}
