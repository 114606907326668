import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ICUnccess } from '../../icons';
import { HRButton } from '../../components/button';
import { usePaymentStatusPageContext } from '.';

export const PaymentFailedPage: React.FC = () => {
    const navigate = useNavigate();
    const { orderDetails } = usePaymentStatusPageContext();

    return <div className="rounded-[12px] bg-white shadow-l-large flex w-full max-w-[562px] flex-col items-center justify-center gap-[32px] p-[32px]">
        <ICUnccess />
        <div className="flex flex-col gap-[24px]">
            <h1 className="text-[28px] mb-[0] font-semibold text-center leading-[40px]">Thanh toán thất bại</h1>
            <div className="flex flex-col gap-[4px]">
                <h4 className="text-[14px] mb-[0] leading-[24px] font-normal text-center">
                    Xin lỗi bạn vì sự bất tiện này.
                </h4>
                <h4 className="text-[14px] mb-[0] leading-[24px] font-normal text-center">
                    Đã có trục trặc xảy ra trong quá trình thanh toán,
                    vui lòng thực hiện lại thanh toán.
                </h4>
                <h4 className="text-[14px] mb-[0] leading-[24px] font-normal text-center">
                    Nếu sự cố này vẫn diễn ra, xin hãy liên hệ với đội ngũ hỗ trợ.
                </h4>
                <h4 className="text-[14px] mb-[0] leading-[24px] font-normal text-center">
                    Cảm ơn bạn.
                </h4>
            </div>
            <div className="flex flex-col items-center justify-center gap-[16px]">
                <HRButton btnType="secondary"
                    onClick={() => {
                        if (orderDetails?.difinition?.assessment?._id) {
                            navigate(`/assessment/${orderDetails?.difinition?.assessment?._id}/details`)
                        } else {
                            navigate(`/my-assessment`)
                        }
                    }}
                    // style={{
                    //     color: 'white',
                    //     fontSize: '16px',
                    //     lineHeight: '24px',
                    //     fontWeight: 'bold',
                    //     height: '56px',
                    //     borderRadius: '8px',
                    //     border: '1px solid #ECECED',
                    //     backgroundColor: '#0084FF',
                    //     boxShadow: '0px 1px 2px -1px rgba(17, 12, 34, 0.08)',
                    // }}
                >
                    Trở về
                </HRButton>
                {/* <HRButton btnType="outline" 
                    style={{
                        color: '#4F4B5C',
                        fontSize: '16px',
                        lineHeight: '24px',
                        fontWeight: 'bold',
                        height: '56px',
                        borderRadius: '8px',
                        border: '1px solid #ECECED',
                    }}>
                        Hỗ trợ
                </HRButton> */}
            </div>
        </div>
    </div>
}
