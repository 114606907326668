import React from "react";
import { ICNext, ICSuccess } from "../../../icons";
import { Typography } from "antd";
import { HRButton } from "../../../components/button";
import { ICClockTime } from '../../../icons/clock_time';
import { useTestingPageContext } from "..";
import { useTesting } from "../hooks/useTesting";
import { STEPS } from "../type";
import { ErrorLink } from "../../../components/errorLink";
import { ERROR_CODE, ERROR_CODE_TITLE_MESSAGE, ERROR_CODE_DESC_MESSAGE } from "../../../utils/constants";

export const FinishTest: React.FC = () => {
    const { getCurrentSectionTesting } = useTesting();
    const [errorCodeCandidate, setErrorCodeCandidate] = React.useState<number | undefined>(undefined);
    const { assessmentDetails, sectionTesting, setSectionTesting, setStep, handleFullScreen } = useTestingPageContext();

    const onBeginTesting = React.useCallback(() => {
        assessmentDetails?._id && getCurrentSectionTesting(assessmentDetails?._id, handleFullScreen, setSectionTesting, setStep, setErrorCodeCandidate);
    }, [assessmentDetails, handleFullScreen, setSectionTesting, getCurrentSectionTesting, setStep]);

    const onTakeBreak = React.useCallback(() => {
        setStep(STEPS.TAKE_BREAK);
    }, [setStep]);

    const titleError = React.useMemo(() => {
        if (errorCodeCandidate === ERROR_CODE.LOCKED) {
            return ERROR_CODE_TITLE_MESSAGE[errorCodeCandidate];
        }
        return undefined;
    }, [errorCodeCandidate]);
    const descriptionError = React.useMemo(() => {
        if (errorCodeCandidate === ERROR_CODE.LOCKED) {
            return ERROR_CODE_DESC_MESSAGE[errorCodeCandidate];
        }
        return undefined;
    }, [errorCodeCandidate]);
    
    return errorCodeCandidate ?
        <ErrorLink title={titleError} description={descriptionError}/>
        :
        <div className="flex px-[32px] py-[54px] w-full items-center justify-center">
            <div className="rounded-[12px] bg-white shadow-e-03 flex w-full max-w-[754px] flex-col items-center justify-center gap-[32px] p-[54px]">
                <ICSuccess />
                <div className="grid grid-cols-1 gap-[16px] items-center justify-center text-center">
                    <Typography.Title style={{
                        fontSize: "24px",
                        lineHeight: "30px",
                    }}>Hoàn thành!</Typography.Title>
                    <div className="grid grid-cols-1 gap-[4px] items-center justify-center text-center">
                        <Typography.Text style={{
                            fontSize: "14px",
                            lineHeight: "17px",
                            fontWeight: 500,
                        }}>Bạn vừa hoàn thành phần thi <span className="font-bold text-[#46485C] text-[14px]">{sectionTesting?.title}</span></Typography.Text>
                        <Typography.Text style={{
                            fontSize: "14px",
                            lineHeight: "17px",
                            fontWeight: 500,
                        }}>Bạn có thể nghỉ ngơi trước khi quay lại làm phần thi kế tiếp.</Typography.Text>
                    </div>
                </div>
                <div className="flex gap-[16px]">
                    <HRButton
                        onClick={onTakeBreak}
                        btnType="primary"
                    >
                        <div className="text-[16px] text-white font-bold flex space-x-2 items-center justify-center">
                            <ICClockTime fill="white" stroke="white" />
                            <span>Nghỉ ngơi</span>
                        </div>
                    </HRButton>
                    <HRButton
                        onClick={onBeginTesting}
                        btnType="outline"
                    >
                        <div className="text-[16px] font-bold text-[#7357FF] leading-[19px] flex space-x-2 items-center justify-center">
                            <span>Tiếp tục phần thi kế tiếp</span>
                            <ICNext fill="#46485C" />
                        </div>
                    </HRButton>
                </div>
            </div>
        </div>
}
