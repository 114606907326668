import { Input, Tooltip } from "antd"
import React from "react"
import { ICCircleInfo, ICQuestion } from "../../../../icons"

type SectionHeaderProps = {
    title: string;
    hint: string;
    hasDescription?: boolean;
}
export const SectionHeader: React.FC<SectionHeaderProps> = ({
    title,
    hint,
    hasDescription,
}) => {
    return <div className="flex flex-col gap-[8px]">
        <div className="flex flex-row gap-[8px]">
            <span className="text-heading-6-medium text-primary-bold">{title}</span>
            <Tooltip
                overlayStyle={{ maxWidth: '440px' }}
                placement="right"
                title={<div className="flex items-center space-x-3">
                    <span className="px-1">
                        <ICCircleInfo />
                    </span>
                    <div className="max-w-[300px] pr-2">{hint}</div>
                </div>}>
                <span>
                    <ICQuestion />
                </span>
            </Tooltip>
        </div>
        {hasDescription && <div className="flex w-1/2">
            <Input
                style={{
                    borderRadius: "4px",
                    border: 0,
                    fontStyle: "italic",
                    fontSize: "14px",
                    lineHeight: "24px",
                    color: "#676472",
                }}
                placeholder="Mô tả..."
            />
        </div>}
    </div>
}
