import React from "react";
import { htmlToText } from "html-to-text";
import { ICChevronUp, ICChevronDown, ICInfoV2, ICForward, ICWarning } from "../../../icons";
import { TSectionTesting, TTestingCandidate } from "../../testing/type";
import { HRTooltip } from "../../../components/tooltip";
import { EQuestionType, ESkillAreaType } from "../../create_test/tab/question/type";
import { useCandidateDetailsData } from "..";
import { LevelATTeam, LevelTalentCompass } from "../data";
import classNames from "classnames";
import { DetailGroupQuestion } from "./detail-group";
import { DetailSpeakingQuestion } from "./detail-speaking-question";

type QuestionCandidateDetailsProps = {
    candidateId: string;
    testQuestions: TTestingCandidate[];
    setSelectSkillArea: React.Dispatch<React.SetStateAction<TSectionTesting | undefined>>;
    setShowEssayModal: React.Dispatch<React.SetStateAction<boolean>>;
    setTestQuestions: React.Dispatch<React.SetStateAction<TTestingCandidate[]>>;
};

export const QuestionCandidateDetails: React.FC<QuestionCandidateDetailsProps> = ({
    candidateId,
    testQuestions = [],
    setShowEssayModal,
    setSelectSkillArea,
    setTestQuestions,
}) => {
    const {
        setOpenDrawer,
        setDataDrawSelected,
    } = useCandidateDetailsData();

    const onToggleCollapse = React.useCallback((index: number) => {
        setTestQuestions(prev => {
            prev[index].isCollapsed = !prev[index].isCollapsed;
            return [...prev];
        });
    }, [setTestQuestions]);
    return <div className="flex flex-col gap-[24px]">
        {
            testQuestions?.map((item, index) => {
                const hasAtTeam = item?.sections?.some((section) => section?.type === ESkillAreaType.ATTEAM);
                const hasNoneScoreList = item?.sections?.filter((section) => section?.questions?.filter(d => d?.type === EQuestionType.ESSAY || d?.type === EQuestionType.GROUP)
                    .some((question) => question?.score === null || question?.score === undefined));
                return (
                    <div key={index} className="flex flex-col px-[24px] py-[16px] border border-outline-med rounded-[12px] shadow-e-01">
                        <div className="grid grid-cols-[1fr_auto] gap-[8px]">
                            <div className="flex flex-col items-start justify-center gap-[8px]">
                                {
                                    hasNoneScoreList?.length === 0 ? (<div className="text-body-medium text-primary-bold">
                                        Đề kiểm tra
                                    </div>) : (
                                        <div className="flex flex-row items-center gap-[8px]">
                                            <ICWarning fill="#F03D3D" />
                                            <div className="text-body-medium text-error">
                                                Sau khi hoàn tất đánh giá tự luận, điểm kiểm tra sẽ hiển thị chính xác.
                                            </div>
                                        </div>
                                    )
                                }
                                <div className="flex flex-col gap-[8px] w-full">
                                    <div className="flex gap-[18px] cursor-pointer min-h-[40px]" onClick={() => onToggleCollapse(index)}>
                                        <div>
                                            {item?.isCollapsed ? <ICChevronUp /> : <ICChevronDown />}
                                        </div>
                                        <div className="text-body-medium text-high-em">
                                            <span className="max-w-[500px] line-clamp-1">
                                                {item.title}
                                            </span>
                                        </div>
                                    </div>
                                    {item?.isCollapsed && <div className="flex flex-col pl-[42px] w-full">
                                        {
                                            item?.sections?.map((section, index) => {
                                                // const atTeamSection = section?.type === ESkillAreaType.ATTEAM;
                                                const hasSpeaking = section.questions.some((question) => question?.type === EQuestionType.SPEAKING);
                                                const hasEssay = section?.questions?.some((question) => question?.type === EQuestionType.ESSAY);
                                                const hasNoneScore = section?.questions?.filter(d => d.type === EQuestionType.ESSAY || d.type === EQuestionType.GROUP)
                                                    .some((question) => question?.score === null || question?.score === undefined) || hasSpeaking;
                                                return <div key={index}>
                                                    <div
                                                        className={classNames([
                                                            'flex flex-row justify-center w-full group min-h-[40px]',
                                                        ])}
                                                    >
                                                        <div
                                                            className={classNames([
                                                                'flex w-full h-full flex-row justify-between gap-[15px] pl-[14px] py-[8px] text-left border-l-[2px] border-outline-low',
                                                                {
                                                                    'bg-surface-error-accent-base border-outline-error-med': hasNoneScore,
                                                                },
                                                                {
                                                                    'group-hover:bg-surface-error-accent-1 group-hover:border-outline-error': hasNoneScore,
                                                                },
                                                                {
                                                                    'group-hover:bg-surface-low': !hasNoneScore,
                                                                },
                                                            ])}
                                                        >
                                                            <div className="flex gap-[8px]">
                                                                <div>
                                                                    <span className="max-w-[500px] line-clamp-1 text-high-em">
                                                                        {htmlToText(section?.name || '')}
                                                                    </span>
                                                                </div>
                                                                {section?.description && <span className="opacity-0 group-hover:opacity-100">
                                                                    <HRTooltip content={section?.description as string}
                                                                        placement={'right'}
                                                                        childNode={<><ICInfoV2 fill="#C6C5CA" /></>}>
                                                                    </HRTooltip>
                                                                </span>}
                                                            </div>
                                                            {hasEssay && <div className="flex gap-[8px] cursor-pointer justify-end" onClick={() => {
                                                                setSelectSkillArea(section);
                                                                setShowEssayModal(true);
                                                            }}>
                                                                {hasNoneScore && <div className="flex gap-[8px] cursor-pointer min-w-[170px]">
                                                                    <span className="text-body-medium text-error group-hover:underline">
                                                                        Chấm điểm tự luận
                                                                    </span>
                                                                    <span><ICForward fill="#F03D3D" /></span>
                                                                </div>}
                                                                {
                                                                    !hasNoneScore && <div className="flex gap-[8px] cursor-pointer min-w-[170px]">
                                                                        <span className="text-body-medium text-low-em group-hover:underline group-hover:text-icon-high-em">
                                                                            Xem câu hỏi
                                                                        </span>
                                                                        <span className="flex group-hover:hidden"><ICForward fill="#B3B1B8" /></span>
                                                                        <span className="hidden group-hover:flex"><ICForward fill="#4F4B5C" /></span>
                                                                    </div>
                                                                }
                                                            </div>}
                                                        </div>
                                                    </div>
                                                    <div className="space-y-4 mt-2">
                                                        {
                                                            section.questions.map(q => <React.Fragment key={q._id}>
                                                                {
                                                                    q.type === EQuestionType.GROUP && <DetailGroupQuestion
                                                                        candidateId={candidateId}
                                                                        testCandidate={item}
                                                                        section={section}
                                                                        setQuestions={setTestQuestions}
                                                                        question={q}
                                                                    />
                                                                }

                                                                {
                                                                    q.type === EQuestionType.SPEAKING && <DetailSpeakingQuestion
                                                                        question={q}
                                                                        setQuestions={setTestQuestions}
                                                                        candidateId={candidateId}
                                                                        testCandidate={item}
                                                                        section={section}
                                                                    />
                                                                }
                                                            </React.Fragment>)
                                                        }
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>}
                                </div>
                            </div>
                            <div className="flex gap-[8px]">
                                {/* {hasAtTeam && <div className="flex flex-col items-center gap-[8px] min-w-[120px] justify-start border border-outline-med rounded-[8px] py-[8px]">
                                    <div className="flex items-center justify-center gap-[8px] cursor-pointer" 
                                        onClick={() => {
                                            setOpenDrawer(true);
                                            setDataDrawSelected(LevelATTeam);
                                        }}>
                                        <span className="text-body-medium text-high-em">
                                            Cấp độ
                                        </span>
                                        <ICInfoV2 fill="#C6C5CA" />
                                    </div>
                                    <div className="flex flex-col items-center justify-center text-center text-standard-bold cursor-pointer min-h-[40px]" onClick={() => onToggleCollapse(index)}>
                                        {item?.atteamRating}
                                    </div>
                                    {item?.isCollapsed && <div className="flex flex-col items-center w-full">
                                        {
                                            item?.sections?.map((section, index) => {
                                                const hasNoneScore = section?.questions?.filter(d => d.type === EQuestionType.ESSAY)
                                                    .some((question) => question?.score === null || question?.score === undefined);
                                                return <div key={index} 
                                                    className={classNames([
                                                        'flex flex-row w-full items-center justify-center gap-[15px] py-[8px] min-h-[40px]',
                                                        {
                                                            'bg-surface-low ': hasNoneScore,
                                                        }
                                                    ])}
                                                >
                                                    <span className={classNames([
                                                        {
                                                            'text-error': hasNoneScore,
                                                        }
                                                    ])}>
                                                        {section?.atteamRating || ''}
                                                    </span>
                                                </div>
                                            })
                                        }
                                    </div>}
                                </div>} */}
                                <div
                                    className={classNames([
                                        'flex text-body-medium border rounded-[8px] py-[8px]',
                                        {
                                            'bg-surface-primary-accent-base text-primary-bold border-outline-primary-med': hasNoneScoreList?.length === 0,
                                        },
                                        {
                                            'text-high-em border-outline-error-med': hasNoneScoreList?.length !== 0,
                                        }
                                    ])}
                                >
                                    <div className="flex items-start gap-[8px] w-full min-w-[120px] justify-center">
                                        <div className="flex flex-col items-center justify-center gap-[8px] w-full">
                                            <div className="flex items-center justify-center gap-[8px] cursor-pointer"
                                                onClick={() => {
                                                    setOpenDrawer(true);
                                                    setDataDrawSelected(LevelTalentCompass);
                                                }}>
                                                <span className="text-body-medium">
                                                    Cấp độ
                                                </span>
                                                <ICInfoV2 fill="#C6C5CA" />
                                            </div>
                                            <div
                                                className={classNames([
                                                    'flex flex-col w-full items-center justify-center text-center text-standard-bold cursor-pointer min-h-[40px]',
                                                    {
                                                        'text-error bg-surface-error-accent-base': hasNoneScoreList?.length !== 0,
                                                    }
                                                ])}
                                                onClick={() => onToggleCollapse(index)}>
                                                {item?.score === -1 ? '-' : item?.standardRating}
                                            </div>

                                            {item?.isCollapsed && <div className="flex flex-col items-center w-full">
                                                {
                                                    item?.sections?.map((section, index) => {
                                                        const hasNoneScore = section?.questions?.filter(d => d.type === EQuestionType.ESSAY)
                                                            .some((question) => question?.score === null || question?.score === undefined);
                                                        return <div key={index}
                                                            className={classNames([
                                                                'flex flex-row w-full justify-center gap-[15px] py-[8px] min-h-[40px]',
                                                                {
                                                                    'bg-surface-error-accent-base': hasNoneScore,
                                                                }
                                                            ])}
                                                        >
                                                            <span className={classNames([
                                                                {
                                                                    'text-error': hasNoneScore,
                                                                }
                                                            ])}>
                                                                {section?.score === -1 ? '-' : section?.standardRating || ''}
                                                            </span>
                                                        </div>
                                                    })
                                                }
                                            </div>}

                                        </div>
                                    </div>
                                    <div className="flex items-start gap-[8px] w-full min-w-[120px] justify-center">
                                        <div className="flex flex-col w-full items-center justify-center gap-[8px]">
                                            <div className="flex items-center justify-center gap-[8px] cursor-pointer" onClick={() => onToggleCollapse(index)}>
                                                <span className="text-body-medium">
                                                    Điểm số
                                                </span>
                                            </div>
                                            <div
                                                className={classNames([
                                                    'flex flex-col w-full items-center justify-center text-center text-standard-bold cursor-pointer min-h-[40px]',
                                                    {
                                                        'text-error bg-surface-error-accent-base': hasNoneScoreList?.length !== 0,
                                                    }
                                                ])}
                                                onClick={() => onToggleCollapse(index)}>
                                                {(item?.score !== undefined && item?.score !== null && item?.score !== -1) ? Math.round(item?.score) + '%' : '-'}
                                            </div>
                                            {item?.isCollapsed && <div className="flex flex-col items-center w-full">
                                                {
                                                    item?.sections?.map((section, index) => {
                                                        const hasNoneScore = section?.questions?.filter(d => d.type === EQuestionType.ESSAY)
                                                            .some((question) => question?.score === null || question?.score === undefined);
                                                        return <div key={index}
                                                            className={classNames([
                                                                'flex flex-row w-full justify-center gap-[15px] py-[8px] min-h-[40px]',
                                                                {
                                                                    'bg-surface-error-accent-base': hasNoneScore,
                                                                }
                                                            ])}
                                                        >
                                                            <span className={classNames([
                                                                {
                                                                    'text-error': hasNoneScore,
                                                                }
                                                            ])}>
                                                                {(section?.score !== undefined && section?.score !== null && section?.score !== -1) ? Math.round(section?.score) + '%' : '-'}
                                                            </span>
                                                        </div>
                                                    })
                                                }
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                )
            })
        }
    </div>
}
