import { uuid } from "./question/helper";
import { EQuestionType, TMultipleChoiceQuestion, TQuestion, TSingleChoiceQuestion, generateOption } from "./question/type";

type BaseQuestion = {
    question: string,
    choices: string[],
}
type SingleChoiceQuestion = BaseQuestion & {
    type: EQuestionType.SINGLE_CHOICE,
    answer: string,
}
type MultipleChoiceQuestion = BaseQuestion & {
    type: EQuestionType.MULTIPLE_CHOICE,
    answer: string[],
}
export type Question = SingleChoiceQuestion | MultipleChoiceQuestion;
export function processAssessment(question: Question[]): TQuestion[] {
    return question.map((q) => {
        if (q.type === EQuestionType.SINGLE_CHOICE) {
            return {
                id: uuid(),
                type: EQuestionType.SINGLE_CHOICE,
                content: q.question,
                options: q.choices.map(generateOption),
                correctAnswer: q.choices.indexOf(q.answer),
                isMixed: false,
                isActive: true,
            } as TSingleChoiceQuestion
        } else {
            return {
                id: uuid(),
                type: EQuestionType.MULTIPLE_CHOICE,
                content: q.question,
                options: q.choices.map(generateOption),
                correctAnswer: q.answer.map((a) => q.choices.indexOf(a)),
                isMixed: false,
                isActive: true,
                scoreCombination: {},
            } as TMultipleChoiceQuestion;
        } 
    }) as TQuestion[];
}