type TErrorLink = {
    title?: string | undefined;
    description?: string | undefined;
}
export const ErrorLink: React.FC<TErrorLink> = ({
    title,
    description,
}) => {
    return (
        <div className="container mx-auto rounded-[12px] flex justify-center items-center">
            <div className="flex bg-white max-w-[562px] rounded-[12px] p-[32px] shadow-e-03 box-border overflow-hidden">
                <div className="flex flex-col gap-[24px]">
                    <h2 className="mb-0 text-heading-5-bold text-high-em">{title || 'Bài đánh giá chưa sẵn sàng'}</h2>
                    <p className="text-standard text-high-em">
                        {description || 'Xin lỗi vì sự bất tiện này, đã có lỗi xảy ra trong quá trình chuẩn bị bài đánh giá từ nhà tuyển dụng. Vui lòng liên hệ đến nhà tuyển dụng về tình trạng này để có thể được giải quyết sớm nhất'}
                    </p>
                </div>
            </div>
        </div>
    )
}
