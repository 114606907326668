import React from 'react';
import { useParams } from 'react-router-dom';
import { usePreviewTestingAssessment } from './hooks/usePreviewTestingAssessment';
import { PreviewTestDetail } from './test/test';
import { TAssessment } from '../my_assessment/create-assessment/type';

type TPreviewTestingAssessmentPage = {
    id: string;
    testDetails: TAssessment;
    setTestDetails: (testDetails: TAssessment) => void;
}
export const PreviewTestingAssessmentPageContext = React.createContext<TPreviewTestingAssessmentPage>({} as TPreviewTestingAssessmentPage);
export const usePreviewTestingAssessmentPageContext = () => React.useContext(PreviewTestingAssessmentPageContext);

export const PreviewTestingAssessmentPage: React.FC = () => {
    const { id = '' } = useParams();
    const { loadTestDetails } = usePreviewTestingAssessment();
    const [testDetails, setTestDetails] = React.useState<TAssessment>({} as TAssessment);

    React.useEffect(() => {
        if (id) {
            loadTestDetails(id, setTestDetails);
        }
    }, [id, loadTestDetails]);

    return <PreviewTestingAssessmentPageContext.Provider
        value={{
            id,
            testDetails,
            setTestDetails
        }}
    >
        <div className="container mx-auto rounded-[12px] flex justify-center items-center">
            <div className="flex items-center justify-center">
                <PreviewTestDetail />
            </div>
        </div>
    </PreviewTestingAssessmentPageContext.Provider>
}
