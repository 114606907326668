import React from "react";
import { useCreateTestPageContext } from "../.."
import { HRButton, IconButton } from "../../../../components/button"
import { ICAdd, ICClose, ICDelete, ICLock, ICLocked, ICTick, ICWarning } from "../../../../icons"
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./editor.style.css";
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { answerToolbar, isAnswerError, isEmptyHTML, toolbar } from "./editor.config";
import { createDefaultQuestion, EQuestionType, ESkillAreaType, generateOption, TChoiceQuestion, TQuestion } from "./type";
import { Checkbox, Modal, Radio } from "antd";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import classNames from 'classnames'
import { getInnerText, isDuplicateAnswer } from "./helper";
import styled from 'styled-components';
import { MAX_QUESTIONS_IN_GROUP } from "./contants";
import _ from "lodash";
import { useNotification } from "../../../../hooks/useNotification";
import { HRSelect } from "../../../../components/form/select";
import { TScoreLevels } from "../../../library/models";
import { htmlToText } from "../../../../utils/htmlToText";

const WrapperEditor = styled.div`
    &.read-only-mode {
        background: transparent;
        border-radius: 8px;
        padding: 16px;
        border: 1px solid #ECECED;
        .ant-radio-wrapper {
            margin: 0 !important;
        }
        .anwser-questions {
            background: transparent;
            display: grid;
            .public-DraftStyleDefault-block {
                padding: 0 !important;
            }
        }
    }
    &.read-only-mode.has-value {
        border: 1px solid #7357FF;
    }
`;

type MutilQuestionAtTeamProps = {
    selectedQuestion: TChoiceQuestion | undefined;
    questionType: EQuestionType.SINGLE_CHOICE | EQuestionType.MULTIPLE_CHOICE;
    onCancel: () => void
}
function product_Range(a: number, b: number) {
    var prd = a, i = a;

    while (i++ < b) {
        prd *= i;
    }
    return prd;
}


function combinations(n: number, r: number) {
    if (n == r || r == 0) {
        return 1;
    }
    else {
        r = (r < n - r) ? n - r : r;
        return product_Range(r + 1, n) / product_Range(1, n - r);
    }
}
const totalCombination = (n: number) => {
    return Array.from({ length: n }, (_, i) => i + 1).reduce((acc, item) => {
        return combinations(n, item) + acc;
    }, 0);
}
export const MutilQuestionAtTeam: React.FC<MutilQuestionAtTeamProps> = ({ selectedQuestion, questionType, onCancel }) => {
    const [question, setQuestion] = React.useState<TChoiceQuestion>(() => selectedQuestion ?? createDefaultQuestion(questionType) as TChoiceQuestion)
    const {
        form,
        selectedSection,
        setShowAddQuestion,
        onCreatedQuestion,
        skillAreaTypeSelected,
        scoreLevelsQuestion,
    } = useCreateTestPageContext();
    const [needCheck, setNeedCheck] = React.useState<boolean>(false);
    const {
        showSuccess
    } = useNotification();

    const [editorQuestionState, setEditorQuestionState] = React.useState<EditorState>(
        EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(question?.content).contentBlocks))
    )
    const [selectedQuestionLevel, setSelectedQuestionLevel] = React.useState<Record<string, string[][]>>({});
    const [otherLevel, setOtherLevel] = React.useState<string>("");

    React.useEffect(() => {
        const q = selectedQuestion ?? createDefaultQuestion(questionType) as TChoiceQuestion
        setQuestion(q)
        setEditorQuestionState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(q.content).contentBlocks)))
    }, [selectedSection, questionType, selectedQuestion]);

    const invalidMaxLengthEditorQuestion = React.useMemo(() => {
        const text = editorQuestionState.getCurrentContent().getPlainText()
        const lines = text.split(/\n/);
        return text.length > 5000 || lines.length > 50;
    }, [editorQuestionState]);

    const isDirty = React.useMemo(() => {
        if (!selectedQuestion) return getInnerText(question.content).replace(/\n/g, '') !== ""
            || question.options.some(o => getInnerText(o.content).replace(/\n/g, '') !== "")
            || (question.type === EQuestionType.SINGLE_CHOICE && question.correctAnswer !== 0)
            || (question.type === EQuestionType.MULTIPLE_CHOICE && !_.isEqual(question.correctAnswer, [0]))
            || !_.isEqual(question.positionLockedOptions, [])

        return question.content !== selectedQuestion?.content
            || question.options.some((o, i) => o !== selectedQuestion?.options[i])
            || question.correctAnswer !== selectedQuestion?.correctAnswer
            || question.positionLockedOptions !== selectedQuestion?.positionLockedOptions
    }, [question, selectedQuestion]);

    const onEditorStateChange = (valueEditorState: EditorState) => {
        const text = valueEditorState.getCurrentContent().getPlainText()
        const oldContent = editorQuestionState.getCurrentContent().getPlainText()
        // const lines = text.split(/\n/);
        if (text.length > 5000) {
            const oldState = ContentState.createFromBlockArray(htmlToDraft(oldContent).contentBlocks);
            setEditorQuestionState(EditorState.moveFocusToEnd(EditorState.push(editorQuestionState, oldState, 'insert-characters')));
        } else {
            setEditorQuestionState(valueEditorState);
            setQuestion(prev => ({
                ...prev,
                content: draftToHtml(convertToRaw(valueEditorState.getCurrentContent()))
            }))
        }
        // else if (lines.length > 50) {
        //     const undo = EditorState.undo(valueEditorState)
        //     setEditorQuestionState(undo)
        // }
    }

    const isQuestionError = React.useMemo(() => {
        return needCheck && (isEmptyHTML(question.content) || invalidMaxLengthEditorQuestion);
    }, [needCheck, question.content, invalidMaxLengthEditorQuestion]);

    const inValidScoreCombination = React.useMemo(() => {
        const isEmptySelection = Object.keys(selectedQuestionLevel).every((key) => {
            const values = selectedQuestionLevel[key];
            return values?.length === 0;
        });
        return _.isEqual(selectedQuestionLevel, {}) || isEmptySelection;
    }, [selectedQuestionLevel]);

    const validationValues = (): boolean => {
        setNeedCheck(true);
        return isEmptyHTML(question.content)
            || question.options.some(o => isEmptyHTML(o.content))
            || isDuplicateAnswer(question.options)
            // || isAnswerError(question)
            || inValidScoreCombination
            || invalidMaxLengthEditorQuestion
            || !otherLevel;
    };

    const onAddOtherLevel = (levelId: string) => {
        setOtherLevel(levelId);
    }
    React.useEffect(() => {
        const selected = Object.keys(question?.scoreCombination || {}).reduce((acc: Record<string, string[][]>, key: string) => {
            const values = question.scoreCombination[key] as number[][];
            const newValues = values.map((item) => {
                const hasOtherLevel = item.some((idx) => idx === -1);
                if (hasOtherLevel) {
                    setOtherLevel(key);
                }
                return item.filter(idx => idx >= 0).map((idx: number) => {
                    return question.options[idx].id;
                })
            }).filter(item => item.length > 0);
            acc[key] = newValues;
            return acc;
        }, {});
        setSelectedQuestionLevel(selected);
    }, [question.scoreCombination]);

    const addScoreAndMixAnswer = (question: TChoiceQuestion) => {
        const newQuestion = { ...question };
        if (!newQuestion.isMixed) {
            newQuestion.positionLockedOptions = [];
        }
        const optionIdToIndex = newQuestion.options.reduce((acc: Record<string, number>, item, index) => {
            acc[item.id] = index;
            return acc;
        }, {});
        newQuestion.scoreCombination = Object.keys(selectedQuestionLevel).reduce((acc: Record<string, number[][]>, key: string) => {
            const values = selectedQuestionLevel[key].map(combination => {
                return combination.map((id: string) => {
                    return optionIdToIndex[id];
                })
            })
            acc[key] = values;
            return acc;
        }, {});
        if (otherLevel) {
            newQuestion.scoreCombination[otherLevel] = (newQuestion.scoreCombination[otherLevel] ?? []).concat([[-1]])
        }
        return newQuestion;
    }

    const onCreateNewQuestion = () => {
        onCreatedQuestion(addScoreAndMixAnswer(question));
    }

    const onSave = () => {
        if (!validationValues()) {
            onCreateNewQuestion();
            setShowAddQuestion(false)
        }
    }

    const onSaveAndAddAnother = () => {
        if (!validationValues()) {
            onCreateNewQuestion();
            showSuccess("Thành công", "Thêm mới câu hỏi thành công")
            setNeedCheck(false);
            const defaultQuestion = createDefaultQuestion(questionType) as TChoiceQuestion
            setQuestion(defaultQuestion)
            setEditorQuestionState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(defaultQuestion.content).contentBlocks)))
        }
    }
    const [showCancelModal, setShowCancelModal] = React.useState<boolean>(false)
    const onCancelAction = React.useCallback(() => {
        if (isDirty) {
            setShowCancelModal(true)
        } else {
            onCancel()
        }
    }, [isDirty, setShowCancelModal, onCancel]);

    const inactiveAddQuestion = () => {
        if (!selectedSection) {
            return false;
        }
        return selectedSection?.questions.length >= MAX_QUESTIONS_IN_GROUP;
    };

    const title = React.useMemo(() => {
        return editorQuestionState.getCurrentContent().getPlainText();
    }, [editorQuestionState]);
    const selectedAnswerText = React.useMemo(() => {
        const correctAnswer = (Array.isArray(question.correctAnswer) ? question.correctAnswer : [question.correctAnswer]).filter(e => e !== undefined)
        return correctAnswer.map(item => {
            return String.fromCharCode("A".charCodeAt(0) + item);
        }).join(" + ");
    }, [question]);
    const totalCombinationQuestion = React.useMemo(() => {
        return totalCombination(question.options.length);
    }, [question.options.length]);
    /**
     * Map:
     * {
     *   s: [["A","B"], ["C"]],
     *   m: [["A","C"], ["B"]],
     * }
     */
    const totalLeftCombination = React.useMemo(() => {
        const totalSeletedCombination = Object.keys(selectedQuestionLevel).reduce((acc, key) => {
            const values = selectedQuestionLevel[key];
            return acc + values.length;
        }, 0);
        return totalCombinationQuestion - totalSeletedCombination;
    }, [totalCombinationQuestion, selectedQuestionLevel]);
    const onChoiceLevel = (value: string) => {
        if (!selectedAnswerText) return;
        const selectedQuestions = Array.isArray(question.correctAnswer) ? question.correctAnswer : [question.correctAnswer];
        const selectedQuestionsIds = selectedQuestions.map(idx => question.options[idx].id);
        const joinedSelectedQuestionText = [...selectedQuestionsIds].sort().join("");
        setSelectedQuestionLevel(prev => {
            // Loop through selectedQuestions find the same selectedQuestion
            const newSelectedQuestions = Object.keys(prev).reduce((acc: Record<string, any[]>, key: string) => {
                const values = prev[key];
                const filteredValues = values.filter((item) => {
                    return [...item].sort().join("") !== joinedSelectedQuestionText;
                })
                return {
                    ...acc,
                    [key]: filteredValues
                }
            }, {});
            if (!newSelectedQuestions[value]) {
                return {
                    ...newSelectedQuestions,
                    [value]: [selectedQuestionsIds]
                }
            } else {
                return {
                    ...newSelectedQuestions,
                    [value]: [...newSelectedQuestions[value], selectedQuestionsIds]
                }
            }
        })
    }
    const isSelectedLevel = (value: string) => {
        const selectedQuestions = (Array.isArray(question.correctAnswer) ? question.correctAnswer : [question.correctAnswer]).filter(e => e !== undefined)
        const selectedQuestionsIds = selectedQuestions.map(idx => question.options[idx].id);
        const selectedQuestionAt = selectedQuestionLevel[value];
        const joinedSelectedQuestionText = [...selectedQuestionsIds].sort().join("");
        if (!selectedQuestionAt) return false;
        return selectedQuestionAt.some((item) => {
            return [...item].sort().join("") === joinedSelectedQuestionText;
        });
    }

    const onDeleteSeletedQuestionLevel = () => {
        const selectedQuestions = Array.isArray(question.correctAnswer) ? question.correctAnswer : [question.correctAnswer];
        const selectedQuestionsIds = selectedQuestions?.map(idx => question?.options[idx]?.id);
        const joinedSelectedQuestionText = [...selectedQuestionsIds].sort().join("");
        setSelectedQuestionLevel(prev => {
            return Object.keys(prev).reduce((acc: Record<string, any[]>, key: string) => {
                const values = prev[key];
                const filteredValues = values.filter((item) => {
                    return [...item].sort().join("") !== joinedSelectedQuestionText;
                });
                acc[key] = filteredValues;
                return acc;
            }, {});
        });
        setNeedCheck(true);
    }

    const onDeleteCombination = (value: string) => {
        setSelectedQuestionLevel(prev => {
            return Object.keys(prev).reduce((acc: Record<string, any[]>, key: string) => {
                if (key === value) return acc;
                acc[key] = prev[key];
                return acc;
            }, {});
        })
    }
    React.useEffect(() => {
        setSelectedQuestionLevel(prev => {
            // Loop through selectedQuestionLevel and find invalid selectedQuestion
            const newSelectedQuestionLevel = Object.keys(prev).reduce((acc: Record<string, any[]>, key: string) => {
                const values = prev[key];
                const filteredValues = values.filter((item) => {
                    return item.every((id: string) => {
                        return question.options.some((option) => option.id === id);
                    })
                })
                acc[key] = filteredValues;
                return acc;
            }, {});
            return newSelectedQuestionLevel;
        });
    }, [question.options]);

    return <div className="pb-16 space-y-8 text-left container mx-auto">
        <div className="flex items-center justify-between gap-[16px]">
            <div>
                <div className="flex space-x-2 items-center font-bold text-[24px]" >
                    <IconButton btnType="sub" btnSize="sm" icon={<ICClose width={18} height={18} />} onClick={onCancelAction} />
                    <span className="max-w-[500px] line-clamp-1">{title || 'Câu hỏi mới'}</span>
                </div>
                <span className="text-xs font-medium text-n-600 ml-12">
                    {form.getFieldValue("title")} / {selectedSection?.name ?? ""}
                </span>
            </div>
            <div className="flex space-x-4">
                <span>
                    <HRButton
                        btnType="sub"
                        btnSize="md"
                        onClick={onCancelAction}
                    >
                        <div className="flex justify-center">
                            Hủy bỏ
                        </div>
                    </HRButton>
                </span>
                {
                    !selectedQuestion && <span><HRButton
                        onClick={onSaveAndAddAnother}
                        btnSize="md"
                        btnType="sub"
                        disabled={inactiveAddQuestion()}
                    >
                        <div className="flex justify-center">
                            Lưu và tạo cái mới
                        </div>
                    </HRButton></span>
                }
                <span>
                    <HRButton
                        btnSize="md"
                        onClick={onSave}
                        className="w-[107px] h-[44px]"
                        disabled={inactiveAddQuestion() && !selectedQuestion}
                    >
                        <div className="flex space-x-2 justify-center">
                            <ICTick />
                            <span>{selectedQuestion ? "Cập nhật" : "Lưu"}</span>
                        </div>
                    </HRButton>
                </span>
            </div>
        </div>
        <div className="mt-8 p-8 bg-white rounded-[10px] grid grid-cols-1 gap-[38px]">
            <div className=" grid grid-cols-1 grid-rows-[auto_1fr] justify-start items-start gap-[16px]">
                <div className="text-primary-bold text-standard-medium">Phần câu hỏi</div>
                <div className="space-y-3"
                    onContextMenu={(e) => {
                        // @ts-ignore
                        document.querySelector(".questions-editor")?.focus?.()
                    }}
                >
                    <Editor
                        stripPastedStyles={true}
                        toolbar={toolbar}
                        editorState={editorQuestionState}
                        toolbarClassName={classNames(isQuestionError ? "error" : "", "editor-toolbar")}
                        wrapperClassName={classNames(isQuestionError ? "error" : "", "questions-wrapper", "editor-wrapper")}
                        editorClassName={classNames(isQuestionError ? "error" : "", "questions-editor", "editor-editor")}
                        onEditorStateChange={onEditorStateChange}
                        placeholder="Nhập câu hỏi..."
                    />
                    {
                        invalidMaxLengthEditorQuestion && <div className="flex items-center space-x-2">
                            <ICWarning fill="#F55858" width={18} height={18} />
                            <span className="text-body italic text-error-500">Bạn đã nhập quá số lượng ký tự cho phép!</span>
                        </div>
                    }
                    {
                        isQuestionError && !invalidMaxLengthEditorQuestion && <div className="flex items-center space-x-2">
                            <ICWarning fill="#F55858" width={18} height={18} />
                            <span className="text-body italic text-error-500">Xin hãy nhập câu hỏi</span>
                        </div>
                    }
                </div>
            </div>
            <div className="grid grid-cols-2 grid-rows-[auto_1fr] justify-start items-start gap-[32px]">
                <div className="flex flex-col gap-[24px]">
                    <div className="text-primary-bold text-standard-medium">Phần đáp án</div>
                    <MultiAnswerWrapper
                        needCheck={needCheck}
                        question={question}
                        onChanges={setQuestion}
                        skillAreaTypeSelected={skillAreaTypeSelected}
                        scoreLevelsQuestion={scoreLevelsQuestion}
                    />
                </div>
                <div>
                    <div className=" flex flex-col p-[16px] border border-outline-med rounded-[12px] gap-[32px]">
                        <div className="flex flex-col gap-[8px]">
                            <div className="text-primary-bold text-standard-medium">Danh sách tổ hợp câu trả lời</div>
                            <div className="text-sub text-med-em">Danh sách tổng hợp các trường hợp đánh giá cấp độ dựa trên kết quả của ứng viên</div>
                        </div>

                        <div className="selection-list flex flex-col gap-[16px] rounded-[4px] bg-surface-info-accent-base p-[16px] text-high-em border border-outline-info-med text-sub-medium">
                            <div className="flex gap-[8px] items-center">
                                <div className="flex flex-col gap-[8px]">Đang chọn:</div>
                                <div className="flex relative">
                                    {selectedAnswerText && <div className="flex cursor-pointer relative shadow-e-01 rounded-[4px] bg-white px-[12px] py-[8px]">
                                        {selectedAnswerText}
                                    </div>}
                                </div>
                            </div>
                            <div className="flex justify-between">
                                <div className="flex gap-[8px] flex-wrap">
                                    {
                                        scoreLevelsQuestion?.filter(q => {
                                            return q.score !== 0 && q.maxScore !== 0;
                                        }).map((item, index) => {
                                            return <div
                                                key={index}
                                                className={classNames([
                                                    "flex gap-[8px] items-center cursor-pointer border border-outline-primary-low rounded-[4px] text-primary",
                                                    {
                                                        "bg-primary-p-400 text-white": isSelectedLevel(item._id)
                                                    },
                                                    {
                                                        "hover:bg-primary-p-400 hover:text-white": selectedAnswerText
                                                    },
                                                    {
                                                        "cursor-not-allowed": !selectedAnswerText
                                                    }
                                                ])}
                                                onClick={onChoiceLevel.bind(this, item._id)}
                                            >
                                                <div className="flex flex-row gap-[8px] px-[8px] py-[4px] text-sub-medium">
                                                    <div>{item?.symbol}</div>
                                                    <div>-</div>
                                                    <div>{item.name}</div>
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                                <div className="flex items-start justify-center cursor-pointer">
                                        <HRButton btnSize="sm" btnType="sub" 
                                            onClick={onDeleteSeletedQuestionLevel}
                                            style={{
                                                padding: "4px 8px",
                                                borderRadius: "4px",
                                            }}>
                                            <div className="flex items-center gap-[8px]">
                                                <ICDelete width={12} height={12} />
                                                <span className="text-sub-medium text-high-em">Xóa cấp độ</span>
                                            </div>
                                        </HRButton>
                                    {/* <span><ICDelete width={12} height={12} /></span>
                                    <span className="text-sub-medium text-high-em">Xóa cấp độ</span> */}
                                </div>
                            </div>
                            {
                                needCheck && inValidScoreCombination && <div className="flex items-center space-x-2">
                                    <ICWarning fill="#F55858" width={18} height={18} />
                                    <span className="text-body italic text-error-500">Xin vui lòng chọn một giá trị</span>
                                </div>
                            }
                        </div>

                        <div className="flex flex-col gap-[32px]">
                            {
                                scoreLevelsQuestion?.map((item, index) => {
                                    return <div
                                        key={`${index}_${item?._id}`}
                                        className="flex items-center"
                                    >
                                        <div className="flex flex-col gap-[12px]">
                                            <div className="flex justify-between">
                                                <div className="text-standard-medium text-high-em">
                                                    Cấp độ {item?.symbol} ({item?.name}):
                                                </div>
                                                {/* <div className="cursor-pointer" onClick={onDeleteCombination.bind(null, item._id)}>Xóa tổ hợp</div> */}
                                            </div>
                                            <div className="flex gap-[12px] flex-wrap">
                                                {
                                                    selectedQuestionLevel[item._id]?.length > 0 && selectedQuestionLevel[item._id]?.map((arrItem, indexData) => {
                                                        const values = arrItem.map((id: string) => {
                                                            const idx = question.options.findIndex((item) => item.id === id);
                                                            return String.fromCharCode("A".charCodeAt(0) + idx)
                                                        }).join("&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;");

                                                        return <div key={indexData} 
                                                            className={classNames([
                                                                "flex px-[12px] py-[6px] bg-white rounded-[4px] text-sub-medium text-high-em",
                                                                {
                                                                    "border border-outline-med shadow-e-01": values
                                                                }
                                                            ])}
                                                        >
                                                            {htmlToText(values)}
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                            {
                                totalLeftCombination > 0 && <div className="flex flex-col gap-[12px]">
                                    <span className="text-standard-medium text-high-em">
                                        {totalLeftCombination} trường hợp còn lại
                                    </span>
                                    <HRSelect
                                        options={(scoreLevelsQuestion ?? []).map((item) => {
                                            return {
                                                label: item.symbol && item.symbol !== '0' ? `${item.symbol} - ${item.name}` : item.name,
                                                value: item._id
                                            }
                                        })}
                                        onChange={onAddOtherLevel}
                                        placeholder="Chọn cấp độ"
                                        hasSearch={false}
                                        hasFilterSort={false}
                                        value={otherLevel}
                                    />
                                    {
                                        needCheck && !otherLevel && <div className="flex items-center space-x-2">
                                            <ICWarning fill="#F55858" width={18} height={18} />
                                            <span className="text-body italic text-error-500">Xin vui lòng chọn một giá trị</span>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div>

                </div>
            </div>
        </div>
        <Modal
            title="Thông báo"
            open={showCancelModal}
            centered={true}
            onCancel={setShowCancelModal.bind(null, false)}
            footer={
                <div className="grid grid-cols-[auto_auto] justify-end gap-[16px]">
                    <HRButton
                        btnSize="sm"
                        btnType="sub"
                        onClick={() => setShowCancelModal(false)}>
                        Không
                    </HRButton>
                    <HRButton btnSize="sm" onClick={onCancel}>
                        Có
                    </HRButton>
                </div>
            }
        >
            <div className="space-y-2">
                <div>Các thông tin nhập vào sẽ bị mất</div>
                <div>Bạn có chắc chắn muốn hủy bỏ?</div>
            </div>
        </Modal>
    </div>
}
type MultiAnswerWrapperProps = {
    question: TChoiceQuestion,
    onChanges?: (question: TChoiceQuestion) => void,
    viewOnly?: boolean;
    needCheck?: boolean;
    skillAreaTypeSelected?: ESkillAreaType | undefined;
    scoreLevelsQuestion?: TScoreLevels[];
}
export const MultiAnswerWrapper: React.FC<MultiAnswerWrapperProps> = ({
    question,
    onChanges,
    viewOnly = false,
    needCheck = false,
    skillAreaTypeSelected,
    scoreLevelsQuestion,
}) => {
    const duplicates = React.useMemo(() => {
        const questionOptionsText = question.options.map(item => getInnerText(item.content))
        return questionOptionsText.map((item, index, arr) => {
            if (!item || item.length === 0) return false;
            return arr.some((item2, index2) => item && item2 && item === item2 && index !== index2)
        })
    }, [question.options])
    const onChangeAnswer = React.useMemo(() => {
        if (viewOnly) return () => { };
        if (!onChanges) return () => { };
        return onChanges
    }, [onChanges, viewOnly])

    const onChangeAnswerContent = (index: number, value: string) => {
        const newQuestion = { ...question };
        newQuestion.options = newQuestion.options.map((item, i) => {
            if (i === index) return { ...item, content: value };
            return item;
        });
        onChangeAnswer(newQuestion);
    }

    const onChangeCorrectAnswer = (index: number, isChecked?: boolean) => {
        switch (question.type) {
            case EQuestionType.MULTIPLE_CHOICE:
                const newMultiQuestion = { ...question };
                if (!Array.isArray(newMultiQuestion.correctAnswer)) {
                    newMultiQuestion.correctAnswer = [];
                }
                if (isChecked) {
                    newMultiQuestion.correctAnswer.push(index);
                } else {
                    newMultiQuestion.correctAnswer = newMultiQuestion.correctAnswer.filter(item => item !== index);
                }
                onChangeAnswer(newMultiQuestion);
                break;
        }
    }

    const onMixAnswer = (isMixed: boolean) => {
        const newQuestion = { ...question };
        newQuestion.isMixed = isMixed;
        onChangeAnswer(newQuestion);
    }

    const onAddNewAnswer = () => {
        const newQuestion = { ...question };
        newQuestion.options = [...newQuestion.options, generateOption("")];
        onChangeAnswer(newQuestion);
    }

    const onRemoveAnswer = (index: number) => {
        if (index < 2) return;
        const newQuestion = _.cloneDeep(question);
        let correctAnswer = newQuestion.correctAnswer;
        if (newQuestion.type === EQuestionType.SINGLE_CHOICE) {
            if (newQuestion.correctAnswer === index) {
                correctAnswer = 0;
            }
        } else if (newQuestion.type === EQuestionType.MULTIPLE_CHOICE) {
            let newCorrectAnswer = [] as number[]
            newQuestion.correctAnswer.forEach((item, i) => {
                if (item === index) return;
                if (item > index) {
                    newCorrectAnswer.push(item - 1);
                } else {
                    newCorrectAnswer.push(item);
                }
            });
            correctAnswer = newCorrectAnswer;
        }
        // Important: careful with immutable
        const newOptions = [...newQuestion.options];
        newOptions.splice(index, 1);
        newQuestion.options = newOptions;
        newQuestion.correctAnswer = correctAnswer;

        // set lock answer
        let newPositionLockedOptions = [] as number[]
        newQuestion.positionLockedOptions?.forEach((item, i) => {
            if (item > index) {
                newPositionLockedOptions.push(item - 1);
            } else {
                newPositionLockedOptions.push(item);
            }
        });
        newQuestion.positionLockedOptions = newPositionLockedOptions;

        onChangeAnswer(newQuestion);
    }

    const onLockAnswer = (index: number) => {
        if (question.options.length === (question?.positionLockedOptions?.length ?? 0) + 2 && !question?.positionLockedOptions?.includes(index)) return;
        const questionClone = _.cloneDeep(question);
        const newQuestion = { ...questionClone, positionLockedOptions: questionClone.positionLockedOptions ?? [] };
        if (newQuestion.positionLockedOptions?.includes(index)) {
            newQuestion.positionLockedOptions = newQuestion.positionLockedOptions?.filter(item => item !== index);
            onChangeAnswer(newQuestion);
            return;
        }
        if (newQuestion?.positionLockedOptions?.length === 0) {
            newQuestion.positionLockedOptions = [index];
            onChangeAnswer(newQuestion);
            return;
        }
        newQuestion.positionLockedOptions?.push(index);
        onChangeAnswer(newQuestion);
    };

    const onChangeRateLevel = (index: number, value: number) => {
        const newQuestion = _.cloneDeep(question);
        if (newQuestion.type === EQuestionType.SINGLE_CHOICE) {
            newQuestion.scoreCombination[index] = value;
            onChangeAnswer(newQuestion);
        }
        if (newQuestion.type === EQuestionType.MULTIPLE_CHOICE) {
            // TODO
        }
    }

    return <div className={classNames('grid grid-cols-1 gap-[24px]', viewOnly ? 'answer-editor-view-only' : '')}>
        {
            question.options.map((answer, index) => {
                const hasDisableLock = question.options.length === (question?.positionLockedOptions?.length ?? 0) + 2 && !question?.positionLockedOptions?.includes(index);
                return <div className="flex flex-col gap-[8px]" key={index}>
                    <div className="flex text-body-medium text-med-em pl-[45px]">
                        Đáp án {String.fromCharCode("A".charCodeAt(0) + index)}
                    </div>
                    <AnswerEditor
                        key={answer.id}
                        index={index} // For what ?
                        answer={answer.content}
                        onChange={onChangeAnswerContent}
                        onChangeCorrectAnswer={onChangeCorrectAnswer}
                        onRemoveAnswer={onRemoveAnswer}
                        onLockAnswer={onLockAnswer}
                        isLocked={question.positionLockedOptions?.includes(index)}
                        isDisabledLock={hasDisableLock}
                        isMixed={question.isMixed}
                        readyLock={question.options.length > 2}
                        viewOnly={viewOnly}
                        isChecked={
                            question.type === EQuestionType.SINGLE_CHOICE ?
                                question.correctAnswer === index :
                                question.type === EQuestionType.MULTIPLE_CHOICE && Array.isArray(question.correctAnswer) ? question.correctAnswer.includes(index) : false
                        }
                        needCheck={needCheck}
                        questionType={question.type}
                        isDuplicate={duplicates[index]}
                        skillAreaTypeSelected={skillAreaTypeSelected}
                    />
                </div>
            })
        }
        {
            !viewOnly && <div className="flex justify-between">
                <div>
                    <HRButton
                        onClick={onAddNewAnswer}
                        btnType="sub"
                        btnSize="md"
                    >
                        <div className="flex space-x-3 items-center">
                            <ICAdd stroke="#4F4B5C" />
                            <span className="text-high-em text-body-bold">Thêm đáp án</span>
                        </div>
                    </HRButton>
                </div>
                <div className="mt-2">
                    <Checkbox
                        checked={question.isMixed}
                        className="purple-checkbox"
                        onChange={(e) => {
                            onMixAnswer(e.target.checked)
                        }}
                    >
                        <span className="text-high-em text-body">Xáo câu trả lời</span>
                    </Checkbox>
                </div>
            </div>
        }
    </div>
}

type AnswerEditorProps = {
    questionType: EQuestionType;
    answer: string
    onChange: (index: number, value: string) => void
    needCheck: boolean,
    isChecked: boolean,
    index: number,
    onChangeCorrectAnswer: (index: number, isChecked?: boolean) => void
    onRemoveAnswer: (index: number) => void;
    onLockAnswer: (index: number) => void;
    viewOnly: boolean;
    isDuplicate?: boolean;
    isLocked?: boolean;
    isMixed?: boolean;
    readyLock?: boolean;
    isDisabledLock?: boolean;
    skillAreaTypeSelected: ESkillAreaType | undefined;
}
const AnswerEditor: React.FC<AnswerEditorProps> = ({
    questionType,
    answer,
    onChange,
    needCheck,
    isChecked,
    index,
    onChangeCorrectAnswer,
    viewOnly,
    onRemoveAnswer,
    onLockAnswer,
    isDuplicate,
    isLocked,
    isMixed,
    readyLock,
    isDisabledLock,
    skillAreaTypeSelected,
}) => {
    const [isFocus, setIsFocus] = React.useState<boolean>(false)

    const [editorState, setEditorState] = React.useState<EditorState>(
        EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(answer).contentBlocks))
    )

    React.useEffect(() => {
        if (!answer) {
            setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(answer).contentBlocks)))
        }
    }, [answer])

    const onEditorStateChange = (valueEditorState: EditorState) => {
        if (viewOnly) return;
        let text = valueEditorState.getCurrentContent().getPlainText();
        const oldContent = editorState.getCurrentContent().getPlainText();
        if (text.length > 5000) {
            const oldState = ContentState.createFromBlockArray(htmlToDraft(oldContent).contentBlocks);
            setEditorState(EditorState.moveFocusToEnd(EditorState.push(editorState, oldState, 'insert-characters')));
        } else {
            setEditorState(valueEditorState);
            onChange(index, draftToHtml(convertToRaw(valueEditorState.getCurrentContent())))
        }
    }
    const isAnswerError = React.useMemo(() => {
        return needCheck && isEmptyHTML(answer)
    }, [answer, needCheck]);

    return <WrapperEditor className={classNames(viewOnly ? "read-only-mode" : "", isChecked ? 'has-value' : '', "grid grid-cols-1")}>
        <div className={classNames("grid items-center gap-[16px] grid-cols-[auto_1fr_auto]")}>
            <div className="flex items-center">
                {
                    questionType === "single_choice" ? <Radio
                        checked={isChecked}
                        onChange={() => onChangeCorrectAnswer(index)}
                    /> : <span className="pr-2">
                        <Checkbox
                            className="purple-checkbox"
                            checked={isChecked}
                            onChange={(e) => {
                                onChangeCorrectAnswer(index, e.target.checked);
                            }}
                        />
                    </span>
                }
            </div>
            <div className="relative">
                <Editor
                    stripPastedStyles={true}
                    readOnly={viewOnly}
                    onFocus={() => setIsFocus(true)}
                    onBlur={() => setIsFocus(false)}
                    toolbar={answerToolbar}
                    editorState={editorState}
                    toolbarClassName={classNames(isFocus ? "answer-toolbar__focus" : "hidden", "answer-toolbar")}
                    wrapperClassName={classNames(isAnswerError || (isDuplicate && !isEmptyHTML(answer)) ? "error" : "", "anwser-questions", "editor-wrapper", viewOnly ? 'view-only' : '')}
                    editorClassName={classNames("anwser-questions anwser-editor", viewOnly ? 'view-only' : '')}
                    onEditorStateChange={onEditorStateChange}
                    handleReturn={() => {
                        return true;
                    }}
                    placeholder="Đáp án..."
                />
                {
                    isDuplicate && !isEmptyHTML(answer) && <div
                        className="relaive flex items-center space-x-2 pt-[8px] pl-[45px]">
                        <ICWarning fill="#F55858" width={18} height={18} />
                        <span className="text-body italic text-error-500">Đáp án này đã tồn tại</span>
                    </div>
                }
            </div>

            {!viewOnly &&
                <div className={classNames("flex flex-col gap-[4px] items-center h-full text-center", !isMixed || !readyLock ? "justify-center" : "justify-between")}>
                    <span className="flex items-center justify-center cursor-pointer w-[24px] h-[24px]"
                        onClick={onRemoveAnswer.bind(this, index)}>
                        <ICClose
                            fill={index > 1 ? "#4F4B5C" : "#D9D8DC"}
                            width={16}
                            height={16}
                        />
                    </span>
                    <span className={classNames("flex items-center justify-center cursor-pointer w-[24px] h-[24px]", !isMixed || !readyLock ? "hidden" : "", isDisabledLock ? "cursor-not-allowed" : "")}
                        onClick={onLockAnswer.bind(this, index)}>
                        {!isLocked && <ICLock
                            fill={!isDisabledLock ? "#4F4B5C" : "#D9D8DC"}
                            width={16}
                            height={16}
                        />}
                        {isLocked && <ICLocked
                            width={16}
                            height={16}
                        />}
                    </span>
                </div>}
        </div>
        {
            isAnswerError && <div className="flex items-center space-x-2 pt-[8px] pl-[45px]">
                <span className="text-body italic text-error-500">Không được để trống</span>
            </div>
        }
    </WrapperEditor>
}
