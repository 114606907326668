import { ContentState, EditorState, convertToRaw } from "draft-js";
import { uploadCallback } from "../../../create_test/tab/question/editor.config";
import classNames from "classnames";
import React from "react";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";
import { DEFAULT_TEMPLATE } from "./default";
import { HRButton } from "../../../../components/button";
import { ICEdit } from "../../../../icons";
import { Modal } from "antd";
import { HRInput } from "../../../../components/form/input";
import { TEmailTemplate } from "../../create-assessment/type";
import draftToHtml from "draftjs-to-html";
const toolbar = {
    options: ['inline', 'list', 'link', "image", "colorPicker", "emoji", "history"],
    inline: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ['bold', 'italic', 'underline'],
    },
    list: {
        options: ['unordered', 'ordered'],
    },
    image: {
        className: 'editor-image',
        uploadCallback: uploadCallback,
        popupClassName: 'editor-image-popup',
        previewImage: true,
        // Remove LCR option
        alignmentEnabled: false,
        // Default to full width
        defaultSize: {
            height: 'auto',
            width: '100%',
        },
    }
}

type EmailTemplateProps = {
    editorAnswerState: EditorState;
    onEditorStateChange: (editorState: EditorState) => void;
}
export const EmailTemplate: React.FC<EmailTemplateProps> = ({
    editorAnswerState,
    onEditorStateChange
}) => {

    return <div>
        <Editor
            stripPastedStyles={true}
            toolbar={toolbar}
            editorStyle={{
                minHeight: '250px',
                color: '#110C22',
            }}
            editorState={editorAnswerState}
            toolbarClassName={'editor-toolbar'}
            wrapperClassName={classNames("questions-wrapper", "editor-wrapper", "answer-essay-wrapper")}
            editorClassName={
                classNames([
                    "questions-editor",
                    "editor-editor editor-essay-editor",
                ])
            }
            onEditorStateChange={onEditorStateChange}
            placeholder="Nhập đáp án"
        />
    </div>
}

type EmailTemplateModalProps = {
    template?: TEmailTemplate;
    onUpdated: (template: TEmailTemplate) => void;
}
export const EmailTemplateModal: React.FC<EmailTemplateModalProps> = ({
    template,
    onUpdated
}) => {
    const [subject, setSubject] = React.useState(template?.subject || "You've been invited to an assessment")
    const [showEditTemplateModal, setShowEditTemplateModal] = React.useState(false);

    const [editorAnswerState, setEditorAnswerState] = React.useState<EditorState>(
        EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(DEFAULT_TEMPLATE).contentBlocks))
    );
    React.useEffect(() => {
        setSubject(template?.subject || "You've been invited to an assessment");
        if (template?.content) {
            setEditorAnswerState(
                EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(template.content).contentBlocks))
            )
        }
    }, [template]);

    const onEditorStateChange = (editorState: EditorState) => {
        setEditorAnswerState(editorState);
    }

    const onUpdate = React.useCallback(() => {
        onUpdated({
            subject,
            content: draftToHtml(convertToRaw(editorAnswerState.getCurrentContent()))
        });
        setShowEditTemplateModal(false);
    }, [subject, editorAnswerState, onUpdated]);
    return <>
        <HRButton
            btnSize="sm"
            btnType="sub"
            onClick={() => setShowEditTemplateModal(true)}
        >
            <div className="text-[14px] text-[#46485C] font-bold flex items-center space-x-2 justify-center">
                <ICEdit width={18} height={18} />
                <span>Chỉnh sửa thư mời</span>
            </div>
        </HRButton>
        <Modal
            centered
            onCancel={() => setShowEditTemplateModal(false)}
            open={showEditTemplateModal}
            closeIcon={null}
            zIndex={1001}
            width={1000}
            title={
                <div className="text-primary text-[24px]">Email template</div>
            }
            bodyStyle={{
                padding: "16px",
            }}
            style={{
                boxShadow: '0px 4px 16px rgba(98, 112, 140, 0.24)',
                borderRadius: '12px'
            }}
            footer={<div className="grid grid-cols-[auto_auto] justify-end gap-[10px]">
                <HRButton
                    btnSize="sm"
                    btnType="sub"
                    onClick={() => setShowEditTemplateModal(false)}>
                    Hủy bỏ
                </HRButton>
                <HRButton
                    btnSize="sm"
                    onClick={onUpdate}
                    disabled={subject === "" || editorAnswerState.getCurrentContent().getPlainText() === ""}
                >
                    Cập nhật
                </HRButton>
            </div>
            }
        >
            <div className="space-y-4">
                <HRInput
                    label="Subject"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                />
                <EmailTemplate
                    editorAnswerState={editorAnswerState}
                    onEditorStateChange={onEditorStateChange}
                />
                <div className="text-[12px]">
                    <i>*Note</i>
                    <ul className="list-none">
                        <li>Để thêm tên người nhận, vui lòng sử dụng <b>{`{{candidateName}}`}</b></li>
                    </ul>
                </div>
            </div>
        </Modal>
    </>

}