import React from "react";
import { useWatch } from "antd/lib/form/Form";
import { FormInstance } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { compareObjects } from "../../../../utils/index";
import { setLoading } from "../../../../redux/slices/appInfo";
import { saveTestLibrary, updateTestLibrary } from "../../../../api/library";
import { useNotification } from '../../../../hooks/useNotification';

export const useCreateTestPage = (
    form: FormInstance,
    initValuesForm: any,
    setTestDetails: (value: any) => void,
    setSelectedTabIndex: (value: number) => void,
) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { showSuccess, showError } = useNotification();
    const title = useWatch('title', form);
    const summary = useWatch('summary', form);
    const description = useWatch('description', form);
    const language = useWatch('language', form);
    const industry = useWatch('industry', form);
    const level = useWatch('level', form);
    const roles = useWatch('roles', form);
    const type = useWatch('type', form);
    const relevant = useWatch('relevant', form);
    const ratingLevel = useWatch('ratingLevel', form);

    const hasChangedValues = React.useMemo(() => {
        const values = {
            title,
            summary,
            description,
            language,
            industry,
            level,
            roles,
            type,
            relevant,
            ratingLevel,
        };
        return !compareObjects(values, initValuesForm);
    }, [
        title,
        summary,
        description,
        language,
        industry,
        level,
        roles,
        type,
        relevant,
        initValuesForm,
        ratingLevel
    ]);

    const insertTestLibrary = React.useCallback(async (values: any) => {
        dispatch(setLoading(true));
        try {
            const result = await saveTestLibrary(values);
            dispatch(setLoading(false));
            setTestDetails(result.data);
            setSelectedTabIndex(2);
            showSuccess('Thành công');
            navigate(`/edit-test/${result.data._id}`);
        } catch (error: any) {
            console.error(error);
            showError("Error", error?.response?.statusText);
        }
        finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, showSuccess, navigate, showError, setSelectedTabIndex, setTestDetails]);

    const updateTest = React.useCallback(async (id: string, values: any) => {
        const valuesForm = form.getFieldsValue(true);
        const payload = {
            ...valuesForm,
            ...values
        };
        dispatch(setLoading(true));
        try {
            const result = await updateTestLibrary(id, payload);
            dispatch(setLoading(false));
            setTestDetails(result.data);
            showSuccess('Thành công');
            // this case will navigate to my test page
            navigate(`/my-test`);
        } catch (error: any) {
            console.error(error);
            showError("Error", error?.response?.statusText);
        }
        finally {
            dispatch(setLoading(false));
        }
    }, [
        form,
        dispatch,
        showSuccess,
        showError,
        setTestDetails,
        navigate
    ]);

    return {
        hasChangedValues,
        insertTestLibrary,
        updateTest
    }
}
