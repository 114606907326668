import React from "react";
import { usePaymentPageContext } from ".";
import { HRInput } from "../../components/form/input";
import { ICSearch } from "../../icons";
import { Radio, RadioChangeEvent } from "antd";
import styled from "styled-components";

const Wrapper = styled.div`
    .ant-radio-group {
        display: flex;
        flex-direction: column;
        .ant-radio-wrapper {
            display: flex;
            justify-content: space-between;
            padding: 16px 0;
            border-bottom: 1px solid #ECECED;
        }
        .ant-radio-wrapper span.ant-radio+* {
            width: calc(100% - 16px);
        }
    }
    input.ant-input {
        background-color: initial;
    }
`;

const DiscountListMockData = [
    {
        id: 1,
        code: "JOYTECH2023",
        discount: -100.000,
        type: "VND",
    },
    {
        id: 2,
        code: "JOYTECH2023",
        discount: -100.000,
        type: "VND",
    },
    {
        id: 3,
        code: "JOYTECH2023",
        discount: -100.000,
        type: "VND",
    },
    {
        id: 4,
        code: "JOYTECH2023",
        discount: -100.000,
        type: "VND",
    },
    {
        id: 5,
        code: "JOYTECH2023",
        discount: -100.000,
        type: "VND",
    },
];

export const DiscountPayment: React.FC = () => {
    const { discountCode, setDiscountCode } = usePaymentPageContext();
    const [keyWord, setKeyWord] = React.useState("");
    const handleChange = React.useCallback(($event: any) => {
        const value = $event.target.value;
        setKeyWord(value);
    }, []);

    return <div className="flex flex-col w-full gap-[32px]">
        <span className="text-high-em font-bold text-[18px] leading-[28px]">Mã giảm giá</span>
        <span className="text-[#676472] font-medium text-[16px] leading-[24px]">Lựa chọn mã giảm giá</span>
        <Wrapper className="flex flex-col">
            <HRInput
                placeholder="Tìm kiếm"
                onChange={handleChange}
                value={keyWord}
                prefix={<ICSearch />}
                style={{
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                }}
            />
            <div>
                <Radio.Group value={discountCode} onChange={(e: RadioChangeEvent) => setDiscountCode(e.target.value)}>
                    {DiscountListMockData.map((item) => {
                        return <Radio key={item.id} value={item.id}>
                            <div className="flex justify-between items-center w-full">
                                <span className="text-[14px] leading-[24px] text-high-em font-normal">{item.code}</span>
                                <span className="text-[14px] leading-[24px] text-[#0BAA60] font-normal">
                                    {item.discount} {item.type}
                                </span>
                            </div>
                        </Radio>
                    })}
                </Radio.Group>
            </div>
        </Wrapper>
</div>
}
