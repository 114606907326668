import React from "react";
import { HRButton } from "../../components/button";
import { Modal } from "antd";
import { DiscountPayment } from "./discount";
import { usePaymentPageContext } from ".";
import { getPaymentUrl } from "../../api/payment";
import { TPaymentRequest } from "./type";
import { setLoading } from "../../redux/slices/appInfo";
import { useDispatch } from "react-redux";
import { useNotification } from "../../hooks/useNotification";
import { formatMoney } from "../../utils";
import { useNavigate } from "react-router-dom";
import { ERROR_CODE } from "../../utils/constants";

export const TotalPayment: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { showError } = useNotification();
    const { id, discountCode, setDiscountCode, language, currentPaymentMethod, activeInvoice, paymentDetails, invoiceForm } = usePaymentPageContext();
    const [showDiscountModal, setShowDiscountModal] = React.useState(false);

    const onSubmitPayment = React.useCallback(async () => {
        try {
            if (activeInvoice) {
                await invoiceForm?.validateFields();
            }
            dispatch(setLoading(true));
            const payload: TPaymentRequest = {
                assessmentId: id,
                language,
                paymentMethod: currentPaymentMethod,
                hasInvoice: activeInvoice,
            };
            if (activeInvoice) {
                const invoiceValues = invoiceForm?.getFieldsValue();
                payload.invoiceInfo = invoiceValues;
            }
            const result = await getPaymentUrl(payload);
            if (result?.data?.url) {
                window.location.href = result.data.url;
            }
        }
        catch (error: any) {
            console.error(error);
            const errorCode = error?.response?.data?.errorCode || '';
            if(errorCode === ERROR_CODE.PAYMENT_NOT_SUPPORT) {
                showError("", "Tạm thời không thể thực hiện được giao dịch qua phương thức này. Vui lòng thử phương thức khác hoặc quay lại sau.");
                return;
            }
            if(errorCode === ERROR_CODE.PAYMENT_FAILED) {
                navigate(`/assessment/${id}/details`);
                return;
            }
            const errorMessage = error?.response?.data?.errorMessage || error?.response?.statusText;
            errorMessage && showError("", errorMessage);
            
        } finally {
            dispatch(setLoading(false));
        }

    }, [dispatch, navigate, invoiceForm, activeInvoice, id, currentPaymentMethod, language, showError]);

    return <div className="flex flex-col w-full gap-[16px]">
        <span className="text-title-bold text-high-em">Thanh toán</span>
        <div className="flex flex-col gap-[16px]" style={{
            boxShadow: "0px 6px 16px -6px rgba(17, 12, 34, 0.1)",
        }}>
            {/* <div className="grid grid-cols-[1fr_44px] gap-[16px]">
                <div className="flex flex-col w-full relative">
                    <HRInput placeholder="Nhập mã giảm giá" 
                        value={discountCode}
                        onChange={(e) => setDiscountCode(e.target.value)}
                        style={{
                            width: '100%',
                            paddingRight: '90px',
                            color: '#110C22',
                            fontSize: '14px',
                            lineHeight: '24px',
                            fontWeight: 700,
                        }} />
                    <span className="absolute right-[10px] top-[7px]">
                        <HRButton btnType="sub" btnSize="xs">
                            Áp dụng
                        </HRButton>
                    </span>
                </div>
                <IconButton btnType="outline" btnSize="md" icon={<ICDiscount />}
                    onClick={() => setShowDiscountModal(true)} />
            </div> */}
            <div className="bg-white rounded-[8px] p-[16px] flex flex-col gap-[16px]" style={{
                boxShadow: "0px 6px 16px -6px rgba(17, 12, 34, 0.1)",
                minHeight: '160px',
            }}>
                <div className="flex justify-between items-center">
                    <span className="text-body-medium text-high-em">Tạm tính</span>
                    <span className="text-body-medium text-high-em">{formatMoney(paymentDetails?.pricing?.price as number)}</span>
                </div>
                <div className="flex justify-between items-center">
                    <span className="text-body-medium text-med-em">Thuế (VAT)</span>
                    <span className="text-body-medium text-med-em">{formatMoney(paymentDetails?.pricing?.tax as number)}</span>
                </div>
                <div className="flex justify-between items-center">
                    <span className="text-body-medium text-med-em">Phí giao dịch</span>
                    <span className="text-body-medium text-med-em">{formatMoney(paymentDetails?.pricing?.transactionFee as number)}</span>
                </div>
                {paymentDetails?.pricing?.voucherDiscount && <div className="flex justify-between items-center">
                    <span className="text-body-medium text-med-em">Voucher giảm giá</span>
                    <span className="text-body-medium text-med-em">{formatMoney(paymentDetails?.pricing?.voucherDiscount as number)}</span>
                </div>}
                <div className="flex w-full bg-[#ECECED] h-[1px]"></div>
                <div className="flex justify-between items-center">
                    <span className="text-body-medium text-high-em">Tổng cộng</span>
                    <span className="text-primary text-title-bold">{formatMoney(paymentDetails?.pricing?.totalPrice as number)}</span>
                </div>

                <div className="flex flex-col gap-[16px]">
                    <HRButton 
                        btnType="danger"
                        btnSize="lg"
                        onClick={onSubmitPayment}>
                        Thanh toán
                    </HRButton>
                    <HRButton 
                        onClick={() => navigate(`/assessment/${id}/details`)}
                        btnType="sub"
                        btnSize="lg">
                        Thanh toán sau
                    </HRButton>
                </div>
            </div>
        </div>
        <Modal
            centered
            onCancel={() => setShowDiscountModal(false)}
            open={showDiscountModal}
            title={null}
            footer={
                <div className="grid grid-cols-[auto_auto] justify-end gap-[16px]">
                    <HRButton
                        btnType="outline"
                        btnSize="sm"
                        onClick={() => setShowDiscountModal(false)}>
                        Hủy bỏ
                    </HRButton>
                    <HRButton
                        btnSize="sm"
                        onClick={() => {
                            setShowDiscountModal(false);
                        }}>
                        Xác nhận
                    </HRButton>
                </div>
            }>
                <DiscountPayment />
        </Modal>
    </div>
}
