import React from "react"
import { HRInput } from "../../components/form/input"
import { ICSearch } from "../../icons"
import debouce from 'lodash/debounce';
import { EStatusAssessment, ISearchMyAssessment } from "./type";

type TFilterProps = {
    status: EStatusAssessment | undefined;
    setStatus: (value: EStatusAssessment) => void;
    onChangeFilter: (value: any) => void;
    filterParams: ISearchMyAssessment;
}

export const Filter: React.FC<TFilterProps> = ({
    status,
    setStatus,
    onChangeFilter,
    filterParams
}) => {
    const [title, setTitle] = React.useState<string | undefined>("");
    const handleChange = React.useCallback(($event: any) => {
        let value = $event.target.value;
        if (value.length > 50) {
            return;
        }
        setTitle(value);
        value = value.replace(/^\s+/, '');
        const newParams = { ...filterParams, title: value, page: 1 };
        onSearchKeyword(newParams);
    }, [filterParams]);

    const onSearchKeyword = React.useMemo(() => {
        return debouce(onChangeFilter, 600);
    }, [onChangeFilter]);

    React.useEffect(() => {
        return () => {
            onSearchKeyword.cancel();
        }
    }, [onSearchKeyword]);
    React.useEffect(() => {
        if (filterParams) {
            setTitle(filterParams.title ?? '');
        }
    }, [filterParams]);

    const onClearValue = React.useCallback(() => {
        setTitle(undefined);
        const newParams = { ...filterParams, title: undefined, page: 1 };
        onChangeFilter(newParams);
    }, [filterParams, onChangeFilter]);

    return <div className="flex w-full items-center justify-start">
        <div className="max-w-[466px] w-full">
            <HRInput
                placeholder="Tìm kiếm"
                value={title}
                hasClearIcon={true}
                onClearValue={onClearValue}
                prefix={<ICSearch />}
                onChange={handleChange}
            />
        </div>
    </div>
}
