import { DatePicker } from "antd";
import React from "react";
import { HRButton } from "../../components/button";
import { HRInput } from "../../components/form/input";
import { Navigator } from "../../components/navigator";
import { ICAlert } from "../../icons/alert";
import { ICCalendar } from "../../icons/calendar";
import { ICUser, ICUserV2 } from "../../icons/user";
import { useNavigate } from "react-router-dom";
import { ICMobile } from "../../icons";
import { isValidPhoneNumber } from "react-phone-number-input";
import { NAME_REGEX } from "../../utils/constants";

type UserInfomationProps = {
    onSubmit: (name: string, birthday: number, phoneNumber: string) => void
}
export const UserInfomation: React.FC<UserInfomationProps> = ({
    onSubmit
}) => {
    const [name, setName] = React.useState<string>("");
    const [nameError, setNameError] = React.useState<string>("");
    const [birthday, setBirthday] = React.useState<number | null>(null);
    const [birthdayError, setBirthdayError] = React.useState<string>("");
    const [phoneRegion, setPhoneRegion] = React.useState<string>("+84");
    const [phone, setPhone] = React.useState<string>("");
    const [phoneError, setPhoneError] = React.useState<string>("");
    const navigate = useNavigate();

    const onSubmitForm = () => {
        let isError = false;
        if (!name) {
            setNameError("Vui lòng nhập họ tên");
            isError = true;
        } else if (name.length > 50 || name.length < 5) {
            setNameError("Họ tên phải từ 5 đến 50 ký tự");
            isError = true;
        } else if (!NAME_REGEX.test(name)) {
            setNameError("Họ và tên không được phép có ký tự đặc biệt");
            isError = true;
        } else {
            setNameError("");
        }
        if (!birthday) {
            setBirthdayError("Vui lòng chọn ngày sinh");
            isError = true;
        } else {
            setBirthdayError("");
        }
        if (!phone) {
            setPhoneError("Vui lòng nhập số điện thoại");
            isError = true;
        } else if (!isValidPhoneNumber(phone, 'VN')) {
            setPhoneError("Số điện thoại không hợp lệ");
            isError = true;
        } else {
            setPhoneError("");
        }
        if (!isError) {
            onSubmit(name.trim(), birthday!, phone.trim());
        }
    };

    const onClose = () => {
        navigate("/");
    };

    return (
        <div className="text-center flex flex-col gap-[48px] p-[48px] min-w-[478px]">
            <Navigator current={3} total={3} />
            <div className="flex flex-col gap-[10px]">
                <h2 className="mb-0 text-high-em text-heading-5-bold">Thông tin cá nhân</h2>
                <div className="text-med-em text-sub">
                    <div>Thông tin cá nhân rất quan trọng</div>
                    <div>để mọi người biết bạn là ai.</div>
                </div>
            </div>
            <div className="flex flex-col gap-[24px]">
                <div className="flex flex-col gap-[16px]">
                    <div className="space-y-1 text-left">
                        <HRInput
                            isError={!!nameError}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            size="large"
                            maxLength={50}
                            placeholder="Họ và tên"
                            prefix={!nameError ? <ICUserV2 width={24} height={24} fill="var(--icon-low-em)" /> : <ICAlert />}
                        />
                        <div className="pt-2 font-medium text-xs text-error-500">{nameError}</div>
                    </div>
                    <div className="space-y-1 text-left">
                        <DatePicker
                            onChange={(val) => {
                                setBirthday(val ? val.valueOf() : null)
                            }}
                            suffixIcon={birthdayError ? <ICAlert /> : <ICCalendar />}
                            style={{
                                width: "100%",
                                padding: "8px",
                                borderRadius: "4px",
                                border: "1px solid #D6D7DD",
                                borderColor: birthdayError ? "#F56565" : "#D6D7DD",
                                fontSize: "14px",
                                fontWeight: 500,
                            }}
                            placeholder="Ngày sinh"
                            format={"DD/MM/YYYY"}
                            showToday={false}
                            disabledDate={(current) => {
                                return current && (current.valueOf() > +new Date() || current.valueOf() <= new Date("1970").valueOf())
                            }}
                        />
                        <div className="pt-2 font-medium text-xs text-error-500">{birthdayError}</div>
                    </div>
                    <div className="space-y-1 text-left">
                        <div className="flex space-x-3 items-center ">
                            <div className="font-medium">+84</div>
                            {/* <HRSelect
                                optionLabelProp="value"
                                labelInValue={true}
                                value={phoneRegion}
                                onChange={(value) => setPhoneRegion(value)}
                                style={{ width: 80 }}
                                showSearch
                                dropdownMatchSelectWidth={240}
                                options={PHONE_REGION.map(({ code, name }) => ({
                                    label: <div className="space-x-1 text-ellipsis overflow-hidden">
                                        <span>{name}</span>
                                        <strong>({code})</strong>
                                    </div>,
                                    value: code
                                }))}
                            /> */}
                            <HRInput
                                wrapperClassName="w-full"
                                prefix={!phoneError ? <ICMobile /> : <ICAlert />}
                                isError={!!phoneError}
                                value={phone}
                                minLength={10}
                                maxLength={11}
                                onChange={(e) => setPhone(e.target.value)}
                                size="large"
                                placeholder="Số điện thoại"
                                style={{
                                    width: "100%"
                                }}
                            />
                        </div>
                        <div className="pt-2 font-medium text-xs text-error-500">{phoneError}</div>
                    </div>
                </div>
                <div className="flex justify-center items-center">
                    <HRButton onClick={onSubmitForm} style={{
                        width: "300px"
                    }}>
                        Hoàn thành
                    </HRButton>
                </div>
                <div onClick={onClose}
                    className="cursor-pointer underline text-body text-info-500 font-medium"
                >
                    Bỏ qua
                </div>
            </div>

        </div>
    )
}
