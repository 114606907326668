import React from "react";
import { getURL } from "../../../utils";
import { ICPlayAudio } from "../../../icons/play-audio";
import { Button } from "antd";
import { useTestingPageContext } from "..";

type AudioPlayerProps = {
    questionId?: string;
    audioUrl: string;
    maximumPlayAudio?: number;
}
export const AudioPlayer: React.FC<AudioPlayerProps> = ({
    questionId,
    audioUrl,
    maximumPlayAudio
}) => {
    const { audioPlayedMap, setAudioPlayedMap } = useTestingPageContext();
    console.log(audioPlayedMap);
    const numberOfPlayLeft = React.useMemo(() => {
        if (!questionId) return 0;
        const playCount = audioPlayedMap.get(questionId) || 0;
        return Math.max(maximumPlayAudio ? maximumPlayAudio - playCount : 0, 0);
    }, [audioPlayedMap, questionId, maximumPlayAudio]);
    const audioRef = React.useRef<HTMLAudioElement>(null);
    const [isPlaying, setIsPlaying] = React.useState(false);
    const onPlayAudio = React.useCallback(() => {
        if (audioRef.current) {
            audioRef.current.play();
            setIsPlaying(true);
            setAudioPlayedMap(prev => {
                const newMap = new Map(prev);
                if(!questionId) return prev;
                const prevPlayCount = prev.get(questionId) ?? 0;
                newMap.set(questionId, prevPlayCount + 1);
                return newMap;
            });
            audioRef.current.onended = () => {
                setIsPlaying(false);
            }
        }
    }, [audioRef, questionId, setAudioPlayedMap]);
    return <div>
        <Button onClick={onPlayAudio} disabled={isPlaying || numberOfPlayLeft <= 0}>
            <div className="flex items-center space-x-2">
                <ICPlayAudio />
                <span>Play</span>
            </div>
        </Button>
        <div className="mt-2 flex items-center space-x-4">
            <audio ref={audioRef}>
                <source src={getURL(audioUrl)} type="audio/mpeg" />
            </audio>
        </div>
    </div>
}