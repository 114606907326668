import React from "react";
import { HRButton } from "../../../components/button";
import { ICCheckV2 } from '../../../icons/check';
import { HRTextArea } from '../../../components/form/textarea';
import { useTestingPageContext } from "..";
import { useNotification } from "../../../hooks/useNotification";
import { STEPS } from "../type";
import { EVENT_NAME_SOCKET } from "../../../utils/constants";
import { useScreenRecord } from "../../../hooks/useScreenRecord";

export const SubmitTest: React.FC = () => {
    const { socket, setStep } = useTestingPageContext();
    const { showError } = useNotification();
    const [feedback, setFeedback] = React.useState<string | undefined>(undefined);
    const {
        onStopRecording
    } = useScreenRecord();
    const onSubmit = React.useCallback(async () => {
        if (!socket) {
            showError("Lỗi", "Không thể kết nối đến server. Vui lòng thử lại sau.");
            return;
        }
        try {
            await socket.emitWithAck(EVENT_NAME_SOCKET.SEND.SUBMIT_TEST, {
                feedback: feedback
            });
            setStep(STEPS.END_TEST);
            onStopRecording();
            socket.disconnect();
        } catch (error) {
            console.warn("error", error);
        }
    }, [socket, showError, setStep, feedback]);

    return <div className="flex w-full items-center justify-center">
        <div className="rounded-[12px] bg-white shadow-e-03 flex w-full max-w-[754px] flex-col items-center justify-center gap-[32px] py-[54px] px-[32px]">
            <div className="grid grid-cols-1 gap-[16px] items-center justify-center text-center">
                <div className="grid grid-cols-1 gap-[8px]">
                    <span className="text-heading-5-bold text-high-em">Cảm ơn bạn!</span>
                    <span className="text-body text-high-em">Bạn vừa hoàn thành bài thi của mình. Bạn cảm thấy như thế nào?</span>
                </div>

            </div>

            <div className="grid grid-cols-1 items-center justify-center text-center w-full">
                <HRTextArea
                    placeholder="Gửi ý kiến thêm của bạn tại đây"
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    maxLength={2000}
                    style={{
                        height: "165px",
                        width: "100%",
                    }}
                />
            </div>
            <div className="flex gap-[16px]">
                <HRButton
                    btnType="primary"
                    onClick={onSubmit}
                >
                    <div className="text-[16px] font-bold text-white leading-[19px] flex space-x-2 items-center justify-center">
                        <ICCheckV2 fill="white" />
                        <span>Gửi bài</span>
                    </div>
                </HRButton>
            </div>
        </div>
    </div>
}
