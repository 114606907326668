import { HRButton } from "../../components/button";
import { HRCheckBox } from "../../components/form/checkbox";
import { HRInput } from "../../components/form/input";
import { ICUserV2 } from "../../icons/user";
import React from "react";
import { ICAlert } from "../../icons/alert";
import { isValidEmail } from "../../utils";
import { Link, useNavigate } from 'react-router-dom';
import { Anchor } from "antd";
import styled from "styled-components";

const WrapperLink = styled(Anchor.Link)`
    border: 0;
    padding: 0;
    margin-top: -2px;
    a {
        color: #0084FF;
    }
`;

type RegisterProps = {
    errorMessage?: string;
    onSubmit: (email: string) => void;
}
export const Register: React.FC<RegisterProps> = ({
    errorMessage = "",
    onSubmit
}) => {
    const navigate = useNavigate();
    const [email, setEmail] = React.useState<string>("");
    const [emailError, setEmailError] = React.useState<string>("");
    const [isAccept, setIsAccept] = React.useState<boolean>(false);
    const [isSubmit, setIsSubmit] = React.useState<boolean>(false);
    const canNext = React.useMemo(() => {
        return (email.length > 0 && isValidEmail(email) && email.length <= 50);
    }, [email]);

    const onGoogleResponse = () => {

    }
    const onNext = () => {
        setIsSubmit(true);
        if(!isAccept) return;
        setEmailError("");
        if(canNext){
            onSubmit(email);
        }else{
            if(!email) setEmailError("Email không được để trống");
            else if (email.length > 255) setEmailError("Email có độ dài tối đa 255 ký tự");
            else setEmailError("Email không đúng định dạng");
        }
    }

    React.useEffect(() => {
        if(email) setEmailError('');
    }
    , [email]);

    return (
        <div className="flex flex-col p-[32px] max-w-[466px] gap-[48px]">
            <div className="flex justify-center">
                <img src="/images/logo-login.jpg" alt="logo" />
            </div>
            <h3 className="text-standard-semi-bold text-primary-bold text-center">
                Chào mừng đến với Talent Compass
            </h3>
            {/* <div className="space-y-1">
                <div className="w-[85%] m-auto">
                    <GoogleLogin
                        clientId="658977310896-knrl3gka66fldh83dao2rhgbblmd4un9.apps.googleusercontent.com"
                        onSuccess={onGoogleResponse}
                        onFailure={onGoogleResponse}
                        cookiePolicy={'single_host_origin'}
                        render={renderProps => (
                            <button onClick={renderProps.onClick} disabled={renderProps.disabled} className="w-full">
                                <div className="flex space-x-2 py-[8px] border border-purple rounded-[6px] w-full justify-center">
                                    <ICGoogle />
                                    <span className="text-primary text-base font-medium">Đăng ký với Google</span>
                                </div>
                            </button>
                        )}
                    />
                </div>
                <div className="flex items-center space-x-4 justify-center py-2">
                    <div className="w-[60px] h-px bg-n-500" />
                    <div className="text-n-500 font-medium text-center">Hoặc</div>
                    <div className="w-[60px] h-px bg-n-500" />
                </div>
            </div> */}
            <div className="flex flex-col gap-[28px]">
                <div className="space-y-1">
                    <HRInput
                        isError={isSubmit && (!email || !!emailError)}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        size="large"
                        placeholder="Email"
                        maxLength={50}
                        prefix={!!emailError ? <ICAlert /> : <ICUserV2 />}
                    />
                    {(!canNext || !!email) && <div className="pt-2 font-medium text-xs text-error-500">{emailError || errorMessage }</div>}
                </div>
                <div className="flex gap-[0px] items-center justify-start">
                    <HRCheckBox style={{
                        borderRadius: "100%"
                    }}
                    checked={isAccept}
                    onChange={(e) => setIsAccept(e.target.checked)}
                    >
                        <div className="space-x-2">
                            <span className="text-body text-high-em">Đồng ý với</span>
                            {/* <span className="text-sy-light font-medium">Điều khoản sử dụng</span> */}
                            {/* <a href="#" className="text-sy-light font-medium">Điều khoản sử dụng</a> */}
                        </div>
                    </HRCheckBox>
                    
                    <WrapperLink href="/privacy-policy" className="text-body text-info underline font-medium"
                        title="Điều khoản và Chính sách" target="blank">
                    </WrapperLink>
                </div>
                <HRButton onClick={onNext} disabled={!isAccept}>
                    Tiếp tục
                </HRButton>
                <div className="text-center text-body text-high-em">
                    Đã có tài khoản? <Link to="/login" className="pl-2 text-info underline font-medium">Đăng nhập</Link>
                </div>
            </div>

        </div>
    )
}
