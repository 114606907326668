import React from "react";
import { ICNext, ICMilkTea } from "../../../icons";
import { Typography } from "antd";
import { HRButton } from "../../../components/button";
import { useTestingPageContext } from "..";
import { useTesting } from "../hooks/useTesting";
import { ErrorLink } from "../../../components/errorLink";
import { ERROR_CODE, ERROR_CODE_TITLE_MESSAGE, ERROR_CODE_DESC_MESSAGE } from "../../../utils/constants";

export const TakeBreak: React.FC = () => {
    const { getCurrentSectionTesting } = useTesting();
    const { assessmentDetails, setSectionTesting, handleFullScreen, setStep } = useTestingPageContext();
    // const [candidateExpired, setCandidateExpired] = React.useState(false);
    const [errorCodeCandidate, setErrorCodeCandidate] = React.useState<number | undefined>(undefined);

    const onBeginTesting = React.useCallback(() => {
        assessmentDetails?._id && getCurrentSectionTesting(assessmentDetails?._id, handleFullScreen, setSectionTesting, setStep, setErrorCodeCandidate);
    }, [assessmentDetails, handleFullScreen, setSectionTesting, getCurrentSectionTesting, setStep]);

    const titleError = React.useMemo(() => {
        if (errorCodeCandidate === ERROR_CODE.LOCKED) {
            return ERROR_CODE_TITLE_MESSAGE[errorCodeCandidate];
        }
        return undefined;
    }, [errorCodeCandidate]);
    const descriptionError = React.useMemo(() => {
        if (errorCodeCandidate === ERROR_CODE.LOCKED) {
            return ERROR_CODE_DESC_MESSAGE[errorCodeCandidate];
        }
        return undefined;
    }, [errorCodeCandidate]);

    return errorCodeCandidate ?
        <ErrorLink title={titleError} description={descriptionError}/>
        :
        <div className="flex px-[32px] py-[54px] w-full items-center justify-center">
            <div className="rounded-[12px] bg-white shadow-e-03 flex w-full max-w-[754px] flex-col items-center justify-center gap-[32px] p-[54px]">
                <ICMilkTea />
                <div className="grid grid-cols-1 gap-[16px] items-center justify-center text-center">
                    <Typography.Title style={{
                        fontSize: "24px",
                        lineHeight: "30px",
                    }}>Thư giãn nào!</Typography.Title>
                    <div className="grid grid-cols-1 gap-[4px] items-center justify-center text-center">
                        <Typography.Text style={{
                            fontSize: "14px",
                            lineHeight: "17px",
                            fontWeight: 500,
                        }}>Hãy thử thả lòng người, hít một hơi thật sâu và thư giãn trước khi bắt đầu phần thi kế tiếp nhé!</Typography.Text>
                    </div>
                </div>
                <div className="grid grid-cols-1 gap-[16px] items-center justify-center text-center pt-[16px]">
                    <div className="grid grid-cols-1 gap-[4px] items-center justify-center text-center">
                        <Typography.Text style={{
                            fontSize: "14px",
                            lineHeight: "17px",
                            fontWeight: 500,
                        }}>Khi bạn đã sẵn sàng thì hãy nhấn nút bên dưới để bắt đầu.</Typography.Text>
                    </div>
                </div>
                <div className="flex gap-[16px]">
                    <HRButton
                        onClick={onBeginTesting}
                        btnType="primary"
                    >
                        <div className="text-[16px] font-bold text-white leading-[19px] flex space-x-2 items-center justify-center">
                            <span>Tiếp tục phần thi kế tiếp</span>
                            <ICNext fill="white" />
                        </div>
                    </HRButton>
                </div>
            </div>
        </div>
}
