import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch } from "react-redux";
import { HomeHeader } from "../home/header";
import { DevicesSection, DevicesSectionProps, Device, DeviceType } from "./devices";
import { getDevices, logoutDevices } from "../../api/device";
import { setLoading } from "../../redux/slices/appInfo";
import { useNotification } from '../../hooks/useNotification';

export const MyDevicePage: React.FC = () => {
    const dispatch = useDispatch();
    const [devices, setDevices] = useState([] as DevicesSectionProps[])
    const loadData = async () => {
        dispatch(setLoading(true));
        try {
            const results = await getDevices();
            const data: Device[] = results.data || [];
            const devicesWindow = data.filter(d => d.isDesktop && d.isWindows);
            const devicesMac = data.filter(d => d.isDesktop && d.isMac);
            const devicesAndroid = data.filter(d => d.isMobile && d.isAndroid);
            const devicesIos = data.filter(d => d.isMobile && d.isIOS);
            const devicesOther = data.filter(d => !d.isWindows && !d.isMac && !d.isMobile);
            const newData: DevicesSectionProps[] = [
                {
                    type: DeviceType.window,
                    devices: devicesWindow
                },
                {
                    type: DeviceType.mac,
                    devices: devicesMac
                },
                {
                    type: DeviceType.android,
                    devices: devicesAndroid
                },
                {
                    type: DeviceType.ios,
                    devices: devicesIos
                },
                {
                    type: DeviceType.other,
                    devices: devicesOther
                }
            ];
            setDevices(newData);

        } catch (e) {
            console.error(e)
        }
        finally {
            dispatch(setLoading(false));
        }
    };
    const {
        showError
    } = useNotification();
    const onLogoutDevice = React.useCallback(async (type: string, data: Device) => {
        dispatch(setLoading(true));
        try {
            await logoutDevices({ deviceId: data.deviceId });
            setDevices(oldDevices => {
                return oldDevices.map(d => {
                    if (d.type === type) {
                        d.devices = d.devices.filter(v => v.deviceId !== data.deviceId);
                    }
                    return { ...d };
                });
            });
        } catch (e: any) {
            const { errorCode, errorMessage } = e?.response?.data ?? {}
            if (errorCode === 404 && errorMessage === "Device not found") {
                showError("Thất bại", "Thiết bị không tồn tại");
            }
            console.error(e)
        }
        finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, showError]);

    useEffect(() => {
        loadData();
    }, []);

    const renderData = useMemo(() => {
        if (devices.length) {
            return devices.map((d: DevicesSectionProps, index) => {
                d.devices.sort(function (a, b) {
                    return new Date(b.lastActive).valueOf() - new Date(a.lastActive).valueOf();
                });
                return <DevicesSection key={index} onActionLogoutDevice={onLogoutDevice} devices={d.devices} type={d.type} />;
            });
        }
        return null;
    }, [devices])

    return (
        <div className="pt-8 space-y-6 pb-16">
            <HomeHeader activedIndex={4} />
            <div className="text-left container mx-auto pt-4">
                <div className="text-[24px] font-medium text-n-800">Thiết bị của bạn</div>
                <div className="space-y-6">
                    {renderData}
                </div>
            </div>
        </div>
    )
}
