import React from "react";
import { ICMoMo, ICVNPay } from "../../icons";
import { HRCheckBox } from "../../components/form/checkbox";
import { InvoicePayment } from "./invoice";
import { useCandidateCreditPageContext } from ".";
import { Radio, RadioChangeEvent } from "antd";
import styled from "styled-components";
import { EPaymentMethod, PaymentMethosMapping } from "../payment/type";

const PaymentMethodList = [
    {
        id: 1,
        code: EPaymentMethod.MOMO,
        logo: <ICMoMo />,
    },
    {
        id: 2,
        code: EPaymentMethod.VNPAY,
        logo: <ICVNPay />,
    },
];

const Wrapper = styled.div`
    .ant-radio-group {
        display: flex;
        flex-direction: column;
        gap: 12px;   
    }
    .ant-radio-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }
`;

export const PaymentMethod: React.FC = () => {
    const { 
        activeInvoice,
        setActiveInvoice,
        currentPaymentMethod,
        setCurrentPaymentMethod,
    } = useCandidateCreditPageContext();

    return <div className="flex flex-col w-full gap-[16px]">
        <span className="text-title-bold text-high-em">Phương thức thanh toán</span>
        <div className="bg-white rounded-[8px] p-[16px] flex flex-col gap-[24px]" style={{
            boxShadow: "0px 6px 16px -6px rgba(17, 12, 34, 0.1)",
            minHeight: '160px',
        }}>
            <div className="flex justify-between items-center">
                <Wrapper className="flex flex-col gap-[16px] items-center pl-[16px]">
                    <Radio.Group value={currentPaymentMethod} onChange={(e: RadioChangeEvent) => {
                        setCurrentPaymentMethod(e.target.value);
                    }}>
                        {
                            PaymentMethodList.map((item) => {
                                return <Radio value={item.code}
                                    key={item.id}
                                >
                                    <div className="flex gap-[16px] items-center pl-[16px]">
                                        {item.logo}
                                        <span className="text-[16px] leading-[24px] text-high-em font-bold">
                                            {PaymentMethosMapping[item.code]}
                                        </span>
                                    </div>
                                    
                                </Radio>
                            })
                        }
                    </Radio.Group>
                </Wrapper>
            </div>
            <div className="flex flex-col items-start gap-[24px] rounded-[12px] border border-[#ECECED] p-[16px]">
                <HRCheckBox checked={activeInvoice}
                    onChange={(e) => setActiveInvoice(e.target.checked)}
                    className="purple-checkbox"
                    style={{
                        fontSize: '14px',
                        lineHeight: '24px',
                        color: '#4F4B5C',
                        fontWeight: 700,
                    }}>Xuất hóa đơn điện tử</HRCheckBox>
                {activeInvoice && <InvoicePayment />}
            </div>
        </div>
    </div>
}
