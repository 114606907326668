import React from 'react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { HRButton } from "../../components/button"
import { SearchParamsStateType, useFilter } from '../../hooks/useFilter';
import { ICAdd } from "../../icons";
import { setLoading } from '../../redux/slices/appInfo';
import { IPagination, ISearchCriteria } from '../library/models';
import { TestList } from "./list"
import { TMyCandidateData, TMyCandidateFilter } from './type';
import { Filter } from './filter';
import { getMyCandidates, getMyCandidatesFilterData } from '../../api/candidate';

type TSortData = {
    field?: string;
    order?: 'asc' | 'desc';
}
type TSortDataContext = {
    sortData: TSortData;
    setSortData: (data: TSortData) => void;
}
const SortDataContext = React.createContext<TSortDataContext>({} as TSortDataContext);
export const useSortData = () => React.useContext(SortDataContext);

export const MyCandidatePage: React.FC = () => {
    const dispatch = useDispatch();
    const [sortData, setSortData] = React.useState<TSortData>({ field: 'lastActiveAt', order: 'desc' });
    const [pagination, setPagination] = React.useState<IPagination>();
    const [filterData, setFilterData] = React.useState<TMyCandidateFilter>();
    const [testList, setTestList] = React.useState<TMyCandidateData[]>([]);
    
    const filtersDefaults: SearchParamsStateType = {
        page: { type: 'number', default: 1 },
        limit: { type: 'number', default: 5 },
        searchString: { type: 'string', default: null },
        assessment: { type: 'string', default: null },
        test: { type: 'string', default: null },
        role: { type: 'string', default: null },
        orderBy: { type: 'string', default: "lastActiveAt::desc" },
    }
    const [filterParams, setFilterParams] = useFilter(filtersDefaults);

    const loadFilterData = useCallback(async () => {
        try {
            const results = await getMyCandidatesFilterData();
            const newData = results.data as TMyCandidateFilter;
            newData.assessments?.map((item) => {
                item.label = item.title;
                item.value = item._id;
            });
            newData.tests?.map((item) => {
                item.label = item.title;
                item.value = item._id;
            });
            setFilterData(newData);
        }
        catch (e) {
            console.error(e)
        }
    }, []);
    
    const onChangeSort = useCallback((data: TSortData) => {
        setSortData(data);
        const formatSortData = `${data.field}::${data.order}`;
        onChangeFilter({
            orderBy: formatSortData,
            page: 1
        })
    }, []);

    React.useEffect(() => {
        loadData(filterParams);
    }, []);

    React.useEffect(() => {
        loadFilterData();
    }, []);

    const onChangeFilter = useCallback((values: any) => {
        const newParams = { ...filterParams, ...values };
        loadData(newParams);
    }, [filterParams]);

    const loadData = async (value?: ISearchCriteria) => {
        const newParams = value || filterParams;
        setFilterParams(newParams);
        dispatch(setLoading(true));
        try {
            const results = await getMyCandidates(newParams);
            const newData = results?.data || {};
            setTestList(newData.data || []);
            setPagination(newData?.metaData);
        } catch (e) {
            console.error(e)
        }
        finally {
            dispatch(setLoading(false));
        }
    };

    return (
        <div className="text-center">
            <div className="pt-8 space-y-6 min-h-screen">
                <div className="pb-16 space-y-8 text-left container mx-auto">
                    <SortDataContext.Provider value={{ sortData, setSortData: onChangeSort }}>
                        <TestHeader />
                        <Filter
                            onChange={onChangeFilter}
                            filterParams={filterParams}
                            filterData={filterData}
                        />
                        <TestList
                            testList={testList}
                            keyword={filterParams?.searchString}
                            pagination={pagination}
                            onChange={onChangeFilter}
                        />
                    </SortDataContext.Provider>
                </div>
            </div>
        </div>
    )
}

const TestHeader: React.FC = () => {
    const navigate = useNavigate();
    const goCreateAssessment = useCallback(() => {
        navigate('/create-assessment');
    }, [navigate]);
    return (
        <div className="flex items-center justify-between">
            <div className="flex items-center text-heading-5-semi-bold text-high-em" >
                <span>Danh sách ứng viên</span>
            </div>
            <div>
                <HRButton btnSize="md" onClick={goCreateAssessment}>
                    <div className="flex space-x-2 justify-center">
                        <ICAdd />
                        <span>Tạo bài đánh giá</span>
                    </div>
                </HRButton>
            </div>
        </div>
    )
}
