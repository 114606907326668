import { Button, Modal, Popover, Row, Table, Tag } from "antd"
import { ColumnType, ColumnsType } from "antd/lib/table";
import React from "react";
import classNames from "classnames";
import Highlighter from "react-highlight-words";
import { useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { HRPagination } from "../../components/pagination";
import { ICAdd, ICSort, ICMoreVertical, ICEdit, ICDelete, ICNoneData, ICSendV2 } from "../../icons";
import { RootState } from "../../redux/reducers";
import { IPagination } from "../library/models";
import { ETestStatus, StatusTestMappingColor, testStatusMapping, TTestData } from "./type";
import { SortOrder } from "antd/lib/table/interface";
import { useSortData } from ".";
import { formatLocaleDateString } from "../../utils/date";
import { HRButton } from "../../components/button";
import styled from "styled-components";
import { HRTooltip } from "../../components/tooltip";
import { useNavigation } from "../../hooks/useNavigate";

const WrapperTable = styled.div`
    .ant-table-thead>tr>th {
        border: 0;
    }
    .ant-table-tbody>tr.ant-table-row>td {
        border-top: 1px solid transparent !important;
        border-bottom: 1px solid transparent !important;
    }
    .ant-table-tbody>tr.ant-table-row >td:first-child {
        border-left: 1px solid transparent !important;
    }
    .ant-table-tbody>tr.ant-table-row >td:last-child {
        border-right: 1px solid transparent !important;
    }
    .ant-table-tbody>tr.ant-table-row:hover>td:first-child, .ant-table-tbody>tr>td.ant-table-cell-row-hover:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 12px;
    }
    .ant-table-tbody>tr.ant-table-row:hover>td:last-child, .ant-table-tbody>tr>td.ant-table-cell-row-hover:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 12px;
    }
`;

type PopoverContentProps = {
    onClose: () => void;
    id: string;
    onDelete: () => void;
}
const PopoverContent: React.FC<PopoverContentProps> = ({ id, onClose, onDelete }) => {
    const [searchParams] = useSearchParams();

    const onCloseWrapper = React.useCallback((event: any) => {
        if (event) {
            event.stopPropagation();
        }
        onClose();
    }, [onClose]);

    const openDeleteModal = React.useCallback((event: any) => {
        if (event) {
            event.stopPropagation();
        }
        onDelete();
        onClose();
    }, [onClose, onDelete]);

    return (
        <div className="flex flex-col min-w-[206px] py-[12px]">
            <Link to={`/edit-test/${id}`} state={{ search: window.location.search }}>
                <div onClick={onCloseWrapper} className="flex gap-[16px] items-center p-[16px] hover:bg-surface-med cursor-pointer text-body-medium text-high-em">
                    <ICEdit />
                    <span>Chỉnh sửa</span>
                </div>
            </Link>
            <div onClick={onCloseWrapper} className="flex gap-[16px] items-center p-[16px] hover:bg-surface-med cursor-pointer text-body-medium text-high-em">
                <ICSendV2 />
                <span>Đăng tải</span>
            </div>
            <div onClick={openDeleteModal} className="flex gap-[16px] items-center p-[16px] hover:bg-surface-med cursor-pointer text-body-medium text-high-em">
                <ICDelete />
                <span>Xóa</span>
            </div>
        </div>
    )
}

const CommonRenderer: React.FC<{
    list: string[],
    commonName: string,
}> = ({ list, commonName }) => {
    const commonData = useSelector((state: RootState) => state.commonData);
    const listStr = React.useMemo(() => {
        return (list ?? []).map((item) => {
            return commonData[commonName]?.find?.((s) => s._id === item)?.label
                || item;
        }).join(', ');
    }, [list, commonData, commonName]);
    return <HRTooltip
        content={listStr}
        childNode={(
            <div className="pr-2 whitespace-nowrap overflow-hidden text-ellipsis max-w-[100px] text-body text-high-em">
                {listStr}
            </div>
        )}
    />
}
const TitleRenderer: React.FC<{ title: string }> = ({ title }) => {
    const { keyword } = React.useContext(TableContext);

    return <Highlighter
        highlightClassName="text-highlight"
        searchWords={[`${keyword || ''}`]}
        autoEscape={true}
        className="text-body text-high-em"
        textToHighlight={title || ''}
    />
}
type SortableColumnProps = {
    title: string;
    order?: SortOrder;
    name: string;
}
const SortableColumn: React.FC<SortableColumnProps> = ({
    title,
    order,
    name
}) => {
    const { setSortData } = useSortData();
    return <div className="flex space-x-3 items-center select-none group" onClick={() => {
        setSortData({
            field: order === 'descend' ? 'createdAt' : name,
            order: order === 'descend' ? 'desc' : !order ? 'asc' : 'desc',
        });
    }}>
        <span className="text-body-bold text-high-em">{title as string}</span>
        <ICSort order={order} />
    </div>
}

type TestListProps = {
    testList: TTestData[];
    keyword?: string;
    pagination?: IPagination;
    onChange: ({ page }: { page: number }) => void;
    onDelete: (id: string) => void;
}
type TableContextProps = {
    keyword: string;
    onDelete: (id: string) => void;
}
const TableContext = React.createContext<TableContextProps>({
    keyword: '',
} as TableContextProps);

export const TestList: React.FC<TestListProps> = ({
    testList,
    keyword,
    pagination,
    onChange,
    onDelete,
}) => {

    const { navigate } = useNavigation();
    const onChangePaging = (page: number) => {
        onChange({ page })
    };
    const [showModalDelete, setShowModalDelete] = React.useState(false);
    const [testId, setTestId] = React.useState('');

    const onShowDeleteModal = React.useCallback((id: string) => {
        setTestId(id);
        setShowModalDelete(true);
    }, [setShowModalDelete]);

    const columns: ColumnsType<TTestData> = [
        {
            title: 'Tên đề kiểm tra',
            dataIndex: 'title',
            key: 'title',
            sorter: true,
            render: (title: string) => {
                return <TitleRenderer title={title} />
            },
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            render: (status: ETestStatus) => {
                return <Tag style={{
                    borderRadius: '4px',
                    fontSize: '12px',
                    fontWeight: 500,
                    lineHeight: '16px',
                    padding: '4px 8px',
                    color: '#FFFFFF',
                    backgroundColor: StatusTestMappingColor[status],
                    borderColor: StatusTestMappingColor[status]
                }}>
                    {testStatusMapping[status] ?? status}
                </Tag>
            },
            sorter: true,
            width: '140px',
        },
        {
            title: 'Ngày tạo',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: true,
            width: '130px',
            render: (createdAt: number) => {
                return <div className="pr-2 text-body text-high-em">
                    <span>{formatLocaleDateString(createdAt)}</span>
                </div>
            }
        },
        {
            title: 'Câu hỏi',
            dataIndex: 'totalQuestions',
            key: 'totalQuestions',
            sorter: true,
            width: '130px',
            render: (totalQuestions: number) => {
                return <div className="pr-2 text-body text-high-em">
                    <span>{totalQuestions ?? 0}</span>
                </div>
            }
        },
        {
            title: 'Kỹ năng',
            dataIndex: 'skillsRequired',
            key: 'skillsRequired',
            width: '120px',
            render: (skillsRequired: string[]) => {
                const joinStr = skillsRequired.join(', ');
                return <HRTooltip
                    content={joinStr}
                    childNode={(
                        <div className="pr-2 whitespace-nowrap overflow-hidden text-ellipsis max-w-[100px]">
                            {joinStr}
                        </div>
                    )} 
                />
            }
        },
        {
            title: 'Chức vụ',
            dataIndex: 'roles',
            key: 'roles',
            width: '120px',
            render: (roles: string[]) => {
                return <CommonRenderer list={roles} commonName="position" />
            }
        }, {
            title: '',
            dataIndex: '_id',
            key: '_id',
            width: '40px',
            render: (_id: string) => {
                return <ItemActions id={_id} onShowDeleteModal={onShowDeleteModal} />
            }
        }
    ].map((col: ColumnType<TTestData>) => {
        if (col.sorter) {
            const oldTitle = col.title;
            col.title = ({ sortColumns }) => {
                const sortedColumn = sortColumns?.find(({ column }) => column.key === col.key);
                return <SortableColumn
                    title={oldTitle as string}
                    order={sortedColumn?.order}
                    name={col.dataIndex as string}
                />
            }
        } else {
            col.title = <div className="font-bold text-base">{col.title as string}</div>;
        }
        col.showSorterTooltip = false;
        return col
    });

    return <WrapperTable className="grid grid-cols-1 shadow-e-03 text-g-400 overflow-hidden box-border rounded-[12px] bg-white">
        <TableContext.Provider value={{
            keyword: keyword ?? '',
            onDelete,
        }}>
            <Table
                className={classNames("white-header")}
                columns={columns}
                dataSource={testList}
                rowKey="_id"
                locale={{
                    emptyText: <EmptyTable />
                }}
                rowClassName="cursor-pointer data-row"
                onRow={(record) => {
                    return {
                        onClick: () => {
                            navigate(`/edit-test/${record?._id}`, {
                                state: {
                                    search: window.location.search
                                }
                            });
                        }
                    }
                }}
                pagination={false}
            />
        </TableContext.Provider>
        {pagination && pagination.totalPages > 1 && <Row gutter={16} 
            style={{
                margin: 0,
            }}
            className="grid items-center justify-end w-full bg-white p-[16px] border-t border-solid border-outline-med rounded-b-[12px]">
            <HRPagination
                onChange={onChangePaging}
                defaultCurrent={pagination?.page}
                defaultPageSize={pagination?.limit}
                total={pagination?.totalDocuments}
                currentPage={pagination?.page}
            />
        </Row>}
        <Modal
            title="Thông báo"
            open={showModalDelete}
            onCancel={() => setShowModalDelete(false)}
            centered={true}
            footer={
                <div className="grid grid-cols-[auto_auto] justify-end gap-[16px]">
                    <HRButton
                        btnSize="sm"
                        btnType="sub"
                        onClick={() => setShowModalDelete(false)}>
                        Không
                    </HRButton>
                    <HRButton btnSize="sm" onClick={() => {
                        onDelete(testId);
                        setShowModalDelete(false);
                    }}>
                        Có
                    </HRButton>
                </div>
            }
        >
            <p>Bạn có chắc chắn muốn xóa?</p>
        </Modal>
    </WrapperTable>
}

const EmptyTable = () => {
    const navigate = useNavigate();
    const goCreateTest = React.useCallback(() => {
        navigate('/create-test')
    }, [navigate])
    return (
        <div className="my-6 flex flex-col gap-[32px]">
            <div className="flex justify-center">
                <ICNoneData />
            </div>
            <div className="text-standard-bold text-high-em">Không có đề kiểm tra nào</div>
            <div className="flex justify-center">
                <span>
                    <HRButton btnType="sub"
                        onClick={goCreateTest}>
                        <div className="flex text-primary items-center space-x-2">
                            <ICAdd stroke="var(--text-primary)" width={18} height={18} />
                            <span>Tạo đề kiểm tra mới</span>
                        </div>
                    </HRButton>
                </span>
            </div>
        </div>
    )
}

type ItemActionsProps = {
    id: string;
    onShowDeleteModal: (id: string) => void;
}
export const ItemActions: React.FC<ItemActionsProps> = ({
    id,
    onShowDeleteModal,
}) => {
    const [open, setOpen] = React.useState(false);
    const onClose = React.useCallback(() => {
        setOpen(false);
    }, [])
    const handleOpenChange = (newOpen: boolean) => {
        setOpen(newOpen);
    };

    const onDeleteWrapper = React.useCallback(() => {
        onShowDeleteModal(id);
        onClose();
    }, [onClose, id, onShowDeleteModal]);
    
    return (
        <div className="text-center">
            <Popover
                open={open}
                placement="bottomRight"
                content={<PopoverContent
                    id={id}
                    onClose={onClose}
                    onDelete={onDeleteWrapper}
                />}
                showArrow={false}
                className="popover-more"
                prefixCls="hr-popover ant-popover"
                trigger="click"
                onOpenChange={handleOpenChange}
            >
                <Button ghost style={{
                    width: "32px",
                    height: "32px",
                    padding: "0px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
                onClick={(event) => event.stopPropagation()}>
                    <ICMoreVertical />
                </Button>
            </Popover>
        </div>
    )
}
