import React from "react"
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from "react-router-dom";
import { getCommonAPI } from "../api/common";
import { useMe } from "../hooks/useMe";
import { MainLayout } from "../layout/main";
import { setCommonData } from "../redux/actions";
import { RootState } from "../redux/reducers";
import { LayoutType } from '../utils/enums';
import { HRButton } from "../components/button";
import { Modal } from "antd";
import { ICComingSoon, ICComingSoonBottom, ICComingSoonTop } from "../icons";
import { ICBack } from "../icons/back";
import { NavigationProvider } from "../hooks/useNavigate";
import { ScrollTop } from "../hooks/useScrollTop";

type TGeneralPageDataProps = {
    showComingSoonModal: boolean,
    setShowComingSoonModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const GeneralPageDataContext = React.createContext<TGeneralPageDataProps>({} as TGeneralPageDataProps);
export const useGeneralPageData = () => React.useContext(GeneralPageDataContext);

type GeneralPageProps = {
    page: React.ReactElement
    isPrivate?: boolean,
    layout?: LayoutType
}
export const GeneralPage: React.FC<GeneralPageProps> = ({
    page,
    isPrivate = false,
    layout = LayoutType.MAIN,
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userInfo = useSelector((state: RootState) => state.userInfo);
    const commonData = useSelector((state: RootState) => state.commonData);
    const [showComingSoonModal, setShowComingSoonModal] = React.useState(false);
    const { getMe } = useMe();

    const [pageContent, setPageContent] = React.useState<React.ReactElement>(<></>);
    React.useEffect(() => {
        if ((isPrivate || typeof isPrivate === "undefined") && !userInfo.token) {
            localStorage.setItem('redirectPathname', location.pathname);
            navigate("/login");
        } else {
            if (layout === LayoutType.MAIN) {
                return setPageContent(<MainLayout>{page}</MainLayout>)
            } else {
                setPageContent(page)
            }
        }
    }, [page, userInfo, isPrivate, navigate, layout]);

    React.useEffect(() => {
        if (isPrivate) {
            getMe()
        }
        // Fetching common data if not exist
        if (!commonData || Object.keys(commonData).length === 0) {
            getCommonAPI().then(({ data }) => {
                dispatch(setCommonData(data));
            }).catch((err) => {
                console.log(err)
            })
        }
    }, []);

    return <NavigationProvider>
        <GeneralPageDataContext.Provider
            value={{
                showComingSoonModal,
                setShowComingSoonModal
            }}
        >
            {pageContent}
            <ScrollTop />
            <Modal
                centered
                onCancel={() => setShowComingSoonModal(false)}
                open={showComingSoonModal}
                closeIcon={null}
                zIndex={1001}
                width={1000}
                title={null}
                bodyStyle={{
                    padding: "56px",
                }}
                style={{
                    boxShadow: '0px 4px 16px rgba(98, 112, 140, 0.24)',
                    borderRadius: '12px'
                }}
                footer={null}>
                <div className="flex flex-row items-center min-h-[414.89px] gap-[56px]">
                    <span className="absolute top-0 left-0">
                        <ICComingSoonTop />
                    </span>
                    <span className="absolute bottom-0 right-0">
                        <ICComingSoonBottom />
                    </span>
                    <div>
                        <ICComingSoon />
                    </div>
                    <div className="flex flex-col gap-[40px]">
                        <div className="flex flex-col gap-[16px]">
                            <h4 className="text-[32px] font-bold leading-[40px] text-left text-high-em mb-0">Tính năng đang phát triển</h4>
                            <p className="text-[#676472] text-[16px] font-normal leading-[24px] mb-0">
                                Tính năng này đang trong quá trình hoàn thiện. Chúng tôi đang nỗ lực để mang đến cho bạn trải nghiệm tốt nhất. Xin chân thành cảm ơn sự kiên nhẫn và ủng hộ của bạn.
                            </p>
                        </div>
                        <HRButton btnSize="lg"
                            btnType="info"
                            onClick={() => setShowComingSoonModal(false)}>
                            <div className="flex justify-center items-center gap-[8px]">
                                <ICBack fill="white" />
                                <span>Quay lại</span>
                            </div>
                        </HRButton>
                    </div>
                </div>
            </Modal>
        </GeneralPageDataContext.Provider>
    </NavigationProvider>
}
