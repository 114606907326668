import { DatePicker } from "antd"
import TextArea from "antd/lib/input/TextArea"
import moment from "moment"
import React from "react"
import { getListNationality, getListPosition } from "../../api/utils"
import { HRInput } from "../../components/form/input"
import { HRSelect } from "../../components/form/select"
import { ICAlert, ICBriefcase, ICCalendar, ICEmail, ICGlobal, ICLock, ICMention, ICMobile, ICUser } from "../../icons/index"
import { TUserInfo } from "../../redux/slices/userInfo"
import { ChangePassword } from "./change_password"
import { NAME_REGEX } from "../../utils/constants"
import { HRButton } from "../../components/button"

type UserInfoProps = {
    userInfo: TUserInfo;
    onChange: (key: string, value: any) => void;
    errors: {
        isEmptyName: boolean;
        isExistsFeatureName: boolean;
        inValidPhone: boolean;
    }
}
export const UserInfo: React.FC<UserInfoProps> = ({
    userInfo,
    onChange,
    errors
}) => {
    const [listPosition, setListPosition] = React.useState<any[]>([]);
    const [listNationality, setListNationality] = React.useState<any[]>([]);
    const [introduce, setIntroduce] = React.useState<string>("");

    React.useEffect(() => {
        setIntroduce(userInfo.introduce ?? "")
    }, [userInfo.introduce])

    React.useEffect(() => {
        getListPosition().then(({ data }) => {
            setListPosition(data.data)
        })
        getListNationality().then(({ data }) => {
            setListNationality(data)
        })
    }, []);

    const inValidName = React.useMemo(() => {
        return userInfo.name ? !NAME_REGEX.test(userInfo.name ?? "") : false;
    }, [userInfo.name]);

    const [isOpenModal, setIsOpenModal] = React.useState(false);

    return <div className="container mx-auto">
        <div className="flex space-x-4">
            <div className="w-1/2 bg-white rounded-[10px] p-8 text-left">
                <div className="mb-8 text-heading-5-medium text-high-em">Thông tin cơ bản</div>
                <div className="space-y-4">
                    <div>
                        <span className="text-body-medium text-med-em">Họ và tên</span>
                        <HRInput
                            maxLength={50}
                            onChange={(e) => onChange("name", e.target.value)}
                            value={userInfo.name ?? userInfo.email}
                            prefix={
                                errors.isEmptyName || inValidName ? <ICAlert /> : <ICUser fill="#C6C5CA" />
                            }
                            isError={errors.isEmptyName || inValidName}
                        />
                        {
                            errors.isEmptyName && !inValidName && <div className="pt-2 font-medium text-xs text-error-500 mt-1">
                                Vui lòng nhập họ và tên
                            </div>
                        }
                         {
                            inValidName && <div className="pt-2 font-medium text-xs text-error-500 mt-1">
                                Họ và tên không được phép có ký tự đặc biệt
                            </div>
                        }
                    </div>
                    <div>
                        <span className="text-body-medium text-med-em">Tên đặc trưng</span>
                        <HRInput
                            isError={errors.isExistsFeatureName}
                            maxLength={50}
                            onChange={(e) => onChange("featuredName", e.target.value)}
                            prefix={
                                errors.isExistsFeatureName ? <ICAlert /> : <ICMention />
                            }
                            value={userInfo.featuredName}
                        />
                        {
                            errors.isExistsFeatureName && <div className="pt-2 font-medium text-xs text-error-500 mt-1">
                                Tên đặc trưng đã tồn tại
                            </div>
                        }
                    </div>
                    <div>
                        <span className="text-body-medium text-med-em">Ngày sinh</span>
                        <DatePicker
                            suffixIcon={<ICCalendar fill="#C6C5CA" />}
                            style={{
                                width: "100%",
                                padding: "8px",
                                borderRadius: "4px",
                                border: "1px solid #D6D7DD",
                                borderColor: "#D6D7DD",
                                fontSize: "14px",
                                fontWeight: 500,
                            }}
                            placeholder="Ngày sinh"
                            format={"DD/MM/YYYY"}
                            showToday={false}
                            disabledDate={(current) => {
                                const isInvalid = current && (current.valueOf() > +new Date() || current.valueOf() <= moment("01/01/1970").valueOf());
                                return isInvalid;
                            }}
                            value={userInfo.dateOfBirth ? moment(userInfo.dateOfBirth) : undefined}
                            onChange={(date) => onChange("dateOfBirth", date?.valueOf() ?? null)}
                        />
                    </div>
                    <div>
                        <HRSelect
                            label="Chức vụ"
                            value={userInfo.positionId}
                            onChange={(value) => {
                                onChange("positionId", value)
                            }}
                            placeholder="Chọn chức vụ"
                            icon={<ICBriefcase />}
                            style={{
                                width: "100%",
                            }}
                            allowClear={false}
                            options={listPosition.map(pos => ({ label: pos.name, value: pos._id }))}
                        />
                    </div>
                    <div>
                        <HRSelect
                            allowClear={false}
                            label="Quốc tịch"
                            value={userInfo.nationalityId}
                            onChange={(value) => {
                                onChange("nationalityId", value)
                            }}
                            placeholder="Chọn Quốc tịch"
                            icon={<ICGlobal fill="#C6C5CA" />}
                            style={{
                                width: "100%",
                            }}
                            options={listNationality.map(nat => ({ label: nat.enShortName, value: nat._id }))}
                        />
                    </div>
                    <div>
                        <span className="text-body-medium text-med-em">Giới thiệu</span>
                        <TextArea style={{
                            minHeight: "180px",
                            borderRadius: "4px",
                            border: "1px solid #D6D7DD",
                        }}
                            placeholder="Hãy giới thiệu về bản thân"
                            value={introduce}
                            onChange={(e) => {
                                const value = e.target.value ? e.target.value.trim() : '';
                                setIntroduce(e.target.value);
                                onChange("introduce", value);
                            }}
                            maxLength={1000}
                        />
                    </div>
                </div>
            </div>
            <div className="w-1/2 flex flex-col gap-[16px]">
                <div className="bg-white rounded-[10px] p-8 text-left">
                    <div className="mb-8 text-heading-5-medium text-high-em">Liên lạc</div>
                    <div className="space-y-4">
                        <div>
                            <span className="text-body-medium text-med-em">Email</span>
                            <HRInput
                                disabled
                                value={userInfo.email}
                                prefix={<ICEmail fill="#9DA3AC" />}
                            />
                        </div>
                        <div>
                            <span className="text-body-medium text-med-em">Số điện thoại</span>
                            <HRInput
                                maxLength={11}
                                minLength={10}
                                type="tel"
                                value={userInfo.phoneNumber ?? ""}
                                prefix={<ICMobile fill="#9DA3AC" />}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if(value[0] === "+"){
                                        if(value.length <= 13 && (/^[0-9]+$/.test(value.slice(1)) || value.slice(1) === "")){
                                            onChange("phoneNumber", e.target.value)
                                        }
                                    }else{
                                        if(value.length <= 11 && /^[0-9]*$/.test(value)){
                                            onChange("phoneNumber", e.target.value)
                                        }
                                    }
                                }}
                            />
                            {errors?.inValidPhone && <div className="pt-2 font-medium text-xs text-error-500 pt-1">Số điện thoại không hợp lệ</div>}
                        </div>
                    </div>
                </div>
                <div className="bg-white rounded-[10px] p-[32px] text-left flex flex-col gap-[24px]">
                    <div className="text-heading-5-medium text-high-em">Quản lý tài khoản</div>
                    <span>
                        <HRButton
                            btnType="secondary"
                            btnSize="md"
                            width="auto"
                            onClick={() => setIsOpenModal(true)}
                        >
                            <div className="flex space-x-3">
                                <ICLock fill="#7357FF" />
                                <span>Đổi mật khẩu</span>
                            </div>
                        </HRButton>
                    </span>
                    <ChangePassword
                        isOpen={isOpenModal}
                        onClose={() => {
                            setIsOpenModal(false)
                        }}
                    />
                </div>
            </div>
        </div>
    </div>
}
