import React from "react";
import { useNotification } from "../../../../hooks/useNotification";
import { FormInstance } from "antd/lib/form";

export const useConfigQuestion = (questionUsageForm: FormInstance<any>) => {
    const { showError } = useNotification();
    const checkValidQuestionUsageForm = React.useCallback(async () => {
        try {
            const touchedFields = Object.keys(questionUsageForm?.getFieldsValue()).filter((el) => questionUsageForm.isFieldTouched(el));
            await questionUsageForm.validateFields(touchedFields);
            return true;
        } catch (error) {
            showError('Error', 'Vui lòng nhập số câu hỏi cho mỗi nhóm');
            return false;
        }
    }, [questionUsageForm, showError]);

    const checkZeroValue = React.useCallback((values: any[]) => {
        const inValid = values.some(item => item === 0);
        if (inValid) {
            showError('Error', 'Số câu hỏi phải lớn hơn 0');
            return true;
        }
        
        return false;
    }, [showError]);

    return {
        checkValidQuestionUsageForm,
        checkZeroValue
    }
}
