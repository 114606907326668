import React from "react"
import { HRInput } from "../../../../components/form/input"
import { HRSelect } from "../../../../components/form/select"
import { ICSearch } from "../../../../icons"
import {
    STATE_QUESTIONS,
    TYPE_QUESTIONS
} from './contants';
import { useCreateTestPageContext } from "../..";
import _ from "lodash";
import { quoteString } from "./helper";

type TFilterQuestions = {
}
export const FilterQuestions: React.FC<TFilterQuestions> = () => {
    const {
        skillAreas,
        sampleSection,
        formFilter,
        setFormFilter
    } = useCreateTestPageContext();
    const questionFilter = [...TYPE_QUESTIONS, {
        label: "Tự luận",
        value: "essay"
    }];
    const [title, setTitle] = React.useState(formFilter.title);
    const onChangeForm = React.useCallback((key: string, value: unknown) => {
        setFormFilter(prev => {
            return {
                ...prev,
                [key]: value
            }
        })
    }, [setFormFilter]);

    const onChangeSearch = React.useCallback(_.debounce((value: string) => {
        setFormFilter(prev => {
            return {
                ...prev,
                title: value
            }
        });
    }, 200, {
        trailing: true
    }), [setFormFilter]);

    React.useEffect(() => {
        return () => {
            onChangeSearch.cancel();
        }
    }, [onChangeSearch]);

    const onChangeTitle = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        // Max length 50
        if (value.length > 50) return;
        setTitle(value);
        onChangeSearch(value);
    }, []);

    const onClearValue = React.useCallback(() => {
        setTitle(undefined);
        setFormFilter(prev => {
            return {
                ...prev,
                title: undefined
            }
        });
    }, [setTitle, setFormFilter]);

    const totalQuestions = React.useMemo(() => {
        return [
            ...skillAreas.map(skillArea => skillArea.questions).flat(),
            ...sampleSection.questions
        ]
    }, [skillAreas, sampleSection]);

    const existFilterResult = React.useMemo(() => {
        const {
            title,
            isActive,
            questionType
        } = formFilter;
        if(title === undefined && isActive === undefined && questionType === undefined) return true;
        const isMatchTitle = (q: string) => {
            if(!title) return true;
            const regex = new RegExp(quoteString(title), 'i');
            return regex.test(q);
        }
        return totalQuestions.some(question => {
            return (
                isMatchTitle(question.content) &&
                (isActive === undefined || isActive === question.isActive) &&
                (questionType === undefined || questionType === question.type)
            )
        })

    }, [
        formFilter,
        totalQuestions
    ]);

    return <div className="space-y-1">
        <div className="flex space-x-4 items-end">
            <div className="w-1/2">
                <HRInput
                    prefix={<ICSearch width={18} height={18} />}
                    placeholder="Tìm kiếm"
                    className="w-full"
                    value={title}
                    hasClearIcon={true}
                    onClearValue={onClearValue}
                    onChange={onChangeTitle}
                />
            </div>
            <div className="w-1/4">
                <HRSelect
                    label="Trạng thái câu hỏi"
                    className="w-full"
                    hasSearch={false}
                    options={STATE_QUESTIONS}
                    value={formFilter.isActive}
                    placeholder="Lựa chọn"
                    onChange={onChangeForm.bind(this, "isActive")}
                />
            </div>
            <div className="w-1/4">
                <HRSelect
                    label="Loại câu hỏi"
                    className="w-full"
                    hasSearch={false}
                    placeholder="Lựa chọn"
                    options={questionFilter}
                    value={formFilter.questionType}
                    onChange={onChangeForm.bind(this, "questionType")}
                    hasFilterSort={false}
                />
            </div>
        </div>
        {
            !existFilterResult && <div className="text-red-500 text-sm">Không có kết quả nào phù hợp</div>
        }
    </div>
}
