import React from "react";
import { HRButton } from "../../../components/button";
import { ICCopy, ICReloadV2 } from "../../../icons";
import { ICCreateLink } from '../../../icons/createLink';
import { Switch } from "antd";
import { useCopyToClipboard } from "../../../hooks/useCopyToClipboard";
import { useAssementDetailsData } from ".";
import { useAssessment } from "../create-assessment/hooks/useAssessment";
import { createLink } from "../../../utils";
import { useNotification } from "../../../hooks/useNotification";

export const CreateLinkAssessment: React.FC = () => {
    const { generateURL, saveStatusAssessment } = useAssessment();
    const { assessmentId, assessmentDetails, setAssessmentDetails, myCandidateCreditInfo } = useAssementDetailsData();
    const [active, setActive] = React.useState(false);
    const { copy } = useCopyToClipboard();
    const { showSuccess } = useNotification();

    React.useEffect(() => {
        setActive(assessmentDetails?.activeUrl || false);
    }, [assessmentDetails]);

    const handleCreateLink = React.useCallback(() => {
        if (assessmentDetails?.id) {
            generateURL(assessmentDetails?.id, setAssessmentDetails);
        }
    }, [assessmentDetails, generateURL, setAssessmentDetails]);

    const handleCopy = React.useCallback(() => {
        if (assessmentDetails?.url) {
            const baseUrl = createLink(assessmentDetails.url);
            copy(baseUrl);
            showSuccess('Sao chép thành công!');
        }
    }, [copy, assessmentDetails]);

    const handleActiveChanged = React.useCallback(() => {
        setActive(prev => !prev);
        saveStatusAssessment(assessmentId, {activeUrl: !active}, setAssessmentDetails);
        if (!assessmentDetails?.url && !active) {
            handleCreateLink();
        }
    }, [assessmentId, saveStatusAssessment, active, handleCreateLink, assessmentDetails, setAssessmentDetails]);
   
    return <div className="grid grid-cols-1 gap-[32px]">
        <div>
            <div className="grid grid-cols-1 gap-[16px]">
                <div className="grid grid-cols-[56px_1fr_50px] gap-[16px] items-center">
                    <ICCreateLink />
                    <span className="flex flex-col gap-[4px]">
                        <span className="text-high-em text-title-bold">Tạo liên kết</span>
                        <span className="text-med-em text-body">
                            Liên kết bài kiểm tra này sẽ được công khai với mọi người
                        </span>
                    </span>
                    <Switch checked={active} disabled={myCandidateCreditInfo?.remainingCandidateCredit === 0} style={{
                        width: '36px',
                    }} onChange={handleActiveChanged} />
                </div>
                {/* {active && <div className="grid grid-cols-1">
                    <HRInput 
                        value={assessmentDetails?.url}
                        disabled={true}
                        style={{
                            color: '#3D6AC4',  
                        }} />
                </div>} */}
            </div>
        </div>
        {active && <div className="grid grid-cols-[auto_auto] gap-[16px] items-center justify-end">
            <HRButton 
                onClick={handleCreateLink}
                btnType="sub"
                btnSize="sm"
            >
                <div className="text-[14px] text-[#46485C] font-bold flex items-center space-x-2 justify-center">
                    <ICReloadV2 />
                    <span>Tạo mới</span>
                </div>
            </HRButton>
            <HRButton
                btnSize="sm"
                btnType="info"
                onClick={handleCopy}
            >
                <div className="flex space-x-2 items-center justify-center">
                    <ICCopy fill="white" width={18} height={18} />
                    <span>Sao chép</span>
                </div>
            </HRButton>
        </div>}
    </div>
}
