import React from "react";
import { HRButton } from "../../../components/button";
import { TSpeakingQuestion } from "../../create_test/tab/question/type";
import { UploadAPI } from "../../../api/upload";
import { Iframe } from "../../../components/iframe";
import { useNotification } from "../../../hooks/useNotification";
import { HRAudioRecorder } from "../../../components/AudioRecorder";

type SpeakingQuestionRenderProps = {
  question: TSpeakingQuestion;
  onChangeCorrectAnswer: (answer: undefined | string) => void;
  currentQuestionIndex: number;
  recordUrl: string;
};

export const SpeakingQuestionRenderer: React.FC<
  SpeakingQuestionRenderProps
> = ({ question, onChangeCorrectAnswer, currentQuestionIndex, recordUrl }) => {
  const { showSuccess, showError } = useNotification();
  const [recordData, setRecordData] = React.useState<File | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isDisableUpload, setIsDisableUpload] = React.useState<boolean>(true);
  const onFinishRecord = (file: File) => {
    setRecordData(file);

    setIsDisableUpload(false);
  };

  const onUploadRecord = async () => {
    // Can not upload again if file aldready uploaded
    if (recordData) {
      setIsLoading(true);
      try {
        const data = await UploadAPI(recordData);
        onChangeCorrectAnswer(data.data.fileUrl);
        setRecordData(data.data.fileUrl);
        showSuccess("Thành công", "Upload file ghi âm thành công");
      } catch (error) {
        showError("Lỗi", "Upload file ghi âm thất bại");
      }
      finally {
        setIsLoading(false);
        setIsDisableUpload(true);
      }

    }
  };

  return (
    <div className="flex flex-col py-[32px] px-[48px] rounded-[12px] bg-white shadow-e-03 box-border overflow-hidden gap-[56px]">
      <div className="w-full">
        <div className="pl-[24px] flex flex-col gap-[24px] border-l-[3px] border-[#C6BBFF]">
          <div className="text-standard-medium text-primary-bold">
            CÂU {currentQuestionIndex}
          </div>
          <Iframe content={(question?.content as string) || ""} />
          <HRAudioRecorder
            onFinishRecord={onFinishRecord}
            recordFileUrl={question.recordFileUrl ?? recordUrl}
          />
          <HRButton
            disabled={isDisableUpload}
            loading={isLoading}
            onClick={onUploadRecord}
          >
            Tải lên file ghi âm
          </HRButton>
        </div>
      </div>
    </div>
  );
};
