import React from "react";
import { Form, Switch } from "antd";
import { HRInput } from "../../../../../components/form/input";
import { HRSelect } from "../../../../../components/form/select";
import { HRTextArea } from "../../../../../components/form/textarea";
import { HRForm } from "../../../../../components/form/form";
import { useAssementData } from "../../hooks/useCreateAssessment";
import { firstValueCommonData } from "../../../../../redux/selectors/commonData";
import { useTranslation } from "react-i18next";

export const GeneralStep: React.FC = () => {
    const {
        generalForm,
        setAssessment
    } = useAssementData();
    const { i18n } = useTranslation();
    const languageDefault = firstValueCommonData('language', i18n.language) || '';
    const onValuesChangeForm = React.useCallback((_: any, allValues: any) => {
        setAssessment((prev) => ({
            ...prev,
            ...allValues,
            language: allValues.language || '',
        }));
    }, [setAssessment]);

    React.useEffect(() => {
        generalForm.setFieldsValue({
            language: languageDefault,
        });
    }, [languageDefault, generalForm]);

    const childForm = (
        <div className="grid gap-[20px] grid-cols-[1fr]">
            <Form.Item
                name="title"
                rules={[
                    {
                        required: true,
                        message: "Bạn chưa điền tên bài đánh giá",
                    },
                ]}
            >
                <HRInput label="Tên bài đánh giá" isRequired={true} maxLength={255} />
            </Form.Item>
            <div className="grid grid-cols-[1fr_1fr] gap-[32px]">
                <div className="grid grid-rows-[auto_1fr] gap-[21px]">
                    <Form.Item
                        name="role"
                        rules={[
                            {
                                required: true,
                                message: "Bạn chưa lựa chọn chức vụ",
                            },
                        ]}
                    >
                        <HRSelect
                            label="Chức vụ"
                            name="position"
                            placeholder="Lựa chọn"
                            isRequired={true}
                        />
                    </Form.Item>
                </div>

                <div className="grid grid-cols-[1fr] gap-[24px]">
                    <Form.Item name="language"
                        rules={[
                            {
                                required: true,
                                message: "Bạn chưa lựa chọn ngôn ngữ",
                            },
                        ]}
                    >
                        <HRSelect
                            label="Ngôn ngữ"
                            name="language"
                            placeholder="Lựa chọn"
                            isRequired={true}
                        />
                    </Form.Item>
                </div>
            </div>
            <div className="grid grid-cols-1 gap-[32px]">
                <Form.Item name="introduce">
                    <HRTextArea
                        label="Giới thiệu"
                        maxLength={3000}
                        style={{
                            height: "105px",
                        }}
                    />
                </Form.Item>
            </div>
            <div className="grid grid-cols-2 gap-[32px]">
                <Form.Item name="note">
                    <HRTextArea
                        label="Note"
                        maxLength={3000}
                        style={{
                            height: "105px",
                        }}
                    />
                </Form.Item>
                <div>
                    <div className="space-x-1 text-body-medium text-med-em">
                        Cấu hình
                    </div>
                    <div className="flex gap-[8px] items-center">
                        <Form.Item
                            name="forceTurnOnCamera"
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>
                        <span className="text-body text-high-em">
                            Yêu cầu bật camera
                        </span>
                    </div>
                </div>

            </div>
        </div>
    );

    return (
        <div className="bg-white p-[32px] rounded[8px]">
            <div className="mb-[24px] flex flex-col gap-[8px]">
                <div className="text-heading-6-bold text-high-em">Thông tin cơ bản</div>
                <div className="text-med-em text-body">Hãy điền các thông tin bắt buộc dưới đây để bắt đầu tạo bài đánh giá mới</div>
            </div>
            <HRForm
                form={generalForm}
                name="createAssessment"
                onValuesChange={onValuesChangeForm}
                childNode={childForm}
            />
        </div>
    );
};
