import { ApiInstance } from "."

export const getListPosition = () => {
    return ApiInstance.getInstance().get("/api/position", {
        params: {
            page: 1,
            limit: 1000,
        }
    })
}

export const getListNationality = () => {
    return ApiInstance.getInstance().get("/api/nationality", {
        params: {
            page: 1,
            limit: 1000,
        }
    })
}