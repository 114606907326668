import ReactDOM from 'react-dom/client';
import 'antd/dist/antd.min.css';

import './tailwind.css';
import './variables.css';
import './index.css';
import "./fonts.css";

import './assets/fonts/fonts.d.ts';
import GlobalFont from './styles/globalFont';

import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { GeneralPage } from './pages/general';
import { Unauthorized } from './pages/unauthorized';
import { HomePage } from './pages/home';
import { LibraryPage } from './pages/library';
import { Provider } from 'react-redux';
import store from "./redux/store";
import { MyAccountPage } from './pages/account';
import { CompanyPage } from './pages/company';
import { MyDevicePage } from './pages/my_device';
import { LoginPage } from './pages/login';
import { RegisterPage } from './pages/register';
import { ForgotPasswordPage } from './pages/forgot';
import { NotFoundPage } from './pages/404';
import { Loading } from './components/loading';
import { MyTestPage } from './pages/my_test';
import { MyAssessmentPage } from './pages/my_assessment';
import { CreateTestPage } from './pages/create_test';
import { CreateAssessmentPage } from './pages/my_assessment/create-assessment';

//Import i18n.ts
import "./i18n";
import { AssessmentDetailsPage } from './pages/my_assessment/details';
import { CandidatePage } from './pages/candidate';
import { TestingPage } from './pages/testing';
import { ReceivingLinkPage } from './pages/testing/receiving-link';
import { PreviewTestingPage } from './pages/preview-testing';
import { ApproveRejectTestingPage } from './pages/approve-reject-testing';
import { CandidateDetailsPage } from './pages/candidate_details';
import { PaymentPage } from './pages/payment';
import { PaymentStatusPage } from './pages/payment_status';
import { CandidateCreditPage } from './pages/candidate_credit';
import { ApplyResumePage } from './pages/apply-resume';
import { PrivacyPolicyPage } from './pages/privacy-policy';
import { PreviewTestingAssessmentPage } from './pages/preview-testing-assessment';
import { MyCandidatePage } from './pages/my_candidate';
import { RecordScreenProvider } from './hooks/useScreenRecord';
import { detectMobileDevice } from './utils';
import { NotSupportedPage } from './pages/not-supported';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const router = createBrowserRouter([
  {
    path: "/",
    element: <GeneralPage page={<HomePage />} isPrivate />,
  },
  {
    path: "/profile",
    element: <GeneralPage page={<HomePage />} isPrivate />
  },
  {
    path: "/library",
    element: <GeneralPage page={<LibraryPage />} isPrivate />
  },
  {
    path: "/account",
    element: <GeneralPage page={<MyAccountPage />} isPrivate />
  },
  {
    path: "/company",
    element: <GeneralPage page={<CompanyPage />} isPrivate />
  },
  {
    path: "/devices",
    element: <GeneralPage page={<MyDevicePage />} isPrivate />
  },
  {
    path: "/my-test",
    element: <GeneralPage page={<MyTestPage />} isPrivate />
  },
  {
    path: "/my-candidate",
    element: <GeneralPage page={<MyCandidatePage />} isPrivate />
  },
  {
    path: "/my-assessment",
    element: <GeneralPage page={<MyAssessmentPage />} isPrivate />
  },
  {
    path: "/create-assessment",
    element: <GeneralPage page={<CreateAssessmentPage />} isPrivate />
  },
  {
    path: "/edit-assessment/:id",
    element: <GeneralPage page={<CreateAssessmentPage mode="edit" />} isPrivate />
  },
  {
    path: "/assessment/:id/details",
    element: <GeneralPage page={<AssessmentDetailsPage />} isPrivate />
  },
  {
    path: "/assessment/:assessmentId/test/:id/details",
    element: <GeneralPage page={<CandidateDetailsPage mode="assessment" />} isPrivate />
  },
  {
    path: "/candidate/:id/details",
    element: <GeneralPage page={<CandidateDetailsPage mode="candidate" />} isPrivate />
  },
  {
    path: "/create-test",
    element: <GeneralPage page={<CreateTestPage />} isPrivate />
  },
  {
    path: "/edit-test/:id",
    element: <GeneralPage page={<CreateTestPage mode="edit" />} isPrivate />
  },
  {
    path: "/login",
    element: <Unauthorized page={<LoginPage />} />
  },
  {
    path: "/register",
    element: <Unauthorized page={<RegisterPage />} />
  },
  {
    path: "/forgot-password",
    element: <Unauthorized page={<ForgotPasswordPage />} />
  },
  {
    path: "/apply-resume/:id",
    element: <CandidatePage page={<ApplyResumePage />} />
  },
  {
    path: "/testing/:url",
    element: <CandidatePage page={<TestingPage />} />
  },
  {
    path: "/testing/:id/preview",
    element: <CandidatePage page={<PreviewTestingPage />} />
  },
  {
    path: "/testing/:id/checking",
    element: <CandidatePage page={<PreviewTestingAssessmentPage />} />
  },
  {
    path: "/testing/validation",
    element: <CandidatePage page={<ApproveRejectTestingPage />} />
  },
  {
    path: "/testing/:assessmentUrl/invitation",
    element: <CandidatePage page={<ReceivingLinkPage />} />
  },
  {
    path: "/payment/:id",
    element: <GeneralPage page={<PaymentPage />} isPrivate />
  },
  {
    path: "/payment/:orderId/:status",
    element: <GeneralPage page={<PaymentStatusPage />} isPrivate />
  },
  {
    path: "/candidate-credit/payment",
    element: <GeneralPage page={<CandidateCreditPage />} isPrivate />
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicyPage />
  },
  {
    path: "/not-supported",
    element: <NotSupportedPage />
  },
  {
    path: "*",
    element: <NotFoundPage />
  }
]);

detectMobileDevice();
root.render(
  <Provider store={store}>
    <GlobalFont />
    <div className="App">
      <RecordScreenProvider>
        <RouterProvider router={router} />
      </RecordScreenProvider>
      <Loading />
    </div>
  </Provider>
)
