import React from "react"
import { HRButton } from "../../../../components/button"
import { QuestionType, TQuestion, TSkillArea } from "./type"
import { useCreateTestPageContext } from "../.."
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { QuestionRow } from "./row-question";
import { reorder } from "../../../../utils"
import { Modal } from "antd"

const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    width: '100%'
});
const getMoveItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    userSelect: "none",
    background: isDragging ? "#F1E8FD" : "white",
    color: isDragging ? "#46485C" : "inherit",
    borderRadius: isDragging ? "8px" : "inherit",
    border: isDragging ? "1px solid #7357FF" : "inherit",
    boxShadow: isDragging ? "0px 4px 16px rgba(79, 117, 140, 0.24)" : "inherit",
    ...draggableStyle
});

type GroupQuestionProps = {
    data: TSkillArea,
    // hasShowDeleteAction?: boolean,
    questionType?: QuestionType,
    onChange: (data: TSkillArea) => void,
    index: number,
    setShowDeleteModal: (value: boolean) => void,
    visibleQuestions: boolean[],
    onMove: (indexQuestion: number) => void
    activeWeight?: boolean
}
export const GroupQuestion: React.FC<GroupQuestionProps> = ({
    data,
    // hasShowDeleteAction = false,
    onChange,
    questionType,
    index,
    setShowDeleteModal,
    visibleQuestions,
    activeWeight,
    onMove
}) => {
    const {
        id,
        setShowAddQuestion,
        testDetails,
        pageIsDirty,
        setSkillAreaTypeSelected,
    } = useCreateTestPageContext();
    const onAddQuestion = React.useCallback(() => {
        setShowAddQuestion(true, index);
    }, [setShowAddQuestion, index]);

    const onUpdateQuestion = React.useCallback((questionIndex: number) => {
        setSkillAreaTypeSelected(data.type);
        setShowAddQuestion(true, index, questionIndex);
    }, [setShowAddQuestion, index, setSkillAreaTypeSelected]);
    const [showConfirm, setShowConfirm] = React.useState(false);

    const goPreviewTest = React.useCallback(() => {
        setShowConfirm(false);
        if (questionType === 'sample') {
            window.open(`/testing/${id}/preview`, '_blank');
        } else {
            window.open(`/testing/${id}/preview?index=${index}`, '_blank');
        }
    }, [id, index, questionType]);

    const onPreviewTesting = React.useCallback(() => {
        if (!pageIsDirty) {
            goPreviewTest();
        } else {
            setShowConfirm(true);
        }
    }, [pageIsDirty, goPreviewTest]);

    const onChangeQuestion = React.useCallback((index: number, question: TQuestion) => {
        const questions = data?.questions?.map((q, i) => {
            if (i === index) {
                q = question;
            }
            return { ...q };
        });
        onChange({
            ...data,
            questions
        });
    }, [data, onChange]);

    const onDeleteQuestion = React.useCallback((index: number) => {
        const questions = data.questions.filter((_, i) => i !== index);
        onChange({
            ...data,
            questions
        })
    }, [data, onChange]);

    // Drop && Drag
    const onDragEnd = (result: any) => {
        const { source, destination } = result;
        if (!result.destination) {
            return;
        }
        data.questions = reorder(
            data.questions,
            source.index,
            destination.index
        );
        onChange({
            ...data,
            questions: data.questions
        });
    }

    const onCollapsed = React.useCallback((index: number, question: TQuestion) => {
        const questions = data.questions.map((q, i) => {
            if (i === index) {
                q = question;
            } else {
                q.isExpand = false;
            }
            return { ...q };
        });
        onChange({
            ...data,
            questions
        });
    }, [data, onChange]);

    const activePreviewQuestion = React.useMemo(() => {
        return (testDetails?.skillSections?.[index > 0 ? index - 1 : index]?.questions?.length || 0) > 0;
    }, [testDetails, index]);

    return <div className="w-full">
        <div className="bg-white rounded-[8px] mb-4 box-border overflow-hidden">
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                            {data.questions.map((item, index) => (
                                <Draggable
                                    key={item.id || item.uuid}
                                    draggableId={item.id || item.uuid || index.toString()}
                                    index={index}
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            className={visibleQuestions[index] ? '' : 'hidden'}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getMoveItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                        >
                                            <QuestionRow
                                                question={item}
                                                index={index}
                                                key={item.id}
                                                isFirst={index === 0}
                                                isLast={index === data.questions.length - 1}
                                                hasActiveWeight={activeWeight}
                                                onChange={onChangeQuestion}
                                                onDelete={onDeleteQuestion}
                                                onUpdate={onUpdateQuestion}
                                                onCollapsed={onCollapsed}
                                                onMove={onMove.bind(this, index)}
                                            />
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
        <Modal
            open={showConfirm}
            centered={true}
            closable={false}
            title={
                <div className="font-bold text-[18px] leading-[22px]">Xem trước bài kiểm tra</div>
            }
            footer={
                <div className="flex justify-end space-x-6">
                    <span>
                        <HRButton
                            btnSize="sm"
                            btnType="outline"
                            onClick={goPreviewTest}>
                            Vẫn tiếp tục Xem trước
                        </HRButton>
                    </span>
                    <span>
                        <HRButton
                            btnSize="sm"
                            btnType="primary"
                            onClick={() => setShowConfirm(false)}>
                            Đã hiểu
                        </HRButton>
                    </span>
                </div>
            }
        >
            <p className="font-medium text-[16px] leading-[24px]">Bạn chưa lưu các thay đổi. Nếu bạn vẫn tiếp tục thì sẽ không thể xem các phần vừa được thay đổi đó.</p>
        </Modal>
    </div>
}
