import { Button, Drawer, InputNumber, Slider, Tooltip } from "antd"
import { HRButton, IconButton } from "../../../components/button"
import { Iframe } from "../../../components/iframe"
import { TGroupQuestionTesting, TSectionTesting, TTestingCandidate } from "../../testing/type"
import { ICClose, ICInfoV2 } from "../../../icons"
import React from "react"
import { HRTextArea } from "../../../components/form/textarea"
import { useDispatch } from "react-redux"
import { setLoading } from "../../../redux/slices/appInfo"
import { evaluateEssayQuestion } from "../../../api/test-candidate"
import { useNotification } from "../../../hooks/useNotification"

type DetailGroupQuestionProps = {
    candidateId: string;
    section: TSectionTesting;
    testCandidate: TTestingCandidate;
    question: TGroupQuestionTesting;
    setQuestions: React.Dispatch<React.SetStateAction<TTestingCandidate[]>>;
}
export const DetailGroupQuestion: React.FC<DetailGroupQuestionProps> = ({ question, setQuestions, testCandidate, section, candidateId }) => {
    const [isMarkScoreDialogOpen, setIsMarkScoreDialogOpen] = React.useState(false);
    const onUpdateScore = (score: number, comment?: string) => {
        setQuestions(prev => {
            return prev.map((candidate) => {
                if (candidate._id !== testCandidate._id) return candidate;
                return {
                    ...candidate,
                    sections: candidate.sections.map((s) => {
                        if (s._id !== section._id) return s;
                        return {
                            ...s,
                            questions: s.questions.map((q) => {
                                if (q._id !== question._id) return q;
                                return {
                                    ...q,
                                    score,
                                    comment,
                                    isAutoEvaluate: false
                                }
                            })
                        }
                    })
                }
            });
        })
    }
    return <>
        <div className="flex justify-between px-2">
            <div className="text-left">
                <span className="text-body-medium">
                    Phần trả lời
                </span>
                <Iframe content={question.content} />
                {
                    question.questions.map((q, index) => {
                        return <div key={index} className="mb-3">
                            <div className="font-semibold">{index + 1}. {q.content}</div>
                            <div className="text-gray">Answer: {q.selectedAnswer}</div>
                        </div>
                    })
                }
            </div>
            <div className="flex w-[100px] flex-col space-y-2">
                <span className="text-body-medium">
                    Điểm số
                </span>
                <div className="font-semibold flex justify-center items-center space-x-1">
                    <span
                        className={question.isAutoEvaluate ? "text-[#f48d04]" : "text-primary"}
                    >
                        {question.score ?? 0}
                    </span>
                    {question.isAutoEvaluate && <Tooltip title="AI tự đánh giá">
                        <div>
                            <ICInfoV2 />
                        </div>
                    </Tooltip>}
                </div>
                <Button onClick={() => setIsMarkScoreDialogOpen(true)}>
                    <span className="text-xs font-semibold">Chấm điểm</span>
                </Button>
            </div>
        </div>
        {
            isMarkScoreDialogOpen && <MarkScoreDialog
                testCandidateId={candidateId}
                question={question}
                isOpen={isMarkScoreDialogOpen}
                onClose={() => setIsMarkScoreDialogOpen(false)}
                onUpdateScore={onUpdateScore}
            />
        }
    </>
}

type MarkScoreDialogProps = {
    testCandidateId: string
    question: TGroupQuestionTesting
    isOpen: boolean
    onClose: () => void
    onUpdateScore: (score: number, comment?: string) => void
}
const MarkScoreDialog: React.FC<MarkScoreDialogProps> = ({
    testCandidateId,
    question,
    isOpen,
    onUpdateScore,
    onClose
}) => {
    const { showSuccess, showError } = useNotification();
    const dispatch = useDispatch();
    const [score, setScore] = React.useState<number | null>(question.score || null);
    const [comment, setComment] = React.useState<string>(question.comment || '');
    const onSubmit = async () => {
        try {
            dispatch(setLoading(true));
            await evaluateEssayQuestion({
                questionId: question._id,
                comment,
                score: score || 0,
                testCandidateId
            });
            showSuccess('Thành công');
            onUpdateScore(score || 0, comment);
            onClose();
        } catch (error) {
            showError('Error', 'Có lỗi xảy ra');
        } finally {
            dispatch(setLoading(false));
        }
    }
    return <Drawer
        title={
            <div className="text-title-medium text-primary-bold">
                Chấm điểm
            </div>
        }
        width={768}
        open={isOpen}
        onClose={onClose}
        closable={false}
        bodyStyle={{ padding: '0 24px 24px 24px', gap: '24px' }}
        headerStyle={{ border: '0', padding: '24px' }}
        extra={
            <div>
                <IconButton btnType="sub" btnSize="sm"
                    onClick={onClose}
                    style={{
                        width: 44,
                        height: 44,
                        borderRadius: '144px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        boxShadow: '0px 1px 2px -1px rgba(17, 12, 34, 0.08)',
                        boxSizing: 'border-box',
                        overflow: 'hidden',
                        border: '1px solid #ECECED'
                    }}
                    icon={<div><ICClose fill="#4F4B5C" width={24} height={24} /></div>}
                />
            </div>
        }
        footer={
            <div className="flex flex-col space-y-4">
                <span className="text-sm font-semibold">
                    Chấm điểm
                </span>
                <div className="flex justify-between items-center w-full gap-[24px]">
                    <div className="flex flex-col gap-[8px] w-1/2">
                        <div className="text-sub-medium text-high-em">Điểm</div>
                        <div className="flex gap-[5px]">
                            <Slider min={0} max={100} step={1}
                                value={typeof score === 'number' ? score : 0}
                                style={{
                                    width: "100%",
                                }}
                                onChange={val => setScore(val)} />
                            <InputNumber
                                max={100}
                                step={1}
                                value={score === null ? undefined : score}
                                onChange={val => {
                                    setScore(val);
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col w-1/2">
                        <HRTextArea value={comment}
                            parentClassName="w-full"
                            onChange={(e) => setComment(e.target.value)}
                            placeholder="Nhập nhận xét của bạn" />
                    </div>
                </div>
                <div className="flex justify-center">
                    <Button
                        disabled={score === null}
                        onClick={onSubmit}
                    >
                        <span className="text-xs font-semibold">Xác nhận</span>
                    </Button>
                </div>
            </div>
        }
    >
        <div className="flex flex-col gap-[24px]">
            <div className="text-left">
                <span className="text-body-medium">
                    Nội dung
                </span>
                <Iframe content={question.content} />
                {
                    question.questions.map((q, index) => {
                        return <div key={index} className="mb-3">
                            <div className="font-semibold text-sm">{index + 1}. {q.content}</div>
                            <div className="text-gray text-sm">Answer: {q.selectedAnswer}</div>
                        </div>
                    })
                }
            </div>
        </div>
    </Drawer>
}