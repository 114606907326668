import React from "react";
import { useCreateTestPageContext } from "../.."
import { HRButton, IconButton } from "../../../../components/button"
import { ICAdd, ICClose, ICLock, ICLocked, ICTick, ICWarning } from "../../../../icons"
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./editor.style.css";
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { answerToolbar, isAnswerError, isEmptyHTML, toolbar } from "./editor.config";
import { createDefaultQuestion, EQuestionType, ESkillAreaType, generateOption, TChoiceQuestion, TQuestion } from "./type";
import { Checkbox, Modal, Radio } from "antd";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import classNames from 'classnames'
import { getInnerText, isDuplicateAnswer } from "./helper";
import styled from 'styled-components';
import { MAX_QUESTIONS_IN_GROUP } from "./contants";
import _ from "lodash";
import { useNotification } from "../../../../hooks/useNotification";
import { HRSelect } from "../../../../components/form/select";
import { TScoreLevels } from "../../../library/models";
import { isSuponsor } from "../../../../redux/selectors/commonData";

const WrapperEditor = styled.div`
    &.read-only-mode {
        background: transparent;
        border-radius: 8px;
        padding: 16px;
        border: 1px solid #ECECED;
        .ant-radio-wrapper {
            margin: 0 !important;
        }
        .anwser-questions {
            background: transparent;
            display: grid;
            .public-DraftStyleDefault-block {
                padding: 0 !important;
            }
        }
    }
    &.read-only-mode.has-value {
        border: 1px solid #7357FF;
    }
`;

// For single choice and multiple choice only
type QuestionEditorProps = {
    selectedQuestion: TChoiceQuestion | undefined;
    questionType: EQuestionType.SINGLE_CHOICE | EQuestionType.MULTIPLE_CHOICE;
    onCancel: () => void
}
export const QuestionEditor: React.FC<QuestionEditorProps> = ({ selectedQuestion, questionType, onCancel }) => {
    const [question, setQuestion] = React.useState<TChoiceQuestion>(() => selectedQuestion ?? createDefaultQuestion(questionType) as TChoiceQuestion)
    const {
        form,
        selectedSection,
        setShowAddQuestion,
        onCreatedQuestion,
        skillAreaTypeSelected,
        scoreLevelsQuestion,
    } = useCreateTestPageContext();
    const [needCheck, setNeedCheck] = React.useState<boolean>(false);
    const {
        showSuccess
    } = useNotification();

    const activeAtTeam = isSuponsor() && skillAreaTypeSelected === ESkillAreaType.ATTEAM;

    const [editorState, setEditorState] = React.useState<EditorState>(
        EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(question.content).contentBlocks))
    )
    React.useEffect(() => {
        const q = selectedQuestion ?? createDefaultQuestion(questionType) as TChoiceQuestion
        setQuestion(q)
        setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(q.content).contentBlocks)))
    }, [selectedSection, questionType, selectedQuestion]);

    const inValidScoreCombination = React.useMemo(() => {
        if (activeAtTeam) {
            if (question.options.length !== Object.values(question.scoreCombination).length) {
                return true;
            }
            return Object.values(question.scoreCombination).some((item) => item === undefined || item === null);
        }
        return false;
    }, [activeAtTeam, question.options.length, question.scoreCombination]);

    const invalidMaxLengthEditorContent = React.useMemo(() => {
        const text = editorState.getCurrentContent().getPlainText()
        const lines = text.split(/\n/)
        return text.length > 5000 || lines.length > 50
    }, [editorState]);

    const isDirty = React.useMemo(() => {
        if (!selectedQuestion) return getInnerText(question.content).replace(/\n/g, '') !== ""
            || question.options.some(o => getInnerText(o.content).replace(/\n/g, '') !== "")
            || (question.type === EQuestionType.SINGLE_CHOICE && question.correctAnswer !== 0)
            || (question.type === EQuestionType.MULTIPLE_CHOICE && !_.isEqual(question.correctAnswer, [0]))
            || !_.isEqual(question.positionLockedOptions, [])
            || inValidScoreCombination;
        return question.content !== selectedQuestion?.content
            || question.options.some((o, i) => o !== selectedQuestion?.options[i])
            || question.correctAnswer !== selectedQuestion?.correctAnswer
            || question.positionLockedOptions !== selectedQuestion?.positionLockedOptions
    }, [question, selectedQuestion, inValidScoreCombination]);

    const onEditorStateChange = (currentValueEditorState: EditorState) => {
        const text = currentValueEditorState.getCurrentContent().getPlainText();
        const oldContent = editorState.getCurrentContent().getPlainText();
        // const lines = text.split(/\n/)
        if (text.length > 5000) {
            const oldState = ContentState.createFromBlockArray(htmlToDraft(oldContent).contentBlocks);
            setEditorState(EditorState.moveFocusToEnd(EditorState.push(editorState, oldState, 'insert-characters')));
        } else {
            setEditorState(currentValueEditorState);
            setQuestion({
                ...question,
                content: draftToHtml(convertToRaw(currentValueEditorState.getCurrentContent()))
            })
        }
        // else if (lines.length > 50) {
        //     const undo = EditorState.undo(editorState)
        //     setEditorState(undo)
        // }
    }

    const isQuestionError = React.useMemo(() => {
        return needCheck && (isEmptyHTML(question.content) || invalidMaxLengthEditorContent)
    }, [needCheck, question.content, invalidMaxLengthEditorContent]);

    const validationValues = (): boolean => {
        setNeedCheck(true);
        return isEmptyHTML(question.content)
            || question.options.some(o => isEmptyHTML(o.content))
            || isDuplicateAnswer(question.options)
            || (!activeAtTeam && isAnswerError(question))
            || inValidScoreCombination
            || invalidMaxLengthEditorContent;
    };

    const onMixAnswer = (question: TChoiceQuestion) => {
        const newQuestion = { ...question };
        if (!newQuestion.isMixed) {
            newQuestion.positionLockedOptions = [];
        }
        return newQuestion;
    }

    const onSave = () => {
        if (!validationValues()) {
            onCreatedQuestion(onMixAnswer(question));
            setShowAddQuestion(false)
        }
    }

    const onSaveAndAddAnother = () => {
        if (!validationValues()) {
            onCreatedQuestion(onMixAnswer(question));
            showSuccess("Thành công", "Thêm mới câu hỏi thành công")
            setNeedCheck(false);
            const defaultQuestion = createDefaultQuestion(questionType) as TChoiceQuestion
            setQuestion(defaultQuestion)
            setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(defaultQuestion.content).contentBlocks)))
        }
    }
    const [showCancelModal, setShowCancelModal] = React.useState<boolean>(false)
    const onCancelAction = React.useCallback(() => {
        if (isDirty) {
            setShowCancelModal(true)
        } else {
            onCancel()
        }
    }, [isDirty, setShowCancelModal, onCancel]);

    const inactiveAddQuestion = () => {
        if (!selectedSection) {
            return false;
        }
        return selectedSection?.questions.length >= MAX_QUESTIONS_IN_GROUP;
    };

    const title = React.useMemo(() => {
        return editorState.getCurrentContent().getPlainText();
    }, [editorState]);

    return <div className="pb-16 space-y-8 text-left container mx-auto">
        <div className="flex items-center justify-between">
            <div>
                <div className="flex space-x-2 items-center font-bold text-[24px]" >
                    <IconButton btnType="sub" btnSize="sm" icon={<ICClose width={18} height={18} />} onClick={onCancelAction} />
                    <span className="max-w-[500px] line-clamp-1">{title || 'Câu hỏi mới'}</span>
                </div>
                <span className="text-xs font-medium text-n-600 ml-12">
                    {form.getFieldValue("title")} / {selectedSection?.name ?? ""}
                </span>
            </div>
            <div className="flex space-x-4">
                <span>
                    <HRButton
                        btnType="sub"
                        btnSize="md"
                        onClick={onCancelAction}
                    >
                        <div className="flex justify-center">
                            Hủy bỏ
                        </div>
                    </HRButton>
                </span>
                {
                    !selectedQuestion && <span><HRButton
                        onClick={onSaveAndAddAnother}
                        btnSize="md"
                        btnType="sub"
                        disabled={inactiveAddQuestion()}
                    >
                        <div className="flex justify-center">
                            Lưu và tạo cái mới
                        </div>
                    </HRButton></span>
                }
                <span>
                    <HRButton
                        btnSize="md"
                        onClick={onSave}
                        className="w-[107px] h-[44px]"
                        disabled={inactiveAddQuestion() && !selectedQuestion}
                    >
                        <div className="flex space-x-2 justify-center">
                            <ICTick />
                            <span>{selectedQuestion ? "Cập nhật" : "Lưu"}</span>
                        </div>
                    </HRButton>
                </span>
            </div>
        </div>
        <div className="mt-8 p-8 bg-white rounded-[10px] grid grid-cols-2 gap-[38px]">
            <div className=" grid grid-cols-1 grid-rows-[auto_1fr] justify-start items-start gap-[16px]">
                <div className="text-primary-bold text-standard-medium">Phần câu hỏi</div>
                <div className="space-y-3"
                    onContextMenu={(e) => {
                        // @ts-ignore
                        document.querySelector(".questions-editor")?.focus?.()
                    }}
                >
                    <Editor
                        stripPastedStyles={true}
                        toolbar={toolbar}
                        editorState={editorState}
                        toolbarClassName={classNames(isQuestionError ? "error" : "", "editor-toolbar")}
                        wrapperClassName={classNames(isQuestionError ? "error" : "", "questions-wrapper", "editor-wrapper")}
                        editorClassName={classNames(isQuestionError ? "error" : "", "questions-editor", "editor-editor")}
                        onEditorStateChange={onEditorStateChange}
                        placeholder="Nhập câu hỏi..."
                    />
                    {
                        invalidMaxLengthEditorContent && <div className="flex items-center space-x-2">
                            <ICWarning fill="#F55858" width={18} height={18} />
                            <span className="text-body italic text-error-500">Bạn đã nhập quá số lượng ký tự cho phép!</span>
                        </div>
                    }
                    {
                        isQuestionError && !invalidMaxLengthEditorContent && <div className="flex items-center space-x-2">
                            <ICWarning fill="#F55858" width={18} height={18} />
                            <span className="text-body italic text-error-500">Xin hãy nhập câu hỏi</span>
                        </div>
                    }
                </div>
            </div>
            <div className="grid grid-cols-1 grid-rows-[auto_1fr] justify-start items-start gap-[32px]">
                <div className="text-primary-bold text-standard-medium">Phần đáp án</div>
                <AnswerWrapper
                    needCheck={needCheck}
                    question={question}
                    onChanges={setQuestion}
                    skillAreaTypeSelected={skillAreaTypeSelected}
                    scoreLevelsQuestion={scoreLevelsQuestion}
                />
            </div>
        </div>
        <Modal
            title="Thông báo"
            open={showCancelModal}
            centered={true}
            onCancel={setShowCancelModal.bind(null, false)}
            footer={
                <div className="grid grid-cols-[auto_auto] justify-end gap-[16px]">
                    <HRButton
                        btnSize="sm"
                        btnType="sub"
                        onClick={() => setShowCancelModal(false)}>
                        Không
                    </HRButton>
                    <HRButton btnSize="sm" onClick={onCancel}>
                        Có
                    </HRButton>
                </div>
            }
        >
            <div className="space-y-2">
                <div>Các thông tin nhập vào sẽ bị mất</div>
                <div>Bạn có chắc chắn muốn hủy bỏ?</div>
            </div>
        </Modal>
    </div>
}
type AnswerWrapperProps = {
    question: TChoiceQuestion,
    onChanges?: (question: TChoiceQuestion) => void,
    viewOnly?: boolean;
    needCheck?: boolean;
    skillAreaTypeSelected?: ESkillAreaType | undefined;
    scoreLevelsQuestion?: TScoreLevels[];
}
// Use for single choice and multiple choice only
export const AnswerWrapper: React.FC<AnswerWrapperProps> = ({
    question,
    onChanges,
    viewOnly = false,
    needCheck = false,
    skillAreaTypeSelected,
    scoreLevelsQuestion,
}) => {
    const activeAtTeam = isSuponsor() && skillAreaTypeSelected === ESkillAreaType.ATTEAM;
    const duplicates = React.useMemo(() => {
        const questionOptionsText = question.options.map(item => getInnerText(item.content))
        return questionOptionsText.map((item, index, arr) => {
            if (!item || item.length === 0) return false;
            return arr.some((item2, index2) => item && item2 && item === item2 && index !== index2)
        })
    }, [question.options]);

    const onChangeAnswer = React.useMemo(() => {
        if (viewOnly) return () => { };
        if (!onChanges) return () => { };
        return onChanges
    }, [onChanges, viewOnly])

    const onChangeAnswerContent = (index: number, value: string) => {
        const newQuestion = { ...question };
        newQuestion.options = newQuestion.options.map((item, i) => {
            if (i === index) return { ...item, content: value };
            return item;
        });
        onChangeAnswer(newQuestion);
    }

    const onChangeCorrectAnswer = (index: number, isChecked?: boolean) => {
        switch (question.type) {
            case EQuestionType.SINGLE_CHOICE:
                const newSingleQuestion = _.cloneDeep(question);
                newSingleQuestion.correctAnswer = index;
                onChangeAnswer(newSingleQuestion);
                break;
            case EQuestionType.ESSAY:
                break;
            case EQuestionType.MULTIPLE_CHOICE:
                const newMultiQuestion = _.cloneDeep(question);
                if (isChecked) {
                    newMultiQuestion.correctAnswer.push(index);
                } else {
                    newMultiQuestion.correctAnswer = newMultiQuestion.correctAnswer.filter(item => item !== index);
                }
                onChangeAnswer(newMultiQuestion);
                break;
        }
    }

    const onMixAnswer = (isMixed: boolean) => {
        const newQuestion = { ...question };
        newQuestion.isMixed = isMixed;
        onChangeAnswer(newQuestion);
    }

    const onAddNewAnswer = () => {
        const newQuestion = { ...question };
        newQuestion.options = [...newQuestion.options, generateOption("")];
        onChangeAnswer(newQuestion);
    }

    const onRemoveAnswer = (index: number) => {
        if (index < 2) return;
        const newQuestion = _.cloneDeep(question);
        let correctAnswer = newQuestion.correctAnswer;
        if (newQuestion.type === EQuestionType.SINGLE_CHOICE) {
            if (newQuestion.correctAnswer === index) {
                correctAnswer = 0;
            }
        } else if (newQuestion.type === EQuestionType.MULTIPLE_CHOICE) {
            let newCorrectAnswer = [] as number[]
            newQuestion.correctAnswer.forEach((item, i) => {
                if (item === index) return;
                if (item > index) {
                    newCorrectAnswer.push(item - 1);
                } else {
                    newCorrectAnswer.push(item);
                }
            });
            correctAnswer = newCorrectAnswer;
        }
        // Important: careful with immutable
        const newOptions = [...newQuestion.options];
        newOptions.splice(index, 1);
        newQuestion.options = newOptions;
        newQuestion.correctAnswer = correctAnswer;

        // set lock answer
        let newPositionLockedOptions = [] as number[]
        newQuestion.positionLockedOptions?.forEach((item, i) => {
            if (item > index) {
                newPositionLockedOptions.push(item - 1);
            } else {
                newPositionLockedOptions.push(item);
            }
        });
        newQuestion.positionLockedOptions = newPositionLockedOptions;

        onChangeAnswer(newQuestion);
    }

    const onLockAnswer = (index: number) => {
        if (question.options.length === (question?.positionLockedOptions?.length ?? 0) + 2 && !question?.positionLockedOptions?.includes(index)) return;
        const questionClone = _.cloneDeep(question);
        const newQuestion = { ...questionClone, positionLockedOptions: questionClone.positionLockedOptions ?? [] };
        if (newQuestion.positionLockedOptions?.includes(index)) {
            newQuestion.positionLockedOptions = newQuestion.positionLockedOptions?.filter(item => item !== index);
            onChangeAnswer(newQuestion);
            return;
        }
        if (newQuestion?.positionLockedOptions?.length === 0) {
            newQuestion.positionLockedOptions = [index];
            onChangeAnswer(newQuestion);
            return;
        }
        newQuestion.positionLockedOptions?.push(index);
        onChangeAnswer(newQuestion);
    };

    const onChangeRateLevel = (index: number, value: number) => {
        const newQuestion = _.cloneDeep(question);
        if (newQuestion.type === EQuestionType.SINGLE_CHOICE) {
            // value = undefined => need to remove object
            if (value === undefined) {
                delete newQuestion.scoreCombination[index];
                onChangeAnswer(newQuestion);
                return;
            }
            newQuestion.scoreCombination[index] = value;
            onChangeAnswer(newQuestion);
        }
        if (newQuestion.type === EQuestionType.MULTIPLE_CHOICE) {
            // TODO
        }
    }

    return <div className={classNames('grid grid-cols-1 gap-[24px]', viewOnly ? 'answer-editor-view-only' : '')}>
        {
            question.options.map((answer, index) => {
                const hasDisableLock = question.options.length === (question?.positionLockedOptions?.length ?? 0) + 2 && !question?.positionLockedOptions?.includes(index);
                return <div className="flex flex-col gap-[12px]" key={index}>
                    <AnswerEditor
                        key={answer.id}
                        index={index} // For what ?
                        answer={answer.content}
                        onChange={onChangeAnswerContent}
                        onChangeCorrectAnswer={onChangeCorrectAnswer}
                        onRemoveAnswer={onRemoveAnswer}
                        onLockAnswer={onLockAnswer}
                        isLocked={question.positionLockedOptions?.includes(index)}
                        isDisabledLock={hasDisableLock}
                        isMixed={question.isMixed}
                        readyLock={question.options.length > 2}
                        viewOnly={viewOnly}
                        isChecked={
                            question.type === EQuestionType.SINGLE_CHOICE ?
                                question.correctAnswer === index :
                                question.type === EQuestionType.MULTIPLE_CHOICE && Array.isArray(question.correctAnswer) ? question.correctAnswer.includes(index) : false
                        }
                        needCheck={needCheck}
                        questionType={question.type}
                        isDuplicate={duplicates[index]}
                        skillAreaTypeSelected={skillAreaTypeSelected}
                        scoreLevelsQuestion={scoreLevelsQuestion}
                        activeAtTeam={activeAtTeam}
                        question={question}
                        onChangeRateLevel={onChangeRateLevel}
                    />
                </div>
            })
        }
        {
            !activeAtTeam && needCheck && isAnswerError(question) && <div className="flex items-center space-x-2" style={{
                marginTop: "24px"
            }}>
                <ICWarning fill="#F55858" width={18} height={18} />
                <span className="text-body italic text-error-500">Xin hãy chọn đáp án đúng</span>
            </div>
        }
        {
            !viewOnly && <div className="flex justify-between">
                <div>
                    <HRButton
                        onClick={onAddNewAnswer}
                        btnType="sub"
                        btnSize="md"
                    >
                        <div className="flex space-x-3 items-center">
                            <ICAdd stroke="#4F4B5C" />
                            <span className="text-high-em text-body-bold">Thêm đáp án</span>
                        </div>
                    </HRButton>
                </div>
                <div className="mt-2">
                    <Checkbox
                        checked={question.isMixed}
                        className="purple-checkbox"
                        onChange={(e) => {
                            onMixAnswer(e.target.checked)
                        }}
                    >
                        <span className="text-high-em text-body">Xáo câu trả lời</span>
                    </Checkbox>
                </div>
            </div>
        }
    </div>
}

type AnswerEditorProps = {
    questionType: EQuestionType;
    answer: string
    onChange: (index: number, value: string) => void
    needCheck: boolean,
    isChecked: boolean,
    index: number,
    onChangeCorrectAnswer: (index: number, isChecked?: boolean) => void
    onRemoveAnswer: (index: number) => void;
    onLockAnswer: (index: number) => void;
    viewOnly: boolean;
    isDuplicate?: boolean;
    isLocked?: boolean;
    isMixed?: boolean;
    readyLock?: boolean;
    isDisabledLock?: boolean;
    skillAreaTypeSelected: ESkillAreaType | undefined;
    activeAtTeam: boolean;
    scoreLevelsQuestion: TScoreLevels[] | undefined;
    question: TChoiceQuestion,
    onChangeRateLevel: (index: number, value: number) => void;
}
const AnswerEditor: React.FC<AnswerEditorProps> = ({
    questionType,
    answer,
    onChange,
    needCheck,
    isChecked,
    index,
    onChangeCorrectAnswer,
    viewOnly,
    onRemoveAnswer,
    onLockAnswer,
    isDuplicate,
    isLocked,
    isMixed,
    readyLock,
    isDisabledLock,
    skillAreaTypeSelected,
    activeAtTeam,
    scoreLevelsQuestion,
    question,
    onChangeRateLevel,
}) => {
    const [isFocus, setIsFocus] = React.useState<boolean>(false);

    const [editorState, setEditorState] = React.useState<EditorState>(
        EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(answer).contentBlocks))
    )

    React.useEffect(() => {
        if (!answer) {
            setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(answer).contentBlocks)))
        }
    }, [answer])

    const onEditorStateChange = (valueEditorState: EditorState) => {
        if (viewOnly) return;
        let text = valueEditorState.getCurrentContent().getPlainText();
        const oldContent = editorState.getCurrentContent().getPlainText();
        if (text.length > 5000) {
            const oldState = ContentState.createFromBlockArray(htmlToDraft(oldContent).contentBlocks);
            setEditorState(EditorState.moveFocusToEnd(EditorState.push(editorState, oldState, 'insert-characters')));
        } else {
            setEditorState(valueEditorState);
            onChange(index, draftToHtml(convertToRaw(valueEditorState.getCurrentContent())))
        }
    }
    const isAnswerError = React.useMemo(() => {
        return needCheck && isEmptyHTML(answer)
    }, [answer, needCheck]);

    return <WrapperEditor className={classNames(viewOnly ? "read-only-mode" : "", isChecked ? 'has-value' : '', "grid grid-cols-1")}>
        <div
            className={classNames("grid items-center gap-[16px]",
                {
                    "grid-cols-[auto_1fr_auto]": skillAreaTypeSelected !== ESkillAreaType.ATTEAM && !viewOnly,
                    "grid-cols-[1fr_auto]": skillAreaTypeSelected === ESkillAreaType.ATTEAM || viewOnly,
                }
            )}
        >
            {skillAreaTypeSelected !== ESkillAreaType.ATTEAM && <div className="flex">
                {
                    questionType === "single_choice" ? <Radio
                        checked={isChecked}
                        onChange={() => onChangeCorrectAnswer(index)}
                    /> : <span className="pr-2">
                        <Checkbox
                            className="purple-checkbox"
                            checked={isChecked}
                            onChange={(e) => {
                                onChangeCorrectAnswer(index, e.target.checked);
                            }}
                        />
                    </span>
                }
            </div>}
            <div className="relative">
                <Editor
                    stripPastedStyles={true}
                    readOnly={viewOnly}
                    onFocus={() => setIsFocus(true)}
                    onBlur={() => setIsFocus(false)}
                    toolbar={answerToolbar}
                    editorState={editorState}
                    toolbarClassName={classNames(isFocus ? "answer-toolbar__focus" : "hidden", "answer-toolbar")}
                    wrapperClassName={classNames(isAnswerError || (isDuplicate && !isEmptyHTML(answer)) ? "error" : "", "anwser-questions", "editor-wrapper", viewOnly ? 'view-only' : '')}
                    editorClassName={classNames("anwser-questions anwser-editor", viewOnly ? 'view-only' : '')}
                    onEditorStateChange={onEditorStateChange}
                    handleReturn={() => {
                        return true;
                    }}
                    placeholder="Đáp án..."
                />
                {
                    isDuplicate && !isEmptyHTML(answer) && <div
                        className="relaive flex items-center space-x-2 pt-[8px]">
                        <ICWarning fill="#F55858" width={18} height={18} />
                        <span className="text-body italic text-error-500">Đáp án này đã tồn tại</span>
                    </div>
                }
            </div>

            {!viewOnly &&
                <div className={classNames("flex flex-col gap-[4px] items-center h-full text-center", !isMixed || !readyLock ? "justify-center" : "justify-between")}>
                    <span className="flex items-center justify-center cursor-pointer w-[24px] h-[24px]"
                        onClick={onRemoveAnswer.bind(this, index)}>
                        <ICClose
                            fill={index > 1 ? "#4F4B5C" : "#D9D8DC"}
                            width={16}
                            height={16}
                        />
                    </span>
                    <span className={classNames("flex items-center justify-center cursor-pointer w-[24px] h-[24px]", !isMixed || !readyLock ? "hidden" : "", isDisabledLock ? "cursor-not-allowed" : "")}
                        onClick={onLockAnswer.bind(this, index)}>
                        {!isLocked && <ICLock
                            fill={!isDisabledLock ? "#4F4B5C" : "#D9D8DC"}
                            width={16}
                            height={16}
                        />}
                        {isLocked && <ICLocked
                            width={16}
                            height={16}
                        />}
                    </span>
                </div>}
        </div>
        {
            isAnswerError && <div className="flex items-center space-x-2 pt-[8px]">
                <span className="text-body italic text-error-500">Không được để trống</span>
            </div>
        }
        {skillAreaTypeSelected === ESkillAreaType.ATTEAM && scoreLevelsQuestion &&
            <div className="pt-[16px]">
                <HRSelect
                    placeholder="Cấp độ"
                    className="w-full max-w-[180px]"
                    hasFilterSort={false}
                    options={(scoreLevelsQuestion ?? []).map((item) => {
                        return {
                            label: item.symbol && item.symbol !== '0' ? `${item.symbol} - ${item.name}` : item.name,
                            value: item?.value,
                        }
                    })}
                    onChange={(value) => onChangeRateLevel(index, value)}
                    value={question.scoreCombination[index]}
                />
                {
                    needCheck && (question.scoreCombination[index] === null || question.scoreCombination[index] === undefined) && <div className="flex items-center space-x-2 pt-[8px]">
                        <span className="text-body italic text-error-500">Không được để trống</span>
                    </div>
                }
            </div>
        }
    </WrapperEditor>
}
