import { Divider, Typography } from 'antd';
import React from 'react';
import { STEPS } from '../../type';
import { HRSelect } from '../../../../components/form/select';
import { ICCamera, ICNext, ICWarning } from '../../../../icons';
import { HRCamera } from '../../../../components/camera';
import { HRButton } from '../../../../components/button';
import { useTestingPageContext } from '../..';

export const CameraVerifyAssessment: React.FC = () => {
    const { setStep, companyProfile, assessmentDetails } = useTestingPageContext();
    const isForceTurnOnCamera = assessmentDetails.assessment.forceTurnOnCamera || false;
    const [isCameraEnabled, setIsCameraEnabled] = React.useState<boolean>(true);
    const [deviceSelected, setDeviceSelected] = React.useState<MediaDeviceInfo>();
    const [devices, setDevices] = React.useState<MediaDeviceInfo[]>([]);

    const onNextAction = React.useCallback(() => {
        setStep(STEPS.AUDIO_MIC_CHECK);
    }, [setStep]);

    React.useEffect(() => {
        if (isCameraEnabled) {
            navigator.mediaDevices.addEventListener('devicechange', event => {
                getDevices();
            });
            const getDevices = async () => {
                let deviceList = await navigator.mediaDevices.enumerateDevices();
                setDevices(deviceList);
            }
            if (navigator.mediaDevices) {
                getDevices();
            }
        }
    }, [isCameraEnabled]);

    const loadDevices = React.useMemo(() => {
        return devices.filter(({ kind }) => kind === "videoinput");
    }, [devices]);

    React.useEffect(() => {
        if (loadDevices.length > 0) {
            setDeviceSelected(loadDevices[0]);
        }
    }, [loadDevices]);

    return <div className="grid grid-cols-1 gap-[56px] bg-white rounded-[12px] px-[32px] py-[54px] shadow-e-03">
        <div className="grid grid-cols-2 gap-[24px] items-start justify-start">
            <div className="grid grid-cols-1 gap-[32px]">
                <div className="text-left">
                    <Typography.Title level={3} style={{
                        color: '#110C22',
                        fontWeight: 700,
                        marginBottom: 0,
                        fontSize: '28px',
                        lineHeight: '40px'
                    }}>Kiểm tra ghi hình</Typography.Title>
                </div>
                <div className="grid grid-cols-1 gap-[4px]">
                    {
                        isForceTurnOnCamera ? <span className="text-body font-bold text-red-400">
                            Bài đánh giá này bắt buộc phải có webcam để thực hiện.
                        </span> : <span className="text-body text-high-em">
                            Bài đánh giá này có yêu cầu webcam để chúng tôi có thể theo dõi được quá trình làm của bạn.
                        </span>
                    }

                    <span className="text-body text-high-em">
                        Vui lòng bật thiết bị ghi hình để chúng tôi có thể đánh giá bạn một cách công bằng nhất.
                    </span>
                </div>
                <div><Divider style={{ margin: '0', borderColor: '#E2E4EB' }} /></div>
                <div>
                    <HRSelect
                        value={deviceSelected?.deviceId || ''}
                        hasSearch={false}
                        icon={<ICCamera fill="#BEC0CA" stroke="#BEC0CA" />}
                        style={{
                            width: "100%",
                            height: "auto",
                            wordWrap: "break-word"
                        }}
                        allowClear={false}
                        options={loadDevices.map(c => ({ label: c.label, value: c.deviceId }))}
                    />
                </div>
            </div>
            {isCameraEnabled && <div className="grid grid-cols-1 gap-[16px]">
                <label className="text-[#46485C] text-[16px] font-bold leading-[19px]">Xem trước</label>
                <HRCamera setIsCameraEnabled={setIsCameraEnabled} deviceId={deviceSelected?.deviceId} />
                <label className="text-[#46485C] text-[14px] font-medium leading-[17px] text-center">{deviceSelected?.label}</label>
            </div>}
            {!isCameraEnabled && <div className="grid grid-cols-1 gap-[16px]">
                <div className="rounded-[12px] bg-[#E9A743] w-full">
                    <div className="grid grid-cols-[24px_1fr] gap-[24px] items-center text-white p-[32px]">
                        <span>
                            <ICWarning fill="white" />
                        </span>
                        <span className="text-body">
                            Có vẻ như bạn không có camera được kết nối với máy tính của mình hoặc camera của bạn bị chặn. Để kích hoạt camera, hãy nhấp vào biểu tượng camera bị chặn trong thanh địa chỉ của trình duyệt và tải lại trang. Nếu bạn không kích hoạt camera, bạn vẫn có thể tham gia bài đánh giá, nhưng HUSA sẽ không thể xác minh tính công bằng.
                        </span>
                    </div>
                </div>
                <div className="rounded-[12px] bg-[#EEF0F6] w-full p-[32px]">
                    <div className="grid grid-cols-1 gap-[16px]">
                        <span className="text-standard-bold text-high-em">
                            Gặp vấn đề với camera?
                        </span>
                        <span className="text-body text-high-em">
                            Hãy đảm bảo rằng bạn đã cho phép trình duyệt của mình truy cập vào camera của bạn.
                        </span>
                        <span className="text-body text-high-em">
                            Hãy đảm bảo rằng bạn đang sử dụng một trình duyệt được hỗ trợ.
                        </span>
                        <span className="text-body text-high-em">
                            Hãy thử khởi chạy bài đánh giá trong chế độ ẩn danh hoặc trong một cửa sổ riêng tư.
                        </span>
                        <span className="text-body text-high-em">
                            Hãy đảm bảo rằng driver camera và trình duyệt web của bạn đã được cập nhật lên phiên bản mới nhất.
                        </span>
                        <span className="text-body text-high-em">
                            Hãy khởi động lại thiết bị của bạn và thử truy cập lại bài đánh giá bằng đường link trong email mời.
                        </span>
                    </div>
                </div>
            </div>}
        </div>
        <div className="flex items-center justify-center">
            <span>
                <HRButton
                    disabled={!isCameraEnabled && isForceTurnOnCamera}
                    onClick={onNextAction}
                    btnType="primary"
                    style={{
                        backgroundColor: companyProfile?.color?.backgroundColor,
                        borderColor: companyProfile?.color?.backgroundColor,
                        color: companyProfile?.color?.fontColor || "#fff",
                    }}
                >
                    <div className="flex space-x-2 items-center justify-center">
                        <span>Tiếp tục</span>
                        <ICNext fill={(companyProfile?.color?.fontColor || "#fff")} />
                    </div>
                </HRButton>
            </span>
        </div>
    </div>
}
