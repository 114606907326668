import React from "react"
import { Typography } from 'antd';
import { ICClose, ICFavored, ICCheckboxList, ICBriefcase, ICClockTime, ICStarOutline, ICGlobeAlt, ICLevel, ICCheckCompleted, ICLogo } from "../../icons/index";
import { HRButton, IconButton } from "../../components/button"
import { ILibrary, TRatingLevel } from './models';
import { LibraryItemDetails } from './components/item_details';
import { useGeneralPageData } from "../general";
import { Iframe } from "../../components/iframe";

const { Title } = Typography;

type LibraryDetailsProps = {
    data?: ILibrary;
    ratingLevel: TRatingLevel[] | undefined;
    setOpen: (value: boolean) => void
}
export const LibraryDetails: React.FC<LibraryDetailsProps> = ({
    data = {} as ILibrary,
    ratingLevel,
    setOpen
}) => {
    const [ratingLevelSelected, setRatingLevelSelected] = React.useState<string | undefined>('');
    const { setShowComingSoonModal } = useGeneralPageData();
    // const hasTestSuponsor = data?.isPublic === true;

    React.useEffect(() => {
        // hasTestSuponsor && 
        if (ratingLevel && ratingLevel?.length > 0) {
            const ratingLevelSelected = ratingLevel?.filter(item => item.id === data?.ratingLevel);
            if (ratingLevelSelected) {
                setRatingLevelSelected(ratingLevelSelected[0]?.label);
            }
        }
    }, []);

    const itemDetails = [
        {
            icon: <ICCheckboxList />,
            title: 'Loại',
            name: 'test_type',
            value: data?.type
        },
        {
            icon: <ICClockTime />,
            title: 'Thời gian',
            value: `${Array.isArray(data?.configuration?.questionDuration)
                ? data?.configuration?.questionDuration.reduce((acc, cur) => acc + cur, 0)
                : data?.configuration?.questionDuration ?? 0
                } phút`
        },
        {
            icon: <ICGlobeAlt />,
            title: 'Ngôn ngữ',
            name: 'language',
            value: data?.language
        },
        {
            icon: <ICLevel />,
            title: 'Cấp bậc',
            // name: hasTestSuponsor ? undefined : 'hard_level',
            // value: hasTestSuponsor ? ratingLevelSelected : data?.level
            name: undefined,
            value: ratingLevelSelected
        },
        {
            icon: <ICStarOutline />,
            title: 'Đánh giá',
            value: data?.avg_rate
        },
        {
            icon: <ICBriefcase fill="#7357FF" />,
            title: 'Lĩnh vực',
            name: 'test_field',
            value: data?.industry
        }
    ];

    const avatar = React.useMemo(() => {
        return "/images/logo-company.jpg"
        // return data.createdBy ? getURL(data.createdBy.avatar) : "/images/logo-company.jpg"
    }, []);

    const renderSkillsRequired = React.useMemo(() => {
        if (data?.skillsRequired?.length) {
            return data?.skillsRequired.map((item, index) => {
                return <span key={index} className="grid grid-cols-[22px_auto] gap-[9px] items-center pl-[16px]">
                    <ICCheckCompleted />
                    <span>{item}</span>
                </span>
            });
        }
        return null;
    }, [data]);

    const onPreview = React.useCallback(() => {
        window.open(`/testing/${data.id}/preview`, '_blank');
    }, [data]);

    return (
        <div className="relative grid grid-cols-[1fr] items-center gap-[32px]">
            <div className="grid grid-cols-[auto_auto] justify-between items-center w-full">
                <span className="grid grid-cols-[auto_auto] gap-[16px] justify-between w-full">
                    {data?.previewSection && data?.previewSection?.length > 0 && <HRButton
                        onClick={onPreview}
                        btnSize="md">
                        Làm thử đề mẫu
                    </HRButton>}
                    <IconButton
                        btnType="sub"
                        btnSize="sm"
                        onClick={() => setShowComingSoonModal(true)}
                        icon={<ICFavored />}
                    />
                </span>
                <span>
                    <HRButton btnSize="md" btnType="sub"
                        onClick={() => setOpen(false)}>
                        <div className="flex gap-[8px] items-center">
                            <ICClose width={18} height={18} />
                            <span>Đóng</span>
                        </div>
                    </HRButton>
                </span>
            </div>

            <div className="grid grid-cols-2 gap-[32px] flex-wrap">
                <div>
                    <div className="grid grid-cols-[1fr] gap-[16px] flex-wrap">
                        <Title level={3} className="" style={{
                            color: '##2E3046',
                            margin: 0,
                            padding: 0,
                            fontWeight: 700,
                            lineHeight: '29px'
                        }}>{data?.title}</Title>

                        <Title level={5} className="" style={{
                            margin: 0,
                            padding: 0,
                            fontSize: '14px',
                            fontWeight: 400
                        }}>
                            {data?.summary}
                        </Title>

                        <div className="grid grid-cols-4 flex-wrap gap-y-[16px]">
                            {itemDetails?.map((item, index) => {
                                return <LibraryItemDetails
                                    key={`${index}-${item.value}`}
                                    index={index}
                                    name={item.name}
                                    value={item.value}
                                    last={itemDetails?.length - 1}
                                    icon={item.icon}
                                    title={item.title} />
                            })}
                        </div>

                        <div className="grid grid-cols-1 flex-wrap gap-[16px] pt-[16px]">
                            <Title level={3} className="" style={{
                                margin: 0,
                                padding: 0,
                                fontSize: '14px',
                                fontWeight: 700
                            }}>
                                Kỹ năng cần có
                            </Title>
                            {renderSkillsRequired}
                        </div>

                        <div className="grid grid-cols-1 flex-wrap gap-[16px] pt-[16px]">
                            <Title level={3} className="" style={{
                                margin: 0,
                                padding: 0,
                                fontSize: '14px',
                                fontWeight: 700
                            }}>
                                Đề kiểm tra liên quan đến
                            </Title>
                            <span>{data?.relevant}</span>
                        </div>

                    </div>

                </div>
                <div className="grid">
                    <div className="grid grid-cols-[1fr] gap-[16px] grid-rows-[auto_auto_1fr] flex-wrap">
                        <Title level={5} className="" style={{
                            color: '##2E3046',
                            margin: 0,
                            padding: 0,
                            fontWeight: 700,
                            lineHeight: '29px',
                            fontSize: '14px'
                        }}>Mô tả</Title>
                        <div className="pr-[48px]">
                            <Iframe content={data?.description as string || ''} />
                        </div>

                        <div className="grid grid-cols-1 grid-rows-[auto_1fr] flex-wrap gap-[16px] pt-[16px]">
                            <Title level={3} className="" style={{
                                margin: 0,
                                padding: 0,
                                fontSize: '14px',
                                fontWeight: 700
                            }}>
                                Giới thiệu về chuyên gia chủ đề
                            </Title>
                            <span className="grid grid-cols-[90px_auto] gap-[16px]">
                                <span className="h-[90px] w-[90px] rounded-[10px] border border-solid boder-[#ededed]">
                                    <ICLogo 
                                        width={90}
                                        height={90}
                                    />
                                </span>
                                <span className="grid grid-rows-[auto_1fr] gap-[8px]">
                                    <Title level={3} className="" style={{
                                        margin: 0,
                                        padding: 0,
                                        fontSize: '14px',
                                        fontWeight: 700
                                    }}>
                                        {/* {data?.createdBy?.name} */}
                                        Công ty Cổ phần Công Nghệ HUSA
                                    </Title>
                                    <span className="flex pr-[48px]">
                                        Công ty Cổ phần Công Nghệ HUSA được thành lập với mục tiêu cung cấp các giải pháp kiểm tra năng lực ứng viên một cách hiệu quả và thuận tiện nhất. Các bài kiểm tra năng lực ứng viên được thực hiện bởi đội ngũ chuyên gia của công ty hoặc được đóng góp bởi các nhà tuyển dụng có trên 10 năm kinh nghiệm và tham gia nhiều chiến dịch tuyển dụng quy mô lớn. Với việc sử dụng các bài kiểm tra năng lực của công ty, nhà tuyển dụng sẽ có nhiều thông tin hơn trong việc đánh giá và hiểu biết về các kỹ năng hoặc kiến thức tiềm ẩn của ứng viên.
                                    </span>
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
