export type TTLevelItem = {
    id: number;
    title: string;
    content: string;
    subContent: string[];
};

export type TLevelData = {
    title: string;
    subTitle?: string[];
    data: TTLevelItem[];
};

export const LevelATTeam: TLevelData = {
    title: 'Hệ thống đánh giá cấp độ của đội ngũ AT TEAM',
    data: [
        {
            id: 1,
            title: 'M (Master - Chuyên gia)',
            content: 'Đạt được cấp độ này có thể ứng viên đáp ứng:',
            subContent: [
                'Lãnh đạo, dẫn dắt, đại diện, làm mẫu, tạo ảnh hưởng, phát triển, đào tạo, khai vấn trong Tiêu Chuẩn Đánh Giá.',
                'Tạo ảnh hưởng đến những đường lối/định hướng của Tổ chức từ nhận thức và tư duy sâu hoặc đánh giá được những khuynh hướng bên ngoài, các ứng dụng liên quan cho thành công lâu dài của Tổ chức.',
                'Kết nối mọi người từ các phòng ban, khối, chức năng khác nhau để tạo cơ hội cho sự phát triển và tạo.',
            ],
        },
        {
            id: 2,
            title: 'A (Advanced – Nâng cao)',
            content: 'Đạt được cấp độ này có thể ứng viên đáp ứng:',
            subContent: [
                'Quản lý, hướng dẫn, đào tạo, khuyến khích, tạo động lực cho đội ngũ và tư vấn cho những người khác hoặc những chuyên gia, lãnh đạo khác.',
                'Thực hiện công việc một cách độc lập và hiệu quả hơn so với giai đoạn Khởi đầu và Kinh nghiệm.',
                'Giải quyết các vấn đề phức tạp và thách thức, đồng thời tiếp tục học hỏi và tự nâng cấp để xác định và giảm thiểu rủi ro, cũng như tận dụng cơ hội để phát triển chuyên môn.',
            ],
        },
        {
            id: 3,
            title: 'S (Skilled – Thuần thục)',
            content: 'Đạt được cấp độ này có thể ứng viên đáp ứng:',
            subContent: [
                'Có năng lực và khả năng thu nhận, áp dụng, tuân thủ, phát triển bản thân tư vấn và hoặc hỗ trợ cho những người khác.',
                'Có khả năng làm việc độc lập và thực hiện công việc hiệu để hoàn thành các nhiệm vụ, công việc...',
                'Có tiềm năng phát triển năng lực trong các lĩnh vực chuyên môn.',
            ],
        },
        {
            id: 4,
            title: 'E (Emerging - Cơ bản)',
            content: 'Đạt được cấp độ này có thể ứng viên đáp ứng:',
            subContent: [
                'Có khả năng thu thập, áp dụng, tuân thủ và phát triển bản thân.',
                'Có khả năng học hỏi và tiếp thu kiến thức, kỹ năng cần thiết để dẫn dắt và làm việc độc lập.',
                'Có khả năng hoạt động tốt trong một phạm vi công việc cụ thể, mặc dù cần nhiều thời gian hơn để làm việc trong các phạm vi công việc khác.',
            ],
        },
    ]
}

export const LevelTalentCompass: TLevelData = {
    title: 'Hệ thống đánh giá cấp độ và tính điểm của Talent Compass',
    subTitle: [
        'E (Emerging)',
        'S (Skilled)',
        'A (Advanced)',
        'M (Master)',
    ],
    data: [
        {
            id: 1,
            title: 'M (Master - Chuyên gia)',
            content: 'Đạt được cấp độ này có thể ứng viên đáp ứng:',
            subContent: [
                'Lãnh đạo, dẫn dắt, đại diện, làm mẫu, tạo ảnh hưởng, phát triển, đào tạo, khai vấn trong Tiêu Chuẩn Đánh Giá.',
                'Tạo ảnh hưởng đến những đường lối/định hướng của Tổ chức từ nhận thức và tư duy sâu hoặc đánh giá được những khuynh hướng bên ngoài, các ứng dụng liên quan cho thành công lâu dài của Tổ chức.',
                'Kết nối mọi người từ các phòng ban, khối, chức năng khác nhau để tạo cơ hội cho sự phát triển và tạo.',
            ],
        },
        {
            id: 2,
            title: 'A (Advanced – Nâng cao)',
            content: 'Đạt được cấp độ này có thể ứng viên đáp ứng:',
            subContent: [
                'Quản lý, hướng dẫn, đào tạo, khuyến khích, tạo động lực cho đội ngũ và tư vấn cho những người khác hoặc những chuyên gia, lãnh đạo khác.',
                'Thực hiện công việc một cách độc lập và hiệu quả hơn so với giai đoạn Khởi đầu và Kinh nghiệm.',
                'Giải quyết các vấn đề phức tạp và thách thức, đồng thời tiếp tục học hỏi và tự nâng cấp để xác định và giảm thiểu rủi ro, cũng như tận dụng cơ hội để phát triển chuyên môn.',
            ],
        },
        {
            id: 3,
            title: 'S (Skilled – Thuần thục)',
            content: 'Đạt được cấp độ này có thể ứng viên đáp ứng:',
            subContent: [
                'Có năng lực và khả năng thu nhận, áp dụng, tuân thủ, phát triển bản thân tư vấn và hoặc hỗ trợ cho những người khác.',
                'Có khả năng làm việc độc lập và thực hiện công việc hiệu để hoàn thành các nhiệm vụ, công việc...',
                'Có tiềm năng phát triển năng lực trong các lĩnh vực chuyên môn.',
            ],
        },
        {
            id: 4,
            title: 'E (Emerging - Cơ bản)',
            content: 'Đạt được cấp độ này có thể ứng viên đáp ứng:',
            subContent: [
                'Có khả năng thu thập, áp dụng, tuân thủ và phát triển bản thân.',
                'Có khả năng học hỏi và tiếp thu kiến thức, kỹ năng cần thiết để dẫn dắt và làm việc độc lập.',
                'Có khả năng hoạt động tốt trong một phạm vi công việc cụ thể, mặc dù cần nhiều thời gian hơn để làm việc trong các phạm vi công việc khác.',
            ],
        },
    ]
};
