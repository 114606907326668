import React, { useMemo } from "react"
import { ICFee, ICTagNew, ICTagPopular, ICTagFree, ICTagPaid } from "../../icons/index";

import { ILibrary, EStatusCard } from './models';

const statusTextMapping = {
    new: "Mới",
    popular: "Phổ biến",
    normal: "Phổ biến",
    pay: "Trả phí",
    free: "Miễn phí",
}

const statusIconMapping = {
    new: "icon-new",
    popular: "icon-popular",
    normal: "icon-normal",
    pay: "icon-paid",
    free: "icon-free",
}

type LibraryTagsProps = {
    data?: ILibrary;
}
export const LibraryTags: React.FC<LibraryTagsProps> = ({
    data = {} as ILibrary
}) => {
    const renderStatus = useMemo(() => {
        switch (data?.statusTag) {
            case EStatusCard.NEW:
                return <ICTagNew />;
            case EStatusCard.POPULAR:
                return <ICTagPopular />;
            case EStatusCard.FREE:
                return <ICTagFree />;
            case EStatusCard.PAY:
                return <ICTagPaid />;
            case EStatusCard.NORMAL:
                return <ICTagFree />;
            default:
                break;
        }
        return null;
    }, [data]);

    const renderStatusTagNormal = useMemo(() => {
        switch (data?.planTag) {
            case EStatusCard.FREE:
                return <ICTagFree />;
            case EStatusCard.PAY:
                return <ICTagPaid />;
            default:
                break;
        }
        return null;
    }, [data]);

    return data?.isPublic === false ? <div className="relative grid grid-cols-[auto_auto] gap-[0px]">
        <span className="tag-free">
            <ICTagFree />
            <span className="absolute text-[12px] leading-[15px] font-bold top-[5px] left-[12px]">
                Private
            </span>
        </span>
    </div> :
        <>
            {data?.statusTag === EStatusCard.NORMAL && <div className="relative grid grid-cols-[auto_auto] gap-[0px]">
                <span className={statusIconMapping[data?.planTag]}>
                    {renderStatusTagNormal}
                    <span className="absolute text-[12px] leading-[15px] font-bold top-[5px] left-[12px]">
                        {statusTextMapping[data?.planTag]}
                    </span>
                </span>
            </div>}
            {data?.statusTag !== EStatusCard.NORMAL && <div className="relative grid grid-cols-[auto_auto] gap-[0px]">
                <span className={statusIconMapping[data?.statusTag]}>
                    {renderStatus}
                    <span className="absolute text-[12px] leading-[15px] font-bold top-[5px] left-[12px]">
                        {statusTextMapping[data?.statusTag]}
                    </span>
                </span>
                {data?.planTag && <span className="relative">
                    <ICFee fill={data?.planTag === EStatusCard.FREE ? "#B3B1B8" : "#FFCB46"} />
                    <span className="absolute top-[5px] left-[16px] text-sub-bold"
                        style={{
                            color: data?.planTag === EStatusCard.FREE ? 'white' : '#2E3046'
                        }}>
                        {statusTextMapping[data?.planTag]}
                    </span>
                </span>}
            </div>}
        </>
}
