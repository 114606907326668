import React, { useState, useRef } from "react";
import { Tag, TagProps, InputRef, Input } from "antd";
import { ICAdd } from "../../icons";
import { HRTagItems } from "./components/tag-item";

type HRTagProps = TagProps & {
    tags?: string[],
    setTags: (value: string[]) => void,
    onChange?: (value: string[]) => void
}
export const HRTags: React.FC<HRTagProps> = ({ style, tags = [], setTags, onChange, ...props }) => {
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const inputRef = useRef<InputRef>(null);

    const showInput = () => {
        setInputVisible(true);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    const handleInputConfirm = () => {
        if (inputValue && tags.indexOf(inputValue) === -1) {
            const newTags = [...tags, inputValue];
            setTags(newTags);
            if (onChange) {
                onChange(newTags);
            }
        }
        setInputVisible(false);
        setInputValue('');
    };

    React.useEffect(() => {
        if (inputVisible) {
            inputRef.current?.focus();
        }
    }, [inputVisible]);

    return (
        <>
            <HRTagItems {...props}
                tags={tags} 
                setTags={setTags} 
                inputValue={inputValue} 
                onChange={onChange}
                setInputValue={setInputValue}/>
            <div>
                {inputVisible ? (
                    <Input
                        ref={inputRef}
                        type="text"
                        size="small"
                        className="tag-input"
                        value={inputValue}
                        maxLength={200}
                        onChange={handleInputChange}
                        onBlur={handleInputConfirm}
                        onPressEnter={handleInputConfirm}
                        style={{
                            width: '160px',
                            border: '1px solid #6A6C7D',
                            borderRadius: '50px',
                            height: '28px',
                            outline: 'none',
                            padding: '6px 16px'
                        }}
                    />
                ) : (
                    <>
                        {tags?.length < 10 && <Tag className="tag-plus" onClick={showInput}
                            style={{
                                display: 'grid',
                                gridTemplateColumns: 'auto auto',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '8px',
                                color: '#7357FF',
                                width: "114px",
                                padding: '8px 16px',
                                background: "#F9F8FF",
                                border: "1.2px solid #E2DCFF",
                                borderRadius: '8px',
                                fontSize: '14px',
                                fontWeight: '700',
                                lineHeight: '17px',
                                boxShadow: '0px 1px 2px -1px rgba(17, 12, 34, 0.08)',
                            }}>
                        <ICAdd stroke="#7357FF" width={18} height={18} /> Thêm
                        </Tag>}
                    </>
                )}
            </div>
        </>
    )
}
