import React, { useState } from "react"
import { Card, Badge, Typography, Divider, Modal, Tooltip } from 'antd';
import Highlighter from "react-highlight-words";
import { HRButton, IconButton } from "../../../../../components/button";
import { ICAdd, ICInfoV2, ICClose, IViewV2 } from "../../../../../icons";
import { ILibrary, TRatingLevel } from "../../../../library/models";
import { LibraryTags } from "../../../../library/tags";
import { LibraryDetails } from "../../../../library/details";
import styled from "styled-components";
import classNames from 'classnames'
const WrapperActions = styled.div`
    &.disabled-btn {
        .add-btn {
            cursor: not-allowed;
            color: #B2B4BF !important;
            background-color: #E2E4EB !important;
            border-color: #E2E4EB !important;
        }
    }
`;


const { Title } = Typography;
const statusClassMapping = {
    new: "tag-new",
    popular: "tag-popular",
    normal: "tag-normal",
    pay: "tag-paid",
    free: "tag-free",
}
type TestLibraryCardProps = {
    data: ILibrary;
    keyword?: string;
    onAddTest: (data: ILibrary) => void;
    domIdRef: string | undefined
    onRemove: (data: ILibrary) => void;
    isAdded: boolean;
    allowAdd?: boolean;
    ratingLevel?: TRatingLevel[] | undefined;
}
export const TestLibraryCard: React.FC<TestLibraryCardProps> = ({
    data = {} as ILibrary,
    keyword,
    onAddTest,
    domIdRef,
    isAdded,
    allowAdd,
    onRemove,
    ratingLevel,
}) => {
    const refCard = React.useRef<HTMLDivElement>(null);
    const onAdd = React.useCallback(() => {
        if (!domIdRef) return;
        const dom = document.getElementById(domIdRef);

        onAddTest(data);
        if (dom && refCard.current) {
            const wrapper = document.createElement("div");
            wrapper.style.opacity = "0.8"
            wrapper.style.position = "fixed";
            const rect = refCard.current.getBoundingClientRect()
            wrapper.style.left = `${rect.left}px`;
            wrapper.style.top = `${rect.top}px`;
            wrapper.style.width = `${rect.width}px`;
            wrapper.style.height = `${rect.height}px`;
            wrapper.style.zIndex = "999"
            const cloned = refCard.current.cloneNode(true);
            wrapper.appendChild(cloned)
            document.body.appendChild(wrapper);
            const domRect = dom.getBoundingClientRect();
            // Animated
            wrapper.animate([
                {
                    transform: 'scale(1)',
                    top: `${rect.top}px`,
                    left: `${rect.left}px`,
                },
                {
                    transform: 'scale(0)',
                    top: `${domRect.top - rect.height / 2 + 15}px`,
                    left: `${domRect.left - 30}px`,
                }
            ], {
                duration: 1000,
                iterations: 1,
            }).addEventListener("finish", () => {
                document.body.removeChild(wrapper)
            })
        }
    }, [data, domIdRef, refCard.current]);

    const [openDetails, setOpenDetails] = useState(false);

    const onPreviewTesting = React.useCallback(() => {
        window.open(`/testing/${data.id}/preview`, '_blank');
    }, [data]);

    return <>
        <Modal
            open={openDetails}
            centered={true}
            closable={false}
            footer={null}
            width={1140}
        >
            <LibraryDetails data={data} ratingLevel={ratingLevel} setOpen={setOpenDetails} />
        </Modal>
        <div className="card-wrapper" ref={refCard}>
            <Badge.Ribbon className={
                classNames(
                    data.isPublic === false ? "tag-private" : `${statusClassMapping[data?.statusTag]} ${statusClassMapping[data?.planTag]}`
                )
            }
                text={<LibraryTags data={data} />} placement={'start'}>
                <Card size="small" className="rounded-[8px]" style={{
                    boxShadow: '0px 6px 16px -6px rgba(17, 12, 34, 0.10)',
                }} bodyStyle={{
                    padding: '16px'
                }}>
                    <div className="grid pt-[47px] grid-cols-1 gap-[16px] text-left">
                        <div className="">
                            <Tooltip title={data?.title} placement="top">
                                <Title level={5}
                                    style={{
                                        minHeight: '48px',
                                        color: '#110C22',
                                        fontWeight: 700,
                                        lineHeight: '24px',
                                        fontSize: '16px'
                                    }}>
                                    <span className="line-clamp-2">
                                        <Highlighter
                                            highlightClassName="text-highlight"
                                            searchWords={[`${keyword || ''}`]}
                                            autoEscape={true}
                                            textToHighlight={data?.title || ''}
                                        />
                                    </span>
                                </Title>
                            </Tooltip>
                        </div>
                        <div>
                            <p className="line-clamp-7 mb-0 text-med-em text-sub"
                                style={{
                                    minHeight: '111px'
                                }}
                                >
                                <Highlighter
                                    highlightClassName="text-highlight"
                                    searchWords={[`${keyword || ''}`]}
                                    autoEscape={true}
                                    textToHighlight={data?.description || ''}
                                />
                            </p>
                        </div>
                        <div className="flex flex-row gap-[8px] items-center justify-start">
                            {
                                data?.tags?.map((item, index) => {
                                    return <div key={index}
                                        className="grid items-center justify-center text-center gap-[8px] rounded-[50px] border-[1px] border-outline-high text-high-em text-sub-medium px-[8px] py-[4px]"
                                        style={{
                                            gridTemplateColumns: item?.icon ? '12px auto' : 'auto'
                                        }}
                                    >
                                        {item?.icon && <span>{item?.icon}</span>}
                                        <span className="m-0 w-full">{item.name}</span>
                                    </div>
                                })
                            }
                        </div>
                        <div><Divider style={{ margin: '0', borderColor: '#E2E4EB' }} /></div>
                        <div className="grid grid-cols-[40px_40px_1fr] grid-rows-[40px] gap-[16px] box-border overflow-hidden">
                            <div>
                                {data?.previewSection && data?.previewSection?.length > 0 && 
                                <IconButton btnType="sub" btnSize="sm" icon={<IViewV2 height={18} width={18} />}
                                    onClick={onPreviewTesting} />}
                            </div>
                            <div>
                                <IconButton btnType="sub" btnSize="sm" icon={<ICInfoV2 />}
                                    onClick={() => setOpenDetails(true)}
                                />
                            </div>
                            <WrapperActions className={allowAdd ? 'disabled-btn' : ''}>
                                {
                                    isAdded ?
                                        <span><HRButton
                                            onClick={() => onRemove(data)}
                                            btnType="danger"
                                            btnSize="sm"
                                        >
                                            <div className="flex gap-[10px] justify-center items-center">
                                                <ICClose fill={"white"} width={18} height={18} />
                                                <span>Xóa</span>
                                            </div>
                                        </HRButton></span>
                                        : <span><HRButton
                                            onClick={onAdd}
                                            btnType="primary"
                                            btnSize="sm"
                                            className="add-btn">
                                            <div className="flex gap-[10px] justify-center items-center">
                                                <ICAdd width={18} stroke={allowAdd ? '#B2B4BF' : 'white'} height={18} />
                                                <span>Thêm</span>
                                            </div>
                                        </HRButton></span>
                                }

                            </WrapperActions>
                        </div>
                    </div>
                </Card>
            </Badge.Ribbon>
        </div>
    </>
}
