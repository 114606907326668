import React from "react";
import { useCandidateCreditPageContext } from ".";
import { HRForm } from "../../components/form/form";
import Form from "antd/lib/form";
import { HRInput } from "../../components/form/input";
import { HRTextArea } from "../../components/form/textarea";
import { EMAIL_REGEX } from "../../utils/constants";
import { HRTooltip } from "../../components/tooltip";

export const InvoicePayment: React.FC = () => {
    const { invoiceForm, companyProfile, userInfo } = useCandidateCreditPageContext();
    const childForm = (
        <div className="grid gap-[16px] grid-cols-[1fr]">
            <div className="grid grid-cols-[1fr_1fr] gap-[16px]">
                <Form.Item
                    name="buyerLegalName"
                    rules={[
                        {
                            required: true,
                            message: "Xin hãy nhập Tên công ty",
                        },
                    ]}
                >
                    <HRInput label="Tên công ty" isRequired={true} maxLength={100}/>
                </Form.Item>
                <HRTooltip 
                    content="Sử dụng cho mục đích xuất hóa đơn điện tử"
                    childNode={
                        <Form.Item
                            name="buyerTaxCode"
                            rules={[
                                {
                                    required: true,
                                    message: "Xin hãy nhập Mã số thuế",
                                },
                            ]}
                        >
                            <HRInput label="Mã số thuế" isRequired={true} maxLength={14}/>
                        </Form.Item>
                    }
                />
            </div>
            <div className="grid grid-cols-[1fr_1fr] gap-[16px]">
                <Form.Item
                    name="buyerName"
                    rules={[
                        {
                            required: true,
                            message: "Xin hãy nhập Tên người nhận",
                        },
                    ]}
                >
                    <HRInput label="Tên người nhận" isRequired={true} maxLength={100}/>
                </Form.Item>
                <Form.Item
                    name="buyerEmail"
                    rules={[
                        {
                            required: true,
                            message: "Xin hãy nhập email",
                        },
                        {
                            pattern: EMAIL_REGEX,
                            message: 'Email không đúng định dạng',
                        },
                    ]}
                >
                    <HRInput label="Email" isRequired={true} maxLength={100}/>
                </Form.Item>
            </div>
            <Form.Item
                name="buyerAddress"
                rules={[
                    {
                        required: true,
                        message: "Xin hãy nhập Địa chỉ công ty",
                    },
                ]}
            >
                <HRTextArea
                    label="Địa chỉ công ty"
                    maxLength={500}
                    style={{
                        height: "120px",
                    }}
                />
            </Form.Item>
        </div>
    );

    return <div className="flex flex-col w-full">
        <HRForm 
            form={invoiceForm} name="createTest" 
            childNode={childForm} 
            fields={[
                {
                    name: ["buyerLegalName"],
                    value: companyProfile?.name,
                },
                {
                    name: ["buyerTaxCode"],
                    value: companyProfile?.taxCode,
                },
                {
                    name: ["buyerName"],
                    value: userInfo?.name,
                },
                {
                    name: ["buyerEmail"],
                    value: companyProfile?.email,
                },
                {
                    name: ["buyerAddress"],
                    value: companyProfile?.address,
                }
            ]}
        />
    </div>
}
