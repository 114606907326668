import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setLoading } from "../../redux/slices/appInfo";
import { Drawer, Modal } from "antd";
import { getListIdsByAssessent, getMyTestCandidateId, getTestCandidateById, rejectCandidate, saveQuestionNote, sendResultCandidate } from "../../api/test-candidate";
import { TAssessmentResponse, TCandidateTestingData, TQuestionTesting } from "../testing/type";
import { ICClose, ICSave } from "../../icons";
import { HRButton, IconButton } from "../../components/button";
import { getMyCandidateDetails, getNotInvitedAssessment } from "../../api/candidate";
import { useNotification } from "../../hooks/useNotification";
import { TCandidate, TSaveNote } from "../my_assessment/type";
import { CandidateDetails } from "./details";
import { ViewQuestionWrapper } from "./details/view-question";
import { HeaderCandidateDetails } from "./details/header";
import { uuid } from "../create_test/tab/question/helper";
import { useNavigation } from "../../hooks/useNavigate";
import {TLevelData } from "./data";
import classNames from "classnames";


type TCandidateDetailsData = {
    id: string;
    mode: 'assessment' | 'candidate';
    assessmentId: string;
    candidateIds: string[];
    otherAssessments: TAssessmentResponse[];
    setOtherAssessments: React.Dispatch<React.SetStateAction<TAssessmentResponse[]>>;
    setShowConfirmSendResult: React.Dispatch<React.SetStateAction<boolean>>;
    setShowRejectModal: React.Dispatch<React.SetStateAction<boolean>>;
    setShowQuestionModal: React.Dispatch<React.SetStateAction<boolean>>;
    setQuestion: React.Dispatch<React.SetStateAction<TQuestionTesting | null>>;
    setCandidateSelected: React.Dispatch<React.SetStateAction<TCandidateTestingData | null>>

    setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
    setDataDrawSelected: React.Dispatch<React.SetStateAction<TLevelData | null>>;

    setCandidateList: React.Dispatch<React.SetStateAction<TCandidateTestingData[]>>;
}
export const CandidateDetailsDataContext = React.createContext<TCandidateDetailsData>({} as TCandidateDetailsData);
export const useCandidateDetailsData = () => React.useContext(CandidateDetailsDataContext);

type TCandidateDetailsPageProps = {
    mode: 'assessment' | 'candidate';
}

export const CandidateDetailsPage: React.FC<TCandidateDetailsPageProps> = ({ mode }) => {
    const dispatch = useDispatch();
    const { navigate } = useNavigation();
    const { showSuccess, showError } = useNotification();
    const { assessmentId = '', id = '' } = useParams();
    const [candidateList, setCandidateList] = React.useState<TCandidateTestingData[]>([]);

    const [myCandidate, setMyCandidate] = React.useState<TCandidate>();
    const [candidateSelected, setCandidateSelected] = React.useState<TCandidateTestingData | null>(null);

    const [candidateIds, setCandidateIds] = React.useState<string[]>([]);
    const [otherAssessments, setOtherAssessments] = React.useState<TAssessmentResponse[]>([]);
    const [showConfirmSendResult, setShowConfirmSendResult] = React.useState(false);
    const [showRejectModal, setShowRejectModal] = React.useState(false);

    const [showQuestionModal, setShowQuestionModal] = React.useState(false);
    const [question, setQuestion] = React.useState<TQuestionTesting | null>(null);

    // additional question
    const [questionNote, setQuestionNote] = React.useState<string>("");
    const [questionRate, setQuestionRate] = React.useState<number>(0);

    // open Drawer
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const [dataDrawSelected, setDataDrawSelected] = React.useState<TLevelData | null>(null);

    const loadOtherAssessment = React.useCallback(async (candidateId: string) => {
        try {
            dispatch(setLoading(true));
            const res = await getNotInvitedAssessment(candidateId);
            setOtherAssessments(res.data || []);
        } finally {
            dispatch(setLoading(false));
        }
    }, [dispatch]);

    const loadCandidateDetails = React.useCallback(async () => {
        if (!id) return;
        if (mode === 'assessment') {
            try {
                dispatch(setLoading(true));
                const res = await getTestCandidateById(id);
                const newData = res.data as TCandidateTestingData;
                newData.uuid = uuid();
                setCandidateList([res.data]);
                // load assessment
                loadOtherAssessment(newData.candidate?._id);

            } finally {
                dispatch(setLoading(false));
            }
        }
    }, [dispatch, id, mode]);

    const loadTestsCandidate = React.useCallback(async () => {
        if (!id) return;
        if (mode === 'candidate') {
            try {
                dispatch(setLoading(true));
                const candidates = await getMyTestCandidateId(id);
                const newData = (candidates.data as TCandidateTestingData[]) || [];
                const result = newData.map((item: TCandidateTestingData) => {
                    item.uuid = uuid();
                    return item;
                });
                setCandidateList(result || []);
                const myCadidate = await getMyCandidateDetails(id);
                setMyCandidate(myCadidate.data);
            } 
            catch (error) {
                console.warn(error);
                navigate({
                    pathname: `/my-candidate`,
                });
            }
            finally {
                dispatch(setLoading(false));
            }
        }
    }, [dispatch, id, mode, navigate]);

    const loadListIdsByAssessmentId = React.useCallback(async () => {
        if (!assessmentId) return;
        try {
            dispatch(setLoading(true));
            const res = await getListIdsByAssessent(assessmentId);
            setCandidateIds(res.data?.testCandidateIds || []);
        } finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, assessmentId]);

    React.useEffect(() => {
        if (!id || !assessmentId) return;
        loadCandidateDetails();
    }, [id, assessmentId]);

    React.useEffect(() => {
        if (assessmentId) {
            loadListIdsByAssessmentId();
        }
    }, []);

    React.useEffect(() => {
        if (id && mode === 'candidate') {
            loadTestsCandidate();
            loadOtherAssessment(id);
        }
    }, []);

    const onRejectCandidate = React.useCallback(async () => {
        try {
            setShowRejectModal(false);
            dispatch(setLoading(true));
            candidateSelected?._id && await rejectCandidate(candidateSelected?._id);
            dispatch(setLoading(false));
            showSuccess('Đã từ chối ứng viên');
        } catch (error: any) {
            dispatch(setLoading(false));
            console.log(error);
            showError("Error", error?.response?.data?.errorMessage || error?.response?.statusText);
        }
    }, [candidateSelected?._id, dispatch, showError, showSuccess]);

    const onSendResultCandidate = React.useCallback(async () => {
        try {
            dispatch(setLoading(true));
            candidateSelected?._id && await sendResultCandidate(candidateSelected?._id);
            dispatch(setLoading(false));
            showSuccess('Đã gửi kết quả');
        } catch (error: any) {
            dispatch(setLoading(false));
            showError("Error", error?.response?.data?.errorMessage || error?.response?.statusText);
        }
    }, [dispatch, candidateSelected?._id, showSuccess, showError]);

    const onSaveQuestionNote = React.useCallback(async () => {
        try {
            setShowQuestionModal(false);
            dispatch(setLoading(true));
            const payload: TSaveNote = {
                note: questionNote,
                rating: questionRate
            }
            candidateSelected?._id && await saveQuestionNote(candidateSelected?._id, question?._id, payload);
            if (mode === 'assessment') {
                const res = await getTestCandidateById(id);
                const newData = res.data as TCandidateTestingData;
                newData.uuid = uuid();
                setCandidateList([res.data]);
            } else {
                const candidates = await getMyTestCandidateId(id);
                const newData = (candidates.data as TCandidateTestingData[]) || [];
                const result = newData.map((item: TCandidateTestingData) => {
                    item.uuid = uuid();
                    return item;
                });
                setCandidateList(result || []);
            }
            // TODO
            // setCandidateList((prev) => {
            //     return prev?.map((item) => {
            //         if (item?._id === candidateSelected?._id && item?.uuid) {
            //             item.uuid = uuid();
            //             const newTests = item?.tests?.map((test) => {
            //                 if (test?.type === 'additional_question') {
            //                     test.sections?.map((section) => {
            //                         return section.questions?.map((q) => {
            //                             if (q?._id === question?._id) {
            //                                 return {
            //                                     ...question,
            //                                     note: questionNote,
            //                                     rating: questionRate
            //                                 }
            //                             }
            //                             return {...question};
            //                         })
            //                     })
            //                 }
            //                 return {...test};
            //             });
            //             return {
            //                 ...item,
            //                 tests: newTests
            //             }
            //         }
            //         return {...item};
            //     });
            // });
            dispatch(setLoading(false));
            showSuccess('Đã lưu ghi chú');
        } catch (error) {
            dispatch(setLoading(false));
            showError('Error', 'Có lỗi xảy ra');
        }
    }, [dispatch, candidateSelected?._id, questionNote, id, question, questionRate, showSuccess, showError, mode]);

    return <CandidateDetailsDataContext.Provider
        value={{
            id,
            mode,
            assessmentId,
            candidateIds,
            otherAssessments,
            setOtherAssessments,
            setShowConfirmSendResult,
            setShowRejectModal,
            setShowQuestionModal,
            setQuestion,
            setCandidateSelected,
            setOpenDrawer,
            setDataDrawSelected,
            setCandidateList
        }}
    >
        <div className="container mx-auto pt-[40px] pb-[40px]">
            <div className="flex flex-col gap-[50px] w-full ">
                {candidateList?.length > 0 && <HeaderCandidateDetails 
                    candidateDetails={candidateList[0]} 
                    myCandidate={myCandidate} 
                />}
                <div className="flex flex-col gap-[56px] pt-[115px]">
                    {
                        candidateList?.length ? candidateList?.map((item) => {
                            return <CandidateDetails
                                key={item?.uuid || item?._id}
                                candidateId={item?._id as string}
                                candidateDetails={item}
                                tests={item?.tests}
                                setShowConfirmSendResult={setShowConfirmSendResult}
                                setShowRejectModal={setShowRejectModal}
                                setShowQuestionModal={setShowQuestionModal}
                                setQuestion={setQuestion}
                                setCandidateSelected={setCandidateSelected}
                                setCandidateList={setCandidateList}
                            />
                        }
                        ) : null
                    }
                </div>
            </div>

            <Drawer
                title={
                    <div className="text-title-medium text-primary-bold">
                        {dataDrawSelected?.title}
                    </div>
                }
                width={622}
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                closable={false}
                bodyStyle={{ padding: '0 24px 24px 24px', gap: '24px' }}
                headerStyle={{ border: '0', padding: '24px'}}
                extra={
                    <div>
                        <IconButton btnType="sub" btnSize="sm" 
                            onClick={() => setOpenDrawer(false)}
                            style={{
                                width: 44,
                                height: 44,
                                borderRadius: '144px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                boxShadow: '0px 1px 2px -1px rgba(17, 12, 34, 0.08)',
                                boxSizing: 'border-box',
                                overflow: 'hidden',
                                border: '1px solid #ECECED'
                            }}
                            icon={<div><ICClose fill="#4F4B5C" width={24} height={24} /></div>}
                        />
                    </div>
                }
            >
                <div className="flex flex-col gap-[24px]">
                    {dataDrawSelected?.subTitle && <div className="flex flex-col gap-[24px]">
                        <div className="flex text-body text-high-em">Cấp độ tương ứng với khoảng điểm:</div>
                        <div className="flex flex-col w-full gap-[8px]">
                            <div className="flex">
                                <div className="flex w-1/2 h-[4px] bg-surface-warning-accent-2"></div>
                                <div className="flex w-[30%] h-[4px] bg-system-success-300"></div>
                                <div className="flex w-[10%] h-[4px] bg-system-error-300"></div>
                                <div className="flex w-[10%] h-[4px] bg-primary"></div>
                            </div>
                            <div className="flex text-sub-medium">
                                <div className="flex w-1/2 text-warning">0</div>
                                <div className="flex w-[30%] text-success-500">50</div>
                                <div className="flex w-[10%] text-error-500">80</div>
                                <div className="flex w-[10%] text-primary justify-between items-center">
                                    <span>90</span>
                                    <span>100</span>
                                </div>
                            </div>
                        </div>
                        <div className="flex">
                            <div className="flex flex-flex-row gap-[32px]">
                                {dataDrawSelected?.subTitle?.map((item, index) => {
                                    return <div className="flex flex-col gap-[8px]" key={index}>
                                        <div className="flex gap-[8px]">
                                            <div className="flex flex-col gap-[8px] justify-center items-center">
                                                <div className={classNames(
                                                    "h-[9px] w-[9px] rounded-[144px]",
                                                    {
                                                        "bg-system-warning-100": index === 0,
                                                        "bg-system-success-300": index === 1,
                                                        "bg-system-error-300": index === 2,
                                                        "bg-primary": index === 3,
                                                    }
                                                )}>
                                                </div>
                                            </div>
                                            <div className="flex text-body-medium text-high-em">
                                                {item}
                                            </div>
                                        </div>
                                    </div>
                                })}
                            </div>

                        </div>
                    </div>}
                    <div className="flex flex-col gap-[24px]">
                        {dataDrawSelected?.data?.map((item, index) => {
                            return <div className="flex flex-col p-[16px] gap-[8px] bg-surface-low rounded-[8px]" key={item?.id}>
                                <div className="flex flex-col gap-[8px]">
                                    <div className="flex">
                                        <div className={classNames(
                                            "text-sub-medium px-[8px] py-[4px] rounded-[4px] max-w-[200px]",
                                            {
                                                "bg-primary text-white": index === 0,
                                                "bg-system-error-300 text-white": index === 1,
                                                "bg-system-success-300 text-white": index === 2,
                                                "bg-system-warning-100 text-high-em": index === 3,
                                            }
                                        )}>
                                            {item?.title}
                                        </div>
                                    </div>
                                </div>
                                <div className="content flex flex-col gap-[5px] text-body text-high-em">
                                    <div className="flex">{item?.content}</div>
                                    <div className="flex pl-[30px]">
                                        <ul className="list-disc">
                                            {item?.subContent?.map((li, index) => {
                                                return <li key={index}>
                                                    <div className="flex">
                                                        {li}
                                                    </div>
                                                </li>
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        })
                    }
                    </div>
                </div>
            </Drawer>
        </div>
        <Modal
            centered
            onCancel={() => setShowConfirmSendResult(false)}
            open={showConfirmSendResult}
            title={
                <div className="font-bold text-[18px]">Gửi kết quả cho ứng viên</div>
            }
            footer={
                <div className="grid grid-cols-[auto_auto] justify-end gap-[16px]">
                    <HRButton
                        btnType="sub"
                        btnSize="sm"
                        onClick={() => setShowConfirmSendResult(false)}>
                        Hủy bỏ
                    </HRButton>
                    <HRButton
                        btnSize="sm"
                        onClick={() => {
                            onSendResultCandidate();
                            setShowConfirmSendResult(false);
                        }}>
                        Gửi
                    </HRButton>
                </div>
            }>
            <p>Gửi kết quả cho ứng viên thông qua email</p>
        </Modal>
        <Modal
            centered
            onCancel={() => setShowRejectModal(false)}
            open={showRejectModal}
            title={
                <div className="font-bold text-[18px]">Từ chối ứng viên</div>
            }
            footer={
                <div className="grid grid-cols-[auto_auto] justify-end gap-[16px]">
                    <HRButton
                        btnSize="sm"
                        btnType="secondary"
                        onClick={() => setShowRejectModal(false)}>
                        Hủy bỏ
                    </HRButton>
                    <HRButton
                        btnSize="sm"
                        onClick={onRejectCandidate}>
                        Xác nhận
                    </HRButton>
                </div>
            }>
            <p>Chúng tôi sẽ gửi thông báo cho ứng viên rằng họ không đủ điều kiện để thực hiện bài đánh giá.</p>
        </Modal>
        <Modal
            centered
            width={1000}
            onCancel={() => setShowQuestionModal(false)}
            open={showQuestionModal}
            title={null}
            footer={
                <div className="flex justify-end gap-[16px]">
                    <span>
                        <HRButton
                            btnType="sub"
                            btnSize="sm"
                            onClick={() => setShowQuestionModal(false)}>
                            <div className="flex items-center space-x-2 justify-center">
                                <span>
                                    <ICClose fill="#2E293D" width={24} height={24} />
                                </span>
                                <span>Hủy bỏ</span>
                            </div>
                        </HRButton>
                    </span>
                    <span>
                        <HRButton
                            btnSize="sm"
                            onClick={onSaveQuestionNote}>
                            <div className="flex space-x-2 items-center justify-center">
                                <span>
                                    <ICSave />
                                </span>
                                <span>Lưu đánh giá</span>
                            </div>
                        </HRButton>
                    </span>
                </div>
            }>
            <ViewQuestionWrapper
                question={question}
                questionNote={questionNote}
                setQuestionNote={setQuestionNote}
                questionRate={questionRate}
                setQuestionRate={setQuestionRate}
            />
        </Modal>
    </CandidateDetailsDataContext.Provider>
}
