import React from 'react';
import { Modal, Spin, Table, Tabs, Tag } from 'antd';
import { ECandidateAssessmenStatus, TCandidateTestingData, TCheatingLog } from '../../testing/type';
import { ApiInstance } from '../../../api';
import { Loading } from '../../../components/loading';
import { ScreenRecord } from './screen-record';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../redux/slices/appInfo';
import { HRButton } from '../../../components/button';
import { formatLocaleDateTimeString } from '../../../utils/date';
const StatusAntiCheatColorMapping: Record<string, string> = {
    'N/A': '#B3B1B8',
    'Có': '#0BAA60',
    'Không': '#F03D3D',
};
type AntiCheatTabProps = {
    candidateDetails: TCandidateTestingData;
}
export const AntiCheatTab: React.FC<AntiCheatTabProps> = ({
    candidateDetails
}) => {
    return <Tabs
        defaultActiveKey="1"
        items={[
            {
                label: 'Tổng quan',
                key: '1',
                children: <AntiCheatGeneral candidateDetails={candidateDetails} />,
            },
            {
                label: 'Screen Record',
                key: '2',
                children: <AntiCheatScreenRecord candidateDetails={candidateDetails} />,
            },
            {
                label: 'Camera Record',
                key: '3',
                children: <AntiCheatCameraRecord candidateDetails={candidateDetails} />,
            },
        ]}
    />
}

export type AntiCheatGeneralProps = {
    candidateDetails: TCandidateTestingData;
}
const AntiCheatGeneral: React.FC<AntiCheatGeneralProps> = ({
    candidateDetails
}) => {
    const [isOpenChangeTabModal, setIsOpenChangeTabModal] = React.useState<boolean>(false);
    return <div className="grid sm:max-sm:grid-cols-1 xs:max-xs:grid-cols-1 grid-cols-[auto_1fr] gap-[56px]">
        <div className="flex flex-col">
            <div className="flex flex-row gap-[24px]">
                <div className="flex flex-col gap-[24px] items-start">
                    <span className="text-med-em text-body">Thiết bị sử dụng</span>
                    <span className="text-med-em text-body min-w-[100px]">Vị trí</span>
                </div>
                <div className="flex flex-col gap-[24px]">
                    <div>
                        {Array.isArray(candidateDetails?.device) && candidateDetails?.device?.length > 0 && <span className="text-high-em text-body-medium flex flex-col">
                            {
                                candidateDetails?.device.map((item, index) => {
                                    return <span key={index}>{item}</span>
                                })
                            }
                        </span>}
                        {candidateDetails?.device?.length === 0 && <span className="text-high-em text-body-medium">
                            <Tag style={{
                                borderRadius: '4px',
                                fontSize: '12px',
                                fontWeight: 500,
                                lineHeight: '16px',
                                padding: '4px 8px',
                                margin: 0,
                                color: '#FFFFFF',
                                boxSizing: 'border-box',
                                backgroundColor: StatusAntiCheatColorMapping['N/A'],
                                borderColor: StatusAntiCheatColorMapping['N/A']
                            }}>
                                N/A
                            </Tag>
                        </span>}
                    </div>
                    <div>
                        {Array.isArray(candidateDetails?.location) && candidateDetails?.location?.length > 0 && <span className="text-high-em text-body-medium text-right flex flex-col">
                            {
                                candidateDetails?.location.map((item, index) => {
                                    return <span key={index}>{item}</span>
                                })
                            }
                        </span>}
                        {candidateDetails?.location?.length === 0 && <span className="text-high-em text-body-medium text-right">
                            <Tag style={{
                                borderRadius: '4px',
                                fontSize: '12px',
                                fontWeight: 500,
                                lineHeight: '16px',
                                padding: '4px 8px',
                                margin: 0,
                                color: '#FFFFFF',
                                backgroundColor: StatusAntiCheatColorMapping['N/A'],
                                borderColor: StatusAntiCheatColorMapping['N/A']
                            }}>
                                N/A
                            </Tag>
                        </span>}
                    </div>
                </div>
            </div>
        </div>

        <div className="flex flex-col">
            <div className="flex flex-row gap-[24px]">
                <div className="flex flex-col items-start gap-[24px]">
                    <span className="text-med-em text-body">Bật webcam?</span>
                    <span className="text-med-em text-body">
                        Đổi tab? {candidateDetails?.status !== ECandidateAssessmenStatus.INVITED && candidateDetails.cheatingLogs?.length > 0 &&
                            <span
                                className="text-[12px] text-primary hover:text-primary-dark hover:underline cursor-pointer"
                                onClick={() => {
                                    setIsOpenChangeTabModal(true);
                                }}
                            >Xem chi tiết</span>
                        }
                    </span>
                    <span className="text-med-em text-body">
                        Bật chế độ xem toàn màn hình?
                    </span>
                </div>
                <div className="flex flex-col gap-[24px]">
                    <div className="flex items-center justify-end">
                        <Tag style={{
                            borderRadius: '4px',
                            fontSize: '12px',
                            fontWeight: 500,
                            lineHeight: '16px',
                            padding: '4px 8px',
                            margin: 0,
                            color: '#FFFFFF',
                            boxSizing: 'border-box',
                            backgroundColor: StatusAntiCheatColorMapping[candidateDetails?.status === ECandidateAssessmenStatus.INVITED ? 'N/A' : (candidateDetails?.turnOnCamera ? 'Có' : 'Không')],
                            borderColor: StatusAntiCheatColorMapping[candidateDetails?.status === ECandidateAssessmenStatus.INVITED ? 'N/A' : (candidateDetails?.turnOnCamera ? 'Có' : 'Không')]
                        }}>
                            {candidateDetails?.status === ECandidateAssessmenStatus.INVITED ? 'N/A' : (candidateDetails?.turnOnCamera ? 'Có' : 'Không')}
                        </Tag>
                    </div>
                    <div className="flex items-center justify-end">
                        <Tag style={{
                            borderRadius: '4px',
                            fontSize: '12px',
                            fontWeight: 500,
                            lineHeight: '16px',
                            padding: '4px 8px',
                            margin: 0,
                            color: '#FFFFFF',
                            backgroundColor: StatusAntiCheatColorMapping[candidateDetails?.status === ECandidateAssessmenStatus.INVITED ? 'N/A' : (candidateDetails?.changeTab ? 'Có' : 'Không')],
                            borderColor: StatusAntiCheatColorMapping[candidateDetails?.status === ECandidateAssessmenStatus.INVITED ? 'N/A' : (candidateDetails?.changeTab ? 'Có' : 'Không')]
                        }}>
                            {candidateDetails?.status === ECandidateAssessmenStatus.INVITED ? 'N/A' : (candidateDetails?.changeTab ? 'Có' : 'Không')}
                        </Tag>
                    </div>
                    <div className="flex items-center justify-end">
                        <Tag style={{
                            borderRadius: '4px',
                            fontSize: '12px',
                            fontWeight: 500,
                            lineHeight: '16px',
                            padding: '4px 8px',
                            margin: 0,
                            color: '#FFFFFF',
                            backgroundColor: StatusAntiCheatColorMapping[candidateDetails?.status === ECandidateAssessmenStatus.INVITED ? 'N/A' : (candidateDetails?.exitFullScreenMode ? 'Không' : 'Có')],
                            borderColor: StatusAntiCheatColorMapping[candidateDetails?.status === ECandidateAssessmenStatus.INVITED ? 'N/A' : (candidateDetails?.exitFullScreenMode ? 'Không' : 'Có')]
                        }}>
                            {candidateDetails?.status === ECandidateAssessmenStatus.INVITED ? 'N/A' : (candidateDetails?.exitFullScreenMode ? 'Không' : 'Có')}
                        </Tag>
                    </div>
                </div>
            </div>
        </div>
        <ChangeTabLogModal
            logs={candidateDetails.cheatingLogs || []}
            isOpen={isOpenChangeTabModal}
            onClose={() => {
                setIsOpenChangeTabModal(false);
            }}
        />
    </div>
}

export type AntiCheatScreenRecordProps = {
    candidateDetails: TCandidateTestingData;
}
const AntiCheatScreenRecord: React.FC<AntiCheatScreenRecordProps> = ({
    candidateDetails
}) => {
    const [records, setRecords] = React.useState<{
        sessionId: string;
        startAt: number;
        files: string[];
    }[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    React.useEffect(() => {
        if (!candidateDetails?.id) return;
        setIsLoading(true);
        ApiInstance.getInstance().get(`/api/test-candidate/session-record/${candidateDetails?.id}`).then(res => {
            setRecords(res.data);
        }).finally(() => {
            setIsLoading(false);
        });
    }, []);
    return <div className='min-h-[75.2px]'>
        {
            isLoading
                ? <div className='w-full h-full flex items-center justify-center min-h-[75.2px] pt-6'>
                    <Spin />
                </div>
                : <div>
                    {
                        records.length === 0
                            ? <div className='px-1'>Hiện tại chưa có dữ liệu screen record của ứng viên</div>
                            : <div className='grid grid-cols-4 mt-2 gap-2'>
                                {
                                    records.map((item) => <ScreenRecord
                                        key={item.sessionId}
                                        sessionId={item.sessionId}
                                        startAt={item.startAt}
                                        candidateDetails={candidateDetails}
                                        files={item.files}
                                    />)
                                }
                            </div>
                    }
                </div>
        }
    </div>
}

const useGetDriveShareLink = (candidateDetails: TCandidateTestingData) => {
    const getDriveShareLink = React.useCallback(async () => {
        const data = await ApiInstance.getInstance().post(`/api/test-candidate/get-drive-share-link/${candidateDetails.id}`).then(res => res.data);
        return data;
    }, [candidateDetails]);
    return getDriveShareLink;
}

export const AntiCheatCameraRecord: React.FC<AntiCheatScreenRecordProps> = ({
    candidateDetails
}) => {
    const getDriveShareLink = useGetDriveShareLink(candidateDetails);
    const dispatch = useDispatch();
    const onSeeCameraRecord = React.useCallback(async () => {
        dispatch(setLoading(true));
        if (candidateDetails.ggDriveShareLink) {
            // Timeout fake loading
            setTimeout(() => {
                window.open(candidateDetails.ggDriveShareLink, '_blank');
                dispatch(setLoading(false));
            }, 200);
        } else {
            try {
                const link = await getDriveShareLink();
                window.open(link, '_blank');
            } catch (err) {
                alert('Lỗi lấy link google drive');
            } finally {
                dispatch(setLoading(false));
            }
        }
    }, [candidateDetails, getDriveShareLink]);
    return <div className='min-h-[75.2px]'>
        {
            !candidateDetails.ggDriveFolderId
                ? <div className='px-1'>Hiện tại chưa có dữ liệu cemera record của ứng viên</div>
                : <div
                    onClick={onSeeCameraRecord}
                    className='px-1 cursor-pointer text-primary hover:text-primary-dark hover:underline'
                >
                    Xem thông tin camera record tại đây
                </div>
        }
    </div>
}

type ChangeTabLogModalProps = {
    logs: TCheatingLog[];
    isOpen: boolean;
    onClose: () => void;
}
const ChangeTabLogModal: React.FC<ChangeTabLogModalProps> = ({
    logs,
    isOpen,
    onClose
}) => {
    return <Modal
        centered
        onCancel={() => onClose()}
        open={isOpen}
        width={600}
        title={
            <div className="flex justify-between items-center pr-[32px]">
                <div className="text-title-medium text-primary">
                    Chi tiết thay đổi tab
                </div>
            </div>
        }
        footer={
            <div className="grid grid-cols-[auto_auto] justify-end gap-[16px]">
                <HRButton
                    btnType="sub"
                    btnSize="sm"
                    onClick={onClose}>
                    Đóng
                </HRButton>
            </div>
        }>
        <div
            className="max-h-[500px] overflow-y-auto"
        >
            <Table
                pagination={false}
                columns={[
                    {
                        title: 'STT',
                        dataIndex: 'stt',
                        key: 'stt',
                        render: (value, record, index) => {
                            return index + 1;
                        }
                    },
                    {
                        title: 'Thời gian',
                        dataIndex: 'at',
                        key: 'at',
                        render: (value) => {
                            return formatLocaleDateTimeString(value);
                        }
                    },
                    {
                        title: 'Hành vi',
                        dataIndex: 'type',
                        key: 'type',
                        render: (value) => {
                            return value === 'change_tab' ? 'Thay đổi tab' : 'Trở lại tab';
                        }
                    },
                ]}
                dataSource={logs}
            />
        </div>
    </Modal>
}