import { uuid } from "./helper";

type TBaseQuestion = {
    _id?: string;
    id: string; // FE only
    isExpand?: boolean; // FE only
    content: string;
    isActive: boolean;

    referenceContent?: string;
    comment?: string;

    audioUrl?: string;
    maximumPlayAudio?: number;
    audioTranscription?: string;
};
export const generateOption = (option: string) => ({ id: uuid(), content: option });
export type IndexedOption = {
    id: string;
    content: string;
}

export enum EQuestionType {
    SINGLE_CHOICE = 'single_choice',
    MULTIPLE_CHOICE = 'multiple_choice',
    CODING = 'coding',
    ESSAY = 'essay',
    GROUP = 'group',
    SPEAKING = 'speaking'
}

export const QuestionTypeMapping = {
    [EQuestionType.SINGLE_CHOICE]: 'Single Choice',
    [EQuestionType.MULTIPLE_CHOICE]: 'Multiple Choice',
    [EQuestionType.CODING]: 'Coding',
    [EQuestionType.ESSAY]: 'Tự luận',
    [EQuestionType.GROUP]: 'Nhóm câu hỏi',
    [EQuestionType.SPEAKING]: 'Speaking'
};

export enum ESkillAreaType {
    STANDARD = 'standard',
    ATTEAM = 'atteam',
};

export const SkillAreaTypeOptions = [
    { label: 'STANDARD' , value: ESkillAreaType.STANDARD },
    { label: 'AT TEAM', value: ESkillAreaType.ATTEAM },
];

export type TChoiceQuestionType = EQuestionType.SINGLE_CHOICE | EQuestionType.MULTIPLE_CHOICE | EQuestionType.ESSAY;


export type TSingleChoiceQuestion = TBaseQuestion & {
    type: EQuestionType.SINGLE_CHOICE;
    options: IndexedOption[];
    correctAnswer: number;
    positionLockedOptions?: number[];
    isMixed: boolean;
    uuid?: string;
    answer?: string;

    weight?: number;
    scoreCombination: Record<string, number>;
};

export type TMultipleChoiceQuestion = TBaseQuestion & {
    type: EQuestionType.MULTIPLE_CHOICE;
    options: IndexedOption[];
    correctAnswer: number[];
    positionLockedOptions?: number[];
    isMixed: boolean;
    answer?: string;

    uuid?: string;
    weight?: number;
    scoreCombination: Record<string, number[]>;
};

export type TEssayChoiceQuestion = TBaseQuestion & {
    type: EQuestionType.ESSAY;

    options: IndexedOption[];
    correctAnswer: number | number[];
    positionLockedOptions?: number[];
    isMixed: boolean;
    answer?: string;

    weight?: number;
    scoreCombination: Record<string, number[][]>;

    uuid?: string;
    referenceContent: string;
};

export type TCodingQuestion = TBaseQuestion & {
    type: EQuestionType.CODING;
    testCases: string[];
    expectedOutputs: string[];

    score: number;
    weight?: number;
    uuid?: string;
};
export type TGroupQuestion = TBaseQuestion & {
    type: EQuestionType.GROUP;
    // TODO: add group question type
    questions: TEssayChoiceQuestion[];
    uuid?: string;

    weight?: number;
}
export type TSpeakingQuestion = TBaseQuestion & {
    type: EQuestionType.SPEAKING;

    recordFileUrl?: string;

    uuid?: string;

    weight?: number;
}
export type TChoiceQuestion = TSingleChoiceQuestion | TMultipleChoiceQuestion | TEssayChoiceQuestion;
export type TQuestion = TSingleChoiceQuestion | TMultipleChoiceQuestion | TCodingQuestion | TEssayChoiceQuestion | TGroupQuestion | TSpeakingQuestion;

type TSerializedSingleChoiceQuestion = Omit<TChoiceQuestion, "options"> & {
    options: string[];
}
export type TSerializedQuestion = TSerializedSingleChoiceQuestion | TCodingQuestion;
export const indexQuestion = (q: any): TQuestion => {
    return {
        ...q,
        uuid: uuid(),
        options: q.options?.map(generateOption)
    }
}
export const formatQuestion = (question: TQuestion): any => {
    if(question.type === EQuestionType.CODING) return question;
    if (question.type === EQuestionType.GROUP) {
        return {
            ...question,
            questions: question.questions.map(formatQuestion),
        }
    }
    if(question.type === EQuestionType.SPEAKING) {
        return {
            ...question
        }
    }
    return {
        ...question,
        options: question.options ? question.options?.map((option) => option?.content) : [],
    }
}

export type TSkillArea = {
    name: string;
    description: string | null;
    questions: TQuestion[];
    hasQuestionWeight?: boolean;
    
    type?: ESkillAreaType;

    value?: any; // move action
    label?: string; // move action
    source?: any; // move action
};

export type QuestionType = 'sample' | 'group';

export const createDefaultQuestion = (type: TQuestion['type']): TQuestion => {
    switch (type) {
        case EQuestionType.SINGLE_CHOICE:
            return {
                id: uuid(),
                type,
                content: '',
                isMixed: false,
                isActive: true,
                options: new Array(4).fill('').map(() => generateOption('')),
                correctAnswer: 0,
                positionLockedOptions: [],
                scoreCombination: {},
                weight: 1,
            };
        case EQuestionType.MULTIPLE_CHOICE:
            return {
                id: uuid(),
                type,
                content: '',
                isMixed: false,
                isActive: true,
                options: new Array(4).fill('').map(() => generateOption('')),
                correctAnswer: [0],
                positionLockedOptions: [],
                scoreCombination: {},
                weight: 1,
            };
        case EQuestionType.ESSAY:
            return {
                id: uuid(),
                type,
                content: '',
                isMixed: false,
                isActive: true,
                options: new Array(4).fill('').map(() => generateOption('')),
                positionLockedOptions: [],
                correctAnswer: 0,
                referenceContent: '',
                scoreCombination: {},
                weight: 1,
            };
        case EQuestionType.CODING:
            return {
                id: uuid(),
                type,
                content: '',
                testCases: new Array(2).fill('').map(() => ''),
                expectedOutputs: new Array(2).fill('').map(() => ''),
                score: 0,
                isActive: true,
                weight: 1,
            };
        case EQuestionType.GROUP:
            return {
                id: uuid(),
                type,
                content: '',
                isActive: true,
                questions: [],
            };
        case EQuestionType.SPEAKING:
            return {
                id: uuid(),
                type,
                content: '',
                isActive: true,
                weight: 1,
            }
    }
}
