import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import { IconProps } from './type';

const SendSVG: React.FC = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 3.91988L15 20.6699C14.965 20.7665 14.9012 20.85 14.8171 20.9091C14.733 20.9681 14.6327 20.9998 14.53 20.9999H14.37C14.2738 20.9999 14.1795 20.9726 14.0981 20.9213C14.0167 20.87 13.9515 20.7967 13.91 20.7099L11.55 15.7099C11.3801 15.3495 11.3217 14.9467 11.3823 14.5529C11.4429 14.1592 11.6197 13.7925 11.89 13.4999L14.89 10.2199C14.9786 10.1269 15.0281 10.0034 15.0281 9.87488C15.0281 9.74641 14.9786 9.62287 14.89 9.52988L14.51 9.14988C14.417 9.06124 14.2935 9.01179 14.165 9.01179C14.0365 9.01179 13.913 9.06124 13.82 9.14988L10.54 12.1499C10.2474 12.4202 9.88068 12.597 9.48694 12.6576C9.09319 12.7181 8.69033 12.6598 8.32999 12.4899L3.32999 10.1299C3.23569 10.0944 3.15403 10.0317 3.0953 9.94987C3.03657 9.86801 3.0034 9.77058 2.99999 9.66988V9.50988C2.99137 9.40043 3.01902 9.29119 3.07866 9.19902C3.1383 9.10685 3.22662 9.03687 3.32999 8.99988L20.08 2.99988C20.1682 2.96748 20.2638 2.96061 20.3558 2.98006C20.4478 2.99952 20.5324 3.04451 20.6 3.10988L20.86 3.36988C20.9363 3.43656 20.9906 3.52487 21.0156 3.62309C21.0406 3.72132 21.0351 3.82482 21 3.91988Z" fill="currentColor" />
        </svg>
    )
}

export const ICSend = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={SendSVG} {...props} />
);

export const ICOutlineSend: React.FC = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M22.7071 1.29292C22.9306 1.5164 23.0262 1.81935 22.9939 2.11081C22.9848 2.19252 22.9657 2.27332 22.9366 2.35121L15.9439 22.3304C15.8084 22.7174 15.4504 22.9825 15.0408 22.9992C14.6311 23.0159 14.2527 22.7808 14.0862 22.4062L10.2424 13.7576L1.59387 9.91384C1.21919 9.74731 0.984122 9.36894 1.00084 8.95926C1.01755 8.54959 1.28265 8.19162 1.66965 8.05617L21.6488 1.06348C21.7272 1.03414 21.8085 1.01497 21.8907 1.00598C21.9511 0.999338 22.0117 0.998262 22.0717 1.00259C22.3032 1.01913 22.5301 1.11591 22.7071 1.29292ZM18.1943 4.3915L4.71108 9.11063L10.7785 11.8073L18.1943 4.3915ZM12.1928 13.2215L19.6085 5.80571L14.8894 19.289L12.1928 13.2215Z" fill="#4F4B5C" />
        </svg>
    )
}

export const ICSendV2: React.FC<IconProps> = ({
    height = 24,
    width = 24,
    fill = "var(--icon-high-em)",
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 3.91988L15 20.6699C14.965 20.7665 14.9012 20.85 14.8171 20.9091C14.733 20.9681 14.6327 20.9998 14.53 20.9999H14.37C14.2738 20.9999 14.1795 20.9726 14.0981 20.9213C14.0167 20.87 13.9515 20.7967 13.91 20.7099L11.55 15.7099C11.3801 15.3495 11.3217 14.9467 11.3823 14.5529C11.4429 14.1592 11.6197 13.7925 11.89 13.4999L14.89 10.2199C14.9786 10.1269 15.0281 10.0034 15.0281 9.87488C15.0281 9.74641 14.9786 9.62287 14.89 9.52988L14.51 9.14988C14.417 9.06124 14.2935 9.01179 14.165 9.01179C14.0365 9.01179 13.913 9.06124 13.82 9.14988L10.54 12.1499C10.2474 12.4202 9.88068 12.597 9.48694 12.6576C9.09319 12.7181 8.69033 12.6598 8.32999 12.4899L3.32999 10.1299C3.23569 10.0944 3.15403 10.0317 3.0953 9.94987C3.03657 9.86801 3.0034 9.77058 2.99999 9.66988V9.50988C2.99137 9.40043 3.01902 9.29119 3.07866 9.19902C3.1383 9.10685 3.22662 9.03687 3.32999 8.99988L20.08 2.99988C20.1682 2.96748 20.2638 2.96061 20.3558 2.98006C20.4478 2.99952 20.5324 3.04451 20.6 3.10988L20.86 3.36988C20.9363 3.43656 20.9906 3.52487 21.0156 3.62309C21.0406 3.72132 21.0351 3.82482 21 3.91988Z" fill={fill} />
        </svg>
    )
}

export const ICSendV3: React.FC<IconProps> = ({
    fill = "var(--icon-high-em)",
    height = 24,
    width = 24,
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.9996 3.92L14.9996 20.67C14.9647 20.7666 14.9008 20.8502 14.8167 20.9092C14.7326 20.9683 14.6323 21 14.5296 21H14.3696C14.2734 21 14.1791 20.9727 14.0977 20.9214C14.0163 20.8701 13.9511 20.7968 13.9096 20.71L11.5496 15.71C11.3797 15.3497 11.3213 14.9468 11.3819 14.5531C11.4425 14.1593 11.6193 13.7926 11.8896 13.5L14.8896 10.22C14.9782 10.127 15.0277 10.0035 15.0277 9.875C15.0277 9.74653 14.9782 9.62299 14.8896 9.53L14.5096 9.15C14.4166 9.06136 14.2931 9.01191 14.1646 9.01191C14.0361 9.01191 13.9126 9.06136 13.8196 9.15L10.5396 12.15C10.247 12.4203 9.88029 12.5971 9.48654 12.6577C9.0928 12.7183 8.68993 12.6599 8.32959 12.49L3.32959 10.13C3.2353 10.0945 3.15363 10.0319 3.0949 9.95C3.03617 9.86813 3.003 9.7707 2.99959 9.67V9.51C2.99097 9.40056 3.01862 9.29132 3.07826 9.19914C3.1379 9.10697 3.22622 9.03699 3.32959 9L20.0796 3C20.1678 2.9676 20.2634 2.96073 20.3554 2.98019C20.4474 2.99964 20.532 3.04464 20.5996 3.11L20.8596 3.37C20.9359 3.43669 20.9902 3.52499 21.0152 3.62321C21.0402 3.72144 21.0347 3.82494 20.9996 3.92Z" fill={fill}/>
        </svg>
    )
}

export const ICSendV4: React.FC = () => {
    return (
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="56" height="56" rx="28" fill="url(#paint0_linear_9252_67365)"/>
            <path d="M36.5311 19.7465L36.5294 19.7514L30.5299 36.5H30.3702C30.3683 36.5 30.3664 36.4995 30.3649 36.4985C30.3633 36.4975 30.3621 36.4962 30.3613 36.4946C30.3612 36.4945 30.3612 36.4944 30.3612 36.4943L28.0023 31.4968C27.8749 31.2265 27.8311 30.9244 27.8766 30.6291C27.922 30.3338 28.0546 30.0588 28.2573 29.8393L28.259 29.8375L31.2561 26.5606C31.4308 26.3752 31.5282 26.1299 31.5282 25.875C31.5282 25.6181 31.4293 25.371 31.252 25.185L31.2521 25.1849L31.2436 25.1764L30.8636 24.7964L30.8637 24.7963L30.8551 24.7881C30.6691 24.6108 30.422 24.5119 30.1651 24.5119C29.9102 24.5119 29.6649 24.6093 29.4795 24.7839L26.2026 27.781L26.2008 27.7827C25.9813 27.9855 25.7063 28.1181 25.411 28.1635C25.1157 28.2089 24.8135 28.1651 24.5433 28.0377L19.5435 25.6778L19.5252 25.6692L19.5063 25.6621C19.5044 25.6614 19.5028 25.6601 19.5017 25.6585C19.5009 25.6575 19.5004 25.6563 19.5001 25.6551V25.51V25.4904L19.4985 25.4708L19.4987 25.4707L36.2487 19.4707L36.2487 19.4707L36.2524 19.4694L36.5065 19.7236L36.5184 19.7355L36.5311 19.7465Z" fill="white" stroke="white"/>
            <defs>
            <linearGradient id="paint0_linear_9252_67365" x1="0" y1="28" x2="56.175" y2="28" gradientUnits="userSpaceOnUse">
            <stop stopColor="#553ADE"/>
            <stop offset="1" stopColor="#7357FF"/>
            </linearGradient>
            </defs>
        </svg>

    )
}
