import { HRButton } from "../../components/button"
import { ICClose } from "../../icons"

export const PrivacyPolicyPage: React.FC = () => {
    return (
        <div className="flex items-center justify-center w-full relative pt-[40px] pb-[40px] bg-surface-low">
            <div className="container mx-auto flex flex-col bg-white rounded-[12px] gap-[32px] shadow-e-03 py-[54px] px-[32px] relative">
                <div className="flex items-center justify-end">
                    <span>
                        <HRButton btnType="secondary" btnSize="sm"
                            onClick={() => {
                                // close window
                                window.close();
                            }}
                            >
                            <div className="flex gap-[8px] items-center">
                                <ICClose fill="#7357FF" height={18} width={18} />
                                <span className="text-body-medium text-primary">Đóng</span>
                            </div>
                        </HRButton>
                    </span>
                </div>
                <div className="flex flex-col gap-[32px]">
                    <h2 className="text-heading-4-bold text-high-em mb-0 text-left">Điều khoản sử dụng</h2>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            GIỚI THIỆU
                        </h4>
                        <div className="flex flex-col gap-[8px]">
                            <span className="text-body text-high-em">
                                Chào mừng đến với phần mềm HR Product qua giao diện website và (trên điện thoại). Trước khi sử dụng phần mềm HR Product hoặc tạo tài khoản  (“Tài khoản”), vui lòng đọc kỹ các Điều khoản sử dụng dưới đây và quy chế hoạt động để hiểu rõ hơn quyền lợi và nghĩa vụ hợp pháp của mình đối với các Điều khoản Sử dụng này (“Điều khoản”, “Điều khoản Sử dụng”) chi phối mối quan hệ giữa bạn và HR Product.
                            </span>
                            <span className="text-body text-high-em">
                                Các điều khoản sử dụng này được áp dụng cho khách truy cập, người dùng và những người khác truy cập hoặc sử dụng.
                            </span>
                            <span className="text-body text-high-em">
                                HR Product bảo lưu quyền thay đổi, chỉnh sửa, tạm ngưng hoặc chấm dứt tất cả hoặc bất kì phần nào của Trang HR Product hoặc Dịch vụ vào bất cứ thời điểm nào theo quy định của pháp luật. Phiên bản thử nghiệm của Dịch vụ có thể không hoàn toàn giống với phiên bản cuối cùng.
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            ĐIỀU KIỆN SỬ DỤNG
                        </h4>
                        <div className="flex flex-col gap-[8px] pl-[10px]">
                            <span className="text-body text-high-em">
                                (1) Để sử dụng dịch vụ bạn phải chắc chắn rằng bạn đã từ mười tám (18) tuổi trở lên và có thể thực hiện các nghĩa vụ hợp đồng một cách hợp pháp;
                            </span>
                            <span className="text-body text-high-em">
                                (2)	Hoàn tất thủ tục đăng ký;
                            </span>
                            <span className="text-body text-high-em">
                                (3)	Đồng ý với các điều khoản và chính sách;
                            </span>
                            <span className="text-body text-high-em">
                                (4)	Cung cấp thông tin liên hệ và thanh toán đúng, đầy đủ và cập nhập chính xác;
                            </span>
                            <span className="text-body text-high-em">
                                (5)	Việc bạn truy cập, tạo tài khoản và sử dụng dịch vụ tại HR Product đồng nghĩa với việc bạn đã chấp nhận và đồng ý tuân thủ các điều khoản này và các chính sách bổ sung. Nếu không đồng ý với bất kỳ phần nào của điều khoản thì bạn không thể truy cập và sử dụng dịch vụ của chúng tôi;
                            </span>
                        </div>
                    </div>

                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            QUY TẮC ỨNG XỬ
                        </h4>
                        <span>
                            Khi đồng ý với các Điều khoản này, bạn đồng ý rằng trong quá trình sử dụng Dịch vụ, bạn sẽ tuân theo các quy tắc sau:
                        </span>
                        <div className="flex flex-col gap-[8px] pl-[10px]">
                            <span className="text-body text-high-em">
                                (1)	Khi đồng ý với các Điều khoản này, bạn đồng ý rằng trong quá trình sử dụng Dịch vụ, bạn sẽ tuân theo các quy tắc sau: 
                            </span>
                            <span className="text-body text-high-em">
                                (2)	Không làm gì bất hợp pháp; 
                            </span>
                            <span className="text-body text-high-em">
                                (3)	Không tải lên, đăng, truyền tải hoặc bằng cách khác công khai bất cứ nội dung nào trái pháp luật, có hại, đe dọa, lạm dụng, quấy rối, gây hoang mang, lo lắng, xuyên tạc, phỉ báng, xúc phạm, khiêu dâm, bôi nhọ, xâm phạm quyền riêng tư của người khác, gây căm phẫn, hoặc phân biệt chủng tộc, dân tộc hoặc bất kỳ nội dung không đúng đắn nào khác; 
                            </span>
                            <span className="text-body text-high-em">
                                (4)	Không tham gia vào hoạt động gian lận, sai trái hoặc gây hiểu lầm; 
                            </span>
                            <span className="text-body text-high-em">
                                (5)	Không sử dụng ứng dụng, nội dung của ứng dụng khiến chúng tôi hoặc bất kỳ bên thứ ba nào bị mang tiếng khiến chúng tôi phải chịu trách nhiệm pháp lý với bất kỳ bên thứ ba nào;
                            </span>
                            <span className="text-body text-high-em">
                                (6)	Không đảo ngược kỹ thuật, dịch ngược, sao chép, sửa đổi, phân phối, truyền tải, cấp phép, cấp phép lại, hiển thị, sửa đổi, thực hiện, chuyển nhượng, bán hoặc cung cấp cho bất kỳ bên thứ ba. Nội dung của nó trừ khi được chúng tôi cho phép theo các Điều khoản này hoặc được quy định rõ ràng theo luật hiện hành; 
                            </span>
                            <span className="text-body text-high-em">
                                (7)	Không phá hủy bất kỳ hạn chế nào về quyền truy cập hoặc khả năng cung cấp Dịch vụ;
                            </span>
                            <span className="text-body text-high-em">
                                (8)	Không sử dụng hệ thống để phát tán virus;
                            </span>
                            <span className="text-body text-high-em">
                                (9)	Không thực hiện bất kỳ hành động nào làm sai lệch hệ thống đánh giá hoặc tiếp nhận phản hồi của HR product;
                            </span>
                            <span className="text-body text-high-em">
                                (10) Người dùng hoàn toàn chịu trách nhiệm về mọi chi phí và phí tổn thất mà có thể phải chịu liên quan đến việc sử dụng ứng dụng và sẽ tự chịu trách nhiệm về việc giữ bí mật mật khẩu và các chi tiết tài khoản.
                            </span>
                        </div>
                    </div>

                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            ĐĂNG KÝ
                        </h4>
                        <span className="text-body text-high-em">
                            Một số phần của Dịch vụ được lập hóa đơn trên cơ sở đăng ký (“(các) đăng ký”). Bạn sẽ được lập hóa đơn trước trên cơ sở định kỳ và định kỳ (“Chu kỳ thanh toán”). Chu kỳ thanh toán được đặt trên cơ sở hàng tháng, tùy thuộc vào loại gói đăng ký bạn chọn khi mua đăng ký. Số chu kỳ thanh toán tối thiểu có thể áp dụng cho một đăng ký.
                        </span>
                        <span className="text-body text-high-em">
                            Vào cuối mỗi chu kỳ thanh toán, gói đăng ký của bạn sẽ tự động gia hạn theo các điều kiện chính xác như nhau trừ khi bạn hủy đăng ký hoặc HR Product hủy đăng ký. Bạn có thể hủy gia hạn Đăng ký của mình thông qua trang cài đặt tài khoản trực tuyến của mình hoặc bằng cách liên hệ với nhóm hỗ trợ khách hàng của HR Product, có tính đến thời gian thông báo ít nhất 30 ngày.
                        </span>
                        <span className="text-body text-high-em">
                            Phương thức thanh toán hợp lệ, bao gồm thẻ tín dụng hoặc VNPAY và xuất hóa đơn điện tử, được yêu cầu để xử lý khoản thanh toán cho đăng ký của bạn. Bạn phải cung cấp cho HR Product thông tin thanh toán chính xác và đầy đủ bao gồm tên đầy đủ, địa chỉ, mã zip, quốc gia, số điện thoại và số thẻ tín dụng, số CVC. Bằng cách gửi thông tin thanh toán như vậy, bạn tự động ủy quyền cho HR Product tính tất cả các khoản phí đăng ký phát sinh thông qua tài khoản của bạn cho bất kỳ công cụ thanh toán nào như vậy.
                        </span>
                        <span className="text-body text-high-em">
                            Nếu thanh toán không xảy ra vấn đề gì, HR Product sẽ phát hành hóa đơn điện tử chỉ ra rằng bạn đã đăng ký thành công gói Dịch vụ vủa chúng tôi và thời hạn thanh toán  đã được nêu trên hóa đơn.
                        </span>
                    </div>

                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            GÓI MIỄN PHÍ
                        </h4>
                        <span className="text-body text-high-em">
                            Chúng tôi có cung cấp gói sử dụng miễn phí, do đó bạn sẽ không bị tính phí khi sử dụng nó.
                        </span>
                        <span className="text-body text-high-em">
                            Bất cứ lúc nào và không cần thông báo, chúng tôi có quyền sửa đổi các điều khoản sử dụng của ưu đãi gói Miễn phí hoặc hủy ưu đãi gói Miễn phí đó.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            THAY ĐỔI PHÍ
                        </h4>
                        <span className="text-body text-high-em">
                            Gói Premium có thể thay đổi phí bất cứ lúc nào theo quyết định của chúng tôi. Mọi thay đổi về phí đăng ký sẽ có hiệu lực vào cuối chu kỳ thanh toán hiện tại.
                        </span>
                        <span className="text-body text-high-em">
                            Chúng tôi sẽ thông báo trước cho bạn khi có bất kỳ thay đổi nào về phí đăng ký, để bạn có cơ hội thay đổi hoặc hủy trước khi có hiệu lực.
                        </span>
                        <span className="text-body text-high-em">
                            Việc bạn tiếp tục sử dụng dịch vụ sau khi thay đổi phí đăng ký có hiệu lực cấu thành đồng nghĩa với việc bạn đồng ý thanh toán số tiền phí đăng ký đã sửa đổi.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            CHÍNH SÁCH HOÀN LẠI TIỀN
                        </h4>
                        <span className="text-body text-high-em">
                            Kế hoạch thanh toán định kì sẽ được thực hiện vào ngày gia hạn. Các gói có thể bị hủy tại bất kỳ thời điểm nào và bạn vẫn sẽ được phép truy cập vào nền tảng cho đến khi kết thúc đăng ký hiện tại. Nếu một gói dịch vụ do bạn hoặc chúng tôi hủy trước khi kết thúc đăng ký hiện tại, bạn sẽ không được hoàn lại tiền. 
                        </span>
                        <span className="text-body text-high-em">
                            Các khoản phí trả trước cũng như bất kỳ khoản phí nào khác đều không được hoàn lại trong bất kỳ trường hợp nào.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            CẤP PHÉP NỘI DUNG VÀ SỞ HỮU TRÍ TUỆ
                        </h4>
                        <span className="text-body text-high-em">
                            Dịch vụ của chúng tôi cho phép bạn đăng liên kết, lưu trữ, chia sẻ và cung cấp một số thông tin, văn bản, đồ họa, video hoặc tài liệu khác (“Nội dung”). Bạn chịu trách nhiệm về nội dung mà bạn đăng lên dịch vụ bao gồm cả tính hợp pháp, độ tin cậy, độ chính xác và tính phù hợp của nội dung đó.
                        </span>
                        <span className="text-body text-high-em">
                            Bạn, với tư cách là người tạo nội dung mà bạn đăng, gửi hoặc hiển thị thông qua dịch vụ, tất cả các quyền sở hữu trí tuệ với các nội dung nói trên bạn là chủ sở hữu quyền duy nhất và có trách nhiệm bảo vệ các quyền nói trên. Chúng tôi thừa nhận rằng việc đăng hoặc hiển thị nội dung này trên dịch vụ theo bất kỳ cách nào không dẫn đến giấy phép ngụ ý hoặc đồng ý sử dụng nội dung thay mặt cho bên thứ ba. Do đó, chúng tôi sẽ không sử dụng nội dung này theo bất kỳ cách nào trừ khi có sự cho phép rõ ràng bằng văn bản của bạn.
                        </span>
                        <span className="text-body text-high-em">
                            Bạn tuyên bố và đảm bảo rằng: Nội dung là của bạn (bạn sở hữu nội dung đó) hoặc bạn có quyền hợp pháp để sử dụng nội dung đó và việc đăng nội dung của bạn hoặc thông qua dịch vụ không vi phạm quyền riêng tư và bảo vệ dữ liệu quyền, bản quyền hoặc quyền sở hữu trí tuệ khác, quyền theo hợp đồng hoặc bất kỳ quyền nào khác của bất kỳ bên thứ ba nào.
                        </span>
                    </div>

                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            SỬ DỤNG DỊCH VỤ ĐƯỢC ỦY QUYỀN
                        </h4>
                        <span className="text-body text-high-em">
                            Bạn chỉ có thể sử dụng dịch vụ cho các mục đích hợp pháp và theo cách phù hợp với bản chất và mục đích của HR Product.
                        </span>
                        <span className="text-body text-high-em">
                            Bạn không được sử dụng dịch vụ để đánh giá ứng viên cho bất kỳ công việc nào trái pháp luật, không an toàn, xúc phạm, phân biệt đối xử hoặc không phù hợp.
                        </span>
                        <span className="text-body text-high-em">
                            Bạn không được sử dụng dịch vụ để thu thập thông tin từ các ứng viên có thể sử dụng để phân biệt đối xử với họ. Việc sử dụng trái phép dịch vụ có thể là hành vi phạm tội và/ hoặc dẫn đến yêu cầu bồi thường thiệt hại.
                        </span>
                        <span className="text-body text-high-em">
                            Việc sử dụng trái phép dịch vụ có thể là hành vi phạm tội và/hoặc dẫn đến yêu cầu bồi thường thiệt hại.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            TÀI KHOẢN
                        </h4>
                        <span className="text-body text-high-em">
                            Khi bạn tạo tài khoản, bạn phải cung cấp cho chúng tôi thông tin chính xác, đầy đủ và cập nhập mọi lúc. Nếu không làm như vậy sẽ cấu thành vi phạm Điều khoản, điều này có thể dẫn đến việc chấm dứt việc hoạt động tài khoản của bạn trên dịch vụ của chúng tôi.
                        </span>
                        <span className="text-body text-high-em">
                            Bạn chịu trách nhiệm bảo vệ mật khẩu mà bạn sử dụng để truy cập dịch vụ và đối với bất kỳ hoạt động hoặc hành động nào bằng mật khẩu của bạn, cho dù mật khẩu của bạn là với Dịch vụ của chúng tôi hay dịch vụ của bên thứ ba.
                        </span>
                        <span className="text-body text-high-em">
                            Bạn đồng ý không tiết lộ mật khẩu của mình cho bất kỳ bên thứ ba nào. Bạn phải thông báo cho chúng tôi ngay lập tức khi nhận thấy bất kỳ hành vi vi phạm bảo mật hoặc sử dụng trái phép tài khoản của bạn.
                        </span>
                        <span className="text-body text-high-em">
                            Bạn không được sử dụng tên người dùng bằng tên của người, tổ chức khác, tên không hợp pháp hoặc nhãn hiệu khác. Tên mà bạn đăng ký không chịu bất kỳ trách nhiệm nào của người hoặc tổ chức khác ngoài bạn mà không có sự cho phép. Tên không được phép sử dụng những từ ngũ gây xúc phạm, thô tục hoặc tục tĩu.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            LIÊN KẾT ĐẾN CÁC TRANG WEB KHÁC
                        </h4>
                        <span className="text-body text-high-em">
                            Dịch vụ chúng tôi có thể chứa các liên kết đến các trang web hoặc dịch vụ của bên thứ ba không thuộc sở hữu và kiểm soát của HR Product. Nếu bạn nhận tài khoản HR Product từ bên thứ ba, bên thứ ba đó có thể có các quyền bổ sung đối với các tài khoản của bạn như khả năng truy cập hoặc xóa tài khoản HR Product của bạn. Hãy xem lại bất kỳ điều khoản bổ sung nào mà bên thứ ba đã cung cấp cho bạn vì HR Product không chịu trách nhiệm liên quan tới các điều khoản bổ sung này. Nếu bạn tạo tài khoản HR Product thay cho một thực thể như doanh nghiệp hoặc người sử dụng lao động của bạn, phải cam kết rằng bạn có thẩm quyền pháp lý để ràng buộc thực thể đó với các điều khoản này. Bạn không được chuyển thông tin đăng nhập tài khoản HR Product của mình cho người dùng hoặc tổ chức khác. Để bảo vệ tài khoản, hãy giữ bí mật mật khẩu và thông tin chi tiết về tài khoản. Bạn sẽ chịu trách nhiệm đối với tất cả hoạt động diễn ra trong tài khoản HR Product của mình.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            BẢO VỆ DỮ LIỆU
                        </h4>
                        <span className="text-body text-high-em">
                            Bạn thừa nhận rằng thông tin về bạn và nội dung được đăng sẽ được chúng tôi thu thập, lưu giữ và sử dụng theo Chính sách quyền riêng tư của chúng tôi.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            GIỚI HẠN TRÁCH NHIỆM PHÁP LÝ
                        </h4>
                        <span className="text-body text-high-em">
                            Trong mọi trường hợp, HR Product cũng như giám đốc, nhân viên, đối tác, đại lý, nhà cung cấp hoặc chi nhánh của HR Product sẽ không chịu trách nhiệm cho bất kỳ thiệt hại gián tiếp, ngẫu nhiên, đặc biệt, do hậu quả hoặc trừng phạt nào, bao gồm nhưng không giới hạn tổn thất về lợi nhuận, dữ liệu, việc sử dụng, thiện chí hoặc các tổn thất vô hình khác, phát sinh từ:
                        </span>
                        <div className="flex flex-col gap-[8px] pl-[10px]">
                            <span className="text-body text-high-em">
                                (1)	Việc bạn truy cập hoặc sử dụng hoặc không có khả năng truy cập hoặc sử dụng Dịch vụ;
                            </span>
                            <span className="text-body text-high-em">
                                (2)	Mọi hành vi hoặc nội dung của bất kỳ bên thứ ba nào trên Dịch vụ; 
                            </span>
                            <span className="text-body text-high-em">
                                (3)	Bất kỳ nội dung nào có được từ Dịch vụ;
                            </span>
                            <span className="text-body text-high-em">
                                (4)	Truy cập trái phép, sử dụng hoặc thay đổi đường truyền hoặc nội dung của bạn, cho dù dựa trên bảo hành, hợp đồng, sai lầm cá nhân (bao gồm cả sơ suất) hoặc bất kỳ lý thuyết pháp lý nào khác, cho dù chúng tôi có được thông báo về khả năng xảy ra thiệt hại đó hay không, và thậm chí nếu một biện pháp khắc phục được nêu ở đây bị phát hiện là không đạt được mục đích thiết yếu của nó. Những hạn chế như vậy sẽ không áp dụng nếu thiệt hại là kết quả của hành động cố ý hoặc sơ suất nghiêm trọng của HR Product hoặc quản lý cấp cao của HR Product. Trong mọi trường hợp, trách nhiệm pháp lý chung của HR Product theo các Điều khoản này sẽ được giới hạn ở các khoản phí mà bạn thực sự trả cho HR Product trong Chu kỳ thanh toán cuối cùng.
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            ĐIỀU CHỈNH
                        </h4>
                        <span className="text-body text-high-em">
                            Các Điều khoản này sẽ được điều chỉnh và hiểu theo luật pháp của Việt Nam, bất kể mâu thuẫn với các quy định của pháp luật.
                        </span>
                        <span className="text-body text-high-em">
                            Tất cả các tranh chấp giữa HR Product và bạn (dù tranh chấp đó có liên quan đến bên thứ ba hay không) sẽ được đệ trình riêng lên tòa án có thẩm quyền tại Việt Nam
                        </span>
                        <span className="text-body text-high-em">
                            Việc chúng tôi không thực thi bất kỳ quyền hoặc quy định nào trong các Điều khoản này sẽ không được coi là từ bỏ các quyền đó. Nếu bất kỳ Điều khoản nào trong các Điều khoản này bị tòa án coi là không hợp lệ hoặc không thể thi hành, thì các điều khoản còn lại của các Điều khoản này sẽ vẫn có hiệu lực. Các Điều khoản này cấu thành toàn bộ thỏa thuận giữa chúng tôi và thay thế mọi thỏa thuận trước đây mà chúng tôi có thể có giữa chúng tôi về Dịch vụ.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            THAY ĐỔI
                        </h4>
                        <span className="text-body text-high-em">
                            Chúng tôi bảo lưu quyền, theo quyết định riêng của mình, sửa đổi hoặc thay thế các Điều khoản này bất cứ lúc nào. Nếu bản sửa đổi là quan trọng, chúng tôi sẽ cố gắng cung cấp thông báo ít nhất 30 ngày trước khi bất kỳ điều khoản mới nào có hiệu lực. Những gì cấu thành một thay đổi quan trọng sẽ được xác định theo quyết định riêng của chúng tôi.
                        </span>
                        <span className="text-body text-high-em">
                            Bằng cách tiếp tục truy cập hoặc sử dụng Dịch vụ của chúng tôi sau khi những sửa đổi đó có hiệu lực, bạn đồng ý bị ràng buộc bởi các điều khoản sửa đổi. Nếu bạn không đồng ý với các điều khoản mới, vui lòng ngừng sử dụng Dịch vụ.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            LIÊN HỆ VỚI CHÚNG TÔI
                        </h4>
                        <span className="text-body text-high-em">
                            Nếu bạn có bất kỳ câu hỏi nào về các Điều khoản này, vui lòng liên hệ với chúng tôi .
                        </span>
                    </div>

                    <div className="flex w-full bg-[#D9D8DC] h-[1px]"></div>
                    <h2 className="text-[32px] font-bold leading-[40px] mb-0 text-left">Chính sách bảo mật</h2>
                    <div className="flex flex-col gap-[8px]">
                        <h4>Chúng tôi muốn bạn hiểu rõ các loại thông tin mà chúng tôi thu thập khi bạn sử dụng dịch vụ của chúng tôi</h4>
                        <h4 className="text-standard-bold text-high-em mb-0">
                            NGUYÊN TẮC BẢO VỆ DỮ LIỆU
                        </h4>
                        <span className="text-body text-high-em">
                            Chúng tôi sẽ tuân thủ luật bảo vệ dữ liệu. Điều này nói rằng dữ liệu cá nhân mà chúng tôi lưu giữ về bạn phải là:
                        </span>
                        <span className="text-body text-high-em">
                            Được sử dụng hợp pháp và minh bạch; chỉ được thu thập cho các mục đích hợp lệ mà chúng tôi đã giải thích rõ ràng cho bạn và không được sử dụng theo bất kỳ cách nào không tương thích với các mục đích đó; Liên quan đến các mục đích mà chúng tôi đã nói với bạn và chỉ giới hạn ở những mục đích đó; Chính xác và luôn được cập nhật; Chỉ được giữ trong thời gian cần thiết cho các mục đích mà chúng tôi đã nói với bạn; và giữ an toàn
                        </span>
                    </div>
                    <div>
                        <h4 className="text-standard-bold text-high-em mb-0">
                            CHÚNG TÔI THU NHẬP LOẠI THÔNG TIN NÀO?
                        </h4>
                        <span className="text-body text-high-em">
                            Chúng tôi thu thập thông tin mà bạn cung cấp cho chúng tôi, bao gồm: tên, địa chỉ email, phương thức thanh toán, số điện thoại và các thông tin nhận dạng khác mà bạn có thể sử dụng. Chúng tôi thu thập thông tin này theo một số cách thức, bao gồm cả thông tin bạn nhập khi sử dụng dịch vụ của chúng tôi, tương tác với dịch vụ khách hàng của chúng tôi hoặc tham gia vào các cuộc khảo sát hoặc quảng cáo tiếp thị;
                        </span>
                        <span className="text-body text-high-em">
                            Các thông tin bạn chọn trong cài đặt tài khoản (bao gồm cả các tùy chọn được thiết đặt trong phần "Tài khoản" trên trang web của chúng tôi) hoặc thông tin bạn cung cấp thông qua dịch vụ của chúng tôi hoặc dịch vụ của bên khác.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[6px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            CHÚNG TÔI SỬ DỤNG DỮ LIỆU CÁ NHÂN CỦA BẠN NHƯ THẾ  NÀO?
                        </h4>
                        <div className="flex flex-col gap-[10px]">
                            <span className="text-body text-high-em">
                                Chúng tôi sử dụng thông tin thu thập được từ dịch vụ của mình cho những mục đích sau:
                            </span>
                            <span className="text-body text-high-em">
                                <strong>Khách hàng:</strong> chúng tôi sử dụng thông tin để thực hiện và quản lý các bài kiểm tra mà bạn đã;
                            </span>
                            <span className="text-body text-high-em">
                                <strong>Liên lạc:</strong> chúng tôi sử dụng thông tin thu thập được, chẳng hạn như địa chỉ email của bạn, để tương tác trực tiếp với bạn. Ngoài ra, chúng tôi có thể thông báo cho bạn biết về các thay đổi hoặc cải tiến sắp tới đối với dịch vụ của chúng tôi. Bạn có thể từ chối nhận tất cả các thông tin liên lạc này từ chúng tôi bằng cách nhấp vào liên kết hủy đăng ký;
                            </span>
                            <span className="text-body text-high-em">
                                <strong>Tối ưu hóa nền tảng:</strong> cải thiện, kiểm tra và giám sát hiệu quả của nền tảng, đồng thời chẩn đoán và khắc phục sự cố công nghệ;
                            </span>
                            <span className="text-body text-high-em">
                                <strong>Quản lý nhà cung cấp:</strong> người cung cấp dịch vụ cho chúng tôi;
                            </span>
                            <span className="text-body text-high-em">
                                <strong>Truy cập dễ dàng:</strong> để giúp bạn truy cập tài khoản của mình một cách nhanh chóng sau khi đăng nhập, chúng tôi ghi nhớ thông tin để bạn không phải nhập lại thông tin đó trong lần truy cập tiếp theo của mình;
                            </span>
                            <span className="text-body text-high-em">
                                <strong>Thống kê:</strong> theo dõi các số liệu như lưu lượng truy cập, mẫu nhân khẩu học và mẫu trong kết quả thử nghiệm của chúng tôi (trên cơ sở ẩn danh và tổng hợp);
                            </span>
                            <span className="text-body text-high-em">
                                <strong>Duy trì và cải thiện dịch vụ:</strong> Chúng tôi cũng sử dụng thông tin của bạn để đảm bảo các dịch vụ của chúng tôi hoạt động như dự tính. Ngoài ra, chúng tôi còn dùng thông tin của bạn để cải tiến các dịch vụ;
                            </span>
                            <span className="text-body text-high-em">
                                <strong>Phát triển:</strong> phát triển, thử nghiệm các sản phẩm và tính năng mới;
                            </span>
                            <span className="text-body text-high-em">
                                <strong>Điểm chuẩn:</strong> sử dụng điểm kiểm tra tổng hợp và ẩn danh cũng như nhân khẩu học tổng hợp để cung cấp điểm chuẩn cho khách hàng và cải thiện dịch vụ của chúng tôi;
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            LÝ DO CHÚNG TÔI SỬ DỤNG DỮ LIỆU CÁ NHÂN CỦA BẠN
                        </h4>
                        <span className="text-body text-high-em">
                            Chúng tôi sẽ xử lý dữ liệu cá nhân của bạn vì một số lý do:
                        </span>
                        <span className="text-body text-high-em">
                            1. Bạn đã đồng ý cho chúng tôi;
                        </span>
                        <div className="text-body text-high-em">
                            <span className="text-body text-high-em">
                                2. Việc xử lý là cần thiết vì lợi ích kinh doanh hợp pháp của chúng tôi hoặc lợi ích của bên thứ ba, miễn là điều này không ghi đè lên bất kỳ lợi ích hoặc quyền nào mà bạn có với tư cách cá nhân. Lợi ích hợp pháp của chúng tôi là:
                            </span>
                            <div className="flex flex-col gap-[8px] pl-[12px]">
                                <span className="text-body text-high-em">
                                    (1)	Quản lý công việc kinh doanh và mối quan hệ của chúng tôi với bạn hoặc công ty, tổ chức của bạn;
                                </span>
                                <span className="text-body text-high-em">
                                    (2)	Hiểu và trả lời các câu hỏi, phản hồi của người dùng;
                                </span>
                                <span className="text-body text-high-em">
                                    (3)	Hiểu cách người dùng sử dụng nền tảng;
                                </span>
                                <span className="text-body text-high-em">
                                    (4)	Xác định những gì bạn muốn và phát triển mối quan hệ giữa chúng tôi với bạn hoặc công ty, tổ chức của bạn;
                                </span>
                                <span className="text-body text-high-em">
                                    (5)	Cải thiện nền tảng và dịch vụ;
                                </span>
                                <span className="text-body text-high-em">
                                    (6)	Quản lý chuỗi cung ứng;
                                </span>
                                <span className="text-body text-high-em">
                                    (7)	Phát triển mối quan hệ với các đối tác kinh doanh;
                                </span>
                                <span className="text-body text-high-em">
                                    (8)	Chia sẻ dữ liệu liên quan đến việc mua lại và chuyển giao hoạt động kinh doanh.
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            CHÚNG TÔI CHIA SẺ DỮ LIỆU CÁ NHÂN CỦA BẠN CHO AI?
                        </h4>
                        <span className="text-body text-high-em">
                            <strong>Khách hàng:</strong> Chúng tôi chia sẻ thông tin của HR Product về phần mềm với khách hàng (tiềm năng), để thông báo cho họ về lý lịch và thông tin xác thực của HR Product về phần mềm do chính HR Product cung cấp. Chúng tôi sẽ không cho thuê hoặc bán thông tin của bạn cho bất kỳ bên thứ ba nào;
                        </span>
                        <span className="text-body text-high-em">
                            <strong>Kết quả bài kiểm tra:</strong> Chúng tôi chia sẻ thông tin của người làm bài kiểm tra với những doanh nghiệp đã tạo bài kiểm tra cho họ; chúng tôi chia sẻ phản hồi của người làm bài kiểm tra cho doanh nghiệp về các bài kiểm tra.
                        </span>
                        <span className="text-body text-high-em">
                            <strong>Các nhà cung cấp:</strong> những người hỗ trợ hoạt động kinh doanh của chúng ta bao gồm các nhà cung cấp CNTT và truyền thông, hỗ trợ kinh doanh thuê ngoài, thông tin kinh doanh thông minh, các đại lý tiếp thị và quảng cáo cũng như các nhà cung cấp dự phòng. Các nhà cung cấp của chúng tôi phải đáp ứng các tiêu chuẩn về bảo mật thông tin và họ sẽ chỉ được cung cấp dữ liệu phù hợp với chức năng của họ.
                        </span>
                        <span className="text-body text-high-em">
                            <strong>Thay đổi quyền kiểm soát:</strong> Nếu chúng tôi bán hoặc chuyển nhượng một phần hoặc toàn bộ HR Prodcut hoặc tài sản của chúng tôi cho một tổ chức khác (ví dụ: trong quá trình giao dịch như sáp nhập, mua lại, phá sản, giải thể, thanh lý), thông tin của bạn và bất kỳ thông tin nào khác được thu thập thông qua nền tảng có thể nằm trong số các mặt hàng được bán hoặc chuyển nhượng. Người mua hoặc người được chuyển nhượng sẽ phải thực hiện các cam kết mà chúng tôi đã đưa ra trong Chính sách bảo mật này.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            AN TOÀN, BẢO MẬT
                        </h4>
                        <span className="text-body text-high-em">
                            Chúng tôi sử dụng các biện pháp hành chính, logic, vật lý và quản lý hợp lý để bảo vệ thông tin cá nhân của bạn khỏi mất mát, trộm cắp và truy cập, sử dụng và sửa đổi trái phép.
                        </span>
                        <span className="text-body text-high-em">
                            Các biện pháp này được thiết lập để đưa ra mức độ bảo mật phù hợp đối với các rủi ro của quá trình xử lý thông tin cá nhân của bạn. Tuy nhiên, chúng tôi không thể đảm bảo tính bảo mật của bất kỳ thông tin nào bạn truyền cho chúng tôi hoặc đảm bảo rằng thông tin trên nền tảng không thể bị truy cập, tiết lộ, thay đổi hoặc phá hủy. Chúng tôi yêu cầu bạn làm một phần để giúp chúng tôi. Bạn chịu trách nhiệm duy trì tính bảo mật của thông tin tài khoản và mật khẩu duy nhất của mình và kiểm soát quyền truy cập vào email giữa bạn và chúng tôi mọi lúc. Chúng tôi không chịu trách nhiệm về chức năng, quyền riêng tư.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            THÔNG TIN VÀ CÁC QUYỀN CỦA BẠN
                        </h4>
                        <span className="text-body text-high-em">
                            Bạn có quyền được tiếp cận thông tin cá nhân của mình hoặc đính chính hay cập nhật thông tin cá nhân đã cũ hay không chính xác mà chúng tôi đang lưu giữ. Bạn cũng có thể yêu cầu chúng tôi xóa thông tin cá nhân mà chúng tôi đang lưu giữ.
                        </span>
                        <span className="text-body text-high-em">
                            Khi bạn truy cập mục "Tài khoản" trên trang web của chúng tôi, bạn có thể truy cập và cập nhật một số lượng lớn thông tin về tài khoản của mình, bao gồm cả thông tin liên hệ, thông tin thanh toán và nhiều thông tin liên quan khác về tài khoản của. Bạn phải đăng nhập để truy cập mục "Tài khoản".
                        </span>
                        <span className="text-body text-high-em">
                            Chúng tôi có thể từ chối những yêu cầu không chính đáng hoặc không bắt buộc theo luật, bao gồm cả những yêu cầu hoàn toàn bất khả thi, hay đòi hỏi phải tốn quá nhiều công sức về mặt kỹ thuật, hay có thể đẩy chúng tôi vào những rủi ro vận hành như gian lận.
                        </span>
                        <span className="text-body text-high-em">
                            Lưu trữ thông tin: Chúng tôi có thể lưu giữ thông tin theo yêu cầu hoặc trong thời gian được phép theo các điều luật và quy định hiện hành, bao gồm cả việc tuân theo các lựa chọn của bạn, cho mục đích lập hóa đơn hoặc lưu trữ thông tin và để đáp ứng các mục đích đã mô tả trong Tuyên bố về quyền riêng tư này. Cụ thể, chúng tôi lưu giữ thông tin vì lợi ích hợp pháp và các mục đích kinh doanh thiết yếu của chúng tôi, chẳng hạn như vận hành, duy trì và cải thiện dịch vụ; tuân thủ các nghĩa vụ pháp lý; và thực hiện các quyền và yêu cầu bồi thường hợp pháp, bao gồm cả việc thực thi các điều khoản sử dụng của chúng tôi. Việc chúng tôi lưu giữ thông tin dựa trên nhiều yếu tố như mối quan hệ của bạn với HR Product (ví dụ: bạn có phải là thành viên hiện tại của HR Product hay không); bản chất của thông tin; tuân thủ các nghĩa vụ pháp lý của chúng tôi; bào chữa hoặc giải quyết các khiếu nại pháp lý thực tế hoặc dự kiến. Chúng tôi cũng thực hiện các biện pháp hợp lý để hủy hoặc xóa thông tin nhận dạng cá nhân một cách cẩn trọng khi chúng không còn cần thiết.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            CÁC ỨNG DỤNG, TRANG WEB VÀ DỊCH VỤ BÊN THỨ BA
                        </h4>
                        <span className="text-body text-high-em">
                            Chúng tôi không chịu trách nhiệm về các hoạt động được sử dụng bởi bất kỳ ứng dụng, trang web hoặc dịch vụ nào được liên kết hoặc đến từ nền tảng của chúng tôi, bao gồm cả thông tin hoặc nội dung có trong chúng. Hãy nhớ rằng khi bạn sử dụng liên kết để đi từ nền tảng của chúng tôi đến một ứng dụng, trang web hoặc dịch vụ nào khác, Chính sách quyền riêng tư không áp dụng cho các ứng dụng, trang web hoặc dịch vụ của bên thứ ba đó. Trình duyệt và tương tác của bạn trên bất kỳ ứng dụng, trang web hoặc dịch vụ nào của bên thứ ba, kể cả những trang web có liên kết trên Nền tảng của chúng tôi, đều phải tuân theo các quy tắc và chính sách riêng của bên thứ ba đó. Ngoài ra, bạn đồng ý rằng chúng tôi không chịu trách nhiệm và không có quyền kiểm soát đối với bất kỳ bên thứ ba nào mà bạn cho phép truy cập vào tài khoản của mình. Nếu bạn đang sử dụng ứng dụng, trang web hoặc dịch vụ của bên thứ ba và bạn cho phép họ truy cập vào tài khoản của mình, bạn sẽ tự chịu rủi ro khi làm như vậy.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            CHÚNG TÔI LƯU GIỮ DỮ LIỆU CỦA BẠN TRONG BAO LÂU?
                        </h4>
                        <span className="text-body text-high-em">
                            Chúng tôi thường chỉ giữ thông tin của bạn trong thời gian cần thiết để cung cấp các dịch vụ trên Nền tảng của chúng tôi. Chúng tôi sẽ lưu giữ thông tin của bạn khi cần thiết để tuân thủ các yêu cầu pháp lý, kế toán hoặc quy định. Khoảng thời gian lưu giữ dữ liệu của người làm bài kiểm tra có sẵn cho khách hàng (ví dụ: tên, địa chỉ email và điểm kiểm tra) là 5 năm. Hình ảnh webcam được chụp như một biện pháp chống gian lận được lưu giữ trong 6 tháng. Các bản ghi video của người dự thi trả lời các câu hỏi tùy chỉnh được lưu giữ trong 2 năm.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            COOKIE
                        </h4>
                        <span className="text-body text-high-em">
                            Chúng tôi sử dụng cookie để xác định trình duyệt của bạn. Nó sẽ thu thập và lưu trữ thông tin về cách bạn sử dụng nó khi truy cập nền tảng, thông qua đó có thể ghi lại việc bạn sử dụng nền tảng, cung cấp cho bạn dịch vụ và trải nghiệm tốt hơn. Dữ liệu cá nhân mà chúng tôi thu thập thông qua các công nghệ này cũng sẽ được sử dụng để quản lý phiên của bạn. Để biết thêm thông tin về cookie và cách chúng tôi sử dụng chúng, vui lòng xem <strong>Chính sách cookie</strong> của chúng tôi.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            TRẺ EM
                        </h4>
                        <span className="text-body text-high-em">
                            Nền tảng của chúng tôi không đề cập đến bất kỳ ai dưới 16 tuổi (“Trẻ em”). Chúng tôi không cố ý thu thập thông tin cá nhân từ trẻ em dưới 16 tuổi. Nếu bạn là cha mẹ hoặc người giám hộ và bạn biết rằng con của bạn đã cung cấp cho chúng tôi dữ liệu cá nhân, vui lòng liên hệ với chúng tôi. Nếu chúng tôi biết rằng chúng tôi đã thu thập dữ liệu cá nhân từ một đứa trẻ dưới 16 tuổi mà không có xác minh về sự đồng ý của cha mẹ, chúng tôi sẽ thực hiện các bước để xóa thông tin đó khỏi máy chủ của chúng tôi.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            NHỮNG THAY ĐỔI ĐỐI VỚI CHÍNH SÁCH NÀY
                        </h4>
                        <span className="text-body text-high-em">
                            Chúng tôi có thể sửa đổi hoặc cập nhật Chính sách quyền riêng tư của mình. Chúng tôi sẽ thông báo cho bạn về bất kỳ thay đổi nào bằng cách đăng Chính sách quyền riêng tư mới trên trang này. Bạn nên xem lại Chính sách quyền riêng tư này định kỳ để biết bất kỳ thay đổi nào. Các thay đổi đối với Chính sách quyền riêng tư này có hiệu lực khi chúng được đăng trên trang này. 
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            LIÊN HỆ VỚI CHÚNG TÔI
                        </h4>
                        <span className="text-body text-high-em">
                            Nếu bạn có bất kỳ câu hỏi nào về Chính sách quyền riêng tư này, vui lòng <strong>liên hệ với chúng tôi.</strong>
                        </span>
                    </div>
                    <div className="flex w-full bg-[#D9D8DC] h-[1px]"></div>

                    <h2 className="text-[32px] font-bold leading-[40px] mb-0">CHÍNH SÁCH COOKIE</h2>
                    <div className="flex flex-col gap-[8px]">
                        <span className="text-body text-high-em">
                            Cảm ơn bạn đã truy cập www.HRProduct.com (“Trang web”). Thông báo cookie này áp dụng cho Trang web, mọi trang web của bên thứ ba và các ứng dụng được truy cập hoặc sử dụng thông qua các trang web hoặc nền tảng đó được điều hành bởi hoặc thay mặt cho HR Product (“Trang web HR Product”).
                        </span>
                        <span className="text-body text-high-em">
                            Bằng cách sử dụng Trang web HR Product, bạn đồng ý cho phép chúng tôi sử dụng cookie và công nghệ theo dõi khác theo thông báo này. Nếu bạn không đồng ý với việc chúng tôi sử dụng cookie và công nghệ theo dõi khác theo cách này, bạn nên đặt cài đặt trình duyệt của mình cho phù hợp hoặc không sử dụng Trang web HR Product. Nếu bạn tắt cookie mà chúng tôi sử dụng, điều này có thể ảnh hưởng đến trải nghiệm người dùng của bạn khi truy cập Trang web HR Product.
                        </span>
                        <span className="text-body text-high-em">
                            Khi sử dụng thiết bị di động để kết nối với internet, bạn cũng nên tham khảo thông báo về quyền riêng tư của Ứng dụng cụ thể mà bạn đang sử dụng để hiểu các phương pháp thu thập dữ liệu cụ thể của ứng dụng đó.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            COOKIE LÀ GÌ?
                        </h4>
                        <span className="text-body text-high-em">
                            Cookie là các tập tin văn bản chứa một lượng thông tin nhỏ được tải xuống thiết bị của bạn khi bạn truy cập Website của chúng tôi. Những công nghệ khác (bao gồm dữ liệu chúng tôi lưu trữ trên trình duyệt web hoặc thiết bị của bạn, thông tin nhận dạng liên quan đến thiết bị của bạn và phần mềm khác) được sử dụng cho các mục đích tương tự. Trong chính sách này, chúng tôi gọi tất cả những công nghệ đó là “cookie”.
                        </span>
                        <span className="text-body text-high-em">
                            Chúng tôi dùng cookie nếu bạn có tài khoản HR Product, sử dụng các tính năng hoặc truy cập vào các trang web và ứng dụng khác. Chính sách này giải thích cách chúng tôi sử dụng cookie và cung cấp lựa chọn dành cho bạn. Trừ khi được quy định khác trong chính sách này, Chính sách quyền riêng tư sẽ áp dụng cho việc chúng tôi xử lý dữ liệu thu thập được qua cookie.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            HR PRODUCT SỬ DỤNG COOKIE NHƯ THẾ NÀO?
                        </h4>
                        <span className="text-body text-high-em">
                            HR Product sử dụng cookie để hiểu rõ hơn về cách khách truy cập sử dụng trang web này. Cookie giúp chúng tôi điều chỉnh trang web HR Product theo nhu cầu cá nhân của bạn, để cải thiện tính thân thiện với người dùng, thu thập phản hồi về sự hài lòng của khách hàng trên trang web của chúng tôi (thông qua các đối tác được chỉ định) và để liên lạc với bạn ở những nơi khác trên web. Để kích hoạt tính năng này, một số cookie được áp dụng khi bạn vào trang web của chúng tôi.
                        </span>
                        <span className="text-body text-high-em">
                            HR Product giữ tất cả thông tin được thu thập từ cookie ở định dạng không thể nhận dạng cá nhân. Cookie HR Product nằm trên máy tính của bạn không giữ lại tên hoặc địa chỉ IP của bạn.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            HR PRODUCT SỬ DỤNG LOẠI COOKIE NÀO?
                        </h4>
                        <div>
                            <span className="text-body text-high-em">
                                Các loại cookie sau được sử dụng trên các trang web HR Product. Tùy thuộc vào mục đích sử dụng chúng tôi sử dụng các loại cookie sau:
                            </span>
                            <div className="flex flex-col gap-[8px] pl-[12px]">
                                <span className="text-body text-high-em">
                                    (1)	Cookie phiên –  những cookie này chỉ xuất hiện trong phiên duyệt web hiện tại và sẽ hết hạn sau một khoản thời gian ngắn. Sau khi bạn đóng trình duyệt, tất cả các cookie phiên sẽ được xóa. Khi bạn khởi động lại trình duyệt của mình và quay lại trang web đã tạo cookie đó, trang web sẽ coi bạn là khách truy cập mới.
                                </span>
                                <span className="text-body text-high-em">
                                    (2)	Cookie cố định – những cookie này được lưu trữ trên thiết bị của bạn giữa các phiên duyệt cho đến khi hết hạn hoặc xóa các cookie này. Do đó, chúng cho phép trang web nhận ra bạn ghi nhớ sở thích của bạn và dịch vụ phù hợp với bạn.
                                </span>
                                <span className="text-body text-high-em">
                                    (3)	Cookie bên thứ ba – những cookie này do bên thứ ba đã được chấp thuận sử dụng.
                                </span>
                            </div>
                            <span className="text-body text-high-em">
                                Website của chúng tôi có thể sử dụng các cookie sau:
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            Cookie tối ưu hóa trang web
                        </h4>
                        <span className="text-body text-high-em">
                            Đây là những cookie thực sự cần thiết cho hoạt động của Website của chúng tôi. Website của chúng tôi sẽ không hoạt động bình thường nếu không có những cookie này. Bạn không thể tắt những cookie trong hệ thống của chúng tôi và những cookie này cũng không lưu trữ bất kỳ thông tin nhận dạng cá nhân nào. Những cookie này cho phép Website của chúng tôi thực hiện các tác vụ như lưu trữ thông tin. Nếu bạn sửa đổi trình duyệt của mình để chặn tất cả cookie, chúng tôi sẽ không thể đảm bảo rằng Website của chúng tôi sẽ hoạt động đúng khi bạn truy cập.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            Cookie chức năng
                        </h4>
                        <span className="text-body text-high-em">
                            Đây là những cookie được thiết lập để cải thiện chức năng của trang web. Giúp ghi nhớ các sự lựa chọn của bạn (như ngôn ngữ hoặc khu vực mà bạn đang sống) và được sử dụng để mang đến trải nghiệm web nâng cao. Toàn bộ thông tin thu thập đều ở dạng tổng hợp và dó đó được ẩn danh. Thông tin này giúp cho chúng tôi liên tục cải thiện chất lượng Website của mình.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            Cookie hướng đối tượng và quảng cáo
                        </h4>
                        <span className="text-body text-high-em">
                            Những cookie này thường được sử dụng cho các mục đích tiếp thị để mang đến cho khách truy cập nhiều thông tin liên quan hơn. Những cookie này thường do chúng tôi hoặc các mạng lưới quảng cáo bên thứ ba được chúng tôi cho phép đặt lên. Những thông tin được thu thập qua vị trí đặt cookie này được chia sẻ với các tổ chức bên thứ ba khác như các công ty quảng cáo, họ có thể sử dụng những thông tin này để cung cấp các nội dung quảng cáo và/hoặc nội dung hướng đối tượng cho bạn trên các trang web khác. Thông thường, cookie hướng đối tượng hay quảng cáo sẽ được liên kết với chức năng của trang do tổ chức khác cung cấp.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            Cookie gửi thông tin cho chúng tôi
                        </h4>
                        <span className="text-body text-high-em">
                            Đây là những cookie mà chúng tôi đặt trên Trang web HR Product và chỉ có Trang web HR Product mới có thể đọc được chúng. Đây được gọi là cookie “Bên thứ nhất”.
                        </span>
                        <span className="text-body text-high-em">
                            Chúng tôi cũng đặt cookie trên các quảng cáo được hiển thị trên các trang web khác thuộc sở hữu của bên thứ ba. Chúng tôi thu thập thông tin qua các cookie đó khi bạn nhấp vào hoặc tương tác với quảng cáo. Trong tình huống này, quảng cáo sẽ đặt cookie "Bên thứ ba". Chúng tôi có thể sử dụng thông tin thu được từ các cookie này để cung cấp cho bạn quảng cáo có liên quan và bạn quan tâm dựa trên hành vi trực tuyến trước đây của bạn.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            Cookie gửi thông tin đến các công ty khác
                        </h4>
                        <span className="text-body text-high-em">
                            Đây là những cookie được đặt trên Trang web HR Product bởi các công ty đối tác của chúng tôi. Họ có thể sử dụng dữ liệu được thu thập từ các cookie này để nhắm mục tiêu quảng cáo ẩn danh đến bạn trên các trang web khác, dựa trên lượt truy cập của bạn vào Trang web này.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            Cookie bên thứ ba
                        </h4>
                        <span className="text-body text-high-em">
                            Để mang đến trải nghiệm Website tốt nhất có thể và để hiểu rõ hơn nhu cầu của các khách truy cập, chúng tôi có thể sử dụng các ứng dụng hoặc chức năng được cung cấp bởi các bên thứ ba (như các mạng lưới quảng cáo, nhà cung cấp dịch vụ phân tích lưu lượng web, v.v.). Chức năng này có thể cho phép các bên thứ ba này thiết lập cookie trên hệ thống của bạn, việc này nằm ngoài tầm kiểm soát của chúng tôi. Những cookie này có khả năng là các cookie phân tích/theo dõi hiệu năng trang web hoặc cookie hướng đối tượng/quảng cáo. Để biết thêm chi tiết, vui lòng tham khảo Cookie bên thứ ba mà chúng tôi sử dụng.
                        </span>
                    </div>
                    <div className="flex flex-col gap-[8px]">
                        <h4 className="text-standard-bold text-high-em mb-0">
                            LÀM CÁCH NÀO ĐỂ THAY ĐỔI CÀI ĐẶT COOKIE CỦA TÔI?
                        </h4>
                        <span className="text-body text-high-em">
                            Vui lòng đảm bảo rằng cài đặt máy tính của bạn phản ánh việc bạn có vui lòng chấp nhận cookie hay không. Bạn có thể đặt trình duyệt của mình cảnh báo bạn trước khi chấp nhận cookie hoặc bạn chỉ cần đặt trình duyệt từ chối chúng, mặc dù bạn có thể không có quyền truy cập vào tất cả các tính năng của trang web này nếu bạn làm như vậy. Xem nút 'trợ giúp' trên trình duyệt của bạn để biết cách thực hiện việc này. Bạn không cần phải bật cookie để sử dụng hoặc điều hướng qua nhiều phần của Trang web HR Product. Hãy nhớ rằng nếu bạn sử dụng các máy tính khác nhau ở các địa điểm khác nhau, bạn sẽ cần đảm bảo rằng mỗi trình duyệt được điều chỉnh để phù hợp với tùy chọn cookie của bạn.
                        </span>
                        <span className="text-body text-high-em">
                            Để xóa cookie và tất cả thông tin đã thu thập, cũng như thay đổi cài đặt cookie của bạn, vui lòng nhấp vào liên kết sau.
                        </span>
                        <span className="text-body text-high-em">
                            {'<Đường link>'}
                        </span>
                        <span className="text-body text-high-em">
                            Trang web cho phép bạn chọn KHÔNG cho phép các dịch vụ tạo và sử dụng các cookie này chạy, mặc dù bạn có thể không có quyền truy cập vào tất cả các tính năng của Trang web nếu bạn làm như vậy.
                        </span>
                        <span className="text-body text-high-em">
                            Để biết thông tin hữu ích về cookie, hãy xem AboutCookies.org
                        </span>
                        <span className="text-body text-high-em">
                            Cập nhật thông báo pháp lý
                        </span>
                        <span className="text-body text-high-em">
                            Chúng tôi bảo lưu quyền thực hiện bất kỳ thay đổi và chỉnh sửa nào đối với thông báo này. Thỉnh thoảng hãy tham khảo trang này để xem lại những thông tin này và thông tin bổ sung mới.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}
