import React from "react"
import styled from 'styled-components';
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers"
import { Tooltip } from "antd";
import { InfoCircleOutlined } from '@ant-design/icons';
import _ from "lodash";
import { HRTooltip } from "../../../components/tooltip";

type WrapperProps = {
    index: number,
    last: number
}
const Wrapper = styled(styled.div``)<WrapperProps>`
    background-color: #EEF0F6;
    border-top-left-radius: ${props => (props.index%4 === 0 ? '8px' : '')};
    border-bottom-left-radius: ${props => (props.index%4 === 0 ? '8px' : '')};
    border-top-right-radius: ${props => (props.index%3 === 0 && props.index !== 0 || props.last === props.index ? '8px' : '')};
    border-bottom-right-radius: ${props => (props.index%3 === 0 && props.index !== 0 || props.last === props.index ? '8px' : '')};
    &:after {
        position: absolute;
        content: '';
        width: 1px;
        height: 100%;
        background-color: ${props => (props.index === 3 || props.last === props.index ? '' : '#CACCD5')};
        right: 0;
        z-index: 1;
    }
`;

type LibraryItemDetailsProps = {
    icon: React.ReactNode;
    title: string;
    index: number,
    last: number,
    name?: string;
    value?: any
}
export const LibraryItemDetails: React.FC<LibraryItemDetailsProps> = ({
    icon,
    title,
    index,
    last,
    name,
    value
}) => {
    const commonData = useSelector((state: RootState) => state.commonData);
    const renderText = React.useMemo(() => {
        if (name && commonData[name]) {
            if(Array.isArray(value)) {
                const dataSelected = commonData[name].filter((item) => value.includes(item._id));
                const textValues = _.uniqBy(dataSelected, '_id').map(d => d.label).toString();
                if (value?.length > 1) {
                    return <HRTooltip
                            content={textValues?.replaceAll(',', ', ')}
                            childNode={(
                                <div className="flex gap-[5px] items-center justify-start">
                                    <span>{textValues.substring(0, textValues.indexOf(','))}</span>
                                    {dataSelected?.length > 1 && <InfoCircleOutlined className="mb-[3px]" style={{
                                        color: '#7357FF'
                                    }} />}
                                </div>
                            )} 
                        />
                }
                return <span className="flex items-center justify-start">
                    <span>{textValues}</span>
                </span>
            }
            return commonData[name].find((item) => item._id === value)?.label || '';
        }
        return value || '';
    }, [commonData, name, value]);
    return (
        <Wrapper index={index} last={last} className="relative flex flex-col justify-center pl-[12px] min-h-[105px] w-full">
            <span>{icon}</span>
            <span className="font-bold text-[14px] pt-[12px] text-[#2E3046]">{title}</span>
            <span className="font-small text-[12px]">{renderText}</span>
        </Wrapper>
    )
}
