import { OTP } from "../../components/otp"
import React from "react"
import { EnterEmail } from "./email"
import { EnterPassword } from "./password"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { setLoading } from "../../redux/slices/appInfo"
import { forgotPasswordApi, forgotPasswordVerifyApi, resetPasswordApi } from "../../api/auth"
import { notification } from "antd"

export const ForgotPasswordPage: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [token, setToken] = React.useState<string>("");
    const [forgotStep, setForgotStep] = React.useState<number>(1);
    const [email, setEmail] = React.useState<string>("");
    const [otpErrorMessage, setOtpErrorMessage] = React.useState<string>("");
    const [isEmailNotFound, setIsEmailNotFound] = React.useState<boolean>(false);
    const [isBlockResend, setIsBlockResend] = React.useState<boolean>(false);

    const onSubmitEmail = async (email: string) => {
        dispatch(setLoading(true));
        try {
            setEmail(email);
            setOtpErrorMessage("");
            setIsBlockResend(false);
            await forgotPasswordApi(email);
            setForgotStep(2)
        } catch (e: any) {
            const status = e.response.status;
            if (status === 404) { // Notfound email
                setIsEmailNotFound(true);
            }
        }
        finally {
            dispatch(setLoading(false));
        }
    }
    const onResendOTP = async () => {
        dispatch(setLoading(true));
        try {
            await forgotPasswordApi(email);
        } catch (e) {
            console.error(e)
        }
        finally {
            dispatch(setLoading(false));
        }
    }
    const onBack = () => {
        setForgotStep(prev => prev - 1)
    }
    const onVerify = async (otp: string) => {
        dispatch(setLoading(true));
        try {
            const result = await forgotPasswordVerifyApi(email, otp);
            setToken(result.data.secondOtp);
            setForgotStep(3)
        } catch (e: any) {
            const status = e.response.status;
            const message = e.response.data.errorMessage;
            const failNumber = e.response.data.failNumber;
            if (status === 400) { // Invalid 
                if (message === "Otp has been expired") setOtpErrorMessage("Mã OTP đã hết hạn");
                else if (message === "Forgot password otp has been blocked") {
                    setOtpErrorMessage("Tính năng quên mật khẩu đã bị khóa 24h");
                    setIsBlockResend(true);
                }
                else if (failNumber === 5) {
                    setOtpErrorMessage("Tính năng quên mật khẩu đã bị khóa 24h");
                    setIsBlockResend(true);
                }
                else setOtpErrorMessage("Mã OTP không chính xác, số lần nhập sai còn lại: " + Math.abs(5 - failNumber));
            }
        }
        finally {
            dispatch(setLoading(false));
        }
    }
    const [errorMessage, setErrorMessage] = React.useState<string>("");
    const onSubmitNewPassword = async (password: string) => {
        dispatch(setLoading(true));
        try {
            await resetPasswordApi(email, password, token);
            notification.success({
                message: "Thành công",
                description: "Đổi mật khẩu thành công",
                placement: "topRight"
            })
            navigate("/login")
        } catch (e: any) {
            const status = e.response.status;
            if (status === 400) { // Invalid OTP
                const message = e.response.data.errorMessage;
                if (message === "New password does not match old password") setErrorMessage("Mật khẩu mới không được trùng với mật khẩu cũ");
            }
        }
        finally {
            dispatch(setLoading(false));
        }
    }
    return (
        <div className="flex w-screen h-screen bg-surface-low overflow-x-hidden items-center justify-center rounded-[12px]">
            <div className="shadow-e-03 bg-white rounded-[12px] overflow-hidden box-border">
                {forgotStep === 1 && <EnterEmail
                    isEmailNotFound={isEmailNotFound}
                    onSubmit={onSubmitEmail}
                />}
                {forgotStep === 2 && <OTP
                    errorMessage={otpErrorMessage}
                    onVerify={onVerify}
                    onResend={onResendOTP}
                    onBack={onBack}
                    isBlockResend={isBlockResend}
                />}
                {forgotStep === 3 && <EnterPassword
                    errorMessage={errorMessage}
                    onSubmit={onSubmitNewPassword}
                />}
            </div>
        </div>
    )
}
