import { Modal } from "antd";
import { useTestingPageContext } from ".";
import { ICInfoV2 } from "../../icons"
import React from "react";
import { HRButton } from "../../components/button";


type TestingPrepareProps = {
    onNext: () => void;
}

const COUNTDOWN = 15;
export const TestingPrepare: React.FC<TestingPrepareProps> = ({
    onNext
}) => {
    const { sectionTesting } = useTestingPageContext();
    const [countdown, setCountdown] = React.useState(COUNTDOWN);
    const [openModal, setOpenModal] = React.useState(false);

    React.useEffect(() => {
        const interval = setInterval(() => {
            setCountdown(countdown - 1);
        }, 1000);
        if (countdown === 0) {
            onNext();
        }
        return () => clearInterval(interval);
    }, [countdown, onNext]);

    return <div className="container mx-auto bg-white px-8 py-[54px] rounded-[12px]">
        <div className="space-y-6">
            <div className="space-y-2">
                <div className="font-bold text-[16px] text-n-800">Phần đánh giá:</div>
                <div className="font-bold text-[24px] text-n-800 flex space-x-2 items-center">
                    <span>{sectionTesting?.title}</span>
                    {sectionTesting?.introduce && (<span className="cursor-pointer" onClick={() => setOpenModal(true)}><ICInfoV2 /></span>)}
                </div>
            </div>
            <div>
                <div>Phần thi sắp sửa bắt đầu</div>
                <div>Chúc bạn thi tốt!</div>
            </div>
            <div className="h-[1.4px] bg-n-150" />
            <div className="text-center space-y-4">
                <div className="font-bold text-[20px] text-n-800">Phần thi sẽ bắt đầu sau <span className="font-bold text-[20px] text-green">{countdown} giây</span></div>
                <div className="font-400 text-n-600">Xin đừng chuyển trang. Bài đánh giá sẽ bị hủy kết quả nếu bạn chuyển trang. </div>
            </div>
        </div>
        <Modal
            centered
            onCancel={() => setOpenModal(false)}
            open={openModal}
            getContainer={false}
            title={
                <div className="text-high-em text-title-bold">{sectionTesting?.title}</div>
            }
            footer={
                <div className="grid grid-cols-[auto_auto] justify-end gap-[16px]">
                    <HRButton
                        btnType="primary"
                        btnSize="sm"
                        onClick={() => setOpenModal(false)}
                        >
                        Trở về
                    </HRButton>
                </div>
            }>
                <p className="text-med-em text-standard-medium">{sectionTesting?.introduce}</p>
        </Modal>
    </div>
}
