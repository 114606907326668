import React from "react"
import { HRButton, IconButton } from "../../components/button";
import { ICChevronDown, ICWarning } from "../../icons";
import styled from "styled-components";
import classNames from "classnames";
import { TWaitingPaymentOrder } from "./type";
import { retrievePaymentUrl } from "../../api/payment";
import { useNavigation } from "../../hooks/useNavigate";

const RowItem = styled.div`
    &:hover {
        .ant-btn {
            border-radius: 8px !important;
            border: 1px solid #FFE0E0 !important;
            background: #FFF9F9 !important;
            box-shadow: 0px 1px 2px -1px rgba(17, 12, 34, 0.08);
        }
    }
`;

type TViewPayment = TWaitingPaymentOrder & {
    title: string;
    isExpanded: boolean;
}

type TWaitingPaymentProps = {
    waitingPaymentList: TWaitingPaymentOrder[];
}
export const WaitingPayment: React.FC<TWaitingPaymentProps> = ({
    waitingPaymentList,
}) => {
    const { navigate } = useNavigation();
    const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
    const [data, setData] = React.useState<TViewPayment[]>([]);
    const title = waitingPaymentList?.length === 1 ? `Chờ thanh toán` : `Chờ thanh toán (${waitingPaymentList?.length})`;

    React.useEffect(() => {
        if (waitingPaymentList?.length === 0) {
            setIsExpanded(false);
        }
        if (waitingPaymentList?.length > 0) {
            setData(prev => {
                return waitingPaymentList.map((item, index) => {
                    if (index === 0) return {
                        ...item,
                        title: waitingPaymentList[0].difinition?.assessment?.title || '',
                        isExpanded: true,
                    };
                    return {
                        ...item,
                        title: waitingPaymentList[index - 1].difinition?.assessment?.title || '',
                        isExpanded: false,
                    }
                })
            });
        }
    }, [waitingPaymentList]);

    const onExpand = React.useCallback(() => {
        if (data?.length === 1) return;
        setIsExpanded(!isExpanded);
        setData(prev => {
            return prev.map((item, index) => {
                if (index === 0) return item;
                return {
                    ...item,
                    isExpanded: !isExpanded,
                }
            })
        });
    }, [isExpanded, data]);

    const onClickName = React.useCallback((item: TViewPayment) => {
        if (item.difinition?.type === 'assessment') {
            navigate(`/assessment/${item.difinition?.assessment?._id}/details`);
        }
    }, [navigate]);

    const onClickPayment = React.useCallback(async (item: TViewPayment) => {
        try {
            const { data } = await retrievePaymentUrl(item._id);
            if (data?.url) {
                window.open(data?.url, '_blank');
            }
        } catch (e) {
            console.error(e);
        }
    }, []);

    const renderRowData = (item: TViewPayment) => {
        return (
            <RowItem
                key={item?._id}
                className={classNames([
                    "group items-center justify-between gap-[16px] h-[56px] py-[8px] pl-[16px] pr-[8px] w-full cursor-pointer border-t border-outline-med",
                    {
                        "hidden": !item?.isExpanded,
                        "flex": item?.isExpanded,
                    }
                ])}
            >
                <div className="flex gap-[8px] items-center">
                    <div onClick={() => onClickName(item)} 
                        className="text-sub-bold text-med-em line-clamp-2 group-hover:underline">
                        {item?.difinition?.assessment?.title}
                    </div>
                </div>
                <div>
                    <HRButton
                        btnSize="sm"
                        btnType="sub_danger"
                        onClick={() => onClickPayment(item)}
                        style={{
                            border: '1px solid transparent',
                        }}
                    >
                        <span className="text-body-medium text-error-500">Thanh toán</span>
                    </HRButton>
                </div>
            </RowItem>
        )
    };

    return <div 
            className={classNames([
                "flex flex-col items-center justify-start bg-white w-[374px] rounded-t-[12px]",
                {
                    "rounded-b-[12px]": !isExpanded,
                }
            ])}
        >
        <div className="flex justify-between items-center h-[40px] px-[8px] w-[374px] cursor-pointer"
            onClick={onExpand}
        >
            <div className="flex gap-[8px] items-center">
                <ICWarning fill="#FF8080" width={18} height={18} />
                <span className="text-sub-bold text-med-em">{title}</span>
            </div>
            <div>
                {data?.length > 1 && <IconButton
                    btnSize="sm"
                    btnType="sub"
                    style={{
                        border: 0,
                        padding: '11px',
                        height: 'auto',
                    }}
                    icon={<ICChevronDown width={18} height={18} />} />
                }
            </div>
        </div>
        <div className="flex flex-col w-full max-h-[224px] overflow-hidden">
            {renderRowData(data[0])}
            {isExpanded && <div className="flex flex-col shadow-e-03 absolute top-[40px] max-h-[224px] w-[374px] overflow-auto bg-white z-10 transition-all ease-out rounded-b-[12px] box-border">
                {data.map((item, index) => {
                    return <div key={index} className="flex w-full h-[56px]">
                        {renderRowData(item)}
                    </div>
                })}
            </div>}
        </div>
    </div>
}
