import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./editor.style.css";
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { isEmptyHTML, toolbar } from "./editor.config";
import { Form, Modal } from "antd";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import classNames from 'classnames'
import _ from "lodash";
import { createDefaultQuestion, EQuestionType, TEssayChoiceQuestion } from "../../../../../create_test/tab/question/type";
import { getInnerText } from "../../../../../create_test/tab/question/helper";
import { MAX_CONTENT_LENGTH, QUESTIONS_TIMING } from "../../../../../../utils/constants";
import { HRButton, IconButton } from "../../../../../../components/button";
import { ICClose, ICTick, ICWarning } from "../../../../../../icons";
import { useForm } from "antd/lib/form/Form";
import { HRForm } from "../../../../../../components/form/form";
import { HRInput } from "../../../../../../components/form/input";
import { HRSelect } from "../../../../../../components/form/select";
import { HRTextArea } from "../../../../../../components/form/textarea";
import { TAdditionalQuestion } from "../../../type";

type EssayEditorProps = {
    onCreateQuestion: (question: TAdditionalQuestion) => void
    selectedQuestion: TAdditionalQuestion | undefined
    questionType: EQuestionType.ESSAY
    onCancel: () => void
}
export const EssayEditor: React.FC<EssayEditorProps> = ({
    onCreateQuestion,
    selectedQuestion,
    questionType,
    onCancel
}) => {
    const [question, setQuestion] = React.useState<TAdditionalQuestion>(() => selectedQuestion ?? createDefaultQuestion(questionType) as TEssayChoiceQuestion)
    const [needCheck, setNeedCheck] = React.useState<boolean>(false);
    const [questionForm] = useForm();
    const [editorState, setEditorState] = React.useState<EditorState>(
        EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(question?.content).contentBlocks))
    );


    React.useEffect(() => {
        const q = selectedQuestion ?? createDefaultQuestion(questionType) as TEssayChoiceQuestion
        setQuestion(q)
        setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(q.content).contentBlocks)))
    }, [questionType, selectedQuestion]);

    const isDirty = React.useMemo(() => {
        if (!selectedQuestion) return getInnerText(question.content).replace(/\n/g, '') !== ""
            || getInnerText(question.referenceContent as string).replace(/\n/g, '') !== ""
        return question.content !== selectedQuestion?.content
            || question.referenceContent !== selectedQuestion?.referenceContent
    }, [question, selectedQuestion]);

    const onEditorStateChange = (editorState: EditorState) => {
        setEditorState(editorState);
        setQuestion({
            ...question,
            content: draftToHtml(convertToRaw(editorState.getCurrentContent()))
        })
    }
    const QuestionsTimes = [
        {
            value: 1,
            label: '1 phút'
        },
        {
            value: 2,
            label: '2 phút'
        },
        ...QUESTIONS_TIMING,
    ];

    const invalidMaxLengthEditor = React.useMemo(() => {
        const text = editorState?.getCurrentContent()?.getPlainText().length;
        if (text && text > MAX_CONTENT_LENGTH) {
            return true
        }
        return false;
    }, [editorState]);


    const isQuestionError = React.useMemo(() => {
        return needCheck && (isEmptyHTML(question.content) || invalidMaxLengthEditor);
    }, [needCheck, question.content, invalidMaxLengthEditor]);

    const validationValues = (): boolean => {
        setNeedCheck(true);
        return isEmptyHTML(question.content) ||
            !question.duration;
    };

    // check data length editorState
    const editorStateDataLength = React.useMemo(() => {
        const numberFormat = new Intl.NumberFormat('en-US');
        const contentLength = editorState.getCurrentContent().getPlainText().length;
        return `${contentLength} / ${numberFormat.format(MAX_CONTENT_LENGTH)}`;
    }, [editorState]);

    const onSave = () => {
        if (!validationValues()) {
            onCreateQuestion(question);
        }
    }
    const [showCancelModal, setShowCancelModal] = React.useState<boolean>(false)
    const onCancelAction = React.useCallback(() => {
        if (isDirty) {
            setShowCancelModal(true)
        } else {
            onCancel()
        }
    }, [isDirty, setShowCancelModal, onCancel]);

    const title = React.useMemo(() => {
        return editorState.getCurrentContent().getPlainText();
    }, [editorState]);

    const childForm = (
        <div className="grid gap-[57px] grid-cols-[1fr]">
            <div className="grid grid-cols-2 gap-[32px]">
                <Form.Item
                    name="title"
                >
                    <HRInput label="Tiêu đề câu hỏi" maxLength={50} />
                </Form.Item>

                <Form.Item name="questionType">
                    <HRSelect
                        name="questionType"
                        label="Phân loại câu hỏi"
                        placeholder="Lựa chọn"
                    />
                </Form.Item>
            </div>

            <div className="grid grid-cols-2 gap-[32px]">
                <Form.Item name="desiredAnswer">
                    <HRTextArea
                        label="Bạn mong muốn câu trả lời là gì?"
                        maxLength={1000}
                        style={{
                            height: "105px",
                        }}
                    />
                </Form.Item>
                <Form.Item name="relatedReason">
                    <HRTextArea
                        label="Tại sao câu hỏi này lại có liên quan"
                        maxLength={1000}
                        style={{
                            height: "105px",
                        }}
                    />
                </Form.Item>
            </div>
        </div>
    );
    const onValuesChangeForm = React.useCallback((_: any, allValues: any) => {
        setQuestion((prev) => ({
            ...prev,
            ...allValues
        }));
    }, [setQuestion]);

    return <div className="pb-16 space-y-8 text-left container mx-auto">
        <div className="flex items-center justify-between">
            <div>
                <div className="flex space-x-2 items-center font-bold text-[24px]" >
                    <IconButton btnType="sub" btnSize="sm" icon={<ICClose width={18} height={18} />} onClick={onCancelAction} />
                    <span className="max-w-[500px] line-clamp-1">{title || 'Câu hỏi mới'}</span>
                </div>
                <span className="text-xs font-medium text-n-600 ml-12">
                    {/* {form.getFieldValue("title")} / {selectedSection?.name ?? ""} */}
                </span>
            </div>
            <div className="flex space-x-4">
                <span>
                    <HRButton
                        btnType="sub"
                        btnSize="md"
                        onClick={onCancelAction}
                    >
                        <div className="flex justify-center">
                            Hủy bỏ
                        </div>
                    </HRButton>
                </span>
                <span>
                    <HRButton
                        btnSize="md"
                        onClick={onSave}
                        className="w-[107px] h-[44px]"
                    >
                        <div className="flex space-x-2 justify-center">
                            <ICTick />
                            <span>{selectedQuestion ? "Cập nhật" : "Lưu"}</span>
                        </div>
                    </HRButton>
                </span>
            </div>
        </div>
        <div className="mt-8 p-8 bg-white rounded-[10px] grid grid-cols-1 gap-[38px]">
            <div className=" grid grid-cols-1 grid-rows-[auto_1fr] justify-start items-start gap-[16px]">
                <div className="text-primary-bold text-standard-medium">Phần câu hỏi</div>

                <div className="space-y-3"
                    onContextMenu={(e) => {
                        // @ts-ignore
                        document.querySelector(".questions-editor")?.focus?.()
                    }}
                >
                    <HRSelect
                        className="w-full"
                        hasSearch={false}
                        isRequired={true}
                        label="Thời lượng trả lời câu hỏi"
                        hasFilterSort={false}
                        allowClear={false}
                        placeholder="Lựa chọn"
                        value={question?.duration}
                        onChange={(value) => {
                            setQuestion((prev) => ({
                                ...prev,
                                duration: value
                            }));
                        }}
                        options={QuestionsTimes}
                    />
                    {needCheck && !question?.duration && <div className="flex items-start">
                        <span className="text-body italic text-error-500">Không được để trống</span>
                    </div>}
                    <Editor
                        stripPastedStyles={true}
                        toolbar={toolbar}
                        editorState={editorState}
                        toolbarClassName={classNames(isQuestionError ? "error" : "", "editor-toolbar")}
                        wrapperClassName={classNames(isQuestionError ? "error" : "", "questions-wrapper", "editor-wrapper")}
                        editorClassName={classNames(isQuestionError ? "error" : "", "questions-editor", "editor-editor")}
                        onEditorStateChange={onEditorStateChange}
                        placeholder="Nhập câu hỏi..."
                    />
                    <div className="noted text-med-em text-tiny-medium flex items-center justify-end">
                        <span>{editorStateDataLength}</span>
                    </div>
                    {
                        invalidMaxLengthEditor && <div className="flex items-center space-x-2">
                            <ICWarning fill="#F55858" width={18} height={18} />
                            <span className="text-body italic text-error-500">Bạn đã nhập quá số lượng ký tự cho phép!</span>
                        </div>
                    }
                    {
                        isQuestionError && !invalidMaxLengthEditor && <div className="flex items-center space-x-2">
                            <ICWarning fill="#F55858" width={18} height={18} />
                            <span className="text-body italic text-error-500">Xin hãy nhập câu hỏi</span>
                        </div>
                    }
                </div>
            </div>
           
        </div>
        <div className="grid grid-cols-1 h-[1.4px] w-full bg-[#E2E4EB]"></div>
        <div className="grid grid-cols-1">
            <div className="grid grid-cols-1 gap-[8px]">
                <span className="text-primary-bold text-standard-medium">Đây là phần thông tin cho đội ngũ của bạn.</span>
                <span className="text-body-medium text-med-em">Các thông tin dưới đây sẽ không hiện cho các ứng viên.</span>
            </div>
        </div>
        <div className="grid grid-cols-1 bg-white rounded-[10px] p-8">
            <HRForm
                form={questionForm}
                name="questionForm"
                initialValues={selectedQuestion}
                onValuesChange={onValuesChangeForm}
                childNode={childForm}
            />
        </div>
        <Modal
            title="Thông báo"
            open={showCancelModal}
            centered={true}
            onCancel={setShowCancelModal.bind(null, false)}
            footer={
                <div className="grid grid-cols-[auto_auto] justify-end gap-[16px]">
                    <HRButton
                        btnSize="sm"
                        btnType="sub"
                        onClick={() => setShowCancelModal(false)}>
                        Không
                    </HRButton>
                    <HRButton btnSize="sm" onClick={onCancel}>
                        Có
                    </HRButton>
                </div>
            }
        >
            <div className="space-y-2">
                <div>Các thông tin nhập vào sẽ bị mất</div>
                <div>Bạn có chắc chắn muốn hủy bỏ?</div>
            </div>
        </Modal>
    </div>
}