import React from "react";
import styled from "styled-components";
import { Rate } from "antd";
import classNames from 'classnames';
import { HRTextArea } from "../../../components/form/textarea";
import { ICUserV4, ICCheckFill } from "../../../icons";
import { EQuestionType } from "../../create_test/tab/question/type";
import { TChoiceQuestionTesting, TQuestionTesting } from "../../testing/type";
import { Iframe } from "../../../components/iframe";

const AnwserWrapper = styled.div`
    &.normal-question {
        border: 1px solid #D9D8DC;
        border-radius: 8px;
        padding-left: 56px;
    }
    &.incorrect {
        padding-left: 16px;
        border: 1px solid #FFA7A7;
    }
    &.correct {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border: 1px solid #4DCC8F;
        border-radius: 8px;
        padding-left: 16px;
    }
`;

type TViewChoiceQuestionProps = {
    question: TChoiceQuestionTesting | null;
    questionNote: string;
    setQuestionNote: React.Dispatch<React.SetStateAction<string>>;
    questionRate: number;
    setQuestionRate: React.Dispatch<React.SetStateAction<number>>;
};

const ViewChoiceQuestion: React.FC<TViewChoiceQuestionProps> = ({
    question,
    questionNote,
    questionRate,
    setQuestionRate,
    setQuestionNote,
}) => {
    React.useEffect(() => {
        setQuestionRate(question?.rating || 0);
        setQuestionNote(question?.note || '');
    }, [question]);
    const renderAnwsers = React.useMemo(() => {
        const isCorrect = (index: number | number[] | undefined) => {
            if (Array.isArray(question?.correctAnswer)) {
                return question?.correctAnswer?.findIndex((item: any) => item === index) !== -1;
            }
            return question?.correctAnswer === index;
        }
        const selectedAnswer = (index: number | number[] | undefined) => {
            if (Array.isArray(question?.selectedAnswer)) {
                return question?.selectedAnswer?.findIndex((item: any) => item === index) !== -1;
            }
            return question?.selectedAnswer === index;
        }
        return question?.options.map((item: any, index) => {
            const hasCorrectQuestion = isCorrect(index);
            const userSelectedAnswer = selectedAnswer(index);
            const isCorrectAnswer = hasCorrectQuestion && userSelectedAnswer;
            return <AnwserWrapper key={index} className={
                classNames(
                    'flex flex-row gap-[16px] p-[16px]',
                    "text-body-medium text-high-em",
                    'normal-question',
                    {
                        'correct': hasCorrectQuestion,
                    },
                    {
                        'incorrect': userSelectedAnswer && !isCorrectAnswer
                    },
                )
            }>
                <div className="flex flex-row gap-[16px]">
                    {userSelectedAnswer && hasCorrectQuestion && isCorrectAnswer && <span>
                        <ICUserV4 fill="#0BAA60" />
                    </span>}
                    {hasCorrectQuestion && !isCorrectAnswer && <span>
                        <ICCheckFill />
                    </span>}
                    {userSelectedAnswer && !isCorrectAnswer && <span>
                        <ICUserV4 />
                    </span>}
                    <EditorViewQuestionWrapper content={item || ''} />
                </div>
            </AnwserWrapper>
        })
    }, [question]);

    return <div className="text-left">
        <div className="grid grid-cols-2 gap-[32px]">
            <div className="flex flex-col gap-[24px] items-start">
                <span className="text-[#2E293D] text-[16px] font-bold">Câu hỏi</span>
                {question && <EditorViewQuestionWrapper content={question?.content || ''} />}
            </div>
            <div className="flex flex-col gap-[24px]">
                <div className="text-[#2E293D] text-[16px] font-bold">Đáp án</div>
                <div className="flex flex-col gap-[16px]">
                    {renderAnwsers}
                </div>
                <div className="flex flex-col gap-[8px]">
                    <span className="text-body-bold">Đánh giá câu trả lời</span>
                    <Rate defaultValue={5} value={questionRate} onChange={setQuestionRate} />
                    <HRTextArea
                        value={questionNote}
                        onChange={(e) => setQuestionNote(e.target.value)}
                        placeholder="Nhận xét của bạn"
                        maxLength={500}
                        style={{
                            height: "109px",
                            width: "538px",
                        }}
                    />
                </div>
            </div>

        </div>
    </div>
}

type TViewQuestionProps = {
    question: TQuestionTesting | null;
    questionNote: string;
    setQuestionNote: React.Dispatch<React.SetStateAction<string>>;
    questionRate: number;
    setQuestionRate: React.Dispatch<React.SetStateAction<number>>;
};
export const ViewQuestionWrapper: React.FC<TViewQuestionProps> = ({
    question,
    ...props
}) => {
    // TODO: Re-check why question nullable
    switch (question?.type) {
        case EQuestionType.SINGLE_CHOICE:
        case EQuestionType.MULTIPLE_CHOICE:
        case null:
            return <ViewChoiceQuestion question={question} {...props} />
        case EQuestionType.CODING:
            return <>Coding question</>
        case EQuestionType.GROUP:
            return <>Group question</>
        case EQuestionType.SPEAKING:
            return <>Speaking question</>
        case EQuestionType.ESSAY:
        default:
            return <ViewEssayQuestion question={question} {...props} />
    }
}


type EditorViewQuestionWrapperProps = {
    content: string;
}
export const EditorViewQuestionWrapper: React.FC<EditorViewQuestionWrapperProps> = ({
    content,
}) => {

    return <Iframe content={content as string || ''} />
}
const escapeHTML = (str: string) => {
    const div = document.createElement('div');
    div.innerHTML = str;
    // Remove all script, event, and data attributes to prevent XSS.
    const scripts = div.getElementsByTagName('script');
    let i = scripts.length;
    while (i--) {
        scripts[i].parentNode?.removeChild(scripts[i]);
    }
    const events = div.getElementsByTagName('*');
    i = events.length;
    while (i--) {
        const attr = events[i].attributes;
        let j = attr.length;
        while (j--) {
            const name = attr[j].name;
            if (name.startsWith('on')) {
                events[i].removeAttribute(name);
            }
        }
    }
    const data = div.getElementsByTagName('*');
    i = data.length;
    while (i--) {
        const attr = data[i].attributes;
        let j = attr.length;
        while (j--) {
            const name = attr[j].name;
            if (name.startsWith('data')) {
                events[i].removeAttribute(name);
            }
        }
    }
    return div.innerHTML;
}
const ViewEssayQuestion: React.FC<TViewChoiceQuestionProps> = ({
    question,
    questionNote,
    questionRate,
    setQuestionRate,
    setQuestionNote,
}) => {
    React.useEffect(() => {
        setQuestionRate(question?.rating || 0);
        setQuestionNote(question?.note || '');
    }, [question]);

    return <div className="text-left">
        <div className="grid grid-cols-2 gap-[32px]">
            <div className="flex flex-col gap-[24px] items-start">
                <span className="text-[#2E293D] text-[16px] font-bold">Câu hỏi</span>
                {question && <EditorViewQuestionWrapper content={question?.content || ''} />}
            </div>
            <div className="flex flex-col gap-[24px]">
                <div className="text-[#2E293D] text-[16px] font-bold">Đáp án</div>
                <div className="flex flex-col gap-[16px]">
                    <span className="w-full h-min line-clamp-7 text-body text-high-em">
                        <span dangerouslySetInnerHTML={{
                            __html: escapeHTML(question?.answer || '')
                        }}></span>
                    </span>
                </div>
                <div className="flex flex-col gap-[8px]">
                    <span className="text-body-bold">Đánh giá câu trả lời</span>
                    <Rate defaultValue={5} value={questionRate} onChange={setQuestionRate} />
                    <HRTextArea
                        value={questionNote}
                        onChange={(e) => setQuestionNote(e.target.value)}
                        placeholder="Nhận xét của bạn"
                        maxLength={500}
                        style={{
                            height: "109px",
                            width: "538px",
                        }}
                    />
                </div>
            </div>

        </div>
    </div>
}