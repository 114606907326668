import React from "react";
import { HRButton, IconButton } from "../../../components/button";
import { ICArrowBack, ICArrowForward, ICChevronDown, ICClose, ICHeadPhone, ICNext, ICPin, ICPointMap, ICUnPin } from "../../../icons";
import { ICBack } from "../../../icons/back";
import { EQuestionType, indexQuestion, TQuestion } from "../../create_test/tab/question/type";
import { QuestionRender } from "./question";
import { useTestingPageContext } from "..";
import { CameraViewer } from "./components/camera_viewer";
import { ECandidateAssessmenStatus, IStartSection, STEPS, TQuestionTesting } from "../type";
import { ICCheckV2 } from '../../../icons/check';
import { useNotification } from "../../../hooks/useNotification";
import { useTesting } from "../hooks/useTesting";
import { ERROR_CODE, ERROR_CODE_DESC_MESSAGE, ERROR_CODE_TITLE_MESSAGE, EVENT_NAME_SOCKET } from "../../../utils/constants";
import { ErrorLink } from "../../../components/errorLink";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/slices/appInfo";
import { TimerQuestion } from "./timer-question";
import { Drawer } from "antd";
import { QuestionListDraw } from "./components/render_questions";
import _ from "lodash";
import classNames from "classnames";
import { useSticky } from "../../../hooks/useSticky";

const MainPage = styled.div`
    div {
        -webkit-user-select: none;
        -webkit-touch-callout: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        user-select: none;
    }
    .sticky-header-testing {
        position: fixed;
        top: 70px;
        left: 0;
        width: 100%;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 11;
    }
`;

const DrawerContent = styled(Drawer)`
    .ant-drawer-header {
        padding: 8px 8px 0 0;
        align-items: flex-start;
        border-bottom: 1px solid var(--tc-outlines-med);
        .ant-drawer-header-title {
            flex-direction: column;
            align-items: flex-start;
            .ant-drawer-close {
                padding: 11px;
                margin: 0 0 0 8px;
            }
            .ant-drawer-title {
                padding: 16px;
                color: var(--tc-text-primary-bold);
                font-family: SVN-Poppins;
                font-size: 22px;
                font-weight: 600;
                line-height: 32px;
            }
        }
    }
`;

const DrawerBottom = styled(Drawer)`
    .ant-drawer-header {
        padding: 16px 48px;
        .ant-drawer-header-title {
            flex-direction: column;
            align-items: flex-start;
            .ant-drawer-title {
                color: var(--tc-text-primary-bold);
                font-family: SVN-Poppins;
                font-size: 22px;
                font-weight: 600;
                line-height: 32px;
            }
        }
    }
    .ant-drawer-content {
        border-radius: 24px 24px 0px 0px;
        box-shadow: 0px 4px 16px 0px rgba(98, 112, 140, 0.24);
    }
`;

const ContentQuestionWrapper = styled.div`
    &.has-pin-questions {
        margin-bottom: 380px;
    }
`;

const CameraWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: calc(100% - 64px);
    &.has-pin-questions {
        .camera-testing {
            bottom: 226px;
        }
        .camera-actions {
            bottom: 162px;
        }
        .camera-wrapper .micro-phone-icon {
            bottom: 240px;
        }
    }
`;

const ScrollDownContainer = styled.div`
    position: fixed;
    bottom: 32px;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateX(-50%);
    z-index: 12;
    .down-icon {
        cursor: pointer;
        bottom: -12px;
        border-radius: 50%;
        height: 18px;
        width: 18px;
        display: flex;
        left: 39%;
        z-index: 12;
        background-color: white;
        border: 1px solid var(--tc-primary-p-75);
    }
`;

export const TestDetail: React.FC = () => {
    const dispatch = useDispatch();
    const {
        url,
        assessmentDetails,
        setAssessmentDetails,
        sectionTesting,
        socket,
        setStep,
        setSectionTesting,
        companyProfile,
        openDrawer,
        setOpenDrawer,
        isPinQuestionList,
        setIsPinQuestionList,
    } = useTestingPageContext();
    const { loadCandidateAssessmentByURL, startSectionTesting } = useTesting();
    const [currentQuestionIndex, setCurrentQuestionIndex] = React.useState(0);
    const [answers, setAnswers] = React.useState<(number[] | number | undefined | string | string[])[]>([]);
    const [timeUsed, setTimeUsed] = React.useState(0);
    const [questionViewed, setQuestionViewed] = React.useState<Set<string>>(new Set());
    const { showError } = useNotification();
    const [errorCodeCandidate, setErrorCodeCandidate] = React.useState<number | undefined>(undefined);
    const [turnOffCamera, setTurnOffCamera] = React.useState(false);
    const [videoStream, setVideoStream] = React.useState<MediaStream | undefined>(undefined);
    const mainPageRef = React.useRef<any>(null);
    const [hasViewMore, setHasViewMore] = React.useState(true);

    const { isSticky, element } = useSticky();

    const durationQuestion = React.useMemo(() => {
        if (sectionTesting?.duration === undefined) {
            return undefined;
        }
        const now = Date.now();
        return Math.floor(((sectionTesting?.duration as number) * 60 * 1000 - (now - (sectionTesting?.startAt ?? now))) / 1000);
    }, [sectionTesting]);

    const timeLeft = React.useMemo(() => {
        return durationQuestion ? timeUsed > durationQuestion ? 0 : durationQuestion - timeUsed : undefined;
    }, [durationQuestion, timeUsed]);

    const questions = React.useMemo(() => {
        if (sectionTesting?.sections?.length) {
            const newQuestions = (sectionTesting?.sections?.map(section => section.questions).reduce((a, b) => [...a, ...b]) || []) || [];
            return newQuestions.map((question) => {
                return indexQuestion(question);
            }) as TQuestionTesting[];
        }
        return [];
    }, [sectionTesting]);

    React.useEffect(() => {
        if (socket) {
            const listenFinishSection = (args: any) => {
                if (sectionTesting?.nextSection) {
                    setStep(STEPS.FINISH_TEST);
                } else {
                    setStep(STEPS.SUBMIT_TEST);
                }
            }
            socket.on("finish-section", listenFinishSection);
            const listenSyncAnswer = (args: any) => {
                const { questionId, selectedAnswer } = args;
                const questionIndex = questions.findIndex(question => question._id === questionId);
                if (questionIndex < 0) {
                    return;
                }
                setAnswers(prev => {
                    const newAnswers = [...prev];
                    newAnswers[questionIndex] = selectedAnswer;
                    return newAnswers;
                });
            };
            socket.on(EVENT_NAME_SOCKET.RECEIVE.SYNC_ANSWER, listenSyncAnswer);
        }
    }, [socket, questions, sectionTesting, answers, setStep]);

    React.useEffect(() => {
        const now = Date.now();
        const interval = setInterval(() => {
            const seconds = Math.round((Date.now() - now) / 1000);
            setTimeUsed(seconds);
        }, 1000);
        return () => {
            clearInterval(interval);
        }
    }, []);
    const onEmitAntiCheat = React.useCallback(async (value: any) => {
        if (!socket) {
            showError("Lỗi", "Không thể kết nối đến server. Vui lòng thử lại sau.");
            return;
        }
        socket.on('reconnect', () => {
            console.warn('reconnect');
        });
        try {
            await socket.emitWithAck(EVENT_NAME_SOCKET.SEND.CHEATING_TEST, value);
        } catch (error) {
            console.warn("error", error);
        }
    }, [socket, showError]);

    const onChangeTab = React.useCallback(async (value: boolean) => {
        if (value) {
            onEmitAntiCheat({ changeTab: true });
        } else {
            onEmitAntiCheat({ backToTab: true })
        }
    }, [onEmitAntiCheat]);
    React.useLayoutEffect(() => {
        // const handleBlur = () => {
        //     onChangeTab(true);
        // }
        const handleVisibilityChange = () => {
            if (document.hidden) {
                onChangeTab(true);
            } else {
                onChangeTab(false)
            }
        };
        // window.addEventListener("blur", handleBlur);
        document.addEventListener("visibilitychange", handleVisibilityChange);
        // check exit fullscreeen
        const exitHandler = () => {
            if (!document.fullscreenElement) {
                console.warn("exitFullScreen");
                onEmitAntiCheat({ exitFullScreenMode: true });
            }
        };
        // Check if user turn on full screen before
        setTimeout(() => {
            if (!document.fullscreenElement) {
                console.warn("exitFullScreen");
                onEmitAntiCheat({ exitFullScreenMode: true });
            }
        }, 1000);

        document.addEventListener('fullscreenchange', exitHandler);
        // check close Alt + F4 and close browser
        const handleKeyDown = (event: any) => {
            if (event.altKey && event.key === 'F4') {
                console.warn("closeBrowser");
                onEmitAntiCheat({ changeTab: true });
            }
        };
        document.addEventListener("keydown", handleKeyDown);
        const closeBrowser = (event: any) => {
            console.warn("closeBrowser");
            onEmitAntiCheat({ changeTab: true });
            event.preventDefault();
            return event.returnValue = 'Are you sure you want to close?';
        }
        window.addEventListener("beforeunload", closeBrowser);

        return () => {
            // window.removeEventListener("blur", handleBlur);
            document.removeEventListener("visibilitychange", handleVisibilityChange);
            document.removeEventListener('fullscreenchange', exitHandler);
            window.removeEventListener("beforeunload", closeBrowser);
        }
    }, []);

    const hasScrollOnPage = React.useMemo(() => {
        const { scrollHeight, clientHeight } = document.documentElement;
        return scrollHeight > clientHeight;
    }, []);

    React.useEffect(() => {
        if (turnOffCamera) {
            console.warn("turnOffCamera", turnOffCamera);
            onEmitAntiCheat({ turnOnCamera: false });
        }
    }, [turnOffCamera]);

    const loadStartSection = React.useCallback(async (coords: any) => {
        try {
            dispatch(setLoading(true));
            const { latitude, longitude } = coords || {};
            const payload: IStartSection = {
                url,
                id: assessmentDetails?._id,
                lat: latitude || 0,
                long: longitude || 0,
            };
            await startSectionTesting(payload, setSectionTesting, setErrorCodeCandidate);
            dispatch(setLoading(false));
        } catch (error) {
            showError("Lỗi", "Không thể kết nối đến server. Vui lòng thử lại sau.");
        }
        finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, url, assessmentDetails, startSectionTesting, setSectionTesting, showError]);

    React.useEffect(() => {
        if (assessmentDetails.status === ECandidateAssessmenStatus.DONE) {
            setStep(assessmentDetails?.isSubmitted ? STEPS.END_TEST : STEPS.SUBMIT_TEST);
        }
        if (assessmentDetails?._id) {

            navigator.permissions.query({ name: "geolocation" }).then((result: any) => {
                console.warn("geolocation", result);
                if (result?.state === "denied") {
                    loadStartSection({});
                } else {
                    if (navigator?.geolocation?.getCurrentPosition) {
                        dispatch(setLoading(true));
                        navigator.geolocation.getCurrentPosition(position => {
                            dispatch(setLoading(false));
                            loadStartSection(position.coords);
                        }, () => {
                            dispatch(setLoading(false));
                            loadStartSection({});
                        });
                    } else {
                        loadStartSection({});
                    }
                }
            });
        }
    }, [assessmentDetails, dispatch]);

    const onChangeCorrectAnswer = React.useCallback(async (answer: number[] | number | undefined | string | string[]) => {
        if (!socket) {
            showError("Lỗi", "Không thể kết nối đến server. Vui lòng thử lại sau.");
            return;
        }
        setAnswers(prev => {
            const newAnswers = [...prev];
            newAnswers[currentQuestionIndex] = answer;
            return newAnswers;
        });
        await socket.emitWithAck(EVENT_NAME_SOCKET.SEND.ANSWER_QUESTION, {
            questionId: questions[currentQuestionIndex]._id,
            selectedAnswer: answer,
        });
    }, [currentQuestionIndex, socket, questions, showError]);

    const onNavigateQuestion = React.useCallback((index: number) => {
        if (index < 0 || index >= questions.length) {
            return;
        }
        setQuestionViewed(prev => {
            if (prev.has(questions[index]._id)) {
                return prev;
            }
            const newSet = new Set(prev);
            newSet.add(questions[index]._id);
            return newSet;
        });
        setCurrentQuestionIndex(index);
    }, [questions]);

    const questionsLength = React.useMemo(() => {
        if (sectionTesting?.sections?.length) {
            return sectionTesting.sections.map(section => section.questions.length).reduce((a, b) => a + b);
        }
        return 0;
    }, [sectionTesting]);

    const recheckTesting = React.useCallback(async () => {
        if (!socket) {
            showError("Lỗi", "Không thể kết nối đến server. Vui lòng thử lại sau.");
            return;
        }
        loadCandidateAssessmentByURL(url, setAssessmentDetails, setErrorCodeCandidate);
    }, [socket, showError, setAssessmentDetails, loadCandidateAssessmentByURL, url]);

    const onFinishTest = React.useCallback(async () => {
        if (!socket) {
            showError("Lỗi", "Không thể kết nối đến server. Vui lòng thử lại sau.");
            return;
        }
        socket.on('reconnect', () => {
            console.warn('reconnect');
        });
        console.warn('socket', socket);
        if (!socket.connected) {
            recheckTesting();;
        }
        await socket.emitWithAck(EVENT_NAME_SOCKET.SEND.SUBMIT_SECTION);
        if (videoStream) {
            videoStream.getTracks().forEach(track => track.stop());
        }
        if (sectionTesting?.nextSection) {
            setStep(STEPS.FINISH_TEST);
        } else {
            setStep(STEPS.SUBMIT_TEST);
        }
    }, [videoStream, sectionTesting, recheckTesting, setStep, socket, showError]);

    React.useEffect(() => {
        if (timeLeft === 0) {
            onFinishTest();
        }
    }, [timeLeft, onFinishTest]);
    React.useEffect(() => {
        if (sectionTesting?.sections?.length) {
            const newQuestions = (sectionTesting?.sections?.map(section => section.questions).reduce((a, b) => [...a, ...b]) || []) || [];
            const newAnswers = newQuestions.map((question) => {
                return question?.selectedAnswer || (question.answer as string);
            });
            setAnswers(newAnswers);
        }
    }, [sectionTesting]);

    const titleError = React.useMemo(() => {
        if (errorCodeCandidate === ERROR_CODE.LOCKED) {
            return ERROR_CODE_TITLE_MESSAGE[errorCodeCandidate];
        }
        return undefined;
    }, [errorCodeCandidate]);
    const descriptionError = React.useMemo(() => {
        if (errorCodeCandidate === ERROR_CODE.LOCKED) {
            return ERROR_CODE_DESC_MESSAGE[errorCodeCandidate];
        }
        return undefined;
    }, [errorCodeCandidate]);

    const questionNotAnswered = React.useMemo(() => {
        return questions.filter((question, index) => {
            if (question.type === EQuestionType.SINGLE_CHOICE || question.type === EQuestionType.ESSAY) {
                const isCorrect = Array.isArray(answers) && answers[index] === 0 ? true : answers && answers[index];
                return !isCorrect;
            }
            if (question.type === EQuestionType.MULTIPLE_CHOICE) {
                return !answers || _.isEmpty(answers[index]);
            }
            return false;
        })?.length;
    }, [questions, answers]);

    const onScrollDownPage = React.useCallback(() => {
        window.scrollTo(0, document.body.scrollHeight);
        window.scrollTo(0, document.documentElement.scrollTop);
        // setHasViewMore(false);
    }, []);

    return errorCodeCandidate ?
        <ErrorLink title={titleError} description={descriptionError} /> :
        <MainPage ref={mainPageRef} className="container mx-auto unselectable">
            <div
                ref={element}
                className={classNames([
                    "flex items-center",
                    {
                        'sticky-header-testing shadow-e-03': isSticky,
                    }
                ])}
            >
                <div className="container flex justify-between items-center gap-[16px] flex-wrap">
                    <div>
                        <TimerQuestion
                            totalTimes={durationQuestion}
                            leftTimes={timeLeft}
                            totalQuestion={questionsLength}
                            setOpenDrawer={setOpenDrawer}
                            isPinQuestionList={isPinQuestionList}
                            answers={answers}
                            questions={questions}
                        />
                    </div>
                    <div className="flex gap-[16px]">
                        {sectionTesting?.sections?.length > 0 && <HRButton
                            btnType="sub"
                            btnSize="md"
                            onClick={() => {
                                onNavigateQuestion(currentQuestionIndex - 1);
                            }}
                            disabled={currentQuestionIndex === 0}
                        >
                            <div className="flex space-x-2 items-center justify-center">
                                <ICBack fill={currentQuestionIndex === 0 ? "var(--tc-gray-g-200)" : "var(--icon-high-em)"} />
                                <span>Câu trước</span>
                            </div>
                        </HRButton>}
                        {
                            sectionTesting?.sections?.length === 0 || currentQuestionIndex === questionsLength - 1 ?
                                <HRButton
                                    btnType="primary"
                                    btnSize="md"
                                    style={{
                                        backgroundColor: companyProfile?.color?.backgroundColor,
                                        borderColor: companyProfile?.color?.backgroundColor,
                                        color: companyProfile?.color?.fontColor || "#fff",
                                    }}
                                    onClick={onFinishTest}>
                                    <div className="flex space-x-2 items-center justify-center">
                                        <ICCheckV2 fill={(companyProfile?.color?.fontColor || "#fff")} />
                                        <span>Hoàn tất</span>
                                    </div>
                                </HRButton> :
                                <HRButton
                                    btnType="primary"
                                    btnSize="md"
                                    style={{
                                        backgroundColor: companyProfile?.color?.backgroundColor,
                                        borderColor: companyProfile?.color?.backgroundColor,
                                        color: companyProfile?.color?.fontColor || "#fff",
                                    }}
                                    onClick={() => {
                                        onNavigateQuestion(currentQuestionIndex + 1);
                                    }}
                                >
                                    <div className="flex space-x-2 items-center justify-center">
                                        <span>Tiếp tục</span>
                                        <ICNext fill={(companyProfile?.color?.fontColor || "#fff")} />
                                    </div>
                                </HRButton>
                        }
                    </div>
                </div>
            </div>
            <ContentQuestionWrapper
                className={classNames([
                    "mt-[40px] z-10 mb-[250px]",
                    {
                        'has-pin-questions': isPinQuestionList,
                    }
                ])}
            >
                {sectionTesting && sectionTesting.sections && questions[currentQuestionIndex] && <QuestionRender
                    question={questions[currentQuestionIndex] as TQuestion}
                    onChangeCorrectAnswer={onChangeCorrectAnswer}
                    correctAnswer={answers[currentQuestionIndex]}
                    key={questions[currentQuestionIndex]._id}
                    currentQuestionIndex={currentQuestionIndex + 1}
                />}
            </ContentQuestionWrapper>
            <CameraWrapper className={classNames([
                'camera-wrapper-main',
                {
                    'has-pin-questions': isPinQuestionList,
                }
            ])}>
                {/* Will be open this code after */}
                {/* <div className="flex items-center">
                    <span>
                        <HRButton
                            shape="circle"
                            btnSize="sm"
                            btnType="sub"
                            style={{
                                borderRadius: '144px',
                            }}
                        >
                            <div className="flex gap-[8px] items-center justify-center">
                                <ICHeadPhone />
                                <span className=" text-body-medium text-icon-high-em">Hỗ trợ</span>
                            </div>
                        </HRButton>
                    </span>
                </div> */}
                <CameraViewer
                    allowTurnOffCamera={!assessmentDetails.assessment?.forceTurnOnCamera}
                    setTurnOffCamera={setTurnOffCamera}
                    setVideoStream={setVideoStream}
                />
            </CameraWrapper>

            {hasViewMore && hasScrollOnPage && <ScrollDownContainer onClick={onScrollDownPage}>
                <div className="relative flex flex-col">
                    <HRButton btnType="sub_rounded_primary" btnSize="xs">
                        <div className="flex flex-col items-center justify-center gap-[8px]">
                            <span className="text-primary text-sub-medium">Xem thêm</span>
                        </div>
                    </HRButton>
                    <div className="absolute down-icon">
                        <ICChevronDown stroke="var(--text-primary)" width={18} height={18} />
                    </div>
                </div>
            </ScrollDownContainer>}

            {/* view questions by Drawer */}
            <DrawerContent
                getContainer={mainPageRef.current}
                title={
                    <div className="text-primary-bold text-heading-6-semi-bold">
                        Danh sách câu hỏi
                    </div>
                }
                width={440}
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                closable={true}
                closeIcon={<ICClose fill="var(--icon-med-em)" width={18} height={18} />}
                bodyStyle={{ padding: '0', gap: '24px' }}
                extra={
                    <div>
                        <HRButton btnType="tertiary" btnSize="sm" onClick={() => {
                            setIsPinQuestionList(true);
                            setOpenDrawer(false);
                        }}>
                            <div className="flex items-center gap-[8px]">
                                <ICPin />
                                <div className="text-high-em text-body-medium">Ghim danh sách</div>
                            </div>
                        </HRButton>
                    </div>
                }
            >
                <div className="flex flex-col gap-[32px]">
                    <div className="flex flex-col gap-[16px] py-[16px] px-[32px]">
                        {sectionTesting && sectionTesting.sections && questions[currentQuestionIndex] && <QuestionListDraw
                            currentQuestionIndex={currentQuestionIndex}
                            onNavigateQuestion={onNavigateQuestion}
                            correctAnswer={answers as Array<number[] | number | undefined | string>}
                            questionViewed={questionViewed}
                            hasPinQuestions={isPinQuestionList}
                            questionData={questions} />}
                    </div>

                </div>
            </DrawerContent>
            <DrawerBottom
                getContainer={mainPageRef.current}
                title={
                    <div className="flex items-center justify-center gap-[16px]">
                        <div className="text-body-bold text-high-em max-w-[300px] line-clamp-1">
                            {sectionTesting?.title}
                        </div>
                        <div className="flex items-center gap-[8px] bg-surface-low px-[12px] py-[4px] rounded-[8px]">
                            <div className="text-sub text-high-em">
                                Chưa trả lời: {questionNotAnswered} câu
                            </div>
                            <div className="flex items-center">
                                <HRButton btnSize="xs" btnType="sub_info">
                                    <div className="flex gap-[8px] items-center">
                                        <span className=" text-tiny-medium text-info-500">Định vị</span>
                                        <ICPointMap />
                                    </div>

                                </HRButton>
                            </div>
                        </div>
                    </div>
                }
                height={130}
                placement="bottom"
                open={isPinQuestionList}
                mask={false}
                closable={false}
                maskClosable={true}
                bodyStyle={{ padding: '0', gap: '24px' }}
                contentWrapperStyle={{ boxShadow: 'none' }}
                extra={
                    <div className="flex gap-[16px]">
                        <IconButton
                            btnType="sub"
                            btnSize="sm"
                            icon={<ICArrowBack width={18} height={18} />}
                            onClick={() => {
                                onNavigateQuestion(currentQuestionIndex - 1);
                            }}
                        />
                        <IconButton
                            btnType="sub"
                            btnSize="sm"
                            icon={<ICArrowForward width={18} height={18} />}
                            onClick={() => {
                                onNavigateQuestion(currentQuestionIndex + 1);
                            }}
                        />
                        <span className="bg-outlines-med h-[40px] w-[1px]">
                        </span>
                        <HRButton btnType="tertiary" btnSize="sm" onClick={() => {
                            setIsPinQuestionList(false);
                            setOpenDrawer(false);
                        }}>
                            <div className="flex items-center">
                                <ICUnPin />
                            </div>
                        </HRButton>
                    </div>
                }
            >
                <div className="flex flex-col gap-[32px]">
                    <div className="flex flex-col gap-[16px] py-[12px] px-[48px]">
                        {sectionTesting && sectionTesting.sections && questions[currentQuestionIndex] && <QuestionListDraw
                            currentQuestionIndex={currentQuestionIndex}
                            onNavigateQuestion={onNavigateQuestion}
                            correctAnswer={answers as Array<number[] | number | undefined | string>}
                            questionViewed={questionViewed}
                            hasPinQuestions={isPinQuestionList}
                            questionData={questions} />}
                    </div>

                </div>
            </DrawerBottom>
        </MainPage>
}
