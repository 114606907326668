import { Tabs, Tooltip } from "antd"
import { useCreateTestPageContext } from ".."
import { ICLock, ICAlertCircle } from "../../../icons";
import { HomeTab } from "./home"
import { QuestionTab } from "./question/index";
import { OverviewTab } from "./overview"
import { HRTooltip } from "../../../components/tooltip";

type TableHeaderProps = {
    title: string,
    icon?: React.ReactNode
}
const TableHeader: React.FC<TableHeaderProps> = ({
    title,
    icon
}) => {
    return <>
        {icon && <HRTooltip
            placement="bottom"
            content={`Nhấn “Lưu” sau khi điền thông tin Trang chủ trước khi đến phần ${title}`}
            hasInfoIcon={true}
            childNode={(
                    <div className="flex items-center justify-center">
                        <div className="flex space-x-2 items-center justify-center text-standard-bold text-high-em">
                            {icon}
                            <span>{title}</span>
                        </div>
                    </div>
                )} 
        />}

        {!icon && <div className="flex items-center justify-center">
            <div className="flex space-x-2 items-center justify-center text-standard-bold text-high-em">
                {icon}
                <span>{title}</span>
            </div>
        </div>}
    </>
}
const TABS_CONFIG = [{
    label: `Tổng quan`,
    children: <OverviewTab />,
    icon: <ICLock />
}, {
    label: `Trang Chủ`,
    children: <HomeTab />,
}, {
    label: `Câu hỏi`,
    children: <QuestionTab />,
    icon: <ICLock />
}];

export const CreateTestTabs: React.FC = () => {
    const { mode, selectedTabIndex, setSelectedTabIndex } = useCreateTestPageContext();
    return (
        <Tabs
            activeKey={`${selectedTabIndex + 1}`}
            onChange={(key) => setSelectedTabIndex(Number(key) - 1)}
            className="full-width-tabs"
            type="card"
            items={TABS_CONFIG.map((tab, index) => ({
                key: `${index + 1}`,
                label: <TableHeader title={tab.label} icon={mode === 'create' ? tab.icon : null} />,
                children: tab.children,
                disabled: mode === 'create' && index !== 1
            }))}
            renderTabBar={(props, DefaultTabBar) => {
                return (
                    <DefaultTabBar
                        {...props}
                        style={{
                            width: '100%',
                            padding: '0 0px',
                            borderRadius: '10px 10px 0 0',
                            margin: 0,
                            height: '56px'
                        }}
                    />
                )
            }}
        />
    )
}
