import { ApiInstance } from "..";

export type User = {
    name: string,
    email: string,
    phoneNumber: string,
    dateOfBirth: number
    featuredName: string
    avatar: string
    backgroundImage: string
    nationalityId: string
    introduce: string
    positionId: string
}

export const updateUserInfo = (userInfo: Partial<User>) => {
    const token = JSON.parse(localStorage.getItem("userInfo") || "{}").token;
    const instance = ApiInstance.getInstance();
    ApiInstance.setToken(token);

    return instance.put("/api/users/me", userInfo, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export const getUserInfo = (accessToken?: string) => {
    if (accessToken) {
        ApiInstance.setToken(accessToken);
        return ApiInstance.getInstance().get("/api/users/me", {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });
    }
    return ApiInstance.getInstance().get("/api/users/me");
}

export const changePasswordAPI = (currentPassword: string, newPassword: string) => {
    return ApiInstance.getInstance().put("/api/users/me/change-my-password", {
        currentPassword,
        newPassword
    });
}

export const getMyCandidateCreditInfo = () => {
    return ApiInstance.getInstance().get("/api/users/me/my-candidate-credit-info");
}
