import React from 'react';
import { useParams } from 'react-router-dom';
import { usePayment } from '../payment/hooks/usePayment';
import { EDifinition, TOrderDetails } from './type';
import { PaymentSuccessAssessmentPage } from './success_assessment';
import { PaymentFailedPage } from './failed';
import { PaymentSuccessCandidateCreditPage } from './success_candidate_credit';

type TPaymentStatusPage = {
    orderDetails?: TOrderDetails;
    setOrderDetails: React.Dispatch<React.SetStateAction<TOrderDetails>>;
}
export const PaymentStatusPageContext = React.createContext<TPaymentStatusPage>({} as TPaymentStatusPage);
export const usePaymentStatusPageContext = () => React.useContext(PaymentStatusPageContext);

export const PaymentStatusPage: React.FC = () => {
    const { orderId = '', status = '' } = useParams();
    const [orderDetails, setOrderDetails] = React.useState<TOrderDetails>({} as TOrderDetails);
    const { loadOrderDetails } = usePayment();

    React.useEffect(() => {
        if (orderId) {
            loadOrderDetails(orderId, setOrderDetails);
        }
    }, []);

    return <PaymentStatusPageContext.Provider
        value={{
            orderDetails,
            setOrderDetails,
        }}
    >
        <div className="container mx-auto rounded-[12px] flex justify-center items-center py-[45px]">
            {status === 'success' && orderDetails?.difinition?.type === EDifinition.ASSESSMENT && <PaymentSuccessAssessmentPage />}
            {status === 'success' && orderDetails?.difinition?.type === EDifinition.CANDIDATE_CREDIT && <PaymentSuccessCandidateCreditPage />}
            {status === 'failed' && <PaymentFailedPage />}
        </div>
    </PaymentStatusPageContext.Provider>
}
