import React from "react";
import { useWatch } from "antd/lib/form/Form";
import { FormInstance } from "antd";
import { compareObjects } from "../../../../utils/index";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useNotification } from "../../../../hooks/useNotification";
import { generateURLAssessment, saveAssessment, updateActiveURL, updateAssessment, updateAssessmentAdditionalInfo, updateAutoInvite, updatePasswordAssessment } from "../../../../api/assessment";
import { setLoading } from "../../../../redux/slices/appInfo";
import { TAssessment, TEmailTemplate } from "../type";
import { getInvitation, getMyCandidate, inviteCandidateAPI, inviteListCandidateAPI, removeInvitation } from "../../../../api/candidate";
import { TCandidate, TCandidateRequest, TInvitationCandidate, TUpdatePasswordAssessment } from "../../type";
import { removeMultiInvitation } from "../../../../api/test-candidate";
import { Errors, isEqualError } from "../../../../api/constants";
import { ERROR_CODE } from "../../../../utils/constants";

export const useGeneralAssessment = (
    form: FormInstance,
    setStep: (value: number) => void,
    setAssessment: (value: TAssessment) => void,
    initValuesForm?: any,
) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { showSuccess, showError } = useNotification();

    const title = useWatch('title', form);
    const role = useWatch('role', form);
    const language = useWatch('language', form);
    const introduce = useWatch('introduce', form);

    const hasChangedValues = React.useMemo(() => {
        const values = {
            title,
            introduce,
            language,
            role,
        };
        return !compareObjects(values, initValuesForm);
    }, [
        title,
        introduce,
        language,
        role,
        initValuesForm
    ]);

    const isValidGeneralForm = React.useMemo(() => {
        return !title || !role;
    }, [title, role]);

    const saveGeneralInformation = React.useCallback(async (values: any) => {
        dispatch(setLoading(true));
        try {
            const result = await saveAssessment(values);
            setAssessment(result.data);
            setStep(1);
            showSuccess('Thành công');
            navigate(`/edit-assessment/${result.data._id}`);
        } catch (error: any) {
            console.error(error);
            showError("Error", error?.response?.statusText);
        }
        finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, showSuccess, navigate, showError, setStep, setAssessment]);

    const updateGeneralInformation = React.useCallback(async (id: string, values: any) => {
        dispatch(setLoading(true));
        try {
            const result = await updateAssessment(id, values);
            setAssessment(result.data);
            setStep(1);
            navigate(`/edit-assessment/${result.data._id}`);
        } catch (error: any) {
            console.error(error);
            showError("Error", error?.response?.statusText);
        }
        finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, navigate, showError, setStep, setAssessment]);

    const updatedAssessment = React.useCallback(async (id: string, values: any) => {
        dispatch(setLoading(true));
        try {
            return await updateAssessment(id, values);
        } catch (error: any) {
            console.error(error);
            const errorCode = error?.response?.data?.errorCode || '';
            if (errorCode === ERROR_CODE.ASSESSMENT_WAITING_FOR_PAYMENT) {
                showError("", "Bài đánh giá đang được thanh toán. Xin vui lòng thử lại sau.");
                return;
            }
            if (errorCode === ERROR_CODE.PAYMENT_FAILED) {
                navigate(`/assessment/${id}/details`);
                return;
            }
            showError("Error", error?.response?.statusText);
        }
        finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, navigate, showError]);

    return {
        hasChangedValues,
        isValidGeneralForm,
        updatedAssessment,
        saveGeneralInformation,
        updateGeneralInformation,
    }
}

export const useAssessment = () => {
    const dispatch = useDispatch();
    const { showSuccess, showError } = useNotification();

    const loadMyCandidate = React.useCallback(async (values: any, setCandidateList: React.Dispatch<React.SetStateAction<TCandidate[]>>) => {
        dispatch(setLoading(true));
        try {
            const result = await getMyCandidate(values);
            setCandidateList(result.data.data || []);
        } catch (error: any) {
            console.error(error);
            showError("Error", error?.response?.data?.errorMessage || error?.response?.statusText);
        }
        finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, showError]);

    const loadInvitation = React.useCallback(async (id: string, values: any, setCandidateList: React.Dispatch<React.SetStateAction<TCandidate[]>>) => {
        dispatch(setLoading(true));
        try {
            const result = await getInvitation(id, values);
            setCandidateList(result.data.data || []);
        } catch (error: any) {
            console.error(error);
            showError("Error", error?.response?.data?.errorMessage || error?.response?.statusText);
        }
        finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, showError]);

    const generateURL = React.useCallback(async (id: string, setAssessmentDetails: React.Dispatch<React.SetStateAction<TAssessment>>) => {
        dispatch(setLoading(true));
        try {
            const result = await generateURLAssessment(id);
            showSuccess('Tạo liên kết thành công');
            if (setAssessmentDetails) {
                setAssessmentDetails((prev: any) => ({
                    ...prev,
                    url: result.data.url,
                }));
            }
        } catch (error: any) {
            console.error(error);
            showError("Error", error?.response?.data?.errorMessage || error?.response?.statusText);
        }
        finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, showError, showSuccess]);

    const setPasswordAssessment = React.useCallback(async (id: string, password: string, setAssessmentDetails: React.Dispatch<React.SetStateAction<TAssessment>>) => {
        dispatch(setLoading(true));
        try {
            const payload: TUpdatePasswordAssessment = {
                password,
            };
            const result = await updatePasswordAssessment(id, payload);
            showSuccess('Thiết lập mật khẩu thành công');
            if (setAssessmentDetails) {
                setAssessmentDetails((prev: any) => ({
                    ...prev,
                    password: result.data.password,
                }));
            }
        } catch (error: any) {
            console.error(error);
            showError("Error", error?.response?.data?.errorMessage || error?.response?.statusText);
        }
        finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, showError, showSuccess]);

    const inviteCandidate = React.useCallback(async (assessmentId: string, values: TCandidateRequest,
        setCandidateList: React.Dispatch<React.SetStateAction<TCandidate[]>>,
        setAssessmentDetails: React.Dispatch<React.SetStateAction<TAssessment>>,
        reloadData: () => void
    ) => {
        dispatch(setLoading(true));
        try {
            const payload: TCandidateRequest = {
                email: values.email.trim(),
                lastName: (values.lastName || '').trim(),
                firstName: (values.firstName || '').trim(),
            };
            const result = await inviteCandidateAPI(assessmentId, payload);
            const newData = result.data || {} as TCandidate;
            if (setCandidateList) {
                setCandidateList((prev: any) => {
                    return [
                        ...prev,
                        newData,
                    ]
                });
            }
            if (setAssessmentDetails) {
                setAssessmentDetails((prev: any) => ({
                    ...prev,
                    totalCandidates: prev.totalCandidates + 1,
                }));
            }
            showSuccess(`Đã mời ${values.email} thành công`);
            reloadData && reloadData();
        } catch (error: any) {
            console.error(error);
            const errorCode = error?.response?.data?.errorCode || '';
            if (errorCode === ERROR_CODE.MAX_INVITE_CANDIDATE_PER_HOUR || isEqualError(error, Errors.MaxInviteCandidatePerHour)) {
                showError("", "Bạn thực hiện thao tác quá nhanh. Vui lòng thử lại sau 1 giờ.");
            }
            else showError("Error", error?.response?.data?.errorMessage || error?.response?.statusText);
        }
        finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, showError, showSuccess]);

    const inviteListCandidate = React.useCallback(async (assessmentId: string, values: TCandidateRequest[]) => {
        dispatch(setLoading(true));
        try {
            const payload: TCandidateRequest[] = values.map(value => ({
                email: value.email.trim(),
                lastName: (value.lastName || '').trim(),
                firstName: (value.firstName || '').trim(),
            }));
            await inviteListCandidateAPI(assessmentId, {
                candidates: payload
            });
            showSuccess(`Đã mời ${values.length} ứng viên thành công`);
        } catch (error: any) {
            console.error(error);
            const errorCode = error?.response?.data?.errorCode || '';
            if (errorCode === ERROR_CODE.MAX_INVITE_CANDIDATE_PER_HOUR || isEqualError(error, Errors.MaxInviteCandidatePerHour)) {
                showError("", "Bạn thực hiện thao tác quá nhanh. Vui lòng thử lại sau 1 giờ.");
            }
            else showError("Error", error?.response?.data?.errorMessage || error?.response?.statusText);
        }
        finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, showError, showSuccess]);

    const removeInvitationCandidate = React.useCallback(async (
        payload: TInvitationCandidate,
        onReload: () => void,
        successMessage: string = 'Đã xóa lời mời thành công',
    ) => {
        dispatch(setLoading(true));
        try {
            await removeInvitation(payload);
            showSuccess(successMessage);
            onReload && onReload();
        } catch (error: any) {
            showError("Error", error?.response?.data?.errorMessage || error?.response?.statusText);
        }
        finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, showError, showSuccess]);

    const removeInvitationMultiCandidate = React.useCallback(async (assessmentId: string, testCandidateIds: string[], onReload: () => void,) => {
        dispatch(setLoading(true));
        try {
            await removeMultiInvitation(assessmentId, testCandidateIds);
            showSuccess('Đã xóa lời mời thành công');
            onReload && onReload();
        } catch (error: any) {
            showError("Error", error?.response?.data?.errorMessage || error?.response?.statusText);
        }
        finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, showError, showSuccess]);

    const saveStatusAssessment = React.useCallback(async (id: string, payload: any, setAssessmentDetails: React.Dispatch<React.SetStateAction<TAssessment>>) => {
        dispatch(setLoading(true));
        try {
            const result = await updateActiveURL(id, payload);
            dispatch(setLoading(false));
            if (setAssessmentDetails) {
                setAssessmentDetails((prev: any) => ({
                    ...prev,
                    activePassword: result.data.activePassword,
                    activeUrl: result.data.activeUrl,
                }));
            }
        } catch (error: any) {
            console.error(error);
            showError("Error", error?.response?.data?.errorMessage || error?.response?.statusText);
        }
        finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, showError]);

    const activePasswordAssessment = React.useCallback(async (id: string, payload: TUpdatePasswordAssessment, setAssessmentDetails: React.Dispatch<React.SetStateAction<TAssessment>>) => {
        dispatch(setLoading(true));
        try {
            const result = await updatePasswordAssessment(id, payload);
            dispatch(setLoading(false));
            if (setAssessmentDetails) {
                setAssessmentDetails((prev: any) => ({
                    ...prev,
                    activePassword: result.data.activePassword,
                    activeUrl: result.data.activeUrl,
                }));
            }
        } catch (error: any) {
            console.error(error);
            showError("Error", error?.response?.data?.errorMessage || error?.response?.statusText);
        }
        finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, showError]);

    const saveAutoInviteAssessment = React.useCallback(async (id: string, isAutoInvite: boolean, setAssessmentDetails: React.Dispatch<React.SetStateAction<TAssessment>>) => {
        dispatch(setLoading(true));
        try {
            const result = await updateAutoInvite(id, isAutoInvite);
            dispatch(setLoading(false));
            if (setAssessmentDetails) {
                setAssessmentDetails((prev: any) => ({
                    ...prev,
                    isAutoInvite: result.data.isAutoInvite,
                }));
            }
        } catch (error: any) {
            console.error(error);
            showError("Error", error?.response?.data?.errorMessage || error?.response?.statusText);
        }
        finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, showError]);

    const updateEmailTemplate = React.useCallback(async (id: string, payload: TEmailTemplate, setAssessmentDetails: React.Dispatch<React.SetStateAction<TAssessment>>) => {
        dispatch(setLoading(true));
        try {
            const result = await updateAssessmentAdditionalInfo(id, {
                emailTemplate: payload,
            });
            if (setAssessmentDetails) {
                setAssessmentDetails((prev: any) => ({
                    ...prev,
                    emailTemplate: result.data.emailTemplate,
                }));
            }
            showSuccess('Thành công');
        } catch (error: any) {
            console.error(error);
            showError("Error", error?.response?.data?.errorMessage || error?.response?.statusText);
        }
        finally {
            dispatch(setLoading(false));
        }
    }, []);
    return {
        generateURL,
        setPasswordAssessment,
        loadMyCandidate,
        inviteCandidate,
        saveStatusAssessment,
        saveAutoInviteAssessment,
        loadInvitation,
        removeInvitationCandidate,
        removeInvitationMultiCandidate,
        activePasswordAssessment,
        updateEmailTemplate,
        inviteListCandidate
    }
}
