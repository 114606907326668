import React, { useCallback } from "react";
import { HRInput } from "../../components/form/input";
import { ICCheckV2, ICCreateLinkV2, ICDelete, ICUploadFile, ICUploadFileHover, ICUploadV2 } from "../../icons";
import { HRButton } from "../../components/button";
import { isValidEmail } from "../../utils";
import { isValidPhoneNumber } from "react-phone-number-input";
import { Typography, notification, Upload, UploadProps } from "antd";
import { useDispatch } from "react-redux";
import { setLoading } from "../../redux/slices/appInfo";
import { useParams } from "react-router-dom";
import { checkReceiveCvStatus, applyCV } from "../../api/assessment-cv";
import { useCandidateLayoutContext } from "../../layout/candidate";
import { getRoleOnAssessment } from "../../api/assessment";
import { RootState } from "../../redux/reducers";
import { useSelector } from "react-redux";
import _ from "lodash";
import styled from "styled-components";
import classNames from "classnames";
import { RcFile } from "antd/lib/upload";
import { useNotification } from "../../hooks/useNotification";

const { Dragger } = Upload;
const Wrapper = styled.div`
    &.has-file {
        .ant-upload.ant-upload-drag {
            border: none !important;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .ant-upload.ant-upload-btn,
        .ant-upload-drag-container {
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 230px;
            width: 100%;
        }
        
        .ant-upload.ant-upload-drag .ant-upload {
            padding: 0px;
        }
    }
    .ant-upload.ant-upload-drag {
        border-radius: 12px;
        border: 1px dashed #C6C5CA;
        background-color: #F8F8F8;
        min-height: 230px;
        .none-hover {
            display: flex;
        }
        .hovering {
            display: none;
        }
        &:hover {
            border: 1px dashed #0084FF;
            background-color: #F5FAFF;
            .none-hover {
                display: none;
            }
            .hovering {
                display: flex;
            }
        }
    }
    .ant-upload-list-text-container {
        display: none;
    }
`;

type TResume = {
    email: string;
    phoneNumber: string;
    name: string;
    attachment: string;
}
type ErrorResume = {
    email: string;
    phoneNumber: string;
    name: string;
    attachment: string;
}
export const ApplyResumePage: React.FC = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const commonData = useSelector((state: RootState) => state.commonData);
    const { companyProfile } = useCandidateLayoutContext();
    const [isEnableForApply, setIsEnableForApply] = React.useState<boolean>(true);
    const [isApplySuccess, setIsApplySuccess] = React.useState<boolean>(false);
    const { showError } = useNotification();
    const [fileUpload, setFileUpload] = React.useState<File | null>(null);
    const [roleId, setRoleId] = React.useState<string | null>(null);

    const setFileAfterUploaded = async (file: RcFile) => {
        setFileUpload(file);
    };

    const validationFile = useCallback((file: RcFile) => {
        return new Promise((resolve, reject) => {
            if (!file) {
                reject('File không tồn tại');
            }
            // pdf, doc, docx
            const whitelistFileTypes = [
                "application/pdf",
                "application/msword",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            ];
            if (!whitelistFileTypes.includes(file.type)) {
                showError('Lỗi', 'Bạn chỉ có thể tải lên file PDF/DOC/DOCX!');
                reject(false);
            }

            const isLt10M = file.size / 1024 / 1024 < 10;
            if (!isLt10M) {
                showError('Vượt quá kích thước tối đa', 'Kích thước tối đa: 10Mb');
                reject(false);
            }
            return resolve(true);
        })
    }, [showError]);

    const uploadProps: UploadProps = {
        name: 'file',
        multiple: false,
        accept: ".pdf, .doc, .docx",
        action: `${process.env.REACT_APP_API_URL}/api/uploader`,
        onChange(info) {
            const { status } = info.file;
            if (status === 'done') {
                notification.success({
                    message: "Thành công",
                    description: `${info?.file?.name} file uploaded successfully.`,
                    placement: "topRight"
                });
                setFileAfterUploaded(info.file.originFileObj as RcFile);
                onSetResume("attachment", info.file?.response?.fileUrl);

            } else if (status === 'error') {
                console.error(`${info?.file?.name} file upload failed.`);
            }
        },
        onDrop(e) {
            const file = e.dataTransfer?.files[0];
            return validationFile(file as RcFile);
        },
        beforeUpload: (file: RcFile) => {
            return new Promise((resolve, reject) => {
                if (!file) {
                    reject('File không tồn tại');
                }
                // pdf, doc, docx
                const whitelistFileTypes = [
                    "application/pdf",
                    "application/msword",
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                ];
                // validate file type
                if (!whitelistFileTypes.includes(file.type)) {
                    showError('Lỗi', 'Bạn chỉ có thể tải lên file PDF/DOC/DOCX!');
                    reject(false);
                }
                const isLt10M = file.size / 1024 / 1024 < 10;
                if (!isLt10M) {
                    showError('Vượt quá kích thước tối đa', 'Kích thước tối đa: 10Mb');
                    reject(false);
                }
                resolve(true);
            });
        },
        progress: {
            strokeColor: {
                '0%': '#108ee9',
                '100%': '#87d068',
            },
            strokeWidth: 3,
            format: percent => percent && `${parseFloat(percent.toFixed(2))}%`,
        },
    };

    React.useEffect(() => {
        dispatch(setLoading(true));
        checkReceiveCvStatus(id!).then(res => {
            const data = res.data;
            if (data?.enableReceiveCV) setIsEnableForApply(true);
            else setIsEnableForApply(false);
        }).finally(() => {
            dispatch(setLoading(false));
        })
    }, [id]);

    const loadCompanyProfileByAssessmentUrl = async () => {
        dispatch(setLoading(true));
        try {
            const { data } = await getRoleOnAssessment(id!);
            setRoleId(data?.role as string);
        } catch (e) {
            console.error(e);
        } finally {
            dispatch(setLoading(false));
        }
    };

    React.useEffect(() => {
        loadCompanyProfileByAssessmentUrl();
    }, [id]);

    const roleName = React.useMemo(() => {
        if (roleId === null || !roleId) return '';
        const roles = _.uniqBy(commonData?.['position'] || [], '_id');
        const role = roles.find((item) => item._id === roleId);
        return role?.label || '';
    }, [commonData, roleId]);

    const [resume, setResume] = React.useState<TResume>({
        email: "",
        phoneNumber: "",
        name: "",
        attachment: "",
    });
    const [errorResume, setErrorResume] = React.useState<ErrorResume>({
        email: "",
        phoneNumber: "",
        name: "",
        attachment: "",
    });

    const onSetResume = (key: keyof TResume, value: string) => {
        setResume({
            ...resume,
            [key]: value,
        });
    }

    React.useEffect(() => {
        if (resume) {
            if (resume.email) setErrorResume(prev => ({ ...prev, email: "" }));
            if (resume.phoneNumber) setErrorResume(prev => ({ ...prev, phoneNumber: "" }));
            if (resume.name) setErrorResume(prev => ({ ...prev, name: "" }));
            if (resume.attachment) setErrorResume(prev => ({ ...prev, attachment: "" }));
        }
    }, [resume]);

    const validate = () => {
        let newErrorResume = {
            email: "",
            phoneNumber: "",
            name: "",
            attachment: "",
        }
        if (!resume.email) {
            newErrorResume.email = "Email không được để trống";
        } else if (!isValidEmail(resume.email)) {
            newErrorResume.email = "Email không đúng định dạng";
        }
        if (!resume.phoneNumber) {
            newErrorResume.phoneNumber = "Số điện thoại không được để trống";
        } else if (!isValidPhoneNumber(resume.phoneNumber, "VN")) {
            newErrorResume.phoneNumber = "Số điện thoại không đúng định dạng";
        }
        if (!resume.name) {
            newErrorResume.name = "Họ và tên không được để trống";
        } else if (resume.name.length > 255) {
            newErrorResume.name = "Họ và tên không được quá 255 ký tự";
        }
        // TODO: will check later
        // if (!resume.attachment) {
        //     newErrorResume.attachment = "File CV không được để trống";
        // }

        setErrorResume(newErrorResume);
        return Object.values(newErrorResume).every((item) => item === "");
    }
    const onApply = async () => {
        // validate
        const isValid = validate();
        if (isValid) {
            dispatch(setLoading(true))
            try {
                await applyCV(id!, resume);
                setIsApplySuccess(true);
            } catch (e: any) {
                const { data } = e.response || {};
                const { errorCode, errorMessage } = data || {};
                if (errorCode === 400 && errorMessage === "Assessment is not active receive CV") {
                    notification.error({
                        message: "Bài đánh giá hiện tại tạm thời không nhận CV",
                        placement: "bottom",
                    });
                } else {
                    notification.error({
                        message: e.message,
                        placement: "bottom",
                    });
                }
            } finally {
                dispatch(setLoading(false))
            }
        }
    }

    const onRemoveFile = () => {
        setFileUpload(null);
        onSetResume("attachment", "");
    };

    return !isEnableForApply ? <NotAllowApply /> :
        !isApplySuccess ? <div className="flex w-full items-center justify-center">
            <div className="flex w-full flex-col px-[32px] py-[54px] rounded-[12px] shadow-e-03 overflow-hidden box-border bg-white max-w-[884px]">

                <div className="flex flex-col gap-[32px]">
                    <div className="flex flex-col justify-start gap-[8px]">
                        <div className="text-title-bold text-high-em">
                            {`Nộp CV ứng tuyển ${roleName} tại ${companyProfile?.name || "HUSA"}`}
                        </div>
                        <div className="text-standard text-high-em">Vui lòng điền các thống tin bên dưới để gửi đi CV của bạn.</div>
                    </div>

                    <div className="flex flex-row gap-[16px]">
                        <div className="flex flex-col w-1/2 gap-[6px]">
                            <div className="text-body-medium text-med-em">Họ và tên: <span className="text-error-500 text-body-medium">(*)</span></div>
                            <HRInput
                                isError={!!errorResume.name}
                                value={resume.name}
                                onChange={(e) => onSetResume('name', e.target.value)}
                                size="large"
                                maxLength={50}
                                placeholder="Nhập họ và tên"
                            />
                            <div className="pt-2 font-medium text-xs text-error-500">{errorResume.name}</div>
                        </div>

                        <div className="flex flex-col w-1/2 gap-[6px]">
                            <div className="text-body-medium text-med-em">Số điện thoại: <span className="text-error-500 text-body-medium">(*)</span></div>
                            <HRInput
                                isError={!!errorResume.phoneNumber}
                                value={resume.phoneNumber}
                                onChange={(e) => onSetResume('phoneNumber', e.target.value)}
                                size="large"
                                maxLength={50}
                                placeholder="Nhập số điện thoại"
                            />
                            <div className="pt-2 font-medium text-xs text-error-500">{errorResume.phoneNumber}</div>
                        </div>
                    </div>

                    <div className="flex flex-col gap-[6px]">
                        <div className="text-body-medium text-med-em">Email: <span className="text-error-500 text-body-medium">(*)</span></div>
                        <HRInput
                            isError={!!errorResume.email}
                            value={resume.email}
                            onChange={(e) => onSetResume('email', e.target.value)}
                            size="large"
                            maxLength={50}
                            placeholder="Nhập email"
                        />
                        <div className="pt-2 font-medium text-xs text-error-500">{errorResume.email}</div>
                    </div>

                    <div className="flex flex-col gap-[16px]">
                        <div className="flex flex-row gap-[16px]">
                            <div className="p-[16px] bg-surface-primary-accent-1 rounded-[44px]">
                                <ICCreateLinkV2 stroke="#7357FF" />
                            </div>
                            <div className="flex flex-col gap-[4px]">
                                <span className="text-body-bold text-high-em">CV đính kèm</span>
                                <span className="text-body italic text-med-em">Các loại file chấp nhận: pdf, doc, docx</span>
                            </div>

                        </div>
                        <Wrapper className={classNames([{
                            'has-file': fileUpload?.name,
                        }])}>
                            <Dragger {...uploadProps}>
                                {fileUpload?.name && <div className="w-full h-full flex flex-col justify-center items-center max-h-[230px] min-h-[230px] relative rounded-[12px] box-border overflow-hidden group">
                                    <div className="flex text-center justify-center items-center text-body-medium text-high-em">
                                        <span className="max-w-[240px] truncate">{fileUpload?.name}</span>
                                    </div>
                                    <div className="absolute bottom-[8px] left-[24px] hidden flex-row gap-[16px] group-hover:flex">
                                        <span>
                                            <HRButton btnType="sub" btnSize="xs">
                                                <div className="flex gap-[8px] items-center justify-center">
                                                    <ICUploadV2 fill="#4F4B5C" />
                                                    <span className="text-tiny-medium text-[#4F4B5C]">Thay đổi file</span>
                                                </div>
                                            </HRButton>
                                        </span>
                                        <span>
                                            <HRButton btnType="sub_danger" btnSize="xs" onClick={(event) => {
                                                event.stopPropagation();
                                                onRemoveFile();
                                            }}>
                                                <div className="flex gap-[8px] items-center justify-center">
                                                    <ICDelete width={12} height={12} fill="#F03D3D" />
                                                    <span className="text-tiny-medium text-[#F03D3D]">Xóa</span>
                                                </div>
                                            </HRButton>
                                        </span>
                                    </div>
                                </div>}
                                {!fileUpload?.name && <div className="flex flex-col justify-center items-center gap-[32px]">
                                    <ICUploadFile />
                                    <div className="hovering box-border overflow-hidden">
                                        <ICUploadFileHover />
                                    </div>
                                    <div className="flex">
                                        <span>
                                            <HRButton btnType="info" btnSize="sm" style={{
                                                borderRadius: '144px',
                                            }}>
                                                <div className="flex gap-[8px] items-center justify-center">
                                                    <ICUploadV2 />
                                                    <span className="text-body-medium text-white">Tải file lên</span>
                                                </div>
                                            </HRButton>
                                        </span>
                                    </div>
                                </div>}
                            </Dragger>
                            {errorResume.attachment && <div className="pt-2 font-medium text-xs text-error-500 pt-[16px]">{errorResume.attachment}</div>}
                        </Wrapper>
                    </div>

                    <div className="flex items-center justify-center">
                        <span>
                            <HRButton
                                btnSize="lg"
                                btnType="primary"
                                onClick={onApply}
                                style={{
                                    backgroundColor: companyProfile?.color?.backgroundColor,
                                    borderColor: companyProfile?.color?.backgroundColor,
                                    color: companyProfile?.color?.fontColor || "#fff",
                                }}
                            >
                                <div className="flex gap-[8px]">
                                    <ICCheckV2 fill={companyProfile?.color?.fontColor || "#fff"} />
                                    <span className="text-standard-bold">
                                        Xác nhận
                                    </span>
                                </div>
                            </HRButton>
                        </span>
                    </div>
                </div>

            </div>
        </div> : <ApplySuccess />
}

const ApplySuccess = () => {
    return <div className="flex w-full items-center justify-center">
        <div className="rounded-[12px] bg-white shadow-e-03 flex w-full max-w-[754px] flex-col items-center justify-center gap-[32px] py-[54px] px-[32px] overflow-hidden box-border">
            <div className="grid grid-cols-1 gap-[16px] items-start justify-center text-left">
                <div className="grid grid-cols-1 gap-[24px]">
                    <Typography.Title style={{
                        fontSize: "28px",
                        lineHeight: "40px",
                        color: "#110C22",
                    }}>Gửi CV ứng tuyển thành công</Typography.Title>
                    <Typography.Text style={{
                        fontSize: "16px",
                        lineHeight: "24px",
                        fontWeight: 400,
                        color: "#110C22",
                    }}>
                        Chúng tôi sẽ liên hệ với bạn trong thời gian sớm nhất
                    </Typography.Text>
                </div>

            </div>
        </div>
    </div>
}

const NotAllowApply = () => {
    return <div className="flex w-full items-center justify-center">
        <div className="rounded-[12px] bg-white shadow-e-03 flex w-full max-w-[754px] flex-col items-center justify-center gap-[32px] py-[54px] px-[32px]">
            <div className="grid grid-cols-1 gap-[16px] items-start justify-center text-left">
                <div className="grid grid-cols-1 gap-[24px]">
                    <Typography.Title style={{
                        fontSize: "28px",
                        lineHeight: "40px",
                        color: "#110C22",
                    }}>
                        Bạn không thể ứng tuyển vào vị trí này
                    </Typography.Title>
                    <Typography.Text style={{
                        fontSize: "16px",
                        lineHeight: "24px",
                        fontWeight: 400,
                        color: "#110C22",
                    }}>
                        Có vẻ như nhà tuyển dụng đã tạm dừng nhận CV ứng tuyển vào vị trí này
                    </Typography.Text>
                </div>

            </div>
        </div>
    </div>
}
