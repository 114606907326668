import React from "react";
import { ISearchCriteria } from "../../../../library/models";
import debouce from 'lodash/debounce';
import { ICFilter, ICSearch } from "../../../../../icons";
import { HRInput } from "../../../../../components/form/input";
import { HRSelect } from "../../../../../components/form/select";
import { IconButton } from "../../../../../components/button";
import { FilterModal } from './components/filter-modal';
import { MY_COMPANY_TESTS } from "../../../../../components/filter";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/reducers";
import { UserRoles } from "../../../../../redux/slices/userInfo";

type TestFilterProps = {
    onChange: (value: any) => void;
    filterParams: ISearchCriteria;
    onReloadData: () => void;
}

export const TestFilter: React.FC<TestFilterProps> = ({
    onChange,
    filterParams,
    onReloadData
}) => {
    const userInfo = useSelector((state: RootState) => state.userInfo);
    const [open, setOpen] = React.useState(false); // Open modal filter
    const [title, setTitle] = React.useState<string>("");
    const handleChange = React.useCallback(($event: any) => {
        let value = $event.target.value;
        const MAX_LENGTH = 50;
        if (value.length > MAX_LENGTH) {
            return;
        }
        setTitle(value);
        const newParams = { ...filterParams, searchText: value, page: 1 };
        onSearchKeyword(newParams);
    }, [filterParams]);

    const onSearchKeyword = React.useMemo(() => {
        return debouce(onChange, 600);
    }, [onChange]);

    React.useEffect(() => {
        return () => {
            onSearchKeyword.cancel();
        }
    }, [onSearchKeyword]);

    React.useEffect(() => {
        if (filterParams) {
            setTitle(filterParams.searchText ?? '');
        }
    }, [filterParams]);
    const onChangeData = React.useCallback((values: any) => {
        onChange(values);
    }, [onChange]);

    const onClearValue = React.useCallback(() => {
        setTitle('');
        const newParams = { ...filterParams, searchText: '', page: 1 };
        onSearchKeyword(newParams);
    }, [setTitle, onSearchKeyword, filterParams]);


    return (
        <div className="flex items-center justify-between">
            <div className="w-[40%] flex space-x-2 items-center">
                <div className="w-[85%]">
                    <HRInput
                        placeholder="Tìm kiếm"
                        maxLength={50}
                        hasClearIcon={true}
                        onChange={handleChange}
                        onClearValue={onClearValue}
                        value={title}
                        prefix={<ICSearch />}
                    />
                </div>
                <IconButton btnSize="md" btnType="sub" icon={<ICFilter />}
                    onClick={() => setOpen(!open)} />
            </div>
            <div className="w-1/3">
                <HRSelect
                    name="test_type"
                    value={filterParams.type ?? undefined}
                    placeholder="Loại đề kiểm tra"
                    className="w-full relative"
                    onChange={(value) => onChangeData({ type: value })}
                    additionalOptions={
                        userInfo.role === UserRoles.USER ?
                            [MY_COMPANY_TESTS]
                            : undefined
                    }
                />
            </div>
            {open && <FilterModal open={open} setOpen={setOpen} filterParams={filterParams} onReloadData={onReloadData} onFilter={onChangeData} />}
        </div>
    )
}
