import { Modal } from "antd"
import React from "react"
import { HRTooltip } from "../../../../../components/tooltip";
import { ICDelete, ICDraggable, ICHasFee, ICSmallClock } from "../../../../../icons";
import { HRButton } from "../../../../../components/button";
import { TReviewQuestion } from "../../type";
import { HRPrice } from "../../../../../components/price";
import { TPrice } from "../../../../library/models";

type ReviewQuestionRowProps = {
    question: TReviewQuestion,
    index: number,
    onDelete: (index: number) => void
}
export const ReviewQuestionRow: React.FC<ReviewQuestionRowProps> = ({
    question,
    index,
    onDelete,
}) => {
    const questionTimer = () => {
        if (question.type === 'additional_question') {
            return question.data.duration;
        }
        const sumQuestionDuration = Array.isArray(question.data.configuration?.questionDuration) ?
            question.data.configuration?.questionDuration.reduce((a, b) => a + b, 0) :
            question.data.duration || question.data.configuration?.questionDuration;
        return sumQuestionDuration;
    };

    const [showDeleteModal, setShowDeleteModal] = React.useState(false);

    return <div className="group py-[8px] px-[16px] border-[1px] border-transparent hover:border-purple rounded-[8px]">
        <div className="flex items-center justify-between">
            <div className="grid grid-cols-[auto_1fr] gap-[8px] items-center w-full">
                <span className="relative p-1 cursor-pointer opacity-0 group-hover:opacity-100">
                    <HRTooltip content='Sắp xếp câu hỏi'
                        placement={'top'}
                        childNode={<><ICDraggable fill="#7357FF" /></>}>
                    </HRTooltip>
                </span>
                <div className="grid grid-cols-[35px_1fr_190px_200px] gap-[8px] justify-start items-center w-full">
                    <span>{index + 1}</span>
                    <span className="">
                        <HRTooltip
                            content={question.data.title || ''}
                            childNode={(
                                <div className="flex gap-[8px] items-center">
                                    {question?.data?.price && <ICHasFee />}
                                    <span className="line-clamp-1 w-full text-[16px]">
                                        {question.data.title}
                                    </span>
                                </div>
                            )}
                        >
                        </HRTooltip>
                    </span>
                    <span className="text-base font-bold pr-[60px]">
                        <span className="grid grid-cols-[auto_1fr] gap-[3px] items-center text-[12px] font-medium"><ICSmallClock />
                            {questionTimer()} phút
                        </span>
                    </span>
                    <span>
                        <HRPrice data={question?.data?.price as TPrice} />
                    </span>
                </div>
            </div>
            <div className="flex space-x-2">
                <span className="p-1 cursor-pointer opacity-0 group-hover:opacity-100" onClick={() => setShowDeleteModal(true)}>
                    <ICDelete stroke="#A6A8B4" />
                </span>
            </div>
        </div>

        <Modal
            centered
            onCancel={() => setShowDeleteModal(false)}
            open={showDeleteModal}
            title={
                <div className="font-bold text-[18px]">Xóa đề kiểm tra</div>
            }
            footer={
                <div className="grid grid-cols-[auto_auto] justify-end gap-[16px]">
                    <HRButton
                        btnType="sub"
                        btnSize="sm"
                        onClick={() => setShowDeleteModal(false)}>
                        Hủy bỏ
                    </HRButton>
                    <HRButton
                        btnSize="sm"
                        onClick={() => {
                            onDelete(index)
                            setShowDeleteModal(false)
                        }}>
                        Xóa
                    </HRButton>
                </div>
            }
        >
            <p>Bạn có chắc muốn xóa đề kiểm tra <strong>{question?.data?.title}</strong></p>
        </Modal >
    </div >
}
